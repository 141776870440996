import { BalanceProps } from "../types";
import { getTokenImgFromDenom } from "@persistenceone/pstake-ui-components";
import { BaseAsset, utilityAsset } from "../config";

export const persistenceInitialBalance = (assetList: BaseAsset[]) => {
  let list: BalanceProps[] = [];
  assetList.forEach((asset) => {
    const getTokenImage = getTokenImgFromDenom(asset.identifier);
    list.push({
      name: asset.name,
      label: asset.identifier.startsWith("ibc/")
        ? `${asset.name}(IBC)`
        : asset.name,
      amount: "0",
      imgUrl: getTokenImage.tokenImg,
      chain: asset.chain,
      network: asset.chain,
      dollorValue: 0,
      coinMinimalDenom: getTokenImage.minimalDenom,
      decimals: asset.coinDecimals
    });
  });
  const getTokenImage = getTokenImgFromDenom(utilityAsset.identifier);
  list.push({
    name: utilityAsset.name,
    label: utilityAsset.identifier.startsWith("ibc/")
      ? `${utilityAsset.name}(IBC)`
      : utilityAsset.name,
    amount: "0",
    imgUrl: getTokenImage.tokenImg,
    chain: utilityAsset.chain,
    network: utilityAsset.chain,
    dollorValue: 0,
    coinMinimalDenom: getTokenImage.minimalDenom,
    decimals: utilityAsset.coinDecimals
  });
  return list;
};

export const externalInitialCosmosBalances = (
  chains,
  assetList: BaseAsset[]
) => {
  let list: BalanceProps[] = [];
  const externalAssets = assetList.filter(
    (item) => item.network === "cosmos" && item.chain !== "persistence"
  );
  chains.forEach((chain) => {
    if (chain.bech32Config.bech32PrefixAccAddr !== "persistence") {
      const asset = externalAssets.find(
        (item) =>
          item.name.toLowerCase() ===
          chain.stakeCurrency.coinDenom.toLowerCase()
      );
      const getTokenImage = getTokenImgFromDenom(asset.identifier);
      list.push({
        name: asset.name,
        label: asset.name,
        amount: "0",
        imgUrl: getTokenImage.tokenImg,
        chain: asset.chain,
        network: asset.chain,
        dollorValue: 0,
        coinMinimalDenom: getTokenImage.minimalDenom,
        decimals: asset.coinDecimals
      });
    }
  });
  return list;
};

export const bnbInitialBalances = (): BalanceProps[] => {
  return [
    {
      name: "BNB",
      amount: "0",
      label: "BNB",
      imgUrl: "/tokens/bnb.svg",
      chain: "BNB Smart Chain",
      network: "BNB Smart Chain",
      dollorValue: 0,
      coinMinimalDenom: "bnb",
      decimals: 18
    },
    {
      name: "stkBNB",
      amount: "0",
      label: "stkBNB",
      imgUrl: "/tokens/bnb.svg",
      chain: "BNB Smart Chain",
      network: "BNB Smart Chain",
      dollorValue: 0,
      coinMinimalDenom: "bnb",
      decimals: 18
    }
  ];
};

export const ethInitialBalances = (): BalanceProps[] => {
  return [
    {
      name: "ETH",
      amount: "0",
      label: "ETH",
      imgUrl: "/tokens/eth.svg",
      chain: "ethereum",
      network: "ethereum",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    },
    {
      name: "stkETH",
      amount: "0",
      label: "stkETH",
      imgUrl: "/tokens/stk_eth.svg",
      chain: "ethereum",
      network: "ethereum",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    }
  ];
};

export const optimismInitialBalances = (): BalanceProps[] => {
  return [
    {
      name: "ETH",
      label: "ETH",
      amount: "0",
      imgUrl: "/tokens/eth_optimism.svg",
      chain: "optimism",
      network: "optimism",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    },
    {
      name: "stkETH",
      label: "stkETH",
      amount: "0",
      imgUrl: "/tokens/stketh_optimism.svg",
      chain: "optimism",
      network: "optimism",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    }
  ];
};

export const arbitrumInitialBalances = (): BalanceProps[] => {
  return [
    {
      name: "ETH",
      label: "ETH",
      amount: "0",
      imgUrl: "/logos/arbitrum.svg",
      chain: "arbitrum",
      network: "arbitrum",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    },
    {
      name: "stkETH",
      label: "stkETH",
      amount: "0",
      imgUrl: "/tokens/stketh_arbitrum.svg",
      chain: "arbitrum",
      network: "arbitrum",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    }
  ];
};
