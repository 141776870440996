import { BaseAsset, BaseAssets, StkAssets } from "./types";
import {
  ChainExternalInfo,
  IBCChainTokens
} from "../../containers/cosmos/helpers/config";
import { getChainSpecificAssets } from "@persistenceone/pstake-ui-components";

export const utilityAsset: BaseAsset = {
  name: "XPRT",
  identifier: "uxprt",
  coinDecimals: 6,
  ibcAsset: false,
  network: "cosmos",
  chain: "persistence",
  prefix: "persistence",
  networkLabel: "Cosmos",
  imgUrl: "/tokens/xprt.svg",
  pair: "stkXPRT"
};

export const baseAssetList: BaseAssets = {
  Testnet: [
    {
      name: "XPRT",
      identifier: "uxprt",
      coinDecimals: 6,
      ibcAsset: false,
      network: "cosmos",
      networkLabel: "Cosmos",
      chain: "persistence",
      prefix: "persistence",
      crossChainNetworks: [],
      imgUrl: "/tokens/xprt.svg",
      pair: "stkXPRT"
    },
    {
      name: "ATOM",
      identifier: IBCChainTokens["Testnet"].atom.denom,
      coinDecimals: 6,
      ibcAsset: true,
      networkLabel: "Cosmos",
      prefix: "cosmos",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      imgUrl: "/tokens/atom.svg",
      chain: "persistence",
      crossChainNetworks: [],
      network: "cosmos",
      pair: "stkATOM"
    },
    {
      name: "ATOM",
      identifier: "uatom",
      coinDecimals: 6,
      ibcAsset: false,
      networkLabel: "Cosmos",
      prefix: "cosmos",
      chainID: ChainExternalInfo["Testnet"].cosmos.chainID,
      imgUrl: "/tokens/atom.svg",
      chain: "cosmos",
      crossChainNetworks: [],
      network: "cosmos",
      pair: "stkATOM"
    },
    {
      name: "OSMO",
      identifier: IBCChainTokens["Testnet"].osmo.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "osmo",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      imgUrl: "/tokens/osmo.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      crossChainNetworks: [],
      pair: "stkOSMO"
    },
    {
      name: "OSMO",
      identifier: "uosmo",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "osmo",
      chainID: ChainExternalInfo["Testnet"].osmosis.chainID,
      imgUrl: "/tokens/osmo.svg",
      chain: "osmosis",
      network: "cosmos",
      networkLabel: "Cosmos",
      crossChainNetworks: [],
      pair: "stkOSMO"
    },
    {
      name: "DV4TNT",
      identifier: IBCChainTokens["Testnet"].dydx.denom,
      coinDecimals: 18,
      ibcAsset: true,
      prefix: "dydx",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      imgUrl: "/tokens/dydx.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      crossChainNetworks: [],
      pair: "stkDV4TNT"
    },
    {
      name: "DV4TNT",
      identifier: "adv4tnt",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "dydx",
      chainID: ChainExternalInfo["Testnet"].dydx.chainID,
      imgUrl: "/tokens/dydx.svg",
      chain: "Dydx",
      network: "cosmos",
      networkLabel: "Cosmos",
      crossChainNetworks: [],
      pair: "stkDV4TNT"
    },
    {
      name: "STARS",
      identifier: "ustars",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "stars",
      chainID: ChainExternalInfo["Testnet"].stars.chainID,
      imgUrl: "/tokens/stars.svg",
      chain: "Stargaze",
      networkLabel: "Cosmos",
      network: "cosmos",
      crossChainNetworks: [],
      pair: "stkSTARS"
    },
    {
      name: "STARS",
      identifier: IBCChainTokens["Testnet"].stars.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "stars",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      imgUrl: "/tokens/stars.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      crossChainNetworks: [],
      pair: "stkSTARS"
    },
    {
      name: "ETH",
      identifier: "eth",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "",
      chainID: "5",
      networkLabel: "Ethereum",
      imgUrl: "/tokens/eth.svg",
      chain: "ethereum",
      network: "ethereum",
      crossChainNetworks: ["Arbitrum"],
      pair: "stkETH"
    },
    {
      name: "BNB",
      identifier: "bnb",
      coinDecimals: 18,
      network: "BNB Smart Chain",
      ibcAsset: false,
      chainID: "97",
      prefix: "",
      networkLabel: "BNB Smart Chain",
      crossChainNetworks: [],
      chain: "BNB Smart Chain",
      imgUrl: "/tokens/bnb.svg",
      pair: "stkBNB"
    },
    {
      name: "ETH",
      identifier: "eth",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "",
      networkLabel: "Optimism",
      chainID: "420",
      imgUrl: "/tokens/eth_optimism.svg",
      chain: "optimism",
      crossChainNetworks: [],
      network: "optimism",
      pair: "stkETH"
    },
    {
      name: "ETH",
      identifier: "eth",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "",
      chainID: "421613",
      networkLabel: "Arbitrum",
      crossChainNetworks: [],
      imgUrl: "/tokens/eth_arbitrum.svg",
      chain: "arbitrum",
      network: "arbitrum",
      pair: "stkETH"
    },
    {
      name: "BLD",
      identifier: "ubld",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "agoric",
      networkLabel: "Cosmos",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Testnet"].agoric.chainID,
      imgUrl: "/tokens/bld.svg",
      chain: "agoric",
      network: "cosmos",
      pair: "stkBLD"
    },
    {
      name: "BLD",
      identifier: IBCChainTokens["Testnet"].agoric.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "agoric",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Testnet"].agoric.chainID,
      imgUrl: "/tokens/bld.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      pair: "stkBLD"
    },
    {
      name: "HUAHUA",
      identifier: "uhuahua",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "chihuahua",
      networkLabel: "Cosmos",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Testnet"].chihuahua.chainID,
      imgUrl: "/tokens/huahua.svg",
      chain: "chihuahua",
      network: "cosmos",
      pair: "stkHUAHUA"
    },
    {
      name: "HUAHUA",
      identifier: IBCChainTokens["Testnet"].chihuahua.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "chihuahua",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Testnet"].chihuahua.chainID,
      imgUrl: "/tokens/huahua.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      pair: "stkHUAHUA"
    }
  ],
  Mainnet: [
    {
      name: "XPRT",
      identifier: "uxprt",
      coinDecimals: 6,
      ibcAsset: false,
      network: "cosmos",
      networkLabel: "Cosmos",
      chain: "persistence",
      prefix: "persistence",
      crossChainNetworks: [],
      imgUrl: "/tokens/xprt.svg",
      pair: "stkXPRT"
    },
    {
      name: "ATOM",
      identifier: IBCChainTokens["Mainnet"].atom.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "cosmos",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
      imgUrl: "/tokens/atom.svg",
      chain: "persistence",
      crossChainNetworks: [],
      network: "cosmos",
      pair: "stkATOM"
    },
    {
      name: "ATOM",
      identifier: "uatom",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "cosmos",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].cosmos.chainID,
      imgUrl: "/tokens/atom.svg",
      crossChainNetworks: [],
      chain: "cosmos",
      network: "cosmos",
      pair: "stkATOM"
    },
    {
      name: "OSMO",
      identifier: IBCChainTokens["Mainnet"].osmo.denom,
      coinDecimals: 6,
      ibcAsset: true,
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
      prefix: "osmo",
      imgUrl: "/tokens/osmo.svg",
      chain: "persistence",
      crossChainNetworks: [],
      network: "cosmos",
      pair: "stkOSMO"
    },
    {
      name: "OSMO",
      identifier: "uosmo",
      coinDecimals: 6,
      ibcAsset: false,
      networkLabel: "Cosmos",
      prefix: "osmo",
      chainID: ChainExternalInfo["Mainnet"].osmosis.chainID,
      imgUrl: "/tokens/osmo.svg",
      chain: "osmosis",
      crossChainNetworks: [],
      network: "cosmos",
      pair: "stkOSMO"
    },
    {
      name: "DYDX",
      identifier: IBCChainTokens["Mainnet"].dydx.denom,
      coinDecimals: 18,
      ibcAsset: true,
      prefix: "dydx",
      chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
      imgUrl: "/tokens/dydx.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      crossChainNetworks: [],
      pair: "stkDYDX"
    },
    {
      name: "STARS",
      identifier: "ustars",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "stars",
      chainID: ChainExternalInfo["Mainnet"].stars.chainID,
      imgUrl: "/tokens/stars.svg",
      chain: "Stargaze",
      networkLabel: "Cosmos",
      network: "cosmos",
      crossChainNetworks: [],
      pair: "stkSTARS"
    },
    {
      name: "STARS",
      identifier: IBCChainTokens["Mainnet"].stars.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "stars",
      chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
      imgUrl: "/tokens/stars.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      crossChainNetworks: [],
      pair: "stkSTARS"
    },
    {
      name: "DYDX",
      identifier: "adydx",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "dydx",
      chainID: ChainExternalInfo["Mainnet"].dydx.chainID,
      imgUrl: "/tokens/dydx.svg",
      chain: "Dydx",
      network: "cosmos",
      networkLabel: "Cosmos",
      crossChainNetworks: [],
      pair: "stkDYDX"
    },
    {
      name: "ETH",
      identifier: "eth",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "",
      chainID: "1",
      networkLabel: "Ethereum",
      imgUrl: "/tokens/eth.svg",
      chain: "ethereum",
      crossChainNetworks: [],
      network: "ethereum",
      pair: "stkETH"
    },
    {
      name: "BNB",
      identifier: "bnb",
      coinDecimals: 18,
      networkLabel: "BNB Smart Chain",
      network: "BNB Smart Chain",
      ibcAsset: false,
      chainID: "56",
      crossChainNetworks: [],
      prefix: "",
      chain: "BNB Smart Chain",
      imgUrl: "/tokens/bnb.svg",
      pair: "stkBNB"
    },
    {
      name: "ETH",
      identifier: "eth",
      coinDecimals: 18,
      ibcAsset: false,
      networkLabel: "Optimism",
      prefix: "",
      chainID: "10",
      imgUrl: "/tokens/eth_optimism.svg",
      chain: "optimism",
      crossChainNetworks: [],
      network: "optimism",
      pair: "stkETH"
    },
    {
      name: "ETH",
      identifier: "eth",
      coinDecimals: 18,
      ibcAsset: false,
      networkLabel: "Arbitrum",
      chainID: "42161",
      prefix: "",
      imgUrl: "/tokens/eth_arbitrum.svg",
      chain: "arbitrum",
      crossChainNetworks: [],
      network: "arbitrum",
      pair: "stkETH"
    },
    {
      name: "BLD",
      identifier: "ubld",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "agoric",
      networkLabel: "Cosmos",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Mainnet"].agoric.chainID,
      imgUrl: "/tokens/bld.svg",
      chain: "agoric",
      network: "cosmos",
      pair: "stkBLD"
    },
    {
      name: "BLD",
      identifier: IBCChainTokens["Mainnet"].agoric.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "agoric",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Mainnet"].agoric.chainID,
      imgUrl: "/tokens/bld.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      pair: "stkBLD"
    },
    {
      name: "HUAHUA",
      identifier: "uhuahua",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "chihuahua",
      networkLabel: "Cosmos",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Mainnet"].chihuahua.chainID,
      imgUrl: "/tokens/huahua.svg",
      chain: "chihuahua",
      network: "cosmos",
      pair: "stkHUAHUA"
    },
    {
      name: "HUAHUA",
      identifier: IBCChainTokens["Mainnet"].chihuahua.denom,
      coinDecimals: 6,
      ibcAsset: true,
      prefix: "chihuahua",
      crossChainNetworks: [],
      chainID: ChainExternalInfo["Mainnet"].chihuahua.chainID,
      imgUrl: "/tokens/huahua.svg",
      chain: "persistence",
      networkLabel: "Cosmos",
      network: "cosmos",
      pair: "stkHUAHUA"
    }
  ]
};

export const stkAssetList: StkAssets = {
  Testnet: [
    {
      name: "stkXPRT",
      identifier: "stk/uxprt",
      coinDecimals: 6,
      network: "cosmos",
      ibcAsset: false,
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      prefix: "persistence",
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_xprt.svg",
      pair: "XPRT"
    },
    {
      name: "stkATOM",
      identifier: "stk/uatom",
      coinDecimals: 6,
      network: "cosmos",
      ibcAsset: false,
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      prefix: "persistence",
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_atom.svg",
      pair: "ATOM"
    },
    {
      name: "stkOSMO",
      identifier: "stk/uosmo",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "persistence",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_osmo.svg",
      network: "cosmos",
      pair: "OSMO"
    },
    {
      name: "stkDV4TNT",
      identifier: "stk/adv4tnt",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "persistence",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_dydx.svg",
      network: "cosmos",
      pair: "DV4TNT"
    },
    {
      name: "stkSTARS",
      identifier: "stk/ustars",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "persistence",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].stars.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_stars.svg",
      network: "cosmos",
      pair: "STARS"
    },
    {
      name: "stkBNB",
      identifier: "stkBNB",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "",
      chainID: "97",
      chain: "BNB Smart Chain",
      networkLabel: "BNB Smart Chain",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_bnb.svg",
      network: "BNB Smart Chain",
      pair: "BNB"
    },
    {
      name: "stkETH",
      identifier: "stkETH",
      coinDecimals: 18,
      ibcAsset: false,
      chainID: "5",
      prefix: "",
      networkLabel: "Ethereum",
      chain: "ethereum",
      crossChainMintNetworks: ["Arbitrum"],
      imgUrl: "/tokens/stk_eth.svg",
      network: "ethereum",
      pair: "ETH"
    },
    {
      name: "stkETH",
      identifier: "stkETH",
      ibcAsset: false,
      chainID: "420",
      prefix: "",
      coinDecimals: 18,
      networkLabel: "Optimism",
      chain: "optimism",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stketh_optimism.svg",
      network: "optimism",
      pair: "ETH"
    },
    {
      name: "stkETH",
      identifier: "stkETH",
      chainID: "421613",
      ibcAsset: false,
      coinDecimals: 18,
      networkLabel: "Arbitrum",
      crossChainMintNetworks: [],
      chain: "arbitrum",
      prefix: "",
      imgUrl: "/tokens/stketh_arbitrum.svg",
      network: "arbitrum",
      pair: "ETH"
    },
    {
      name: "stkBLD",
      identifier: "stk/ubld",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "agoric",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].agoric.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_bld.svg",
      network: "cosmos",
      pair: "BLD"
    },
    {
      name: "stkHUAHUA",
      identifier: "stk/uhuahua",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "chihuahua",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].chihuahua.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_huahua.svg",
      network: "cosmos",
      pair: "HUAHUA"
    }
  ],
  Mainnet: [
    {
      name: "stkXPRT",
      identifier: "stk/uxprt",
      coinDecimals: 6,
      network: "cosmos",
      ibcAsset: false,
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Testnet"].persistence.chainID,
      prefix: "persistence",
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_xprt.svg",
      pair: "XPRT"
    },
    {
      name: "stkATOM",
      identifier: "stk/uatom",
      coinDecimals: 6,
      network: "cosmos",
      ibcAsset: false,
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
      prefix: "persistence",
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_atom.svg",
      pair: "ATOM"
    },
    {
      name: "stkOSMO",
      identifier: "stk/uosmo",
      coinDecimals: 6,
      chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
      ibcAsset: false,
      prefix: "persistence",
      crossChainMintNetworks: [],
      networkLabel: "Cosmos",
      chain: "persistence",
      imgUrl: "/tokens/stk_osmo.svg",
      network: "cosmos",
      pair: "OSMO"
    },
    {
      name: "stkDYDX",
      identifier: "stk/adydx",
      coinDecimals: 18,
      ibcAsset: false,
      prefix: "persistence",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_dydx.svg",
      network: "cosmos",
      pair: "DYDX"
    },
    {
      name: "stkSTARS",
      identifier: "stk/ustars",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "persistence",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].stars.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_stars.svg",
      network: "cosmos",
      pair: "STARS"
    },
    {
      name: "stkBNB",
      identifier: "stkBNB",
      chainID: "56",
      coinDecimals: 18,
      ibcAsset: false,
      networkLabel: "BNB Smart Chain",
      crossChainMintNetworks: [],
      prefix: "",
      chain: "BNB Smart Chain",
      imgUrl: "/tokens/stk_bnb.svg",
      network: "BNB Smart Chain",
      pair: "BNB"
    },
    {
      name: "stkETH",
      identifier: "stkETH",
      coinDecimals: 18,
      chainID: "1",
      ibcAsset: false,
      prefix: "",
      chain: "ethereum",
      networkLabel: "Ethereum",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_eth.svg",
      network: "ethereum",
      pair: "ETH"
    },
    {
      name: "stkETH",
      identifier: "stkETH",
      ibcAsset: false,
      prefix: "",
      chainID: "10",
      crossChainMintNetworks: [],
      coinDecimals: 18,
      chain: "optimism",
      networkLabel: "Optimism",
      imgUrl: "/tokens/stketh_optimism.svg",
      network: "optimism",
      pair: "ETH"
    },
    {
      name: "stkETH",
      identifier: "stkETH",
      ibcAsset: false,
      coinDecimals: 18,
      chainID: "42161",
      networkLabel: "Arbitrum",
      chain: "arbitrum",
      crossChainMintNetworks: [],
      prefix: "",
      imgUrl: "/tokens/stketh_arbitrum.svg",
      network: "arbitrum",
      pair: "ETH"
    },
    {
      name: "stkBLD",
      identifier: "stk/ubld",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "agoric",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].agoric.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_bld.svg",
      network: "cosmos",
      pair: "BLD"
    },
    {
      name: "stkHUAHUA",
      identifier: "stk/uhuahua",
      coinDecimals: 6,
      ibcAsset: false,
      prefix: "chihuahua",
      networkLabel: "Cosmos",
      chainID: ChainExternalInfo["Mainnet"].chihuahua.chainID,
      chain: "persistence",
      crossChainMintNetworks: [],
      imgUrl: "/tokens/stk_huahua.svg",
      network: "cosmos",
      pair: "HUAHUA"
    }
  ]
};

export const ethereumAssetList = [
  {
    name: "USDT",
    identifier: "usdt",
    coinDecimals: 6,
    network: "",
    ibcAsset: false,
    chainID: "",
    prefix: "",
    chain: "",
    imgUrl: "/tokens/usdt.svg",
    pair: "stkETH"
  },
  {
    name: "USDC",
    identifier: "usdc",
    coinDecimals: 6,
    network: "",
    ibcAsset: false,
    chainID: "",
    prefix: "",
    chain: "",
    imgUrl: "/tokens/usdc.svg",
    pair: "stkETH"
  },
  {
    name: "DAI",
    identifier: "dai",
    coinDecimals: 18,
    network: "",
    ibcAsset: false,
    chainID: "",
    prefix: "",
    chain: "",
    imgUrl: "/tokens/dai.svg",
    pair: "stkETH"
  },
  {
    name: "ETH",
    identifier: "eth",
    coinDecimals: 18,
    ibcAsset: false,
    prefix: "",
    chainID: "5",
    imgUrl: "/tokens/eth.svg",
    chain: "",
    network: "",
    pair: "stkETH"
  },
  {
    name: "stkETH",
    identifier: "stkETH",
    coinDecimals: 18,
    ibcAsset: false,
    prefix: "",
    chainID: "5",
    imgUrl: "/tokens/stk_eth.svg",
    chain: "",
    network: "",
    pair: "stkETH"
  },
  {
    name: "WETH",
    identifier: "weth",
    coinDecimals: 18,
    ibcAsset: false,
    prefix: "",
    chainID: "5",
    imgUrl: "/tokens/weth.svg",
    chain: "",
    network: "",
    pair: "stkETH"
  }
];

export const baseAssetList2 = {
  Testnet: {
    ethereum: getChainSpecificAssets(ethereumAssetList, "ethereum", "5"),
    optimism: getChainSpecificAssets(ethereumAssetList, "optimism", "420"),
    arbitrum: getChainSpecificAssets(ethereumAssetList, "arbitrum", "421613"),
    "bnb smart chain": [
      {
        name: "BNB",
        identifier: "bnb",
        coinDecimals: 18,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "97",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/bnb.svg",
        pair: "stkBNB"
      },
      {
        name: "WBNB",
        identifier: "wbnb",
        coinDecimals: 18,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "97",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/bnb.svg",
        pair: "stkBNB"
      },
      {
        name: "USDT",
        identifier: "usdt",
        coinDecimals: 6,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "97",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/usdt.svg",
        pair: "stkBNB"
      },
      {
        name: "USDC",
        identifier: "usdc",
        coinDecimals: 6,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "97",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/usdc.svg",
        pair: "stkBNB"
      },
      {
        name: "DAI",
        identifier: "dai",
        coinDecimals: 18,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "97",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/dai.svg",
        pair: "stkBNB"
      },
      {
        name: "stkBNB",
        identifier: "stkBNB",
        coinDecimals: 18,
        ibcAsset: false,
        prefix: "",
        chainID: "97",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/stk_bnb.svg",
        network: "BNB Smart Chain",
        pair: "stkBNB"
      }
    ],
    cosmos: [
      {
        name: "ATOM",
        identifier: IBCChainTokens["Testnet"].atom.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "cosmos",
        chainID: ChainExternalInfo["Testnet"].persistence.chainID,
        imgUrl: "/tokens/atom.svg",
        chain: "persistence",
        network: "cosmos",
        pair: "stkATOM"
      },
      {
        name: "ATOM",
        identifier: "uatom",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "cosmos",
        chainID: ChainExternalInfo["Testnet"].cosmos.chainID,
        imgUrl: "/tokens/atom.svg",
        chain: "cosmos",
        network: "cosmos",
        pair: "stkATOM"
      },
      {
        name: "OSMO",
        identifier: IBCChainTokens["Testnet"].osmo.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "osmo",
        chainID: ChainExternalInfo["Testnet"].persistence.chainID,
        imgUrl: "/tokens/osmo.svg",
        chain: "persistence",
        network: "cosmos",
        pair: "stkOSMO"
      },
      {
        name: "OSMO",
        identifier: "uosmo",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "osmo",
        chainID: ChainExternalInfo["Testnet"].osmosis.chainID,
        imgUrl: "/tokens/osmo.svg",
        chain: "osmosis",
        network: "cosmos",
        pair: "stkOSMO"
      },
      {
        name: "DV4TNT",
        identifier: IBCChainTokens["Testnet"].dydx.denom,
        coinDecimals: 18,
        ibcAsset: true,
        prefix: "Dydx",
        chainID: ChainExternalInfo["Testnet"].persistence.chainID,
        imgUrl: "/tokens/dydx.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        pair: "stkDV4TNT"
      },
      {
        name: "DV4TNT",
        identifier: "adv4tnt",
        coinDecimals: 18,
        ibcAsset: false,
        prefix: "dydx",
        chainID: ChainExternalInfo["Testnet"].dydx.chainID,
        imgUrl: "/tokens/dydx.svg",
        chain: "Dydx",
        network: "cosmos",
        networkLabel: "Cosmos",
        pair: "stkDV4TNT"
      },
      {
        name: "STARS",
        identifier: "ustars",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "stars",
        chainID: ChainExternalInfo["Testnet"].stars.chainID,
        imgUrl: "/tokens/stars.svg",
        chain: "Stargaze",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkSTARS"
      },
      {
        name: "STARS",
        identifier: IBCChainTokens["Testnet"].stars.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "stars",
        chainID: ChainExternalInfo["Testnet"].persistence.chainID,
        imgUrl: "/tokens/stars.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkSTARS"
      },
      {
        name: "BLD",
        identifier: "ubld",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "agoric",
        chainID: ChainExternalInfo["Testnet"].agoric.chainID,
        imgUrl: "/tokens/bld.svg",
        chain: "agoric",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkBLD"
      },
      {
        name: "BLD",
        identifier: IBCChainTokens["Testnet"].agoric.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "agoric",
        chainID: ChainExternalInfo["Testnet"].agoric.chainID,
        imgUrl: "/tokens/bld.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkBLD"
      },
      {
        name: "HUAHUA",
        identifier: "uhuahua",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "chihuahua",
        chainID: ChainExternalInfo["Testnet"].chihuahua.chainID,
        imgUrl: "/tokens/huahua.svg",
        chain: "chihuahua",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkHUAHUA"
      },
      {
        name: "HUAHUA",
        identifier: IBCChainTokens["Testnet"].chihuahua.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "chihuahua",
        chainID: ChainExternalInfo["Testnet"].chihuahua.chainID,
        imgUrl: "/tokens/huahua.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkHUAHUA"
      }
    ]
  },
  Mainnet: {
    ethereum: getChainSpecificAssets(ethereumAssetList, "ethereum", "1"),
    optimism: getChainSpecificAssets(ethereumAssetList, "optimism", "10"),
    arbitrum: getChainSpecificAssets(ethereumAssetList, "arbitrum", "42161"),
    "bnb smart chain": [
      {
        name: "BNB",
        identifier: "bnb",
        coinDecimals: 18,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "56",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/bnb.svg",
        pair: "stkBNB"
      },
      {
        name: "WBNB",
        identifier: "wbnb",
        coinDecimals: 18,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "56",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/bnb.svg",
        pair: "stkBNB"
      },
      {
        name: "USDT",
        identifier: "usdt",
        coinDecimals: 6,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "56",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/usdt.svg",
        pair: "stkBNB"
      },
      {
        name: "USDC",
        identifier: "usdc",
        coinDecimals: 6,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "56",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/usdc.svg",
        pair: "stkBNB"
      },
      {
        name: "DAI",
        identifier: "dai",
        coinDecimals: 18,
        network: "BNB Smart Chain",
        ibcAsset: false,
        chainID: "56",
        prefix: "",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/dai.svg",
        pair: "stkBNB"
      },
      {
        name: "stkBNB",
        identifier: "stkBNB",
        coinDecimals: 18,
        ibcAsset: false,
        prefix: "",
        chainID: "56",
        chain: "BNB Smart Chain",
        imgUrl: "/tokens/stk_bnb.svg",
        network: "BNB Smart Chain",
        pair: "stkBNB"
      }
    ],
    cosmos: [
      {
        name: "ATOM",
        identifier: IBCChainTokens["Mainnet"].atom.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "cosmos",
        chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
        imgUrl: "/tokens/atom.svg",
        chain: "persistence",
        network: "cosmos",
        pair: "stkATOM"
      },
      {
        name: "ATOM",
        identifier: "uatom",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "cosmos",
        chainID: ChainExternalInfo["Mainnet"].cosmos.chainID,
        imgUrl: "/tokens/atom.svg",
        chain: "cosmos",
        network: "cosmos",
        pair: "stkATOM"
      },
      {
        name: "OSMO",
        identifier: IBCChainTokens["Mainnet"].osmo.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "osmo",
        chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
        imgUrl: "/tokens/osmo.svg",
        chain: "persistence",
        network: "cosmos",
        pair: "stkOSMO"
      },
      {
        name: "OSMO",
        identifier: "uosmo",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "osmo",
        chainID: ChainExternalInfo["Mainnet"].osmosis.chainID,
        imgUrl: "/tokens/osmo.svg",
        chain: "osmosis",
        network: "cosmos",
        pair: "stkOSMO"
      },
      {
        name: "DYDX",
        identifier: IBCChainTokens["Mainnet"].dydx.denom,
        coinDecimals: 18,
        ibcAsset: true,
        prefix: "Dydx",
        chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
        imgUrl: "/tokens/dydx.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        pair: "stkDYDX"
      },
      {
        name: "DYDX",
        identifier: "adydx",
        coinDecimals: 18,
        ibcAsset: false,
        prefix: "dydx",
        chainID: ChainExternalInfo["Mainnet"].dydx.chainID,
        imgUrl: "/tokens/dydx.svg",
        chain: "Dydx",
        network: "cosmos",
        networkLabel: "Cosmos",
        pair: "stkDYDX"
      },
      {
        name: "STARS",
        identifier: "ustars",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "stars",
        chainID: ChainExternalInfo["Mainnet"].stars.chainID,
        imgUrl: "/tokens/stars.svg",
        chain: "Stargaze",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkSTARS"
      },
      {
        name: "STARS",
        identifier: IBCChainTokens["Mainnet"].stars.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "stars",
        chainID: ChainExternalInfo["Mainnet"].persistence.chainID,
        imgUrl: "/tokens/stars.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkSTARS"
      },
      {
        name: "BLD",
        identifier: "ubld",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "agoric",
        chainID: ChainExternalInfo["Mainnet"].agoric.chainID,
        imgUrl: "/tokens/bld.svg",
        chain: "agoric",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkBLD"
      },
      {
        name: "BLD",
        identifier: IBCChainTokens["Mainnet"].agoric.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "agoric",
        chainID: ChainExternalInfo["Mainnet"].agoric.chainID,
        imgUrl: "/tokens/bld.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkBLD"
      },
      {
        name: "HUAHUA",
        identifier: "uhuahua",
        coinDecimals: 6,
        ibcAsset: false,
        prefix: "chihuahua",
        chainID: ChainExternalInfo["Mainnet"].chihuahua.chainID,
        imgUrl: "/tokens/huahua.svg",
        chain: "chihuahua",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkHUAHUA"
      },
      {
        name: "HUAHUA",
        identifier: IBCChainTokens["Mainnet"].chihuahua.denom,
        coinDecimals: 6,
        ibcAsset: true,
        prefix: "chihuahua",
        chainID: ChainExternalInfo["Mainnet"].chihuahua.chainID,
        imgUrl: "/tokens/huahua.svg",
        chain: "persistence",
        networkLabel: "Cosmos",
        network: "cosmos",
        crossChainNetworks: [],
        pair: "stkHUAHUA"
      }
    ]
  }
};
