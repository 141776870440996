import { StateCreator } from "zustand";
import { SliceActions, StakeTransactionState } from "./types";
import { baseAssetList, stkAssetList } from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let initialAsset = baseAssetList[env].find(
  (baseAsset) => baseAsset.name === "ATOM" && !baseAsset.ibcAsset
);

let initialStakeOutAsset = stkAssetList[env].find(
  (stkAsset) => stkAsset.pair === initialAsset.name && !stkAsset.ibcAsset
);

const initialState: StakeTransactionState = {
  stakeTransaction: {
    amount: "",
    stepNumber: 0,
    stakeInToken: initialAsset,
    stakeOutToken: initialStakeOutAsset,
    stakeOption: "wallet",
    liquidStakeType: "directStaking",
    validatorModal: false,
    delegationStakeAmount: "",
    delegatedValidators: {
      list: [],
      eligible: [],
      nonEligible: [],
      totalAmount: 0
    },
    selectedDelegatedValidator: {
      name: "",
      amount: "",
      identity: "",
      imgUrl: "",
      inputAmount: "",
      validatorAddress: "",
      status: "active"
    },
    stakeInTokenModal: false,
    stakeOutTokenModal: false,
    delegatedValidatorsLoader: false,
    tokenizedShares: {
      sharesOnSourceChain: {
        list: [],
        totalAmount: 0
      },
      sharesOnDestinationChain: {
        list: [],
        totalAmount: 0
      }
    },
    tokenizedModal: false,
    tokenizeSharesLoader: false,
    stakeTab: "stake"
  }
};

export type StakeTransactionSlice = StakeTransactionState & SliceActions;

export const createStakeTransactionSlice: StateCreator<
  StakeTransactionSlice
> = (set) => ({
  ...initialState,
  stakeTransactionActions: {
    setStakeAmount: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          amount: val
        }
      })),
    setStakeTxnStepNumber: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stepNumber: val
        }
      })),
    setLiquidStakeTxnType: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          liquidStakeType: val
        }
      })),
    setLiquidStakeOption: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeOption: val
        }
      })),
    setValidatorModal: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          validatorModal: val
        }
      })),
    setStakeInToken: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeInToken: val
        }
      })),
    setStakeInTokenModal: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeInTokenModal: val
        }
      })),
    setStakeOutToken: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeOutToken: val
        }
      })),
    setStakeOutTokenModal: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeOutTokenModal: val
        }
      })),
    setDelegatedValidators: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          delegatedValidators: val
        }
      })),
    setSelectedDelegatedValidator: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          selectedDelegatedValidator:
            val === null
              ? {
                  name: "",
                  amount: "",
                  identity: "",
                  imgUrl: "",
                  inputAmount: "",
                  validatorAddress: "",
                  status: "active"
                }
              : val
        }
      })),
    setDelegationsStakeAmount: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          delegationStakeAmount: val
        }
      })),
    setDelegatedValidatorsLoader: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          delegatedValidatorsLoader: val
        }
      })),
    setTokenizedShareModal: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          tokenizedModal: val
        }
      })),
    setTokenizeSharesLoader: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          tokenizeSharesLoader: val
        }
      })),
    setTokenizedShares: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          tokenizedShares: val
        }
      })),
    setStakeTab: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeTab: val
        }
      }))
  }
});
