import { StateCreator } from "zustand";
import { SliceActions, BalanceDataState } from "./types";

const initialState: BalanceDataState = {
  balanceData: {
    bnbBalance: 0,
    stkBnbBalance: 0,
    // add exact tokens names present bep20TokenAddresses
    tokenBalances: []
  }
};

export type BalanceSlice = BalanceDataState & SliceActions;

export const createBalanceSlice: StateCreator<BalanceSlice> = (set) => ({
  ...initialState,
  balanceDataActions: {
    setBnbBalance: (val) =>
      set((state) => ({
        balanceData: {
          ...state.balanceData,
          bnbBalance: val
        }
      })),
    setStkBnbBalance: (val) =>
      set((state) => ({
        balanceData: {
          ...state.balanceData,
          stkBnbBalance: val
        }
      })),
    setAllBnbTokenBalance: (val) =>
      set((state) => ({
        balanceData: {
          ...state.balanceData,
          tokenBalances: val
        }
      }))
  }
});
