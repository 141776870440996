import { StateCreator } from "zustand";
import { SliceActions, StakeTransactionState } from "./types";
import { baseAssetList, stkAssetList } from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let initialAsset = baseAssetList[env].find(
  (baseAsset) => baseAsset.name === "BNB" && !baseAsset.ibcAsset
);

let initialStakeOutAsset = stkAssetList[env].find(
  (stkAsset) => stkAsset.pair === initialAsset.name && !stkAsset.ibcAsset
);

const initialState: StakeTransactionState = {
  stakeTransaction: {
    amount: "",
    stakeInToken: initialAsset,
    stakeOutToken: initialStakeOutAsset,
    stakeInTokenModal: false,
    stakeOutTokenModal: false
  }
};

export type StakeTransactionSlice = StakeTransactionState & SliceActions;

export const createStakeTransactionSlice: StateCreator<
  StakeTransactionSlice
> = (set) => ({
  ...initialState,
  stakeTransactionActions: {
    setStakeAmount: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          amount: val
        }
      })),
    setStakeInToken: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeInToken: val
        }
      })),
    setStakeInTokenModal: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeInTokenModal: val
        }
      })),
    setStakeOutToken: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeOutToken: val
        }
      })),
    setStakeOutTokenModal: (val) =>
      set((state) => ({
        stakeTransaction: {
          ...state.stakeTransaction,
          stakeOutTokenModal: val
        }
      }))
  }
});
