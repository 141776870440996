import { chains, contracts, Networks, wethContractAddress } from "./config";
import { Scope } from "@sentry/nextjs";
import { useEthereumStore } from "../store/store";
import {
  Staking,
  Staking__factory,
  StkEth,
  StkEth__factory
} from "../contracts/types";
import { BigNumberish, ethers, utils } from "ethers";
import moment from "moment";
import { sentryReport } from "./index";
import { AlchemyProvider, JsonRpcSigner } from "@ethersproject/providers";
import { Instances } from "@/containers/ethereum/store/slices/wallet-slice";
import { BalanceProps } from "@/utils";
import { getTokenImgFromDenom } from "@persistenceone/pstake-ui-components";
const ETH_SUB_GRAPH =
  "https://api.thegraph.com/subgraphs/name/rohitaudit/pstake";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const getInstance = (
  signer: JsonRpcSigner | AlchemyProvider
): Instances => {
  const network: Networks = useEthereumStore.getState().network.name!;
  let stkEthAddress: string = contracts[env][network]["stkETH"];
  let stakingContractAddress: string = contracts[env][network]["staking"];
  console.log("stkEthContract-", stkEthAddress, "in getInstance");
  console.log("ethIssuerContract-", stakingContractAddress, "in getInstance");
  const stkEthContract: StkEth = StkEth__factory.connect(stkEthAddress, signer);
  const stakingContract: Staking = Staking__factory.connect(
    stakingContractAddress,
    signer
  );
  return {
    stakingInstance: stakingContract,
    stkEthInstance: stkEthContract
  };
};

export const getEthBalance = async (): Promise<string | number> => {
  try {
    const signer = useEthereumStore.getState().walletSigner;
    const balance: BigNumberish = await signer?.getBalance()!;
    return utils.formatEther(balance!);
  } catch (e) {
    console.log(e, "-eth error in getEthBalance");

    return "0";
  }
};

export const getStkEthBalance = async () => {
  try {
    const stkEthInstance: StkEth =
      useEthereumStore.getState().instances?.stkEthInstance!;
    const address: string = useEthereumStore.getState().wallet.account!;
    const balance = await stkEthInstance.balanceOf(address);
    return utils.formatEther(balance!);
  } catch (e) {
    console.log(e, "error in getStkEthBalance");
    return "0";
  }
};

export const getWethBalance = async () => {
  try {
    const signer = useEthereumStore.getState().walletSigner;
    const network: Networks = useEthereumStore.getState().network.name!;
    let wEthAddress: string = wethContractAddress[env][network];
    const wEthContract: StkEth = StkEth__factory.connect(wEthAddress, signer);
    const address: string = useEthereumStore.getState().wallet.account!;
    const balance = await wEthContract.balanceOf(address);
    return utils.formatEther(balance!);
  } catch (e) {
    console.log(e, "error in getStkEthBalance");
    return "0";
  }
};

export const getContractStatus = async () => {
  try {
    const chain = chains[env]["ethereum"];
    const JsonRpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
    let stkEthAddress: string = contracts[env]["ethereum"]["stkETH"];
    const stkEthContract: StkEth = StkEth__factory.connect(
      stkEthAddress,
      JsonRpcProvider
    );
    const stkEth = await stkEthContract.paused();
    return stkEth;
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      ["ERROR_WHILE_INTERACT_CONTRACT"]: "ethereum"
    });
    sentryReport(e, customScope);
    return true;
  }
};

export const getAllowances = async () => {
  try {
    const network: Networks = useEthereumStore.getState().network.name!;
    const address: string = useEthereumStore.getState().wallet.account!;
    let stakingAddress: string = contracts[env][network]["staking"];
    const signer = useEthereumStore.getState().walletSigner;
    let wEthAddress: string = wethContractAddress[env][network];
    const wEthContract: StkEth = StkEth__factory.connect(wEthAddress, signer);

    console.log(stakingAddress, wEthAddress, "-eth getAllowances params");

    const allowance: BigNumberish = await wEthContract.allowance(
      address,
      stakingAddress
    );
    return allowance;
  } catch (e: any) {
    console.log(e, "error in getAllowances");
    return utils.parseEther("0");
  }
};

export const getExchangeRate = async () => {
  try {
    const network: Networks = useEthereumStore.getState().network.name!;
    const stkETHContractAddress = contracts[env][network]["stkETH"];

    const chain = chains[env][network];
    console.log(
      network,
      stkETHContractAddress,
      chain,
      "-eth getExchangeRate params"
    );

    const JsonRpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
    const stkEthContract: StkEth = StkEth__factory.connect(
      stkETHContractAddress,
      JsonRpcProvider
    );
    const balance = await stkEthContract.pricePerShare();
    return utils.formatEther(balance!);
  } catch (e) {
    console.log(e, "-eth error in getExchangeRate");
    return "0";
  }
};

export const getMessenger = async () => {
  try {
    const network: Networks = useEthereumStore.getState().network.name!;
    const stakingContractAddress = contracts[env][network]["staking"];

    const chain = chains[env][network];

    const JsonRpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
    const stakingContract: Staking = Staking__factory.connect(
      stakingContractAddress,
      JsonRpcProvider
    );
    return await stakingContract.messengers(4);
  } catch (e) {
    console.log(e, "-eth error in getMessenger");
    return { messenger: "" };
  }
};

export const getExchangeRateList = async (range: number) => {
  try {
    const currentUnix = moment().unix();
    const rangeUnix = moment().subtract(range, "days").unix();
    const query = `{
               cValueChanges(where: {chainId: "1", timestamp_gt: "${rangeUnix.toString()}", timestamp_lt: "${currentUnix.toString()}"}, orderBy: timestamp, orderDirection: asc) {
                  timestamp
                  cValue
                }
             }`;
    const res = await fetch(ETH_SUB_GRAPH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: query
      })
    });
    const responseJson = await res.json();
    if (
      responseJson &&
      responseJson.data &&
      responseJson.data.cValueChanges.length > 0
    ) {
      const listUpdated = responseJson.data.cValueChanges.map(
        (element: any) => [
          Number(element.timestamp) * 1000,
          Number(Number(ethers.utils.formatEther(element.cValue)).toFixed(6))
        ]
      );
      return listUpdated;
    }
    return [];
  } catch (e) {
    console.log(e, "-eth error in getExchangeRate List");
    return [];
  }
};

export const getApr = async () => {
  try {
    const oldestCvalueQuery = `{
        cValueChanges(
          where: {chainId: "1"}
          orderBy: timestamp
          orderDirection: asc
          first: 1
        ) {
          cValue
          timestamp
        }
      }`;

    const newCvalueQuery = `{
        cValueChanges(
          where: {chainId: "1"}
          orderBy: timestamp
          orderDirection: desc
          first: 1
        ) {
          cValue
          timestamp
        }
      }`;

    const oldestCvalueQueryResponse = await fetch(ETH_SUB_GRAPH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: oldestCvalueQuery
      })
    });
    const newCvalueQueryResponse = await fetch(ETH_SUB_GRAPH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: newCvalueQuery
      })
    });
    const oldestCvalueQueryJson = await oldestCvalueQueryResponse.json();
    const newCvalueQueryJson = await newCvalueQueryResponse.json();
    console.log(oldestCvalueQueryJson, "getApr response", newCvalueQueryJson);
    if (
      oldestCvalueQueryJson &&
      oldestCvalueQueryJson.data &&
      newCvalueQueryJson &&
      newCvalueQueryJson.data
    ) {
      const timeFactor =
        31556926 /
        (newCvalueQueryJson.data.cValueChanges[0].timestamp -
          oldestCvalueQueryJson.data.cValueChanges[0].timestamp);
      const APR =
        (timeFactor *
          (newCvalueQueryJson.data.cValueChanges[0].cValue -
            oldestCvalueQueryJson.data.cValueChanges[0].cValue) *
          100) /
        oldestCvalueQueryJson.data.cValueChanges[0].cValue;
      return APR && !isNaN(APR) ? APR.toFixed(2) : 0;
    }
    return 0;
  } catch (e) {
    console.log(e, "-eth error in getExchangeRate List");
    return 0;
  }
};

export const getTVU = async () => {
  try {
    const stakingContractAddress = contracts[env]["ethereum"]["staking"];
    const chain = chains[env]["ethereum"];
    const JsonRpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
    const stakingContract: Staking = Staking__factory.connect(
      stakingContractAddress,
      JsonRpcProvider
    );
    const balance = await stakingContract.ethStaked();
    return Number(utils.formatEther(balance!)).toLocaleString();
  } catch (e) {
    console.log(e, "eth error in getTVU");
    return "0";
  }
};

export const formBalanceItem = async (
  network,
  token,
  blc
): Promise<BalanceProps> => {
  const tokenInfo = getTokenImgFromDenom(`${token}/${network}`);
  return {
    name: tokenInfo.denom,
    label: tokenInfo.denom,
    amount: blc,
    imgUrl: tokenInfo.tokenImg,
    chain: network,
    network: network,
    dollorValue: 0,
    coinMinimalDenom: "eth",
    decimals: 18
  };
};

export const formBalanceList = async (network, blc) => {
  console.log(network, blc, "network, blc");
  let list = [];
  for (let key in blc) {
    const response = await formBalanceItem(network, key, blc[key]);
    list.push(response);
  }
  return list;
};
export const resetStore = () => {
  useEthereumStore.getState().resetWalletSlice();
  useEthereumStore.getState().resetBalanceSlice();
  useEthereumStore.getState().resetInitialDataSlice();
  useEthereumStore.getState().resetTxnSlice();
};
