import { Tendermint34Client } from "@cosmjs/tendermint-rpc";
import {
  createProtobufRpcClient,
  QueryClient,
  setupIbcExtension
} from "@cosmjs/stargate";
import { Decimal } from "@cosmjs/math";
import { Scope } from "@sentry/nextjs";
import * as Sentry from "@sentry/nextjs";
import { fetchAccountBalance, getTokenBalance } from "@/api/cosmos";
import { PollingConfig } from "./config";
import {
  ATOM_PRICE_URL,
  COSMOS_GAS_PRICE,
  DYDX_GAS_PRICE,
  OSMO_GAS_PRICE,
  OSMO_PRICE_URL,
  PERSISTENCE_GAS_PRICE,
  RANGE_ALL_TIME,
  RANGE_WEEK,
  TEST_NET
} from "../../../constants/static";
import { QueryDenomTraceResponse } from "cosmjs-types/ibc/applications/transfer/v1/query";
import { DstChainName } from "./types";
import moment from "moment";
import { QueryAllBalancesResponse } from "cosmjs-types/cosmos/bank/v1beta1/query";
import { getTokenImgFromDenom } from "@persistenceone/pstake-ui-components";
import { PLATFORM_MIN_BALANCE } from "@/constants/dynamic";
const encoding = require("@cosmjs/encoding");
const tendermint = require("cosmjs-types/ibc/lightclients/tendermint/v1/tendermint");

export async function RpcClient(rpc: string) {
  const tendermintClient = await Tendermint34Client.connect(rpc);
  const queryClient = new QueryClient(tendermintClient);
  return createProtobufRpcClient(queryClient);
}

export const decimalize = (valueString: string | number, decimals = 6) => {
  let truncate: number;
  if (typeof valueString === "string") {
    truncate = Number(valueString);
  } else {
    truncate = valueString;
  }
  const values = Decimal.fromAtomics(
    decimals === 18 ? valueString.toString() : Math.trunc(truncate!).toString(),
    decimals
  ).toString();
  if (Number(values) > PLATFORM_MIN_BALANCE) {
    return values;
  }
  return "0";
};

export const unDecimalize = (valueString: string | number, decimals = 6) => {
  return Decimal.fromUserInput(valueString.toString(), decimals).atomics;
};

export const genericErrorHandler = (e: any, scope = new Scope()) => {
  console.log(e);
  Sentry.captureException(e, scope);
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export async function pollAccountBalanceList(initialList, address, rpc) {
  await delay(PollingConfig.initialTxHashQueryDelay);
  for (let i = 0; i < PollingConfig.numberOfRetries; i++) {
    console.log(initialList, "initialList");
    try {
      const balances: QueryAllBalancesResponse = await fetchAccountBalance(
        address,
        rpc
      );
      console.log(balances, "balances-poll");
      if (
        balances &&
        balances.balances.length !==
          (initialList ? initialList.balances.length : 0)
      ) {
        return balances.balances;
      } else {
        throw Error("Balance unchanged");
      }
    } catch (error: any) {
      printConsole(
        "polling balance in " +
          PollingConfig.scheduledTxHashQueryDelay +
          ": " +
          i +
          "th time"
      );
      await delay(PollingConfig.scheduledTxHashQueryDelay);
    }
  }
  return [];
}

export async function pollAccountBalance(
  address: string,
  denom: string,
  rpc: string,
  availableAmount: string
) {
  console.log(
    denom,
    availableAmount,
    rpc,
    address,
    "pollAccountBalance-params"
  );
  const coinInfo = getTokenImgFromDenom(denom);
  let initialBalance;
  if (availableAmount) {
    initialBalance = availableAmount;
  } else {
    const balances: any = await fetchAccountBalance(address, rpc);
    initialBalance = getTokenBalance(balances, denom);
  }
  printConsole(initialBalance, "initialBalance");
  await delay(PollingConfig.initialTxHashQueryDelay);
  for (let i = 0; i < PollingConfig.numberOfRetries; i++) {
    try {
      const balances: any = await fetchAccountBalance(address, rpc);
      const fetchResult = getTokenBalance(balances, denom);
      console.log(fetchResult, "fetchResult", initialBalance);
      if (decimalize(fetchResult, coinInfo.decimals) !== initialBalance) {
        return fetchResult;
      } else {
        throw Error("Balance unchanged");
      }
    } catch (error: any) {
      printConsole(
        "polling balance in " +
          PollingConfig.scheduledTxHashQueryDelay +
          ": " +
          i +
          "th time"
      );
      await delay(PollingConfig.scheduledTxHashQueryDelay);
    }
  }
  throw new Error("failed all retries");
}

export const decodeTendermintClientStateAny = (clientState: any) => {
  if (
    (clientState === null || clientState === void 0
      ? void 0
      : clientState.typeUrl) !== "/ibc.lightclients.tendermint.v1.ClientState"
  ) {
    throw new Error(
      `Unexpected client state type: ${
        clientState === null || clientState === void 0
          ? void 0
          : clientState.typeUrl
      }`
    );
  }
  return tendermint.ClientState.decode(clientState.value);
};

// copied from node_modules/@cosmjs/stargate/build/queries/ibc.js
export const decodeTendermintConsensusStateAny = (consensusState: any) => {
  if (
    (consensusState === null || consensusState === void 0
      ? void 0
      : consensusState.typeUrl) !==
    "/ibc.lightclients.tendermint.v1.ConsensusState"
  ) {
    throw new Error(
      `Unexpected client state type: ${
        consensusState === null || consensusState === void 0
          ? void 0
          : consensusState.typeUrl
      }`
    );
  }
  return tendermint.ConsensusState.decode(consensusState.value);
};

export const printConsole = (message: any, helpText = "") => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === TEST_NET) {
    console.log(message, helpText);
  }
};

export const getGasPrice = (denom: string) => {
  switch (denom) {
    case "cosmos":
      return COSMOS_GAS_PRICE;
    case "persistence":
      return PERSISTENCE_GAS_PRICE;
    case "osmo":
      return OSMO_GAS_PRICE;
    case "adv4tnt":
      return DYDX_GAS_PRICE;
    case "adydx":
      return DYDX_GAS_PRICE;
    default:
      return "0" + denom;
  }
};

export const getIbcDenom = async (ibcDenom: string, rpc: string) => {
  try {
    const tendermintClient = await Tendermint34Client.connect(rpc);
    const queryClient = new QueryClient(tendermintClient);
    // fetch ibc denom trace
    let denomText = ibcDenom.substring(ibcDenom.indexOf("/") + 1);
    const ibcExtension = setupIbcExtension(queryClient);
    let denomTraceResponse: QueryDenomTraceResponse =
      await ibcExtension.ibc.transfer.denomTrace(denomText);
    return denomTraceResponse.denomTrace?.baseDenom;
  } catch (e) {
    return "";
  }
};

export const getTokenPriceUrl = (denom: DstChainName) => {
  switch (denom) {
    case "Cosmos":
      return ATOM_PRICE_URL;
    case "Osmosis":
      return OSMO_PRICE_URL;
    default:
      return ATOM_PRICE_URL;
  }
};

export function getRangeLimitTimestamp(range: number): number {
  if (range == RANGE_ALL_TIME) {
    return 0;
  }
  const currentTime = moment();
  return currentTime.subtract(range, "days").valueOf();
}

export const getPercentChange = (data: any[]) => {
  if (data.length > RANGE_WEEK) {
    const firstData = data[0];
    const lastData = data[data.length - 1];
    const percentChange = ((lastData[1] - firstData[1]) * 100) / firstData[1];
    return percentChange;
  }
  return "0";
};

export const isBech32Address = (address, prefix) => {
  try {
    let decodedAddress = encoding.fromBech32(address);
    return decodedAddress.prefix === prefix;
  } catch (e) {
    return false;
  }
};

export const getDefaultApy = (prefix) => {
  if (prefix === "cosmos") {
    return 14.1;
  } else if (prefix === "osmo") {
    return 9.9;
  } else if (prefix === "dydx") {
    return 14;
  }
  return "--";
};

export const getValidatorsUrl = (prefix) => {
  const testNetAppUrl =
    "https://pstake-website-git-update-persistence.vercel.app/"; // update based on preview link
  const mainNetAppUrl = "https://pstake.finance";
  const prefixUpdate =
    prefix === "cosmos"
      ? "atom"
      : prefix === "agoric"
      ? "bld"
      : prefix === "chihuahua"
      ? "huahua"
      : prefix === "persistence"
      ? "xprt"
      : prefix;
  if (
    process.env.NEXT_PUBLIC_DEPLOYMENT === "staging" ||
    process.env.NEXT_PUBLIC_DEPLOYMENT === "testnet"
  ) {
    return `${testNetAppUrl}/${prefixUpdate}/validators`;
  } else {
    return `${mainNetAppUrl}/${prefixUpdate}/validators`;
  }
};

export const getUnbondPeriod = (prefix) => {
  switch (prefix) {
    case "cosmos":
      return 1814400; //21days
    case "osmo":
      return 1209600; //14days
    case "dydx":
      return 2592000; //30days
    case "persistence":
      return 1814400; //21days
    case "chihuahua":
      return 1814400; //21days
    case "stars":
      return 1209600; //14days
    case "agoric":
      return 1814400; //21days
  }
};
