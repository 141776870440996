import moment from "moment";
import { bigNumberToEther } from "@/utils";
import { BigNumber } from "ethers";
import { JsonRpcSigner } from "@ethersproject/providers";
import { CLAIM, UN_STAKE } from "@/constants/static";
import { useAppStore } from "@/store/store";
import {
  fetchBalance,
  fetchClaimAccounts
} from "@/containers/bnb/store/sagas/fetching-sagas";
import { UnStaked, UnStaking } from "../slices/transaction-slices/claim/types";

export interface FilteredClaims {
  unstaking: UnStaking[];
  unstaked: UnStaked[];
  activeClaim: number;
  unstakingAmount: number;
}

export const filterClaimsIntoStakingAndUnstaked = (
  claims: Array<any>,
  coolDownPeriod: number
): FilteredClaims => {
  const filteredClaims: FilteredClaims = {
    unstaking: [],
    unstaked: [],
    activeClaim: 0,
    unstakingAmount: 0
  };
  let activeClaim = BigNumber.from("0");
  let unstakingAmount = BigNumber.from("0");
  const curDate = moment();
  if (claims) {
    claims.forEach((claim, index) => {
      const { weiToReturn, createdAt } = claim;
      const unstakedOn = moment(createdAt.toNumber() * 1000);
      const diff = curDate.diff(unstakedOn, "days", true);
      const amount = bigNumberToEther(weiToReturn);
      const formattedUnstakeDate = moment
        .utc(unstakedOn)
        .format("DD MMM YYYY hh:mm A UTC");
      console.log(
        diff,
        coolDownPeriod,
        claim,
        unstakedOn,
        amount,
        formattedUnstakeDate,
        "cgecad"
      );
      if (diff < coolDownPeriod) {
        const unstaknigInfo: UnStaking = {
          unstakedOn: formattedUnstakeDate,
          amount: amount,
          queryIndex: index,
          daysRemaining: coolDownPeriod - Math.floor(diff),
          imgUrl: "/tokens/stk_bnb.svg",
          tokenName: "stkBNB",
          status: "Unbonding"
        };
        filteredClaims.unstaking.push(unstaknigInfo);
        unstakingAmount = unstakingAmount.add(weiToReturn);
      } else {
        filteredClaims.unstaked.push({
          unstakedOn: formattedUnstakeDate,
          amount,
          daysRemaining: 0,
          imgUrl: "/tokens/stk_bnb.svg",
          tokenName: "stkBNB"
        });
        activeClaim = activeClaim.add(weiToReturn);
      }
    });
  }

  filteredClaims.activeClaim = Number(bigNumberToEther(activeClaim));
  filteredClaims.unstakingAmount = Number(bigNumberToEther(unstakingAmount));

  return filteredClaims;
};

export const actionsWhileTransactionIsInProgress = async (
  txn: any,
  account: string,
  signer: JsonRpcSigner,
  txnName?: string
) => {
  fetchBalance({
    accountAddress: account,
    signer: signer
  });
  if (txnName === CLAIM || txnName === UN_STAKE) {
    await fetchClaimAccounts({ address: account });
  }
};

export const failedTransactionActions = (message?: string) => {
  console.log(message, "test-tttt");
  useAppStore.getState().transactionActions.setTxnFailed(true);
  useAppStore.getState().transactionActions.resetTransactionProgress();
  useAppStore.getState().transactionActions.setTransactionStatus("failed");
  useAppStore.getState().transactionActions.setTxnFailedResponse(message);
};
