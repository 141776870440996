import { buildSocketTx, getSocketQuote } from "../ethereum/external-apis";
import { executeSquidQuote, getSquidQuote } from "./squid";
import { COSMOS, EVM } from "@/constants/static";

export const getSwapRoute = async (
  sourceChainId: number | string,
  destinationChainId: number | string,
  amount: string,
  userAddress: string,
  recipientAddress: string,
  fromToken: string,
  toToken: string,
  ecosystem: string
): Promise<any> => {
  console.log(
    sourceChainId,
    destinationChainId,
    amount,
    userAddress,
    recipientAddress,
    fromToken,
    toToken,
    ecosystem,
    "getSwapRoute params"
  );
  if (ecosystem === EVM) {
    return await getSocketQuote(
      sourceChainId,
      destinationChainId,
      amount,
      userAddress,
      fromToken,
      toToken
    );
  } else if (ecosystem === COSMOS) {
    return await getSquidQuote(
      sourceChainId,
      destinationChainId,
      amount,
      userAddress,
      recipientAddress,
      fromToken,
      toToken
    );
  } else {
    throw "Unsupported Ecosystem";
  }
};

export async function executeSwap(
  signer: any,
  route: any,
  ecosystem: string
): Promise<any> {
  if (ecosystem === EVM) {
    const txData: any = await buildSocketTx(route);
    console.log(txData, "txData executeTransferTransaction param");
    // execute the transaction data with signer
    const txn = await signer.sendTransaction({
      from: await signer.getAddress(),
      to: txData?.txTarget,
      data: txData?.txData,
      value: txData?.value,
    });
    return txn;
  } else if (ecosystem === COSMOS) {
    const txn = await executeSquidQuote(
      route,
      signer,
      route.params.cosmosSignerAddress,
      "rpc"
    );
    return txn;
  } else {
    throw "Unsupported Ecosystem";
  }
}
