"use client";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { createBalanceSlice, BalanceSlice } from "./slices/balance-slice";
import {
  createInitialSlice,
  InitialDataSlice
} from "./slices/initial-data-slice";
import {
  createStakeTransactionSlice,
  StakeTransactionSlice
} from "./slices/transaction-slices/stake";
import {
  createUnStakeTransactionSlice,
  UnStakeTransactionSlice
} from "./slices/transaction-slices/unstake";
import {
  createTokenTransferTransactionSlice,
  TokenTransferTransactionSlice
} from "./slices/transaction-slices/token-transfer";
import {
  createUnBondingInfoSlice,
  UnBondingInfoSlice
} from "./slices/unbondigs-slice";
import {
  createSwapTransactionSlice,
  SwapTransactionSlice
} from "./slices/transaction-slices/swap";
import { createWalletDataSlice, WalletSlice } from "./slices/wallet-slice";
import { createLiveDataSlice, LiveDataSlice } from "./slices/live-data";
import { mountStoreDevtool } from "simple-zustand-devtools";

type StoreState = BalanceSlice &
  InitialDataSlice &
  StakeTransactionSlice &
  UnStakeTransactionSlice &
  TokenTransferTransactionSlice &
  UnBondingInfoSlice &
  LiveDataSlice &
  WalletSlice &
  SwapTransactionSlice;

export const useCosmosStore = createWithEqualityFn<StoreState>()(
  (...a) => ({
    ...createBalanceSlice(...a),
    ...createInitialSlice(...a),
    ...createStakeTransactionSlice(...a),
    ...createUnStakeTransactionSlice(...a),
    ...createTokenTransferTransactionSlice(...a),
    ...createUnBondingInfoSlice(...a),
    ...createLiveDataSlice(...a),
    ...createWalletDataSlice(...a),
    ...createSwapTransactionSlice(...a)
  }),
  shallow
);

mountStoreDevtool("cosmosStore", useCosmosStore);
