import React, { useState } from "react";
import { Icon } from "@/components";
import { truncateToFixedDecimalPlaces } from "@persistenceone/pstake-ui-components";
import { useEthereumStore } from "../store/store";
import CustomImage from "@/components/molecules/image";

const BalanceList = () => {
  const [open, setOpen] = useState<any>({
    availableToStake: true,
    liquidStaked: false,
    unStaking: false
  });
  const balance = useEthereumStore((state) => state.balance);
  const network = useEthereumStore((state) => state.network.name);

  const handleCollapse = (value: string) => {
    for (const key in open) {
      if (key === value) {
        open[key] = !open[key];
      }
      setOpen({ ...open });
    }
  };

  return (
    <>
      <div>
        <p
          onClick={() => handleCollapse("availableToStake")}
          className={`flex items-center justify-between navLink moreListHeader cursor-pointer m-0 
                 ${
                   open["availableToStake"] ? "opened" : "closed"
                 } py-3 px-8 group`}
        >
          <span className="flex items-center">
            <CustomImage
              src={`/tokens/eth.svg`}
              width={22}
              className={"mr-2"}
              height={22}
              alt="atom"
            />
            <span className="text-white-200 text-sm flex items-center font-medium leading-normal">
              Available to Stake
            </span>
          </span>
          <Icon iconName="right-arrow" viewClass="side-bar-icon arrow" />
        </p>
        <div
          id="availableToStake"
          className={`${
            open["availableToStake"] ? "active" : ""
          } collapseMenu ease-in overflow-hidden relative px-6`}
        >
          <div className="pb-4 pt-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-white-600 text-sm leading-5 ml-2.5">
                  On <span className={"capitalize"}>{network}</span>
                </span>
              </div>
              <p className="text-white-600 text-sm font-medium leading-5">
                {truncateToFixedDecimalPlaces(balance.eth, 6)}&nbsp;ETH
              </p>
            </div>
          </div>
          <div className="pb-4 pt-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-white-600 text-sm leading-5 ml-2.5">
                  On <span className={"capitalize"}>{network}</span>
                </span>
              </div>
              <p className="text-white-600 text-sm font-medium leading-5">
                {truncateToFixedDecimalPlaces(balance.weth, 6)}&nbsp;WETH
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p
          onClick={() => handleCollapse("liquidStaked")}
          className={`flex items-center justify-between navLink moreListHeader cursor-pointer m-0 
                 ${open["liquidStaked"] ? "opened" : "closed"} py-3 px-8 group`}
        >
          <span className="flex items-center">
            <CustomImage
              src={`/tokens/stk_eth.svg`}
              width={22}
              className={"mr-2"}
              height={22}
              alt="atom"
            />
            <span
              className="text-white-200 flex items-center
             text-sm flex items-center font-medium leading-normal"
            >
              Liquid Staked
            </span>
          </span>
          <Icon iconName="right-arrow" viewClass="side-bar-icon arrow" />
        </p>
        <div
          id="liquidStaked"
          className={`${
            open["liquidStaked"] ? "active" : ""
          } collapseMenu overflow-hidden relative px-6`}
        >
          <div className="flex justify-between items-center pb-4 pt-2">
            <div className="flex items-center">
              <span className="text-white-600 text-sm leading-5 ml-2.5">
                On <span className={"capitalize"}>{network}</span>
              </span>
            </div>
            <p className="text-white-600 text-sm font-medium leading-5">
              {truncateToFixedDecimalPlaces(balance.stkETH, 6)}&nbsp;stkETH
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceList;
