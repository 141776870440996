import { displayToast } from "@/components";
import {
  ETH,
  ETH_REQUEST_ACCOUNTS,
  ETH_TOKEN_DECIMALS,
  META_MASK,
  METAMASK_ERROR,
  WALLET_ERROR
} from "../../../constants/static";
import { chains, ChainInfo, Networks } from "./config";
import { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import { ethers } from "ethers";
import { ExternalProvider, JsonRpcFetchFunc } from "@ethersproject/providers";
import { useEthereumStore } from "../store/store";
import { WalletInfo, WalletNames } from "../store/slices/wallet-slice";
import { getWalletProvider } from "./index";
import { ToastType } from "@/components/molecules/toast/types";
import * as Sentry from "@sentry/nextjs";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

// this function helps to switch connected network to supported network,
// if network not present in metamask, it will add network to metamask.
export const addNetwork = async (provider: any, chain: ChainInfo) => {
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chain.networkIdHex }] // Hexadecimal version of 80001, prefixed with 0x
    });
    return true;
  } catch (error: any) {
    Sentry.captureException(error?.message);
    if (error.code === 4902) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chain.networkIdHex, // Hexadecimal version of 80001, prefixed with 0x
              chainName: chain.networkName,
              nativeCurrency: {
                name: ETH,
                symbol: ETH,
                decimals: ETH_TOKEN_DECIMALS
              },
              rpcUrls: [chain.rpcUrl],
              blockExplorerUrls: [chain.explorerUrl],
              iconUrls: [""]
            }
          ]
        });
        return true;
      } catch (addError) {
        console.log(addError, "-eth error in addNetwork");
        // Sentry.captureException("Could not add the bsc network");
        return false;
      }
    }
    return false;
  }
};

export const registerToken = async (
  walletInfo: WalletInfo,
  tokenContractAddress: string
) => {
  const provider = getWalletProvider(walletInfo.walletName!);
  try {
    const response = await provider.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenContractAddress,
          symbol: "stkETH",
          decimals: 18,
          image: window.location.origin + "/tokens/stk_eth.svg"
        }
      }
    });
  } catch (error) {
    console.log(error, "-eth error in registerToken");
  }
};

export const handleMetamask = async (network?: Networks) => {
  const connectWallet = useEthereumStore.getState().connectWallet;
  const provider = window.ethereum!;
  const response = await handleSwitchNetwork(network);
  if (response) {
    await connectWallet(provider, META_MASK, network);
  }
};

export const handleSwitchNetwork = async (network?: Networks) => {
  if (!window.ethereum || !window.ethereum) {
    displayToast(
      {
        heading: METAMASK_ERROR,
        message: "Please install metamask"
      },
      ToastType.ERROR
    );
    return false;
  }
  const provider = window.ethereum!;
  const chainInfo: any =
    chains[env][
      network !== "ethereum" && network !== "optimism" && network !== "arbitrum"
        ? "ethereum"
        : network
    ]; // prevention from undefined error
  console.log(
    chainInfo,
    provider.chainId,
    network,
    provider,
    "-eth handleMetamask params"
  );
  if (provider.chainId !== chainInfo.networkIdHex) {
    const response = await addNetwork(provider, chainInfo);
    if (!response) {
      displayToast(
        {
          heading: WALLET_ERROR,
          message: "Error while Switching network"
        },
        ToastType.ERROR
      );
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const handleWalletConnection = async (
  contract: ExternalProvider | JsonRpcFetchFunc | any,
  wallet: WalletNames,
  network: Networks
): Promise<WalletInfo> => {
  try {
    let accounts;
    accounts = await contract.request({ method: ETH_REQUEST_ACCOUNTS });
    if (accounts && accounts.length !== 0) {
      const account = accounts[0];
      const provider: JsonRpcProvider = new ethers.providers.Web3Provider(
        contract
      );
      const signer: JsonRpcSigner = await provider.getSigner();
      // @ts-ignore
      await useEthereumStore.getState().handleWalletNetwork(network);
      await useEthereumStore.getState().handleWalletSigner(signer);
      await useEthereumStore.getState().fetchInstances(signer);
      localStorage.setItem("eth-address", JSON.stringify(account));
      localStorage.setItem("eth-network", JSON.stringify(network));
      return {
        account: account,
        walletConnection: true,
        walletName: wallet
      };
    } else {
      throw new Error("No authorized account found");
    }
  } catch (e: any) {
    displayToast(
      {
        heading: WALLET_ERROR,
        message: e.message!
      },
      ToastType.ERROR
    );
    console.error(e);
    return {
      account: "",
      walletConnection: false,
      walletName: null
    };
  }
};
