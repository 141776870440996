import {
  QueryAllBalancesResponse,
  QueryClientImpl as BankQuery,
  QuerySupplyOfResponse,
  QueryTotalSupplyResponse
} from "cosmjs-types/cosmos/bank/v1beta1/query";

import {
  QueryClientImpl as StakeQuery,
  QueryDelegatorDelegationsResponse,
  QueryDelegatorValidatorsResponse,
  QueryValidatorResponse
} from "cosmjs-types/cosmos/staking/v1beta1/query";

import {
  decimalize,
  genericErrorHandler,
  getUnbondPeriod,
  printConsole,
  RpcClient
} from "../../containers/cosmos/helpers/utils";

import { QueryClientImpl } from "persistenceonejs/pstake/lscosmos/v1beta1/query";

import { QueryClientImpl as LiquidStakeQueryClient } from "persistenceonejs/pstake/liquidstakeibc/v1beta1/query";

import { QueryClientImpl as NativeLiquidStakeQueryClient } from "persistenceonejs/pstake/liquidstake/v1beta1/query";

import { QueryClientImpl as EpochQueryClient } from "persistenceonejs/persistence/epochs/v1beta1/query";

import { Scope } from "@sentry/nextjs";
import { Coin } from "@cosmjs/proto-signing";
import moment from "moment";
import {
  COSMOS_UNBOND_TIME,
  DYDX_UNBOND_TIME,
  INSTANT_REDEEM_FEE,
  MIN_STAKE,
  OSMOSIS_UNBOND_TIME
} from "../../constants/dynamic";
import { StatusResponse, Tendermint34Client } from "@cosmjs/tendermint-rpc";
import { Validator } from "cosmjs-types/cosmos/staking/v1beta1/staking";
import {
  DelegatedValidator,
  DelegatedValidators
} from "../../containers/cosmos/store/slices/transaction-slices/stake/types";
import { QueryClient, setupIbcExtension } from "@cosmjs/stargate";
import { ChainInfo } from "@keplr-wallet/types";
import {
  cosmosSupportedChains,
  externalChains,
  persistenceChain
} from "../../helpers/utils";
import {
  ChainExternalInfo,
  dstChainsList,
  ExternalChains
} from "@/containers/cosmos/helpers/config";
import { DstChainItem } from "@/containers/cosmos/helpers/types";
import { PendingClaimList } from "@/containers/cosmos/store/slices/unbondigs-slice/types";
import { Cosmos } from "@/helpers/feature-flags";
import { TvuList } from "@/containers/cosmos/store/slices/live-data/types";
import { utilityAsset } from "@/utils";
import {
  ExchangeRateList,
  PstakeValidator
} from "@/containers/cosmos/store/slices/initial-data-slice";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const getTokenBalance = (
  balances: QueryAllBalancesResponse,
  tokenDenom: string
) => {
  if (balances && balances!.balances!.length) {
    const token: Coin | undefined = balances.balances.find(
      (item: Coin) => item.denom === tokenDenom
    );
    if (token === undefined) {
      return "0";
    } else {
      return token!.amount;
    }
  } else {
    return "0";
  }
};

export const fetchAccountBalance = async (
  address: string,
  rpc: string
): Promise<QueryAllBalancesResponse> => {
  try {
    const rpcClient = await RpcClient(rpc);
    const bankQueryService = new BankQuery(rpcClient);
    return await bankQueryService.AllBalances({
      address: address
    });
  } catch (error) {
    printConsole(error);
    return {
      balances: []
    };
  }
};

export const getTokenizedSharesFromBalance = async (
  balances: QueryAllBalancesResponse,
  address: string,
  rpc: string,
  prefix: string
): Promise<any[]> => {
  try {
    const tendermintClient = await Tendermint34Client.connect(rpc);
    const queryClient = new QueryClient(tendermintClient);
    if (balances && balances!.balances!.length) {
      let balancesList: Coin[] = [];
      for (let item of balances!.balances) {
        if (item.denom.includes("ibc/")) {
          const ibcExtension = setupIbcExtension(queryClient);
          let ibcDenomeResponse = await ibcExtension.ibc.transfer.denomTrace(
            item.denom
          );
          if (ibcDenomeResponse.denomTrace.baseDenom.includes(prefix)) {
            const balance = {
              denom: item.denom,
              baseDenom: ibcDenomeResponse.denomTrace.baseDenom,
              amount: item.amount
            };
            balancesList.push(balance);
          }
        }
      }
      return balancesList.length > 1
        ? balancesList.sort(
            (a, b) =>
              Number(a.denom.substring(a.denom.length - 1)) -
              Number(b.denom.substring(b.denom.length - 1))
          )
        : balancesList;
    }
    return [];
  } catch (error) {
    printConsole(error);
    return [];
  }
};

export const getExchangeRateFromRpc = async (
  rpc: string,
  chainId: string,
  prefix: string
): Promise<number> => {
  try {
    console.log(rpc, chainId, "chainIdchainId");
    if (prefix === utilityAsset.prefix) {
      const rpcClient = await RpcClient(persistenceChain.rpc);
      const pstakeQueryService = new NativeLiquidStakeQueryClient(rpcClient);
      const cvalue = await pstakeQueryService.States();
      console.log(
        cvalue,
        "-persistence cvalue in getExchangeRateFromRpc",
        Number(decimalize(cvalue.netAmountState.mintRate, 18)),
        Number(decimalize(decimalize(cvalue.netAmountState.mintRate, 18)))
      );
      return Number(decimalize(cvalue.netAmountState.mintRate, 18));
    } else {
      const rpcClient = await RpcClient(rpc);
      const pstakeQueryService = new LiquidStakeQueryClient(rpcClient);
      const cvalue = await pstakeQueryService.ExchangeRate({
        chainId: chainId
      });
      console.log(
        cvalue,
        "-cosmos cvalue in getExchangeRateFromRpc",
        Number(decimalize(cvalue.rate, 18)),
        decimalize(decimalize(cvalue.rate, 18)),
        chainId
      );
      return Number(decimalize(cvalue.rate, 18));
    }
  } catch (e) {
    console.log(e, "error in getExchangeRateFromRpc");
    return 1;
  }
};

export const getExchangeRateList = async () => {
  let exchangeRateList: ExchangeRateList = {
    cosmos: 1,
    osmo: 1,
    dydx: 1,
    persistence: 1,
    stars: 1,
    agoric: 1,
    chihuahua: 1
  };
  try {
    // TODO: update after xprt
    for (const chain of cosmosSupportedChains) {
      const exchangeRate = await getExchangeRateFromRpc(
        persistenceChain.rpc,
        chain.chainId,
        chain.bech32Config.bech32PrefixAccAddr
      );
      exchangeRateList[chain.bech32Config.bech32PrefixAccAddr] =
        exchangeRate === 0 ? 1 : exchangeRate;
    }
    return exchangeRateList;
  } catch (e) {
    console.log(e, "error in getExchangeRateList");
    return exchangeRateList;
  }
};

export const getChainTVU = async (rpc: string) => {
  let tvuList: TvuList = {
    cosmos: 1,
    osmo: 1,
    dydx: 1,
    persistence: 1,
    stars: 1,
    agoric: 1,
    chihuahua: 1
  };
  try {
    // TODO: update after xprt
    for (const chain of cosmosSupportedChains) {
      const selectedItem: DstChainItem | undefined = dstChainsList.find(
        (responseItem) =>
          responseItem.prefix === chain.bech32Config.bech32PrefixAccAddr
      );
      const rpcClient = await RpcClient(rpc);
      const bankQueryService = new BankQuery(rpcClient);
      const supply: QuerySupplyOfResponse = await bankQueryService.SupplyOf({
        denom: selectedItem.stkTokenDenom
      });
      tvuList[chain.bech32Config.bech32PrefixAccAddr] = supply
        ? supply.amount?.amount
        : 0;
    }
    return tvuList;
  } catch (e) {
    console.log(e, "error in getChainTVU");
    return tvuList;
  }
};
export const getHostChainInfo = async (
  rpc: string,
  hostChainId: string
): Promise<{ fee: number; validatorsLength: number }> => {
  try {
    const rpcClient = await RpcClient(rpc);
    const pstakeQueryService = new LiquidStakeQueryClient(rpcClient);
    const chainParamsResponse = await pstakeQueryService.HostChain({
      chainId: hostChainId
    });

    const fee = chainParamsResponse.hostChain.params.redemptionFee;
    const filteredList = chainParamsResponse.hostChain.validators.filter(
      (item) => Number(item.delegatedAmount) > 0 || Number(item.weight) > 0
    );
    console.log(
      chainParamsResponse,
      "chainParamsResponse",
      hostChainId,
      filteredList
    );
    return {
      fee: Number(Number(decimalize(fee, 18)).toFixed(6)),
      validatorsLength:
        hostChainId !== ChainExternalInfo[env].persistence.chainID
          ? filteredList.length
          : 0
    };
  } catch (e) {
    console.log(e, "error in getFee");
    return {
      fee: INSTANT_REDEEM_FEE,
      validatorsLength: 0
    };
  }
};

export const getXprtValidators = async (rpc: string) => {
  try {
    let filteredList: any[] = [];
    const rpcClient = await RpcClient(rpc);
    const pstakeQueryService = new NativeLiquidStakeQueryClient(rpcClient);
    const liquidValidatorsResponse =
      await pstakeQueryService.LiquidValidators();
    console.log(
      liquidValidatorsResponse,
      "-persistence cvalue in getExchangeRateFromRpc"
    );
    if (liquidValidatorsResponse && liquidValidatorsResponse.liquidValidators) {
      liquidValidatorsResponse.liquidValidators?.forEach((item) => {
        filteredList = liquidValidatorsResponse.liquidValidators.filter(
          (item) => Number(item.liquidTokens) > 0 || Number(item.weight) > 0
        );
      });
    }
    return {
      fee: 0,
      validatorsLength: filteredList.length
    };
  } catch (e) {
    console.log(e, "error-");
    return {
      fee: 0,
      validatorsLength: 0
    };
  }
};

export const getFeeLSCosmo = async (rpc: string): Promise<number> => {
  try {
    const rpcClient = await RpcClient(rpc);
    const pstakeQueryService = new QueryClientImpl(rpcClient);
    const chainParamsResponse = await pstakeQueryService.HostChainParams({});
    const fee =
      chainParamsResponse.hostChainParams?.pstakeParams?.pstakeRedemptionFee!;
    return Number(Number(decimalize(fee, 18)).toFixed(6));
  } catch (e) {
    console.log(e, "error in getFeeLSCosmo");
    return 0;
  }
};

export const fetchNativeUnbondingList = async (address: string) => {
  try {
    const rpcClient = await RpcClient(persistenceChain.rpc);
    let list: PendingClaimList[] = [];
    const pstakeQueryService = new StakeQuery(rpcClient);
    const response = await pstakeQueryService.DelegatorUnbondingDelegations({
      delegatorAddr: address
    });
    const dstChainItem: DstChainItem | undefined = dstChainsList.find(
      (responseItem) => responseItem.prefix === utilityAsset.prefix
    );
    if (response.unbondingResponses.length) {
      console.log(response.unbondingResponses, "native unbondings");
      response.unbondingResponses.map((item) => {
        item.entries.map((itemEntry) => {
          const date = moment(
            Number(itemEntry.completionTime.seconds)! * 1000
          ).format("DD MMM YYYY hh:mm A");
          const remainingTime = moment(date).fromNow(true);
          console.log(date, "native completionTime", remainingTime);
          list.push({
            imgUrl: dstChainItem.tokenImgUrl,
            tokenName: dstChainItem.tokenName,
            tokenDenom: dstChainItem.tokenName,
            unbondAmount: itemEntry.balance,
            unStakedon: date,
            daysRemaining: remainingTime.toString(),
            status: "unbonding"
          });
        });
      });
    }
    const unique = list.filter(
      (obj, index) =>
        list.findIndex((item) => item.unStakedon === obj.unStakedon) === index
    );
    let filtered: PendingClaimList[] = [];
    unique.forEach((item) => {
      const dstAssetTotalAmount = list
        .filter((obj) => item.unStakedon === obj.unStakedon)
        .reduce((accumulator, object) => {
          return accumulator + Number(object?.unbondAmount);
        }, 0);
      const arrayItem = { ...item, unbondAmount: dstAssetTotalAmount };
      filtered.push(arrayItem);
    });
    console.log(list, "list completionTime", filtered);
    return filtered;
  } catch (e) {
    return [];
  }
};

export const fetchUnbondingList = async (rpc: string, address: string) => {
  try {
    const chains = ExternalChains[env].filter((chain) => {
      if (chain.bech32Config.bech32PrefixAccAddr === "dydx") {
        if (Cosmos.dydx) return chain;
        else return null;
      } else {
        return chain;
      }
    });
    console.log(rpc, address, "fetchUnbondingList");
    const rpcClient = await RpcClient(rpc);
    const lsQueryService = new LiquidStakeQueryClient(rpcClient);
    const response = await lsQueryService.UserUnbondings({
      address: address
    });
    console.log(response, "fetchUnbondingList-");
    let list: PendingClaimList[] = [];
    let claimableAmount: number = 0;
    if (response.userUnbondings.length) {
      for (let item of response.userUnbondings) {
        for (let chain of chains) {
          const dstChainItem: DstChainItem | undefined = dstChainsList.find(
            (responseItem) =>
              responseItem.prefix === chain.bech32Config.bech32PrefixAccAddr
          );
          if (chain.chainId === item.chainId) {
            const unbondTime = await getUnbondTime(
              item.epochNumber,
              rpc,
              dstChainItem.prefix
            );
            list.push({
              imgUrl: dstChainItem.stkTokenImgUrl,
              tokenName: dstChainItem.stkTokenName,
              tokenDenom: dstChainItem.tokenName,
              unbondAmount: item.unbondAmount.amount,
              unStakedon: unbondTime.time.toString(),
              daysRemaining: unbondTime.remainingDays.toString(),
              status: "unbonding"
            });
          }
        }
      }
    }
    let nativeUnbondigs = [];
    if (Cosmos.stkXPRT) {
      nativeUnbondigs = await fetchNativeUnbondingList(address);
    }
    return { list: list.concat(nativeUnbondigs), claimableAmount: 0 };
  } catch (e) {
    console.log(e, "fetchUnbondingList-error");
    return { list: [], claimableAmount: 0 };
  }
};

const getUnbondTime = async (
  epochNumber: BigInt,
  rpc: string,
  prefix: string
) => {
  try {
    const epochInfo = await getEpochInfo(rpc);
    const currentEpochNumberResponse = await getCurrentEpoch(rpc);
    const currentEpochNumber = Number(currentEpochNumberResponse.currentEpoch);

    const nextEpochNumber = Number(epochNumber);
    const drs = Number(epochInfo.epochs[0]?.duration?.seconds);

    const diff = (nextEpochNumber - currentEpochNumber + 1) * drs;

    // TODO: make dynamic wrt prefix
    const tentativeTime = moment(
      Number(epochInfo!.epochs[0]!.currentEpochStartTime?.seconds)! * // ms conversion
        1000
    )
      .add(diff + getUnbondPeriod(prefix), "seconds")
      .local()
      .format("DD MMM YYYY hh:mm A");
    const remainingTime = moment(tentativeTime).fromNow(true);
    console.log(
      diff,
      remainingTime,
      "remainingTime",
      COSMOS_UNBOND_TIME,
      tentativeTime
    );
    return {
      time: tentativeTime,
      remainingDays: remainingTime
    };
  } catch (e) {
    return {
      time: 0,
      remainingDays: 0
    };
  }
};

export const getEpochInfo = async (rpc: string) => {
  const rpcClient = await RpcClient(rpc);
  const persistenceQueryService = new EpochQueryClient(rpcClient);
  return await persistenceQueryService.EpochInfos({});
};

export const getCurrentEpoch = async (rpc: string) => {
  const rpcClient = await RpcClient(rpc);
  const persistenceQueryService = new EpochQueryClient(rpcClient);
  return await persistenceQueryService.CurrentEpoch({
    identifier: "day"
  });
};
export const getMaxRedeem = async (
  rpc: string,
  chainId: string
): Promise<any> => {
  try {
    const rpcClient = await RpcClient(rpc);
    const pstakeQueryService = new LiquidStakeQueryClient(rpcClient);
    const moduleAccountResponse =
      await pstakeQueryService.DepositAccountBalance({ chainId: chainId });
    console.log(moduleAccountResponse, "moduleAccountResponse getMaxRedeem");
    return moduleAccountResponse ? moduleAccountResponse.balance?.amount : 0;
  } catch (e) {
    console.log(e, "error in getMaxRedeem");
    return 0;
  }
};

export const getChainStatus = async (rpc: string): Promise<boolean> => {
  try {
    const tmClient: Tendermint34Client = await Tendermint34Client.connect(rpc);
    const status: StatusResponse = await tmClient.status();
    const latestBlockTime = status.syncInfo.latestBlockTime;
    const startTime = moment(latestBlockTime.toString()).format(
      "DD-MM-YYYY hh:mm:ss"
    );
    const endTime = moment().local().format("DD-MM-YYYY hh:mm:ss");
    const ms = moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(
      moment(startTime, "DD/MM/YYYY HH:mm:ss")
    );
    const duration = moment.duration(ms);
    const seconds = duration.asSeconds();
    return seconds > 60;
  } catch (e) {
    console.log(e, "error in getChainStatus");
    return true;
  }
};

export const getCosmosUnbondTime = async (rpc: string): Promise<number> => {
  try {
    const rpcClient = await RpcClient(rpc);
    const pstakeQueryService = new StakeQuery(rpcClient);
    const chainParamsResponse = await pstakeQueryService.Params({});
    if (chainParamsResponse.params?.unbondingTime?.seconds) {
      return chainParamsResponse.params?.unbondingTime?.seconds.toNumber();
    }
    return 0;
  } catch (e) {
    console.log(e, "error in getCosmosUnbondTime");
    return 0;
  }
};

export const getDelegations = async (
  address: string,
  chainInfo: ChainInfo,
  validators: PstakeValidator[]
): Promise<DelegatedValidators> => {
  try {
    console.log(address, chainInfo.rpc, validators, "params getDelegations");
    const delegations: DelegatedValidator[] = [];
    let eligibleDelegations: DelegatedValidator[] = [];
    let notEligibleDelegations: DelegatedValidator[] = [];
    let totalAmount: number = 0;
    const rpcClient = await RpcClient(chainInfo.rpc);
    const stakingQueryService = new StakeQuery(rpcClient);
    const delegationsResponse: QueryDelegatorDelegationsResponse =
      await stakingQueryService.DelegatorDelegations({
        delegatorAddr: address
      });

    const delegatedValidators: QueryDelegatorValidatorsResponse =
      await stakingQueryService.DelegatorValidators({
        delegatorAddr: address
      });
    console.log(
      delegatedValidators,
      "delegatedValidators",
      delegationsResponse
    );

    if (delegationsResponse.delegationResponses.length > 0) {
      totalAmount = delegationsResponse.delegationResponses.reduce(
        (accumulator, object) => {
          return accumulator + Number(object?.balance?.amount);
        },
        0
      );
      for (const delegation of delegationsResponse.delegationResponses) {
        const validator = delegatedValidators.validators.find(
          (validator: Validator) => {
            return (
              validator.operatorAddress ===
              delegation.delegation?.validatorAddress
            );
          }
        );
        console.log(
          delegation,
          validators,
          validator,
          "validator-123",
          delegation.balance?.amount
        );
        const eligibilityCheck = validators.find(
          (item) => item.operatorAddress === validator!.operatorAddress
        );
        const activeCheck = !validator!.jailed && validator!.status === 3;
        console.log(eligibilityCheck, "activeCheck", activeCheck);
        if (Number(delegation.balance?.amount) > 0) {
          delegations.push({
            tokenName: "ATOM",
            name: validator!.description?.moniker!,
            identity:
              env === "Testnet"
                ? ""
                : `https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/${validator.operatorAddress}.png`,
            imgUrl:
              env === "Testnet"
                ? ""
                : `https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/${validator.operatorAddress}.png`,
            amount: decimalize(
              delegation.balance?.amount!,
              chainInfo.stakeCurrency.coinDecimals
            ),
            inputAmount: "",
            validatorAddress: validator!.operatorAddress,
            status:
              eligibilityCheck === undefined
                ? "not-eligible"
                : Number(
                    decimalize(
                      delegation.balance?.amount!,
                      chainInfo.stakeCurrency.coinDecimals
                    )
                  ) < MIN_STAKE
                ? "insufficient Amount"
                : !activeCheck
                ? "inactive"
                : !eligibilityCheck.delegable
                ? "not-bonded"
                : Number(eligibilityCheck.weight) <= 0
                ? "not in active set"
                : "active"
          });
        }
      }
    }
    if (delegations.length > 0) {
      eligibleDelegations = delegations.filter(
        (item) => item.status === "active"
      );
      notEligibleDelegations = delegations.filter(
        (item) => item.status !== "active"
      );
    }
    console.log(delegations, "delegations-12");
    return {
      list: eligibleDelegations.concat(notEligibleDelegations),
      eligible: eligibleDelegations,
      nonEligible: notEligibleDelegations,
      totalAmount: decimalize(totalAmount, chainInfo.stakeCurrency.coinDecimals)
    };
  } catch (e) {
    console.log(e, "error in getDelegations");
    return {
      list: [],
      eligible: [],
      nonEligible: [],
      totalAmount: 0
    };
  }
};

export const getValidators = async (
  rpc: string,
  hostChainId: string
): Promise<PstakeValidator[]> => {
  try {
    let validators: PstakeValidator[] = [];
    const rpcClient = await RpcClient(rpc);
    const pstakeQueryService = new LiquidStakeQueryClient(rpcClient);
    const chainParamsResponse = await pstakeQueryService.HostChain({
      chainId: hostChainId
    });
    console.log(chainParamsResponse, "chainParamsResponse", hostChainId);
    if (chainParamsResponse && chainParamsResponse.hostChain?.validators) {
      if (chainParamsResponse.hostChain?.validators.length > 0) {
        chainParamsResponse.hostChain?.validators.forEach((item) =>
          validators.push({
            operatorAddress: item.operatorAddress,
            status: item.status,
            weight: item.weight,
            delegable: item.delegable
          })
        );
      }
    }
    console.log(validators, "validators-test");
    return validators;
  } catch (e) {
    return [];
  }
};

export const getTokenizedShares = async (
  balances: QueryAllBalancesResponse,
  address: string,
  chainInfo: ChainInfo,
  dstChainInfo: ChainInfo,
  chain: string,
  prefix: string,
  validators: PstakeValidator[]
) => {
  try {
    const tendermintClient = await Tendermint34Client.connect(chainInfo.rpc!);
    const queryClient = new QueryClient(tendermintClient);
    let totalAmount: number = 0;
    const delegations: any = [];
    if (balances && balances!.balances!.length) {
      for (let item of balances!.balances) {
        let valAddress: string = "";
        if (chain === "cosmos") {
          if (item.denom.startsWith(prefix)) {
            valAddress = item.denom.substring(0, item.denom.indexOf("/"));
          }
        } else if (chain === "persistence") {
          if (item.denom.includes("ibc/")) {
            const ibcExtension = setupIbcExtension(queryClient);
            let ibcDenomeResponse = await ibcExtension.ibc.transfer.denomTrace(
              item.denom
            );
            if (ibcDenomeResponse!.denomTrace!.baseDenom.includes(prefix)) {
              valAddress = ibcDenomeResponse!.denomTrace!.baseDenom.substring(
                0,
                ibcDenomeResponse!.denomTrace!.baseDenom.indexOf("/")
              );
            }
          }
        }
        console.log(valAddress, "valAddress-123", item);
        const validatorCheck = validators.find(
          (item) => item.operatorAddress === valAddress
        );
        if (
          valAddress !== "" &&
          Number(
            decimalize(item?.amount!, dstChainInfo.stakeCurrency.coinDecimals)
          ) >= MIN_STAKE &&
          validatorCheck
        ) {
          console.log(valAddress, "valAddress-123-IN", item);
          const amount = Number(
            decimalize(item?.amount!, dstChainInfo.stakeCurrency.coinDecimals)
          );
          totalAmount = totalAmount + amount;
          const rpcClient = await RpcClient(dstChainInfo.rpc);
          const stakingQueryService = new StakeQuery(rpcClient);
          const vresponse: QueryValidatorResponse =
            await stakingQueryService.Validator({
              validatorAddr: valAddress
            });
          delegations.push({
            denom: item.denom,
            name: vresponse.validator!.description!.moniker!,
            identity:
              env === "Testnet"
                ? ""
                : `https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/${
                    vresponse.validator!.operatorAddress
                  }.png`,
            amount: item?.amount,
            inputAmount: "",
            validatorAddress: vresponse.validator!.operatorAddress,
            status:
              !vresponse.validator!.jailed &&
              vresponse.validator!.status === 3 &&
              amount >= MIN_STAKE
          });
        }
      }
    }
    console.log(delegations, "delegations");
    return {
      list: delegations,
      totalAmount: totalAmount
    };
  } catch (e) {
    return {
      list: [],
      totalAmount: 0
    };
  }
};
