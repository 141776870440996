import React, { useEffect, useRef, useState } from "react";
import { Icon, Copy } from "@/components";
import {
  Dropdown,
  useOnClickOutside,
  useWindowSize,
  stringTruncate,
  getStorageValue,
  Button
} from "@persistenceone/pstake-ui-components";
import CustomImage from "@/components/molecules/image";
import { baseAssetList, externalChains } from "@/helpers/utils";
import { DstChainItem } from "@/containers/cosmos/helpers/types";
import { dstChainsList } from "@/containers/cosmos/helpers/config";
import { useFeatureFlags } from "@/context/feature-flags-context";
import { usePathname, useSearchParams } from "next/navigation";
import { WalletType } from "@/containers/cosmos/store/slices/wallet-slice/types";
import { Cosmos } from "@/helpers/feature-flags";
import { utilityAsset } from "@/utils";
import { useCosmosStore } from "@/containers/cosmos/store";
import { useShallow } from "zustand/react/shallow";
import {
  connectCosmosWallet,
  fetchTokenizeShares,
  fetchDelegations
} from "@/containers/cosmos/store/sagas/fetching-sagas";
import { useRouter } from "next/router";

const getLogo = (walletType: WalletType) => {
  switch (walletType) {
    case "cosmosStation":
      return "cosmos_station";
    case "keplr":
      return "keplr_round";
    case "leap":
      return "leap";
  }
};

export const LoginOptions = () => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { isMobile } = useWindowSize();
  const pathname = usePathname();
  const walletInfo = getStorageValue("cosmos-walletInfo", "");
  const { useFlag } = useFeatureFlags();
  const isOsmoEnabled = useFlag("stkosmo_support");
  const isDydxEnabled = useFlag("dydx_support");

  const {
    validators,
    setDstChainItem,
    isWalletConnected,
    srcAccountData,
    walletType,
    externalChainSigners,
    dstAccountData,
    srcChainInfo,
    dstChainInfo,
    setWalletModal
  } = useCosmosStore(
    useShallow((state) => ({
      validators: state.initData.validators,
      setDstChainItem: state.setDstChainItem,
      isWalletConnected: state.walletData.walletInfo.isWalletConnected,
      srcAccountData: state.walletData.walletInfo.srcAccountData,
      walletType: state.walletData.walletInfo.walletType,
      externalChainSigners: state.walletData.walletInfo.externalChainSigners,
      dstAccountData: state.walletData.walletInfo.dstAccountData,
      srcChainInfo: state.walletData.walletInfo.srcChainInfo,
      dstChainInfo: state.walletData.walletInfo.dstChainInfo,
      setWalletModal: state.walletDataActions.setWalletModal
    }))
  );

  const connectHandler = async () => {
    setWalletModal(true);
  };

  const disconnectHandler = async () => {
    sessionStorage.removeItem("cosmos-terms");
    localStorage.removeItem("cosmos-walletInfo");
    window.location.reload();
  };

  useEffect(() => {
    const fetchApi = async () => {
      if (walletType === "keplr") {
        window.addEventListener("keplr_keystorechange", async () => {
          window.location.reload();
          //   connectCosmosWallet({
          //     walletType: "keplr",
          //     dstChainPrefix: walletInfo.dstChainPrefix,
          //     experimentalChains: {
          //       osmo: isOsmoEnabled,
          //       dydx: isDydxEnabled
          //     }
          //   })
        });
      } else if (walletType === "cosmosStation") {
        window.cosmostation.cosmos.on("accountChanged", async () => {
          //   connectCosmosWallet({
          //     walletType: "cosmosStation",
          //     dstChainPrefix: walletInfo.dstChainPrefix,
          //     experimentalChains: {
          //       osmo: isOsmoEnabled,
          //       dydx: isDydxEnabled
          //     }
          //   })
          window.location.reload();
        });
      }
      return null;
    };
    if (isWalletConnected) {
      console.log("account-changed");
      fetchApi();
    }
  }, [walletType, isWalletConnected]);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setDropdownOpen(false);
  });

  // re-login on every reload or refresh
  useEffect(() => {
    if (!router.isReady) return;
    let token = searchParams.get("token");
    let chain = searchParams.get("chain");
    console.log("fetch-login-re", token, chain, pathname);
    if (walletInfo !== "" && !isWalletConnected) {
      const routeCheck = baseAssetList.find(
        (asset) => asset.name === token && asset.chain === chain
      );
      if (!routeCheck) {
        token = "ATOM";
        chain = "cosmos";
      }
      let baseAsset = baseAssetList.find(
        (baseAsset) =>
          baseAsset.name === token &&
          baseAsset.ibcAsset ===
            (chain === "persistence" && token !== utilityAsset.name)
      );
      if (!baseAsset) {
        baseAsset = baseAssetList.find(
          (asset) =>
            asset.network === "cosmos" &&
            asset.prefix === walletInfo.dstChainPrefix &&
            !asset.ibcAsset
        );
      }
      console.log("baseAsset-login-re", baseAsset);
      const dropDownSelection: DstChainItem | undefined = dstChainsList.find(
        (responseItem) => responseItem.tokenName === baseAsset.name
      );
      console.log("dropDownSelection-login-re", dropDownSelection);
      setDstChainItem(dropDownSelection!);
      connectCosmosWallet({
        walletType: walletInfo!.walletName,
        dstChainPrefix: baseAsset.prefix,
        experimentalChains: {
          osmo: Cosmos.osmo,
          dydx: Cosmos.dydx,
          agoric: Cosmos.stkBLD
        }
      });
    }
  }, [searchParams, router]);

  // fetch account delegations
  useEffect(() => {
    if (
      isWalletConnected &&
      validators.length > 0 &&
      externalChainSigners.length > 0
    ) {
      console.log("fetch-delegations");
      const cosmosChainAccount = externalChainSigners.find(
        (item) => item.prefix === "cosmos"
      );
      const cosmosChainInfo = externalChains.find(
        (item) => item.bech32Config.bech32PrefixAccAddr === "cosmos"
      );
      fetchDelegations({
        address: cosmosChainAccount.address!,
        chainInfo: cosmosChainInfo,
        validators: validators
      });
    }
  }, [isWalletConnected, validators, externalChainSigners]);

  // fetch tokenized shares by supported validators everytime token changed
  useEffect(() => {
    if (isWalletConnected && validators.length > 0 && dstAccountData.address) {
      console.log("fetch-fetchTokenizeShares");
      fetchTokenizeShares({
        srcAddress: srcAccountData!.address,
        dstAddress: dstAccountData!.address,
        srcChainInfo: srcChainInfo!,
        dstChainInfo: dstChainInfo!,
        validators: validators
      });
    }
  }, [isWalletConnected, validators, dstAccountData]);

  return (
    <div className="inline-block w-fit cursor-pointer relative">
      {isWalletConnected ? (
        <Dropdown
          className="text-white-100"
          closeDropdown={dropdownOpen}
          closeHandler={(value) => setDropdownOpen(value)}
          dropdownLabel={
            <div className="flex items-center">
              <span
                className={`${
                  dropdownOpen ? "pointer-events-none" : "pointer-events-auto"
                }
              flex items-center !text-sm`}
                onClick={() => {
                  setDropdownOpen(true);
                }}
              >
                <CustomImage
                  src={`/wallets/${getLogo(walletType)}.svg`}
                  alt={"logo"}
                  width={20}
                  height={20}
                />
                <span className="ml-3 text-green-500">
                  {stringTruncate(srcAccountData!.address, isMobile ? 3 : 6)}
                </span>
              </span>
            </div>
          }
          dropDownButtonClass="rounded-md !h-[37px] !text-sm !font-medium text-white-100 leading-[21px] !py-2.5"
          dropdownType={"click"}
          staticBackDrop={false}
          dropDownIcon={
            <Icon
              viewClass="arrow-right fill-[#fff] !w-[10px]"
              iconName="chevron"
            />
          }
          dropDownContentClass="!bg-black-400 w-max md:p-0"
        >
          <div
            className="px-4 py-3 flex items-center justify-center md:py-3
                        hover:cursor-pointer hover:rounded-md hover:bg-[#2828288a] text-black-400 whitespace-nowrap"
          >
            <div className="text-white-100 text-sm font-normal leading-normal md:text-xsm text-center flex-1">
              <Copy
                id={srcAccountData!.address}
                customView={
                  <span className={"flex"}>
                    <Icon
                      iconName="copy2"
                      viewClass="disconnect md:!w-[16px] md:!h-[16px] mr-4"
                    />
                    Copy Address
                  </span>
                }
              />
            </div>
          </div>
          <div
            className="px-4 py-3 flex items-center md:py-3
                        hover:cursor-pointer hover:rounded-md hover:bg-[#2828288a] text-black-400 whitespace-nowrap"
            onClick={disconnectHandler}
          >
            <Icon
              iconName="logout"
              viewClass="disconnect md:!w-[16px] md:!h-[16px]"
            />
            <span className="ml-4 text-white-100 text-sm font-normal leading-normal md:text-xsm md:ml-2">
              Disconnect
            </span>
          </div>
        </Dropdown>
      ) : (
        <Button
          className={`button !font-medium md:text-sm`}
          type="primary"
          size="auto"
          disabled={false}
          content={"Connect Wallet"}
          onClick={connectHandler}
        />
      )}
    </div>
  );
};

export default LoginOptions;
