const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;
const deployment: string = process.env.NEXT_PUBLIC_DEPLOYMENT!;

export const Cosmos = {
  /** toggle delegation stake modal */
  delegationLiquidStake: false,
  osmo: true,
  /** toggle swap modal */
  swap: false,
  dydx: true,
  stkXPRT: true,
  stkBLD: deployment !== "production",
  xprt: true //TODO: remove once stkXPRT launched
};

export const EthereumFlags = {
  optimism: false,
  ethereum: true,
  arbitrum: false
};
