"use client";
import React from "react";
import CustomImage from "@/components/molecules/image";
import { SelectionDrawerTypes } from "./types";
import { Icon } from "../../atoms";
import Tooltip from "rc-tooltip";
import {
  useWindowKeyActions,
  Dropdown,
  emptyFunc
} from "@persistenceone/pstake-ui-components";

export const DestinationDropdown = ({
  show,
  title,
  onClose = emptyFunc,
  className,
  staticBackDrop = true,
  closeButton = true,
  list = [],
  onSelect = emptyFunc,
  children,
  srcAsset,
  connectedNetwork,
  handleDropdown
}: SelectionDrawerTypes) => {
  useWindowKeyActions({
    Escape: onClose
  });

  const onCloseProp = () => {
    onClose?.();
  };
  return (
    <Dropdown
      className="text-white-100 md:hidden"
      closeDropdown={show}
      closeHandler={(value) => handleDropdown(value)}
      dropdownLabel={
        <div className="flex items-center">
          <div className="rounded-full flex items-center justify-center w-[24px] h-[24px] mr-2">
            <CustomImage
              src={srcAsset.imgUrl}
              alt={srcAsset.name}
              width={24}
              height={24}
            />
          </div>
          <span className="text-lg text-white-100 font-medium leading-normal md:text-xsm md:ml-2">
            {srcAsset.name} on{" "}
            <span className={"capitalize"}>{srcAsset.chain}</span>
          </span>
        </div>
      }
      dropDownButtonClass="!bg-transparent text-[12px] text-white-100 !py-1.5 bg-dark-700
         button md:text-sm w-full bg-transparent !px-6 !justify-between"
      dropdownType={"click"}
      staticBackDrop={false}
      dropDownIcon={
        <Icon
          viewClass="arrow-right fill-[#fff] !w-[10px]"
          iconName="chevron"
        />
      }
      dropDownContentClass="!bg-[#242424] drop-shadow-md round-md w-max py-1 md:p-0"
    >
      {list.map((item, index) => (
        <div
          className={`${
            item.name.toLowerCase() === srcAsset.name.toLowerCase() &&
            item.network === srcAsset.network
              ? "bg-[#181818] pointer-events-none"
              : "bg-transparent"
          } px-6 py-3 flex items-center md:py-3
                        hover:cursor-pointer hover:bg-[#18181836] text-black-400 whitespace-nowrap`}
          key={index}
          onClick={() => onSelect(item)}
        >
          <div className={"flex items-center justify-between flex-1"}>
            <div className="mr-2 flex items-center">
              <CustomImage
                src={item.imgUrl}
                alt={item.name}
                width={24}
                height={24}
                className={"mr-2"}
              />
              <div className="flex items-center">
                <span className="text-base text-white-200 font-medium leading-normal md:text-xsm md:ml-2 mr-2">
                  {item.name}
                </span>
                {connectedNetwork.toLowerCase() !==
                item.network.toLowerCase() ? (
                  <Tooltip
                    placement="top"
                    overlay={<p>Network Change Required</p>}
                  >
                    <button>
                      <Icon iconName="network-change" viewClass="icon" />
                    </button>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex items-center">
              <span className="text-white-700 text-sm capitalize leading-normal md:text-xsm md:ml-2">
                {item.chain}
              </span>
              <Icon
                iconName="right"
                viewClass={`${
                  item.name.toLowerCase() === srcAsset.name.toLowerCase() &&
                  item.network === srcAsset.network
                    ? "block"
                    : "hidden"
                } !w-[14px] !h-[14px] mb-1 ml-1`}
              />
            </div>
          </div>
        </div>
      ))}
    </Dropdown>
  );
};
