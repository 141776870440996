import { StateCreator } from "zustand";
import { SliceActions, UNStakeTransactionState } from "./types";
import { baseAssetList, stkAssetList } from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let initialUnStakeInAsset = stkAssetList[env].find(
  (stkAsset) => stkAsset.name === "stkBNB" && !stkAsset.ibcAsset
);

let initialUnStakeOutAsset = baseAssetList[env].find(
  (baseAsset) => baseAsset.name === "BNB" && !baseAsset.ibcAsset
);

const initialState: UNStakeTransactionState = {
  unStakeTransaction: {
    amount: "",
    type: "instant",
    unStakeInTokenModal: false,
    unStakeInToken: initialUnStakeInAsset,
    unStakeOutToken: initialUnStakeOutAsset,
    unStakeOutTokenModal: false
  }
};

export type UnStakeTransactionSlice = UNStakeTransactionState & SliceActions;

export const createUnStakeTransactionSlice: StateCreator<
  UnStakeTransactionSlice
> = (set) => ({
  ...initialState,
  unStakeTransactionActions: {
    setUnStakeAmount: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          amount: val
        }
      })),
    setUnStakeInToken: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          stakeInToken: val
        }
      })),
    setUnStakeInTokenModal: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          stakeInTokenModal: val
        }
      })),
    setUnStakeOutToken: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          stakeOutToken: val
        }
      })),
    setUnStakeOutTokenModal: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          stakeOutTokenModal: val
        }
      })),
    setUnStakeOption: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          type: val
        }
      }))
  }
});
