import { StateCreator } from "zustand";
import {
  formBalanceList,
  getAllowances,
  getEthBalance,
  getStkEthBalance,
  getWethBalance
} from "../../helpers/utils";
import { BigNumber, utils } from "ethers";
import {
  fetchArbitrumBalances,
  fetchEthereumBalances,
  fetchOptimismBalances
} from "@/utils";
import { useAppStore } from "@/store/store";

export interface BalanceSliceState {
  balance: {
    eth: number | string;
    stkETH: number | string;
    weth: number | string;
  };
  checkAllowance: BigNumber;
}

export interface BalanceSliceActions {
  fetchAllEthBalances: (
    address: string,
    env: string,
    network: string
  ) => Promise<any>;
  resetBalanceSlice: () => void;
  fetchAllowances: () => void;
}

const initialState: BalanceSliceState = {
  balance: {
    eth: 0,
    stkETH: 0,
    weth: 0
  },
  checkAllowance: utils.parseEther("0")
};

export type BalanceSlice = BalanceSliceState & BalanceSliceActions;

export const createBalanceSlice: StateCreator<BalanceSlice> = (set) => ({
  ...initialState,
  fetchAllEthBalances: async (address: string, env: string, network) => {
    console.log(env, "fetchAllEthBalances", network);
    const ethBalance = await getEthBalance();
    const sktEthBalance = await getStkEthBalance();
    const wEthBalance = await getWethBalance();
    set({
      balance: {
        eth: ethBalance,
        stkETH: sktEthBalance,
        weth: wEthBalance
      }
    });
    let ethereumBalances;
    if (network === "ethereum") {
      ethereumBalances = await formBalanceList(network, {
        eth: ethBalance,
        stkETH: sktEthBalance,
        weth: wEthBalance
      });
    } else {
      ethereumBalances = await fetchEthereumBalances(address, env);
    }
    useAppStore.getState().setEthereumBalances(ethereumBalances); // updating global store
    let optimismBalances;
    if (network === "optimism") {
      optimismBalances = await formBalanceList(network, {
        eth: ethBalance,
        stkETH: sktEthBalance,
        weth: wEthBalance
      });
    } else {
      optimismBalances = await fetchOptimismBalances(address, env);
    }
    console.log(optimismBalances, "optimismBalances");
    useAppStore.getState().setOptimismBalances(optimismBalances); // updating global store
    let arbitrumBalances;
    if (network === "arbitrum") {
      arbitrumBalances = await formBalanceList(network, {
        eth: ethBalance,
        stkETH: sktEthBalance,
        weth: wEthBalance
      });
    } else {
      arbitrumBalances = await fetchArbitrumBalances(address, env);
    }
    console.log(arbitrumBalances, "arbitrumBalances");
    useAppStore.getState().setArbitrumBalances(arbitrumBalances); // updating global store
  },
  fetchAllowances: async () => {
    const response = await getAllowances();
    console.log(response, "-eth getAllowances resposne");
    set({
      checkAllowance: response!
    });
  },
  resetBalanceSlice: () => {
    set(initialState);
  }
});
