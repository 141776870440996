import { Spinner } from "@persistenceone/pstake-ui-components";

export default function Loading() {
  // You can add any UI inside Loading, including a Skeleton.
  return (
    <div className="loader-wrapper h-screen">
      <div className="absolute left-0 right-0 top-0 bottom-0 w-[50px] h-[50px] m-auto">
        <Spinner size={"large"} />
      </div>
    </div>
  );
}
