export interface Alert {
  message?: any;
  txHash?: string;
  heading?: string;
  explorerUrl?: string;
  actionHandler?: () => void;
}

export const enum ToastType {
  SUCCESS,
  ERROR,
  LOADING,
  INFO,
  WARNING,
  MINIMIZE
}
