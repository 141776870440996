import {
  NO_OF_BLOCK_CONFIRMATIONS,
  SDK_ENV,
  sdkInstance
} from "../../../../constants/dynamic";
import {
  filterClaimsIntoStakingAndUnstaked,
  FilteredClaims
} from "./saga-helpers";
import { Scope } from "@sentry/nextjs";
import { genericErrorHandler, getTokenBalances } from "../../helpers/utils";
import { truncateToFixedDecimalPlaces } from "@persistenceone/pstake-ui-components";
import { getBNBBalance, getConfig, getStkBNBBalance } from "@/api/bnb";
import {
  fetchBeefyInfo,
  fetchOpenLeverage,
  fetchPancakeInfo,
  fetchPancakeV3Info,
  fetchThenaInfo,
  fetchWambot
} from "@/api/bnb";
import { InitialTvlApyTypes } from "../slices/initial-data-slice/types";
import { initialTVLAPY } from "../slices/initial-data-slice";
import {
  COIN_BASE,
  ETH_REQUEST_ACCOUNTS,
  LEDGER_LIVE,
  SECONDS_IN_A_DAY,
  WALLET_CONNECT
} from "@/constants/static";
import { ethers } from "ethers";
import { StkBNBWebSDK } from "@persistenceone/stkbnb-web-sdk";
import { useBnbStore } from "@/containers/bnb/store";
import { ConnectBnbWalletParams } from "@/containers/bnb/store/slices/wallet-slice/types";
import { FetchClaimSagaParams } from "@/containers/bnb/store/slices/transaction-slices/claim/types";
import { FetchBalanceSagaParams } from "@/containers/cosmos/store/slices/transaction-slices/stake/types";

export const connectBnbWallet = async (payload: ConnectBnbWalletParams) => {
  const { contract, wallet } = payload;
  try {
    let accounts;
    if (wallet === WALLET_CONNECT) {
      accounts = await contract.accounts;
    } else if (wallet === COIN_BASE) {
      accounts = await contract._addresses;
    } else if (wallet === LEDGER_LIVE) {
      accounts = await contract.enabled;
    } else {
      accounts = await contract.request({ method: ETH_REQUEST_ACCOUNTS });
    }
    console.log(accounts, "test-2");
    if (accounts && accounts.length !== 0) {
      const account = accounts[0];
      const provider = new ethers.providers.Web3Provider(contract);
      const signer = provider.getSigner();
      const sdkInstance = StkBNBWebSDK.getInstance({
        signerOrProvider: signer,
        env: SDK_ENV,
        numConfirmations: Number(NO_OF_BLOCK_CONFIRMATIONS)
      });
      const walletResponse = {
        walletType: wallet,
        address: account
      };
      window.localStorage.setItem(
        "bnb-walletInfo",
        JSON.stringify(walletResponse)
      );
      useBnbStore.getState().walletDataActions.setWalletInfo({
        contract: sdkInstance,
        walletConnection: true,
        signer: signer,
        account: account
      });
      await fetchBalance({
        accountAddress: account,
        signer: signer
      });
      useBnbStore.getState().claimTransactionActions.setClaimFetchStatus(true);
      await fetchClaimAccounts({ address: account });
    }
    useBnbStore.getState().walletDataActions.setWalletLoader(false);
  } catch (e) {
    console.log(e, "wall-etre");
    useBnbStore.getState().claimTransactionActions.setClaimFetchStatus(false);
    useBnbStore.getState().walletDataActions.setWalletLoader(false);
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Unable to claim request count": "address"
    });
    genericErrorHandler(e, customScope);
  }
};

export const fetchClaimAccounts = async (payload: FetchClaimSagaParams) => {
  const { address } = payload;
  try {
    const config = await getConfig();
    const coolDownPeriod: number =
      config.cooldownPeriod.toNumber() / SECONDS_IN_A_DAY;
    const claims: Array<any> = await sdkInstance.getClaims(payload.address);
    const filteredClaims: FilteredClaims =
      await filterClaimsIntoStakingAndUnstaked(claims, coolDownPeriod);
    useBnbStore
      .getState()
      .claimTransactionActions.setClaimsList(filteredClaims);
    useBnbStore.getState().claimTransactionActions.setClaimFetchStatus(false);
  } catch (e) {
    useBnbStore.getState().claimTransactionActions.setClaimFetchStatus(false);
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Unable to claim request count": address
    });
    genericErrorHandler(e, customScope);
  }
};
// @ts-ignore
export const fetchDefiData = async () => {
  try {
    const [openLeverage, beefyInfo, wombatInfo, thena] = await Promise.all([
      fetchOpenLeverage(),
      fetchBeefyInfo(),
      fetchWambot(),
      fetchThenaInfo()
    ]);
    const pancakeInfo: InitialTvlApyTypes = await fetchPancakeInfo();
    const pancakeV3Info: InitialTvlApyTypes = await fetchPancakeV3Info();
    useBnbStore.getState().initialDataActions.setInitialDefiData({
      shieldApiInfo: initialTVLAPY,
      openLeverageApiInfo: openLeverage,
      beefyInfo: beefyInfo,
      panCakeApiInfo: pancakeInfo,
      panCakeV3ApiInfo: pancakeV3Info,
      wombatApiInfo: wombatInfo,
      thenaApiInfo: thena
    });
  } catch (e) {
    console.log(e, "error while fetching defi");
  }
};

export const fetchBalance = async (payload: FetchBalanceSagaParams) => {
  const balance = await getBNBBalance(payload.accountAddress);
  const tokenBalance = await getStkBNBBalance(payload.accountAddress);
  useBnbStore
    .getState()
    .balanceDataActions.setBnbBalance(truncateToFixedDecimalPlaces(balance));
  useBnbStore
    .getState()
    .balanceDataActions.setStkBnbBalance(
      truncateToFixedDecimalPlaces(tokenBalance)
    );
  const balances = await getTokenBalances(
    payload.signer,
    payload.accountAddress
  );
  useBnbStore.getState().balanceDataActions.setAllBnbTokenBalance(balances);
};
