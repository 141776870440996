import { StateCreator } from "zustand";
import { SliceActions, WalletSliceState } from "./types";
import {
  ChainExternalInfo,
  ExternalChains
} from "@/containers/cosmos/helpers/config";
import { ChainInfo } from "@keplr-wallet/types";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let srcChain = ExternalChains[env].find(
  (chain: ChainInfo) =>
    chain.chainId === ChainExternalInfo[env].persistence.chainID
);

const initialState: WalletSliceState = {
  walletData: {
    walletInfo: {
      dstAccountData: null,
      srcAccountData: null,
      srcChainInfo: srcChain,
      dstChainInfo: null,
      srcSigner: null,
      dstSigner: null,
      externalChainSigners: [],
      isWalletConnected: false,
      walletType: "keplr"
    },
    walletModal: false,
    walletLoader: false,
    defiActiveToken: ""
  },
  sideBar: {
    show: false
  }
};

export type WalletSlice = WalletSliceState & SliceActions;

export const createWalletDataSlice: StateCreator<WalletSlice> = (set) => ({
  ...initialState,
  walletDataActions: {
    setWalletInfo: (val) =>
      set((state) => ({
        walletData: {
          ...state.walletData,
          walletInfo: val
        }
      })),
    setWalletModal: (val) =>
      set((state) => ({
        walletData: {
          ...state.walletData,
          walletModal: val
        }
      })),
    setWalletLoader: (val) =>
      set((state) => ({
        walletData: {
          ...state.walletData,
          walletLoader: val
        }
      })),
    setDefiActiveToken: (val) =>
      set((state) => ({
        walletData: {
          ...state.walletData,
          defiActiveToken: val
        }
      })),
    handleMobileSidebar: (val) =>
      set((state) => ({
        sideBar: {
          ...state.sideBar,
          show: val
        }
      }))
  }
});
