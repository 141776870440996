import {
  getBnbExchangeRate,
  getBnbTVL,
  getConfig,
  getBnbAPY,
  getContractStatus
} from "@/api/bnb";
import { SECONDS_IN_A_DAY } from "../../../../constants/static";
import { bigNumberToEther } from "@/utils";
import { useBnbStore } from "@/containers/bnb/store";

export const fetchInit = async () => {
  const config: any = await getConfig();
  if (config) {
    console.log(config, "config-tes");
    const coolDownPeriod: number =
      config.cooldownPeriod.toNumber() / SECONDS_IN_A_DAY;
    const minBNBDeposit = Number(bigNumberToEther(config.minBNBDeposit));
    const minTokenWithdrawal = Number(
      bigNumberToEther(config.minTokenWithdrawal)
    );
    const fee = (config.fee / 100000000000) * 100;
    useBnbStore.getState().initialDataActions.setFee(fee);
    useBnbStore.getState().initialDataActions.setMinDeposit(minBNBDeposit);
    useBnbStore
      .getState()
      .initialDataActions.setMinTokenWithdrawal(minTokenWithdrawal);
    useBnbStore.getState().initialDataActions.setCoolDownPeriod(coolDownPeriod);
  }
  const contractStatus = await getContractStatus();
  useBnbStore.getState().initialDataActions.setContractStatus(contractStatus);
  const [tvu, apy] = await Promise.all([getBnbTVL(), getBnbAPY()]);
  const exchangeRate = await getBnbExchangeRate();
  useBnbStore.getState().initialDataActions.setExchangeRate(exchangeRate);
  useBnbStore.getState().initialDataActions.setApy(Number(apy));
  useBnbStore.getState().initialDataActions.setTVU(tvu);
};
