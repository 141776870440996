import { StateCreator } from "zustand";
import { Networks } from "../../helpers/config";
import { handleWalletConnection } from "../../helpers/wallets";
import { Staking, StkEth } from "../../contracts/types";
import { AlchemyProvider, JsonRpcSigner } from "@ethersproject/providers";
import { getInstance } from "../../helpers/utils";

export interface Instances {
  stkEthInstance: StkEth;
  stakingInstance: Staking;
}

export type WalletNames = "Metamask";

export interface WalletInfo {
  walletConnection: boolean;
  account: string | null;
  walletName: WalletNames | null;
}

export interface WalletSliceState {
  wallet: WalletInfo;
  tokenModal: {
    modal: boolean;
    networkToSwitch: Networks | null;
  };
  instances: Instances | null;
  network: {
    error: boolean;
    name: Networks | null;
  };
  walletSigner: JsonRpcSigner | null;
}

export interface WalletSliceActions {
  handleAddTokenNetwork: (value: Networks) => void;
  handleAddTokenModal: (value: boolean) => void;
  handleWalletSigner: (signer: JsonRpcSigner) => void;
  fetchInstances: (signer: JsonRpcSigner | AlchemyProvider) => Promise<any>;
  connectWallet: (
    contract: any,
    wallet: WalletNames,
    network: Networks
  ) => Promise<any>;
  handleWalletNetwork: (value: Networks) => void;
  handleNetworkError: (value: boolean) => void;
  resetWalletSlice: () => void;
}

export type WalletSlice = WalletSliceState & WalletSliceActions;

const initialState = {
  wallet: {
    account: "",
    walletConnection: false,
    walletName: null
  },
  walletSigner: null,
  networkError: {
    name: null,
    error: null
  },
  tokenModal: {
    modal: false,
    networkToSwitch: null
  },
  network: {
    error: false,
    name: "ethereum" as const
  },
  instances: null
};

export const createWalletSlice: StateCreator<WalletSlice> = (set) => ({
  ...initialState,
  fetchInstances: async (signer: JsonRpcSigner | AlchemyProvider) => {
    const response: Instances = await getInstance(signer);
    set((state) => ({
      ...state,
      instances: response
    }));
  },
  handleAddTokenModal: (val) =>
    set((state) => ({
      tokenModal: {
        ...state.tokenModal,
        modal: val
      }
    })),
  handleWalletSigner: (val) =>
    set(() => ({
      walletSigner: val
    })),
  handleAddTokenNetwork: (val) =>
    set((state: WalletSlice) => ({
      tokenModal: {
        ...state.tokenModal,
        networkToSwitch: val
      }
    })),
  handleWalletNetwork: (val) =>
    set((state: WalletSlice) => ({
      network: {
        ...state.network,
        name: val
      }
    })),
  handleNetworkError: (val) =>
    set((state: WalletSlice) => ({
      network: {
        ...state.network,
        error: val
      }
    })),
  connectWallet: async (
    contract: any,
    wallet: WalletNames,
    network: Networks
  ) => {
    const response: WalletInfo = await handleWalletConnection(
      contract,
      wallet,
      network
    );
    set((state) => ({
      ...state,
      wallet: response
    }));
  },
  resetWalletSlice: () => {
    set(initialState);
  }
});
