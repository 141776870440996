import React, { useEffect, useRef, useState } from "react";
import { Icon, Copy } from "@/components";
import { Button, Dropdown } from "@persistenceone/pstake-ui-components";
import {
  useOnClickOutside,
  useWindowSize,
  stringTruncate
} from "@persistenceone/pstake-ui-components";
import { BINANCE, META_MASK, OKX } from "../../../constants/static";
import CustomImage from "@/components/molecules/image";
import { registerToken } from "@/containers/bnb/helpers/wallets";
import { useBnbStore } from "@/containers/bnb/store";
import { useShallow } from "zustand/react/shallow";

const getWalletIcon = (walletName: string) => {
  if (walletName === META_MASK) {
    return "metamask";
  } else if (walletName === BINANCE) {
    return "bnb";
  } else if (walletName === OKX) {
    return "okx";
  }
  return "metamask";
};

export const LoginOptions = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [walletIcon, setWalletIcon] = useState("metamask");

  const { walletData, walletName, setWalletModal } = useBnbStore(
    useShallow((state) => ({
      walletData: state.walletData.walletInfo,
      walletName: state.walletData.walletName,
      setWalletModal: state.walletDataActions.setWalletModal
    }))
  );

  const { account, walletConnection } = walletData;

  const { isMobile } = useWindowSize();

  const disconnectHandler = async () => {
    sessionStorage.removeItem("bnb-terms");
    localStorage.removeItem("bnb-walletInfo");
    window.location.reload();
  };

  useEffect(() => {
    const wallet = getWalletIcon(walletName);
    setWalletIcon(wallet);
  }, [walletName]);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setDropdownOpen(false);
  });

  const walletHandler = () => {
    setWalletModal(true);
  };

  return (
    <div className="inline-block w-fit cursor-pointer relative">
      {walletConnection ? (
        <Dropdown
          className="text-white-100"
          closeDropdown={dropdownOpen}
          closeHandler={(value) => setDropdownOpen(value)}
          dropdownLabel={
            <div className="flex items-center">
              <span
                className={`${
                  dropdownOpen ? "pointer-events-none" : "pointer-events-auto"
                }
              flex items-center !text-sm`}
                onClick={() => {
                  setDropdownOpen(true);
                }}
              >
                <CustomImage
                  src={`/wallets/${walletIcon}.svg`}
                  alt={"logo"}
                  width={20}
                  height={20}
                />
                <span className="ml-3 text-green-500">
                  {stringTruncate(account, isMobile ? 3 : 6)}
                </span>
              </span>
            </div>
          }
          dropDownButtonClass="bg-[#181818] !text-sm !h-[37px] !font-medium text-white-100 leading-[21px] !py-2.5"
          dropdownType={"click"}
          staticBackDrop={false}
          dropDownIcon={
            <Icon
              viewClass="arrow-right fill-[#fff] !w-[10px]"
              iconName="chevron"
            />
          }
          dropDownContentClass="!bg-black-400 round-md w-max md:p-0"
        >
          <div
            className="px-4 py-3 flex items-center justify-center md:py-3
                        hover:cursor-pointer hover:rounded-md hover:bg-[#2828288a] text-black-400 whitespace-nowrap"
          >
            <div className="text-white-100 text-sm font-normal leading-normal md:text-xsm text-center flex-1">
              <Copy
                id={account}
                customView={
                  <span className={"flex"}>
                    <Icon
                      iconName="copy2"
                      viewClass="disconnect md:!w-[16px] md:!h-[16px] mr-4"
                    />
                    Copy Address
                  </span>
                }
              />
            </div>
          </div>
          {walletConnection && walletName === META_MASK ? (
            <div
              className="px-4 py-3 flex items-center md:py-3
                        hover:cursor-pointer hover:rounded-md hover:bg-[#2828288a] text-black-400 whitespace-nowrap"
              onClick={() => registerToken(walletName)}
            >
              <CustomImage
                src={`/wallets/metamask.svg`}
                alt={"logo"}
                width={16}
                height={16}
              />
              <span className="ml-4 text-white-100 text-sm font-normal leading-normal md:text-xsm md:ml-2">
                Add stkBNB
              </span>
            </div>
          ) : null}
          <div
            className="px-4 py-3 flex items-center md:py-3
                        hover:cursor-pointer hover:rounded-md hover:bg-[#2828288a] text-black-400 whitespace-nowrap"
            onClick={disconnectHandler}
          >
            <Icon
              iconName="logout"
              viewClass="disconnect md:!w-[16px] md:!h-[16px]"
            />
            <span className="ml-4 text-white-100 text-sm font-normal leading-normal md:text-xsm md:ml-2">
              Disconnect
            </span>
          </div>
        </Dropdown>
      ) : (
        <Button
          className={`button !font-medium md:text-sm ${
            dropdownOpen ? "pointer-events-none" : "pointer-events-auto"
          }
              `}
          type="primary"
          size="auto"
          disabled={false}
          content={"Connect Wallet"}
          onClick={walletHandler}
        />
      )}
    </div>
  );
};

export default LoginOptions;
