import React from "react";
import Styles from "./styles.module.css";
import Link from "next/link";
import { useWindowSize, emptyFunc } from "@persistenceone/pstake-ui-components";
import { usePathname } from "next/navigation";
import { Icon } from "@/components";
import { useEthereumStore } from "../store/store";
import BalanceList from "./balance-list";
import Tooltip from "rc-tooltip";
import { ethChainsInfo, getActiveSideBarItem } from "../../../helpers/utils";
import CustomImage from "@/components/molecules/image";

import { BUG_REPORT_URL } from "@/constants/static";

const socialList = [
  {
    url: "/",
    iconName: "twitter-logo",
    tooltip: "Twitter"
  },
  {
    url: " https://t.me/pstakefinancechat",
    iconName: "telegram-plane",
    tooltip: "Telegram"
  },
  {
    url: "https://blog.pstake.finance",
    iconName: "medium-m",
    tooltip: "Medium"
  },
  {
    url: "https://pstake.finance/atom",
    iconName: "globe",
    tooltip: "Website"
  },
  {
    url: "https://discord.com/invite/qXRmTTGcYD",
    iconName: "discord",
    tooltip: "discord"
  },
  {
    url: BUG_REPORT_URL,
    iconName: "bug",
    tooltip: "Bug Report"
  },
  {
    url: "https://docs.pstake.finance/stkETH_Overview/",
    iconName: "docs",
    tooltip: "Docs"
  }
];

const SidebarContent = () => {
  const { isMobile } = useWindowSize();
  const { handleSidebar } = useEthereumStore();
  const pathName = usePathname();
  const wallet = useEthereumStore((state) => state.wallet);
  const network = useEthereumStore((state) => state.network.name);

  const routeList = [
    {
      icon: "staking",
      text: "Staking",
      path: "/eth",
      route: "/staking"
    },
    {
      icon: "bridge",
      text: "Bridge",
      path: "/eth/bridge",
      route: "/bridge"
    },
    {
      icon: "defi",
      text: "DeFi",
      path: "/eth/defi",
      route: "/defi"
    }
  ];

  return (
    <aside className="w-[284px] md:w-[220px]">
      <div
        className={`${Styles.sideBarContent} flex flex-col justify-between overflow-y-auto sticky`}
      >
        <div>
          <div className="text-center pt-8 pb-[1.9rem]">
            <Link href="/" className="nav-link" passHref>
              <CustomImage
                src={`/logo.svg`}
                alt={"logo"}
                className="m-auto"
                height={22}
                width={isMobile ? 90 : 124}
              />
            </Link>
          </div>
          <div className="pb-4">
            {routeList.map((item, index) => (
              <li className={`list-none`} key={index}>
                <Link href={item.path} passHref>
                  <span
                    className={`py-3 px-8 flex items-center cursor-pointer ${
                      getActiveSideBarItem(item.route, pathName)
                        ? `bg-black-400 border-r-[3px] border-[#C73238] navItemActive`
                        : "group"
                    }`}
                    onClick={isMobile ? () => handleSidebar(false) : emptyFunc}
                  >
                    <span className={"mr-8 md:mr-4 "}>
                      <Icon
                        iconName={item.icon}
                        viewClass="!w-[20px] !h-auto fill-[#a6a6a6] [.navItemActive_&]:fill-[#C73238] side-bar-icon
                         group-hover:fill-[#fcfcfc]"
                      />
                    </span>
                    <span
                      className="[.navItemActive_&]:text-white-100 text-white-600 leading-6 text-base md:text-sm
                      group-hover:text-white-100"
                    >
                      {item.text}
                    </span>
                  </span>
                </Link>
              </li>
            ))}
            {wallet.walletConnection ? (
              <li className={`list-none`}>
                <Link
                  href={`${ethChainsInfo[network!].explorerUrl}/address/${
                    wallet.account
                  }`}
                  className="nav-link"
                  passHref
                  target={"_blank"}
                >
                  <span
                    className={`${Styles.navBarLink} group py-[0.625rem] sm:pb-6 px-8 flex items-center cursor-pointer`}
                    onClick={isMobile ? () => handleSidebar(false) : emptyFunc}
                  >
                    <span className={"mr-8 md:mr-4 "}>
                      <Icon
                        iconName="transactions"
                        viewClass={`!w-[18px] !h-[18px] side-bar-icon  group-hover:fill-[#fcfcfc]`}
                      />
                    </span>
                    <span className="text text-white-600 leading-6 text-base md:text-sm group-hover:text-white-100">
                      Transactions
                    </span>
                    <span>
                      <Icon
                        iconName="new-tab"
                        viewClass={`!w-[8px] !h-[8px] side-bar-icon -mb-0.5 mr-8 ml-1.5 group-hover:fill-[#fcfcfc]`}
                      />
                    </span>
                  </span>
                </Link>
              </li>
            ) : null}
          </div>
        </div>
        <div className="border-t border-solid border-[#2b2b2b]">
          <BalanceList />
          <div
            className={`socialLinks flex py-3 px-8 justify-between border-t border-solid border-[#2b2b2b]`}
          >
            {socialList.map((item, index) => (
              <Tooltip placement="bottom" overlay={item.tooltip} key={index}>
                <a
                  href={item.url}
                  rel="noopener noreferrer"
                  className="mr-2.5"
                  target="_blank"
                >
                  <Icon
                    viewClass="socialIcon fill-[#a6a6a6] !w-[12px] !h-[12px]"
                    iconName={item.iconName}
                  />
                </a>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SidebarContent;
