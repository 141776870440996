import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types/types/scope";
import { Primitive } from "@sentry/types";
import { displayToast } from "@/components";
import { ToastType } from "@/components/molecules/toast/types";
import { WalletNames } from "../store/slices/wallet-slice";
import { useEthereumStore } from "../store/store";
import { Scope } from "@sentry/nextjs";
import { Networks } from "./config";

export const getWalletProvider = (wallet: WalletNames): any => {
  let provider: any;
  switch (wallet) {
    case "Metamask":
      provider = window.ethereum;
      break;
    default:
      provider = window.ethereum;
      break;
  }
  return provider;
};

export const exceptionHandle = (
  e: any,
  sentryTag: { [key: string]: Primitive }
) => {
  displayToast(
    {
      message:
        e && e.message && e.message.length < 100
          ? e.message
          : "This transaction could not be completed"
    },
    ToastType.ERROR
  );
  useEthereumStore.getState().setTxnInfo(false, null, "failed");
  const customScope = new Scope();
  customScope.setLevel("fatal");
  customScope.setTags(sentryTag);
  sentryReport(e, customScope);
};

export const sentryReport = (exception: any, scope = new Scope()) => {
  console.log(exception);
  Sentry.captureException(exception, scope);
};

export const getLogoPath = (network: Networks) => {
  let imgUrl = "";
  switch (network) {
    case "ethereum":
      imgUrl = `/tokens/eth.svg`;
      break;
    case "optimism":
      imgUrl = `/logos/optimism.svg`;
      break;
    case "arbitrum":
      imgUrl = `/logos/arbitrum.svg`;
      break;
    default:
      imgUrl = `/tokens/eth.svg`;
  }
  return imgUrl;
};
