import Loading from "@/components/molecules/loader";
import { useRouter } from "next/router";
import NavigationBar from "@/containers/root/components/organisms/navigation";
import Sidebar from "@/containers/root/components/organisms/sidebar";
import { FeatureFlagsProvider } from "@/context/feature-flags-context";
import InitialDataProvider from "@/context/initial-data-context";
import { Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "rc-tooltip/assets/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/styles.css";
import { GA_TRACKING_ID } from "@/constants/static";
import Script from "next/script";
import * as gtag from "../utils/gtag";

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      {/* eslint-disable-next-line @next/next/inline-script-id */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
        }}
      />
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        closeButton={false}
      />
      <FeatureFlagsProvider>
        <InitialDataProvider>
          <Suspense fallback={<Loading />}>
            <div className="flex md:block bg-body-bg">
              <Sidebar />
              <div className={"mainContainer flex-1 h-screen bg-no-repeat"}>
                <NavigationBar />
                <Component {...pageProps} />
              </div>
            </div>
          </Suspense>
        </InitialDataProvider>
      </FeatureFlagsProvider>
    </>
  );
}
