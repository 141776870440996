import { AddressListProps, TokenPrices, BalanceProps } from "../types";
import { ChainInfo } from "@keplr-wallet/types";
import {
  decimalize,
  printConsole,
  RpcClient
} from "../../containers/cosmos/helpers/utils";
import { QueryClientImpl as BankQuery } from "cosmjs-types/cosmos/bank/v1beta1/query";
import { BaseAsset } from "../config/types";
import { chains, contracts } from "../../containers/cosmos/helpers/eth-config";
import {
  StkEth,
  StkEth__factory
} from "../../containers/ethereum/contracts/types";
import { BigNumberish, ethers } from "ethers";
import { LATEST } from "../../constants/static";
import { APP_ETHERS_PROVIDER, sdkInstance } from "../../constants/dynamic";
import { bigNumberToEther } from "../func";
import { getTokenImgFromDenom } from "@persistenceone/pstake-ui-components";
import { AccountData } from "@cosmjs/launchpad/build/signer";
import WalletHandler from "../../containers/cosmos/helpers/wallets";
import {
  arbitrumInitialBalances,
  bnbInitialBalances,
  ethInitialBalances,
  externalInitialCosmosBalances,
  optimismInitialBalances,
  persistenceInitialBalance
} from "./initial-data";
import { ExternalSignerInfo } from "@/containers/cosmos/store/slices/wallet-slice/types";

export const fetchBalanceWithDenom = async (
  address: string,
  rpc: string,
  denom: string
) => {
  try {
    const rpcClient = await RpcClient(rpc);
    const bankQueryService = new BankQuery(rpcClient);
    const response = await bankQueryService.Balance({
      address: address,
      denom: denom
    });
    return response.balance;
  } catch (error) {
    printConsole(error);
    return {
      denom: denom,
      amount: "0"
    };
  }
};

export const fetchAccountBalance = async (address: string, rpc: string) => {
  try {
    const rpcClient = await RpcClient(rpc);
    const bankQueryService = new BankQuery(rpcClient);
    const response = await bankQueryService.AllBalances({
      address: address
    });
    return response.balances;
  } catch (error) {
    printConsole(error);
    return [];
  }
};

export const externalCosmosBalances = async (
  addressList: AddressListProps[],
  chains: ChainInfo[],
  assetList: BaseAsset[]
): Promise<BalanceProps[]> => {
  try {
    const list: BalanceProps[] = [];
    for (const chain of chains) {
      if (chain.bech32Config.bech32PrefixAccAddr !== "persistence") {
        const account = addressList.find(
          (item) => item.prefix === chain.bech32Config.bech32PrefixAccAddr
        );
        const asset = assetList.find(
          (item) =>
            item.name.toLowerCase() ===
              chain.stakeCurrency.coinDenom.toLowerCase() &&
            item.chain !== "persistence"
        );
        const getTokenImage = getTokenImgFromDenom(asset.identifier);
        const balanceResponse: any = await fetchBalanceWithDenom(
          account.address,
          chain.rpc,
          chain.stakeCurrency.coinMinimalDenom
        );
        list.push({
          name: asset.name,
          label: asset.name,
          amount: decimalize(balanceResponse.amount, asset.coinDecimals),
          imgUrl: `${getTokenImage.tokenImg!}`,
          chain: asset.chain,
          network: asset.network,
          dollorValue: 0,
          decimals: chain.stakeCurrency.coinDecimals,
          coinMinimalDenom: chain.stakeCurrency.coinMinimalDenom
        });
      }
    }
    return list;
  } catch (e) {
    console.log(e, "error externalCosmosBalances");
    return externalInitialCosmosBalances(chains, assetList);
  }
};

export const persistenceBalance = async (
  assetList: BaseAsset[],
  address: string,
  rpc: string
): Promise<BalanceProps[]> => {
  try {
    console.log(assetList, "assetList-1");
    let list: BalanceProps[] = [];
    const balances: any = await fetchAccountBalance(address, rpc);
    console.log(balances, "balances0123");
    if (balances && balances!.length) {
      // const token = balances.find(
      //   (item) => item.denom === utilityAsset.identifier
      // );
      // const getTokenImage = getTokenImgFromDenom(utilityAsset.identifier);
      // if (token !== undefined) {
      //   list.push({
      //     name: utilityAsset.name,
      //     label: utilityAsset.identifier.startsWith("ibc/")
      //       ? `${utilityAsset.name}(IBC)`
      //       : utilityAsset.name,
      //     amount: Number(decimalize(token.amount, utilityAsset.coinDecimals)),
      //     imgUrl: getTokenImage.tokenImg,
      //     chain: utilityAsset.chain,
      //     network: utilityAsset.network,
      //     dollorValue: 0,
      //     decimals: 6,
      //     coinMinimalDenom: utilityAsset.identifier
      //   });
      // } else {
      //   list.push({
      //     name: utilityAsset.name,
      //     label: utilityAsset.identifier.startsWith("ibc/")
      //       ? `${utilityAsset.name}(IBC)`
      //       : utilityAsset.name,
      //     amount: "0",
      //     imgUrl: getTokenImage.tokenImg,
      //     chain: utilityAsset.chain,
      //     network: utilityAsset.network,
      //     dollorValue: 0,
      //     decimals: 6,
      //     coinMinimalDenom: utilityAsset.identifier
      //   });
      // }
      assetList.forEach((asset) => {
        const token = balances.find((item) => item.denom === asset.identifier);
        const getTokenImage = getTokenImgFromDenom(asset.identifier);

        if (token !== undefined) {
          list.push({
            name: asset.name,
            label: asset.identifier.startsWith("ibc/")
              ? `${asset.name}(IBC)`
              : asset.name,
            amount: Number(decimalize(token.amount, asset.coinDecimals)),
            imgUrl: `${getTokenImage.tokenImg!}`,
            chain: asset.chain,
            network: asset.network,
            dollorValue: 0,
            decimals: asset.coinDecimals,
            coinMinimalDenom: getTokenImage.minimalDenom
          });
        } else {
          list.push({
            name: asset.name,
            label: asset.identifier.startsWith("ibc/")
              ? `${asset.name}(IBC)`
              : asset.name,
            amount: "0",
            imgUrl: `${getTokenImage.tokenImg!}`,
            chain: asset.chain,
            network: asset.network,
            dollorValue: 0,
            decimals: asset.coinDecimals,
            coinMinimalDenom: getTokenImage.minimalDenom
          });
        }
      });
    } else {
      throw Error("empty list");
    }
    return list;
  } catch (e) {
    console.log(e, "error in persistenceBalance");
    return persistenceInitialBalance(assetList);
  }
};

export const fetchEthereumBalances = async (
  address: string,
  env: string
): Promise<BalanceProps[]> => {
  try {
    const list: any[] = [];
    const chain = chains[env]["ethereum"];
    const JsonRpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
    const stkETHContractAddress = contracts[env]["ethereum"]["stkETH"];

    const stkEthContract: StkEth = StkEth__factory.connect(
      stkETHContractAddress,
      JsonRpcProvider
    );

    const balance: BigNumberish = await JsonRpcProvider.getBalance(address);

    list.push({
      name: "ETH",
      amount: bigNumberToEther(balance),
      imgUrl: "/tokens/eth.svg",
      chain: "ethereum",
      label: "ETH",
      network: "ethereum",
      dollorValue: 0
    });

    const stkBalance = await stkEthContract.balanceOf(address);

    list.push({
      name: "stkETH",
      amount: bigNumberToEther(stkBalance),
      imgUrl: "/tokens/stk_eth.svg",
      chain: "ethereum",
      label: "ETH",
      network: "ethereum",
      dollorValue: 0
    });
    console.log(list, address, chain, balance, "-fetchEthereumBalances");
    return list;
  } catch (e) {
    console.log(e, "-error in fetchEthereumBalances");
    return ethInitialBalances();
  }
};

export const fetchOptimismBalances = async (
  address: string,
  env: string
): Promise<BalanceProps[]> => {
  try {
    const list: BalanceProps[] = [];
    const chain = chains[env]["optimism"];
    const JsonRpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
    const stkETHContractAddress = contracts[env]["optimism"]["stkETH"];

    const stkEthContract: StkEth = StkEth__factory.connect(
      stkETHContractAddress,
      JsonRpcProvider
    );

    const balance: BigNumberish = await JsonRpcProvider.getBalance(address);

    list.push({
      name: "ETH",
      label: "ETH",
      amount: bigNumberToEther(balance),
      imgUrl: "/tokens/eth_optimism.svg",
      chain: "optimism",
      network: "optimism",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    });
    const stkBalance = await stkEthContract.balanceOf(address);

    list.push({
      name: "stkETH",
      label: "stkETH",
      amount: bigNumberToEther(stkBalance),
      imgUrl: "/tokens/stketh_optimism.svg",
      chain: "optimism",
      network: "optimism",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    });

    return list;
  } catch (e) {
    console.log(e, "-error in fetchOptimismBalances");
    return optimismInitialBalances();
  }
};

export const fetchArbitrumBalances = async (
  address: string,
  env: string
): Promise<BalanceProps[]> => {
  try {
    const list: BalanceProps[] = [];
    const chain = chains[env]["arbitrum"];
    const JsonRpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
    const stkETHContractAddress = contracts[env]["arbitrum"]["stkETH"];

    const stkEthContract: StkEth = StkEth__factory.connect(
      stkETHContractAddress,
      JsonRpcProvider
    );

    const balance: BigNumberish = await JsonRpcProvider.getBalance(address);

    list.push({
      name: "ETH",
      label: "ETH",
      amount: bigNumberToEther(balance),
      imgUrl: "/logos/arbitrum.svg",
      chain: "arbitrum",
      network: "arbitrum",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    });
    const stkBalance = await stkEthContract.balanceOf(address);

    list.push({
      name: "stkETH",
      label: "stkETH",
      amount: bigNumberToEther(stkBalance),
      imgUrl: "/tokens/stketh_arbitrum.svg",
      chain: "arbitrum",
      network: "arbitrum",
      dollorValue: 0,
      coinMinimalDenom: "eth",
      decimals: 18
    });

    return list;
  } catch (e) {
    console.log(e, "-error in fetchArbitrumBalances");
    return arbitrumInitialBalances();
  }
};

export const fetchBnbBalances = async (address: string) => {
  try {
    console.log("fetchBnbBalances");
    const bnbBalanceResponse = await APP_ETHERS_PROVIDER.getBalance(
      address,
      LATEST
    );
    const bnbBalance = bigNumberToEther(bnbBalanceResponse);
    const stkBblResponse = await sdkInstance.stkBNB.balanceOf(address);
    const stkBnb = bigNumberToEther(stkBblResponse);
    const list: BalanceProps[] = [];
    list.push(
      {
        name: "BNB",
        amount: bnbBalance,
        label: "BNB",
        imgUrl: "/tokens/bnb.svg",
        chain: "BNB Smart Chain",
        network: "BNB Smart Chain",
        dollorValue: 0,
        coinMinimalDenom: "bnb",
        decimals: 18
      },
      {
        name: "stkBNB",
        amount: stkBnb,
        label: "stkBNB",
        imgUrl: "/tokens/stk_bnb.svg",
        chain: "BNB Smart Chain",
        network: "BNB Smart Chain",
        dollorValue: 0,
        coinMinimalDenom: "bnb",
        decimals: 18
      }
    );
    return list;
  } catch (e) {
    console.log(e, "error-called bnbInitialBalances");
    return bnbInitialBalances();
  }
};

export const fetchTokenPrices = async (): Promise<TokenPrices> => {
  let data: TokenPrices = {
    stkATOM: 0,
    ATOM: 0,
    XPRT: 0,
    OSMO: 0,
    stkBNB: 0,
    stkETH: 0,
    ETH: 0,
    stkOSMO: 0,
    WETH: 0,
    WBNB: 0,
    BNB: 0,
    DAI: 0,
    USDC: 0,
    USDT: 0,
    DV4TNT: 0,
    stkDV4TNT: 0,
    stkXPRT: 0,
    DYDX: 0,
    stkDYDX: 0,
    STARS: 0,
    stkSTARS: 0,
    BLD: 0,
    stkBLD: 0,
    HUAHUA: 0,
    stkHUAHUA: 0
  };
  try {
    const ipfs = process.env.NEXT_PUBLIC_IPFS_DEPLOYMENT;
    let pricesResponse: any;
    if (ipfs === "true") {
      const tokens = [
        "ethereum",
        "pstake-staked-bnb",
        "cosmos",
        "persistence",
        "osmosis",
        "weth",
        "binancecoin",
        "dydx-chain",
        "stargaze",
        "agoric",
        "chihuahua-token"
      ];
      const pricesData = await fetch(
        `https://pro-api.coingecko.com/api/v3/simple/price?ids=${tokens.join(
          ","
        )}&vs_currencies=usd`,
        {
          headers: {
            "x-cg-pro-api-key": process.env.NEXT_PUBLIC_COINGECKO_API_KEY!
          }
        }
      );
      pricesResponse = await pricesData.json();
    } else {
      const response = await fetch(`/api/prices`);
      pricesResponse = await response.json();
    }
    data.stkBNB = Number(pricesResponse.data["pstake-staked-bnb"].usd);
    data.ATOM = Number(pricesResponse.data["cosmos"].usd);
    data.XPRT = Number(pricesResponse.data["persistence"].usd);
    data.OSMO = Number(pricesResponse.data["osmosis"].usd);
    data.ETH = Number(pricesResponse.data["ethereum"].usd);
    data.WETH = Number(pricesResponse.data["weth"].usd);
    data.BNB = Number(pricesResponse.data["binancecoin"].usd);
    data.DV4TNT = Number(pricesResponse.data["dydx-chain"].usd);
    data.DYDX = Number(pricesResponse.data["dydx-chain"].usd);
    data.STARS = Number(pricesResponse.data["stargaze"].usd);
    data.BLD = Number(pricesResponse.data["agoric"].usd);
    data.HUAHUA = Number(pricesResponse.data["chihuahua-token"].usd);
    return data;
  } catch (e) {
    return data;
  }
};
export const fetchCosmosBalances = async (
  walletType,
  ExternalChains: ChainInfo[],
  baseAssetList: BaseAsset[],
  stkAssetList: BaseAsset[]
): Promise<BalanceProps[]> => {
  try {
    const chains = ExternalChains;
    let persistenceChainInfo = chains.find(
      (chain: ChainInfo) =>
        chain.bech32Config.bech32PrefixAccAddr === "persistence"
    );
    let chainResponse: ExternalSignerInfo[] = [];
    let walletResponse = await WalletHandler(chains, walletType);
    for (const wallet in walletResponse) {
      let accountResponse: readonly AccountData[] = await walletResponse[
        wallet
      ]!.getAccounts();
      const prefix = accountResponse[0]!.address.split("1")[0];
      chainResponse.push({
        prefix: prefix,
        signer: walletResponse[wallet],
        address: accountResponse[0]!.address
      });
    }
    const srcWalletResponse = chainResponse.find(
      (item) => item.prefix === "persistence"
    ).signer;
    let srcAccountResponse: readonly AccountData[] =
      await srcWalletResponse!.getAccounts();

    const baseList = baseAssetList.filter(
      (item) => item.chain === "persistence"
    );

    const stkList = stkAssetList.filter((item) => item.chain === "persistence");
    const externalAssets = baseAssetList
      .concat(stkAssetList)
      .filter(
        (item) => item.network === "cosmos" && item.chain !== "persistence"
      );

    const pBalance = await persistenceBalance(
      baseList.concat(stkList),
      srcAccountResponse[0].address,
      persistenceChainInfo!.rpc
    );

    const externalChainBalance = await externalCosmosBalances(
      chainResponse,
      chains,
      externalAssets
    );
    console.log(externalChainBalance, "externalChainBalance");
    return pBalance.concat(externalChainBalance);
  } catch (e) {
    console.log(e);
  }
};
