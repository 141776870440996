import { FetchBalanceParams } from "../slices/balance-slice";
import {
  fetchAccountBalance,
  fetchUnbondingList,
  getChainTVU,
  getDelegations,
  getMaxRedeem,
  getTokenizedShares
} from "@/api/cosmos/on-chain";
import {
  baseAssetList,
  cosmosStkAssetList,
  externalChains
} from "../../../../helpers/utils";
import {
  DelegatedValidators,
  FetchDelegatedValidatorsParams,
  FetchTokenizeSharesParams
} from "../slices/transaction-slices/stake/types";
import { ChainInfo } from "@keplr-wallet/types";
import {
  ConnectCosmosWalletParams,
  ExternalSignerInfo
} from "@/containers/cosmos/store/slices/wallet-slice/types";
import { ExternalChains } from "@/containers/cosmos/helpers/config";
import {
  externalCosmosBalances,
  persistenceBalance,
  utilityAsset
} from "@/utils";
import WalletHandler from "@/containers/cosmos/helpers/wallets";
import { AccountData } from "@cosmjs/launchpad/build/signer";
import { useAppStore } from "@/store/store";
import { useCosmosStore } from "@/containers/cosmos/store";
import { FetchLiveDataParams } from "../slices/live-data/types";
import { FetchPendingClaimSagaParams } from "../slices/unbondigs-slice/types";
import { displayToast } from "@/components";
import { ToastType } from "@/components/molecules/toast/types";
import { Cosmos } from "@/helpers/feature-flags";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const connectCosmosWallet = async (
  payload: ConnectCosmosWalletParams
) => {
  const { walletType, dstChainPrefix, experimentalChains } = payload;
  try {
    const chains = ExternalChains[env].filter((chain) => {
      const key = experimentalChains[chain.bech32Config.bech32PrefixAccAddr];
      if (key === undefined || key) {
        return chain;
      }
    });
    console.log(chains, "chains", dstChainPrefix, experimentalChains);
    let destChainData = chains.find(
      (chain: ChainInfo) =>
        chain.bech32Config.bech32PrefixAccAddr === dstChainPrefix
    );
    let persistenceChainInfo = chains.find(
      (chain: ChainInfo) =>
        chain.bech32Config.bech32PrefixAccAddr === "persistence"
    );

    let chainResponse: ExternalSignerInfo[] = [];
    let walletResponse = await WalletHandler(chains, walletType);
    for (const wallet in walletResponse) {
      let accountResponse: readonly AccountData[] = await walletResponse[
        wallet
      ]!.getAccounts();
      const prefix = accountResponse[0]!.address.split("1")[0];
      chainResponse.push({
        prefix: prefix,
        signer: walletResponse[wallet],
        address: accountResponse[0]!.address
      });
    }

    console.log(chainResponse, "chainResponse");
    const srcWalletResponse = chainResponse.find(
      (item) => item.prefix === "persistence"
    )!.signer;

    const dstWalletResponse = chainResponse.find(
      (item) => item.prefix === dstChainPrefix
    )!.signer;

    let srcAccountResponse: readonly AccountData[] =
      await srcWalletResponse!.getAccounts();
    let dstAccountResponse: readonly AccountData[] =
      await dstWalletResponse!.getAccounts();

    //TODO: Update after STKXPRT launch
    const cosmosBaseAssetList = baseAssetList.filter((asset) => {
      return asset.networkLabel === "Cosmos"
        ? (asset.name === "XPRT" ? (Cosmos.xprt ? asset : null) : asset) &&
            (asset.name === "BLD" ? (Cosmos.stkBLD ? asset : null) : asset)
        : null; // condition to exclude certain token
    });

    const baseList = cosmosBaseAssetList.filter(
      (item) => item.chain === "persistence"
    );

    const stkList = cosmosStkAssetList.filter(
      (item) => item.chain === "persistence"
    );

    const externalAssets = cosmosBaseAssetList
      .concat(cosmosStkAssetList)
      .filter(
        (item) => item.network === "cosmos" && item.chain !== "persistence"
      );

    console.log(externalAssets, "externalAssets-2");
    useCosmosStore.getState().walletDataActions.setWalletLoader(true);
    useCosmosStore.getState().unBondingDataActions.setClaimFetchStatus(true);
    const pBalance = await persistenceBalance(
      baseList.concat(stkList),
      srcAccountResponse[0].address,
      persistenceChainInfo!.rpc
    );

    console.log(pBalance, "pBalance");
    useCosmosStore.getState().walletDataActions.setWalletInfo({
      isWalletConnected: true,
      walletType: walletType,
      externalChainSigners: chainResponse,
      srcChainInfo: persistenceChainInfo,
      srcSigner: srcWalletResponse,
      srcAccountData: srcAccountResponse[0],
      dstChainInfo: destChainData,
      dstAccountData: dstAccountResponse[0],
      dstSigner: dstWalletResponse
    });

    const walletInfo = {
      walletName: walletType,
      dstChainPrefix
    };

    window.localStorage.setItem(
      "cosmos-walletInfo",
      JSON.stringify(walletInfo)
    );
    const externalChainBalance = await externalCosmosBalances(
      chainResponse,
      chains,
      externalAssets
    );
    console.log(
      pBalance.concat(externalChainBalance),
      "334test-1",
      externalChainBalance
    );
    useAppStore
      .getState()
      .setCosmosBalances(pBalance.concat(externalChainBalance));
    fetchPendingClaims({
      address: srcAccountResponse[0]!.address,
      srcChainInfo: persistenceChainInfo!
    });
    useCosmosStore.getState().walletDataActions.setWalletLoader(false);
  } catch (e) {
    useCosmosStore.getState().walletDataActions.setWalletLoader(false);
    console.log(e.message, "e || e.message", e);
    displayToast(
      {
        message: e.message || e
      },
      ToastType.ERROR
    );
  }
};

export const fetchTokenizeShares = async (payload: FetchBalanceParams) => {
  const { srcAddress, dstAddress, srcChainInfo, dstChainInfo, validators } =
    payload;
  console.log("check-fetchTokenizeShares");
  /*TODO: make it dynamic*/
  if (dstChainInfo.bech32Config.bech32PrefixAccAddr === "cosmos") {
    console.log(
      srcAddress,
      dstAddress,
      srcChainInfo,
      dstChainInfo,
      "-cosmos fetchBalance params"
    );
    const srcBalancesList: any = await fetchAccountBalance(
      srcAddress,
      srcChainInfo.rpc
    );

    //fetch balance on destination chain
    const dstBalancesList: any = await fetchAccountBalance(
      dstAddress,
      dstChainInfo.rpc
    );

    if (
      dstBalancesList.balances.length > 0 ||
      srcBalancesList.balances.length > 0
    ) {
      fetchTokenizeSharesList({
        address: srcAddress,
        dstAddress: dstAddress,
        srcChain: srcChainInfo!,
        dstChain: dstChainInfo!,
        srcChainBalances: srcBalancesList,
        dstChainBalances: dstBalancesList,
        validators
      });
      useCosmosStore.getState().setSrcChainBalances(srcBalancesList);
      useCosmosStore.getState().setDstChainBalances(srcBalancesList);
    }
  }
};

export const fetchPendingClaims = async (
  payload: FetchPendingClaimSagaParams
) => {
  const { address, srcChainInfo }: any = payload;
  // @ts-ignore
  const response = await fetchUnbondingList(srcChainInfo.rpc, address);
  console.log(response, "-fetchUnbondingList-");
  useCosmosStore
    .getState()
    .unBondingDataActions.setClaimableBalance(response.claimableAmount);
  useCosmosStore
    .getState()
    .unBondingDataActions.setPendingClaimList(response.list);
  useCosmosStore.getState().unBondingDataActions.setClaimFetchStatus(false);
};

export const fetchDepositBalance = async (payload: FetchLiveDataParams) => {
  let dstChain = externalChains.find(
    (chain: ChainInfo) =>
      chain.bech32Config.bech32PrefixAccAddr === payload.dstChainInfo.prefix
  );
  if (dstChain.bech32Config.bech32PrefixAccAddr !== utilityAsset.prefix) {
    const maxRedeem = await getMaxRedeem(
      payload.srcChainInfo.rpc,
      dstChain.chainId
    );
    useCosmosStore.getState().setMaxRedeem({
      chainPrefix: dstChain.bech32Config.bech32PrefixAccAddr,
      amount: maxRedeem
    });
  }
};

export const fetchChainTVUList = async (payload: FetchLiveDataParams) => {
  const tvuList = await getChainTVU(payload.srcChainInfo.rpc);
  useCosmosStore.getState().liveDataActions.setTvuList(tvuList);
};

export const fetchDelegations = async (
  payload: FetchDelegatedValidatorsParams
) => {
  useCosmosStore
    .getState()
    .stakeTransactionActions.setDelegatedValidatorsLoader(true);
  const response: DelegatedValidators = await getDelegations(
    payload.address,
    payload.chainInfo,
    payload.validators
  );
  useCosmosStore
    .getState()
    .stakeTransactionActions.setDelegatedValidators(response);
  useCosmosStore
    .getState()
    .stakeTransactionActions.setDelegatedValidatorsLoader(false);
};

export const fetchTokenizeSharesList = async (
  payload: FetchTokenizeSharesParams
): Promise<any> => {
  useCosmosStore
    .getState()
    .stakeTransactionActions.setTokenizeSharesLoader(true);
  const sharesOnPersistence = await getTokenizedShares(
    payload.srcChainBalances,
    payload!.address,
    payload.srcChain!,
    payload.dstChain!,
    "persistence",
    "cosmos",
    payload.validators
  );
  console.log(sharesOnPersistence, "sharesOnPersistence");
  const sharesOnCosmos = await getTokenizedShares(
    payload.dstChainBalances,
    payload.dstAddress,
    payload.dstChain!,
    payload.dstChain!,
    "cosmos",
    "cosmos",
    payload.validators
  );
  console.log(sharesOnCosmos, "sharesOnCosmos");
  useCosmosStore.getState().stakeTransactionActions.setTokenizedShares({
    sharesOnDestinationChain: sharesOnCosmos,
    sharesOnSourceChain: sharesOnPersistence
  });
  useCosmosStore
    .getState()
    .stakeTransactionActions.setTokenizeSharesLoader(false);
};
