import { BaseAsset } from "@/utils";
import { BridgeTokenList, BridgeNetworkList } from "./types";

export type Networks = "ethereum" | "optimism" | "arbitrum";

export interface ChainInfo {
  networkID: number;
  networkIdHex: string;
  network: string;
  networkName: string;
  rpcUrl: string;
  explorerUrl: string;
}

export type ChainList = {
  [index: string | "Testnet" | "Mainnet" | "Devnet"]: {
    [key in Networks]: ChainInfo;
  };
};

export const chains: ChainList = {
  Devnet: {
    ethereum: {
      networkID: 900,
      networkIdHex: "0x384",
      networkName: "Goerli Devnet",
      network: "goerli",
      rpcUrl: "https://geth.devnet.eth.pstake.finance",
      explorerUrl: "https://goerli.etherscan.io"
    },
    optimism: {
      networkID: 901,
      network: "optimism-goerli",
      networkIdHex: "0x385",
      networkName: "Optimism Goerli (devnet)",
      rpcUrl: "https://op.geth.devnet.eth.pstake.finance",
      explorerUrl: "https://goerli-explorer.optimism.io"
    },
    arbitrum: {
      networkID: 421613,
      network: "optimism-goerli",
      networkIdHex: "0x66eed",
      networkName: "Arbitrum Testnet (devnet)",
      rpcUrl:
        "https://arb-goerli.g.alchemy.com/v2/l-RCvRRC8jRWi8uZKccl1l4F2W1Cf9yL",
      explorerUrl: "https://testnet.arbiscan.io"
    }
  },
  Testnet: {
    ethereum: {
      networkID: 5,
      networkIdHex: "0x5",
      networkName: "Goerli Testnet",
      network: "goerli",
      rpcUrl:
        "https://eth-goerli.g.alchemy.com/v2/xxBSEQUWrcgWH9Uqqy-0SModdapVP7Ef",
      explorerUrl: "https://goerli.etherscan.io"
    },
    optimism: {
      networkID: 420,
      network: "optimism-goerli",
      networkIdHex: "0x1a4",
      networkName: "Optimism Goerli (testnet)",
      rpcUrl:
        "https://opt-goerli.g.alchemy.com/v2/ngcfNAzcmrlqiAvFcJz0R281DxhU_pTi",
      explorerUrl: "https://goerli-explorer.optimism.io"
    },
    arbitrum: {
      networkID: 421613,
      network: "Arbitrum Testnet",
      networkIdHex: "0x66eed",
      networkName: "Arbitrum Testnet",
      rpcUrl:
        "https://arb-goerli.g.alchemy.com/v2/l-RCvRRC8jRWi8uZKccl1l4F2W1Cf9yL",
      explorerUrl: "https://testnet.arbiscan.io/"
    }
  },
  Mainnet: {
    ethereum: {
      networkID: 1,
      networkIdHex: "0x1",
      network: "mainnet",
      networkName: "Ethereum",
      rpcUrl:
        "https://eth-mainnet.g.alchemy.com/v2/M-n1pPlyw7XQTqSyYe83UiEUJHM5uaM8",
      explorerUrl: "https://etherscan.io"
    },
    optimism: {
      networkID: 10,
      network: "optimism",
      networkIdHex: "0xA",
      networkName: "Optimism",
      rpcUrl: "https://mainnet.optimism.io",
      explorerUrl: "https://optimistic.etherscan.io"
    },
    arbitrum: {
      networkID: 42161,
      network: "arbitrum",
      networkIdHex: "0xa4b1",
      networkName: "Arbitrum One",
      rpcUrl:
        "https://arb-goerli.g.alchemy.com/v2/l-RCvRRC8jRWi8uZKccl1l4F2W1Cf9yL",
      explorerUrl: "https://testnet.arbiscan.io/"
    }
  }
};

// provide exact name of all the contract json files. ex:ethContract.json
export type ContractName = "stkETH" | "staking";

export type ContractList = {
  [index: string | "Testnet" | "Mainnet" | "Devnet"]: {
    [key in Networks]: {
      [key in ContractName]: string;
    };
  };
};

export const messengerId: any = {
  Devnet: 0,
  Testnet: 0,
  Mainnet: 0
};

export const arbitrumMessenger: any = {
  Devnet: "0xAF6A0a4e14c8E02Ad32Ba02b1f115bA44ccad00e",
  Testnet: "0xAF6A0a4e14c8E02Ad32Ba02b1f115bA44ccad00e",
  Mainnet: "0xAF6A0a4e14c8E02Ad32Ba02b1f115bA44ccad00e"
};

export const l2ArbitrumMessenger: any = {
  Devnet: "0xAF6A0a4e14c8E02Ad32Ba02b1f115bA44ccad00e",
  Testnet: "0xAF6A0a4e14c8E02Ad32Ba02b1f115bA44ccad00e",
  Mainnet: "0xAF6A0a4e14c8E02Ad32Ba02b1f115bA44ccad00e"
};

export const wethContractAddress = {
  Testnet: {
    ethereum: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    optimism: "0x4200000000000000000000000000000000000006",
    arbitrum: "0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3"
  },
  Mainnet: {
    ethereum: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    optimism: "0x4200000000000000000000000000000000000006",
    arbitrum: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
  }
};

export const contracts: ContractList = {
  Devnet: {
    ethereum: {
      stkETH: "0xebA68aF5a384D29F1Dff8fBfAa1fCe1517e1F459",
      staking: "0xE0D56558351AF8a5f8300A6fA504d7305E4a276f"
    },
    optimism: {
      stkETH: "0x0D9105b0A9994AB63C4516d3A2546E8f45BEDB88",
      staking: "0x4632E3c61E5f1CB89cAB4624ECac7c707960E164"
    },
    arbitrum: {
      stkETH: "0xCEc13e113f54d9e1984416Eff18214Ef3F140B77",
      staking: "0xCC8Af5814E856813c23aba2775AD294d8d62b804"
    }
  },
  Testnet: {
    ethereum: {
      stkETH: "0xebA68aF5a384D29F1Dff8fBfAa1fCe1517e1F459",
      staking: "0xE0D56558351AF8a5f8300A6fA504d7305E4a276f"
    },
    optimism: {
      stkETH: "0x0D9105b0A9994AB63C4516d3A2546E8f45BEDB88",
      staking: "0xCEc13e113f54d9e1984416Eff18214Ef3F140B77"
    },
    arbitrum: {
      stkETH: "0xCEc13e113f54d9e1984416Eff18214Ef3F140B77",
      staking: "0x9c67130C8c2E3807553ce4a85Bf5044Cd4A9d77d"
    }
  },
  Mainnet: {
    ethereum: {
      stkETH: "0xebA68aF5a384D29F1Dff8fBfAa1fCe1517e1F459",
      staking: "0xE0D56558351AF8a5f8300A6fA504d7305E4a276f"
    },
    optimism: {
      stkETH: "0x0D9105b0A9994AB63C4516d3A2546E8f45BEDB88",
      staking: "0xCEc13e113f54d9e1984416Eff18214Ef3F140B77"
    },
    arbitrum: {
      stkETH: "0xCEc13e113f54d9e1984416Eff18214Ef3F140B77",
      staking: "0x9c67130C8c2E3807553ce4a85Bf5044Cd4A9d77d"
    }
  }
};

export const erc20TokenAddresses: any = {
  Testnet: {
    ethereum: {
      USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      DAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      WBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      stkETH: "0x2C5Bcad9Ade17428874855913Def0A02D8bE2324"
    },
    optimism: {},
    arbitrum: {}
  },
  Mainnet: {
    ethereum: {
      USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      DAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      WBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      stkETH: "0x2C5Bcad9Ade17428874855913Def0A02D8bE2324"
    },
    optimism: {},
    arbitrum: {}
  }
};
export const nativeBridge: any = {
  Testnet: {
    ethereum: "",
    optimism: " https://app.optimism.io/bridge/deposit",
    arbitrum: "https://bridge.arbitrum.io/?l2ChainId=421613"
  },
  Mainnet: {
    ethereum: "",
    optimism: "",
    arbitrum: ""
  }
};

export const bridgeTokenList: BridgeTokenList[] = [
  {
    token: "stkETH",
    chain: "ethereum",
    imgUrl: "/tokens/stk_eth.svg",
    network: "ethereum"
  },
  {
    token: "ETH",
    imgUrl: "/tokens/eth.svg",
    chain: "ethereum",
    network: "ethereum"
  }
];

export const bridgeNetworkList: BridgeNetworkList[] = [
  {
    name: "optimism",
    image: `/logos/optimism.svg`,
    type: "l2"
  },
  {
    name: "arbitrum",
    image: `/logos/arbitrum.svg`,
    type: "l2"
  },
  {
    name: "ethereum",
    image: `/tokens/ethereum.svg`,
    type: "l1"
  }
];

export const ethereumTokens: BaseAsset[] = [
  {
    name: "WETH",
    identifier: "weth",
    coinDecimals: 18,
    ibcAsset: false,
    prefix: "",
    imgUrl: "/tokens/weth.svg",
    chain: "ethereum",
    network: "ethereum",
    pair: "stkETH"
  },
  {
    name: "WETH",
    identifier: "weth",
    coinDecimals: 18,
    ibcAsset: false,
    prefix: "",
    imgUrl: "/tokens/eth_optimism.svg",
    chain: "optimism",
    network: "optimism",
    pair: "stkETH"
  },
  {
    name: "WETH",
    identifier: "weth",
    coinDecimals: 18,
    ibcAsset: false,
    prefix: "",
    imgUrl: "/tokens/eth_arbitrum.svg",
    chain: "arbitrum",
    network: "arbitrum",
    pair: "stkETH"
  }
];
