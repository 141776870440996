import { StateCreator } from "zustand";
import { SliceActions, ClaimTransactionState } from "./types";

const initialState: ClaimTransactionState = {
  claimTransaction: {
    claims: {
      unstaking: [],
      unstaked: [],
      activeClaim: 0,
      unstakingAmount: 0
    },
    claimsFetchStatus: false
  }
};

export type ClaimTransactionSlice = ClaimTransactionState & SliceActions;

export const createClaimTransactionSlice: StateCreator<
  ClaimTransactionSlice
> = (set) => ({
  ...initialState,
  claimTransactionActions: {
    setClaimsList: (val) =>
      set((state) => ({
        claimTransaction: {
          ...state.claimTransaction,
          claims: val
        }
      })),
    setClaimFetchStatus: (val) =>
      set((state) => ({
        claimTransaction: {
          ...state.claimTransaction,
          claimsFetchStatus: val
        }
      }))
  }
});
