import { ChainInfo } from "@keplr-wallet/types";
import { OfflineSigner } from "@cosmjs/launchpad";
import { OfflineDirectSigner } from "@cosmjs/proto-signing";
import { WalletType } from "@/containers/cosmos/store/slices/wallet-slice/types";

declare global {
  interface Window {
    keplr: any;
    cosmostation?: any;
    leap: any;
  }
}

export const WalletHandler = async (
  chains: ChainInfo[],
  wallet: WalletType
): Promise<OfflineSigner | OfflineDirectSigner[]> => {
  console.log(wallet, chains, "WalletHandler-111");
  const response: OfflineSigner | OfflineDirectSigner[] = [];
  const chainIdList: string[] = [];
  let extension: any = undefined;
  if (wallet === "cosmosStation") {
    extension = window.cosmostation && window.cosmostation?.providers.keplr;
  } else if (wallet === "leap") {
    extension = window.leap;
  } else {
    extension = window.keplr;
  }
  if (!extension) {
    throw new Error(`install ${wallet} extension`);
  } else {
    if (extension.experimentalSuggestChain) {
      for (const chainItem in chains) {
        chainIdList.push(chains[chainItem].chainId);
        console.log(chains[chainItem], chainItem, "in0sde");
        try {
          await extension.experimentalSuggestChain({
            chainId: chains[chainItem].chainId,
            chainName: chains[chainItem].chainName,
            rpc: chains[chainItem].rpc,
            rest: chains[chainItem].rest,
            stakeCurrency: chains[chainItem].stakeCurrency,
            walletUrlForStaking: `https://wallet.persistence.one/`,
            bip44: {
              coinType: chains[chainItem].bip44.coinType
            },
            bech32Config: chains[chainItem].bech32Config,
            currencies: chains[chainItem].currencies,
            feeCurrencies: chains[chainItem].feeCurrencies,
            coinType: chains[chainItem].bip44.coinType,
            gasPriceStep: chains[chainItem].gasPriceStep,
            features: ["ibc-transfer", "ibc-go"]
          });
        } catch (e) {
          console.log(e, "wallet-error");
          throw new Error("Failed to suggest the chain");
        }
      }
    } else {
      throw new Error("Please use the recent version of extension");
    }
  }

  console.log(chainIdList, "chainIdList");
  if (wallet === "leap") {
    let trys = 3;
    while (trys > 0)
      try {
        await extension.enable(chainIdList);
        break;
      } catch (err) {
        console.log(err);
        trys--;
      }
  } else {
    await extension.enable(chainIdList);
  }
  for (const chainItem in chainIdList) {
    const offlineSigner = await extension.getOfflineSignerAuto!(
      chainIdList[chainItem]
    );
    response.push(offlineSigner);
  }
  console.log(response, "response-123");
  return response;
};

export default WalletHandler;
