import { StateCreator } from "zustand";
import { SliceActions, UnBondingInfoState } from "./types";

const initialState: UnBondingInfoState = {
  unBondingData: {
    pendingClaimList: [],
    claimableBalance: 0,
    claimFetchStatus: false
  }
};

export type UnBondingInfoSlice = UnBondingInfoState & SliceActions;

export const createUnBondingInfoSlice: StateCreator<UnBondingInfoSlice> = (
  set
) => ({
  ...initialState,
  unBondingDataActions: {
    setPendingClaimList: (val) =>
      set((state) => ({
        unBondingData: {
          ...state.unBondingData,
          pendingClaimList: val
        }
      })),
    setClaimableBalance: (val) =>
      set((state) => ({
        unBondingData: {
          ...state.unBondingData,
          claimableBalance: val
        }
      })),
    setClaimFetchStatus: (val) =>
      set((state) => ({
        unBondingData: {
          ...state.unBondingData,
          claimFetchStatus: val
        }
      }))
  }
});
