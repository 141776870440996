import { StateCreator } from "zustand";
import { TokenPrices } from "@/utils";
export type EnvTypes = "Testnet" | "Mainnet" | string;
const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export interface AppSliceState {
  activeNetwork: string;
  tokenPrices: TokenPrices;
  activeEnv: EnvTypes;
  networkStatus: boolean;
}

export interface AppSliceActions {
  setActiveNetwork: (val: string) => void;
  setActiveEnv: (val: EnvTypes) => void;
  setTokenPrices: (val: TokenPrices) => void;
  setNetworkStatus: (val: boolean) => void;
}

const initialState: AppSliceState = {
  activeNetwork: "cosmos",
  activeEnv: env,
  tokenPrices: {
    stkATOM: 0,
    ATOM: 0,
    XPRT: 0,
    OSMO: 0,
    stkBNB: 0,
    stkETH: 0,
    ETH: 0,
    stkOSMO: 0,
    WETH: 0,
    BNB: 0,
    USDC: 0,
    USDT: 0,
    DAI: 0,
    WBNB: 0,
    DV4TNT: 0,
    stkDV4TNT: 0,
    stkXPRT: 0,
    DYDX: 0,
    stkDYDX: 0,
    STARS: 0,
    stkSTARS: 0,
    BLD: 0,
    stkBLD: 0,
    HUAHUA: 0,
    stkHUAHUA: 0
  },
  networkStatus: false
};

export type AppSlice = AppSliceState & AppSliceActions;

export const createAppSlice: StateCreator<AppSlice> = (set) => ({
  ...initialState,
  setActiveNetwork: (val) => {
    set({ activeNetwork: val });
  },
  setActiveEnv: (val) => {
    set({ activeEnv: val });
  },
  setTokenPrices: (val) => {
    set({ tokenPrices: val });
  },
  setNetworkStatus: (val) => {
    set({ networkStatus: val });
  }
});
