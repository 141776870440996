import { StateCreator } from "zustand";
import { BalanceProps } from "@/utils";

export type SupportedNetworks =
  | "cosmos"
  | "ethereum"
  | "bnb"
  | "optimism"
  | "arbitrum";

export type AllBalances = { [key in SupportedNetworks]: BalanceProps[] };

export type BalanceSliceState = {
  allBalance: {
    [key in SupportedNetworks]: BalanceProps[];
  };
};

export interface AppSliceActions {
  setCosmosBalances: (val: any) => void;
  setBnbBalances: (val: any) => void;
  setEthereumBalances: (val: any) => void;
  setArbitrumBalances: (val: any) => void;
  setOptimismBalances: (val: any) => void;
}

const initialState: BalanceSliceState = {
  allBalance: {
    ethereum: [],
    optimism: [],
    bnb: [],
    cosmos: [],
    arbitrum: []
  }
};

export type BalanceSlice = BalanceSliceState & AppSliceActions;

export const createBalanceSlice: StateCreator<BalanceSlice> = (set) => ({
  ...initialState,
  setCosmosBalances: (val) =>
    set((state) => ({
      allBalance: {
        ...state.allBalance,
        cosmos: val
      }
    })),
  setBnbBalances: (val) =>
    set((state) => ({
      allBalance: {
        ...state.allBalance,
        bnb: val
      }
    })),
  setEthereumBalances: (val) =>
    set((state) => ({
      allBalance: {
        ...state.allBalance,
        ethereum: val
      }
    })),
  setOptimismBalances: (val) =>
    set((state) => ({
      allBalance: {
        ...state.allBalance,
        optimism: val
      }
    })),
  setArbitrumBalances: (val) =>
    set((state) => ({
      allBalance: {
        ...state.allBalance,
        arbitrum: val
      }
    }))
});
