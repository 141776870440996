"use client";
import React from "react";
import { usePathname } from "next/navigation";
import CosmosSidebar from "../../../../cosmos/sidebar";
import EthereumSideBar from "../../../../ethereum/sidebar";
import BnbSidebar from "../../../../bnb/sidebar";

const Sidebar = () => {
  const pathname = usePathname();
  return (
    <>
      {pathname.includes("eth") ? (
        <EthereumSideBar />
      ) : pathname.includes("bnb") ? (
        <BnbSidebar />
      ) : pathname.includes("cosmos") ? (
        <CosmosSidebar />
      ) : null}
    </>
  );
};

export default Sidebar;
