import { StateCreator } from "zustand";
import { SliceActions, InitialDataState } from "./types";
import { COOLDOWN_PERIOD } from "@/constants/static";

const initialBNBPrice = 0;
const initialExchangeRate = 1;
const initialTVL = 0;
const initialMinTokenWithdraw = 0.000001;
export const initialTVLAPY = { tvl: 0.0, total_apy: 0.0 };

const initialState: InitialDataState = {
  initialData: {
    exchangeRate: initialExchangeRate,
    tvu: initialTVL,
    minDeposit: 0,
    contractStatus: false,
    coolDownPeriod: COOLDOWN_PERIOD,
    minWithdrawal: initialMinTokenWithdraw,
    bnbPrice: initialBNBPrice,
    maxClaimableBalance: 0,
    apy: 0,
    fee: 0,
    defiData: {
      beefyInfo: initialTVLAPY,
      wombatApiInfo: initialTVLAPY,
      openLeverageApiInfo: initialTVLAPY,
      shieldApiInfo: initialTVLAPY,
      panCakeApiInfo: initialTVLAPY,
      panCakeV3ApiInfo: initialTVLAPY,
      thenaApiInfo: initialTVLAPY
    }
  }
};

export type InitialDataSlice = InitialDataState & SliceActions;

export const createInitialDataSlice: StateCreator<InitialDataSlice> = (
  set
) => ({
  ...initialState,
  initialDataActions: {
    setExchangeRate: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          exchangeRate: val
        }
      })),
    setMaxClaimable: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          maxClaimableBalance: val
        }
      })),
    setTVU: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          tvu: val
        }
      })),
    setMinDeposit: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          minDeposit: val
        }
      })),
    setMinTokenWithdrawal: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          minWithdrawal: val
        }
      })),
    setCoolDownPeriod: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          coolDownPeriod: val
        }
      })),
    setBNBPrice: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          bnbPrice: val
        }
      })),
    setApy: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          apy: val
        }
      })),
    setFee: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          fee: val
        }
      })),
    setContractStatus: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          contractStatus: val
        }
      })),
    setInitialDefiData: (val) =>
      set((state) => ({
        initialData: {
          ...state.initialData,
          defiData: val
        }
      }))
  }
});
