import { LATEST } from "../../constants/static";
import {
  APP_ETHERS_PROVIDER,
  sdkInstance,
  SPEEDY_NODE_URL,
  STAKE_POOL_CONTRACT,
  STK_BNB_CONTRACT
} from "../../constants/dynamic";
import { genericErrorHandler } from "../../containers/bnb/helpers/utils";
import { bigNumberToEther } from "@/utils";
import * as Sentry from "@sentry/nextjs";
import { Scope } from "@sentry/nextjs";
import { StkBNBWebSDK } from "@persistenceone/stkbnb-web-sdk";
import { ethers } from "ethers";

const STK_BNB_SUBGRAPH_API =
  "https://api.thegraph.com/subgraphs/name/persistenceone/stkbnb";

export const getBNBBalance = async (account: string) => {
  try {
    const balance = await APP_ETHERS_PROVIDER.getBalance(account, LATEST);
    return bigNumberToEther(balance);
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Unable to fetch user balance": account
    });
    genericErrorHandler(e, customScope);
    return 0;
  }
};

export const getStkBNBBalance = async (account: string) => {
  try {
    const balance = await STK_BNB_CONTRACT.balanceOf(account);
    return bigNumberToEther(balance);
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Unable to fetch user stkBNB balance": account
    });
    genericErrorHandler(e, customScope);
    return 0;
  }
};

export const getBnbTVL = async () => {
  const tvl = await sdkInstance.getTvl();
  return Number(StkBNBWebSDK.format(tvl, 2));
};

export const getBnbExchangeRate = async () => {
  try {
    const exchangeRate = await sdkInstance.convertToBNB(
      ethers.constants.WeiPerEther
    );
    return Number(StkBNBWebSDK.format(exchangeRate));
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Unable to fetch exchange rate": SPEEDY_NODE_URL
    });
    genericErrorHandler(e, customScope);
    return 1;
  }
};

export const getBnbAPY = async () => {
  try {
    const res = await fetch(STK_BNB_SUBGRAPH_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
                  stats {
                    apr
                  }
             }`
      })
    });
    const responseJson = await res.json();
    if (responseJson && responseJson.data) {
      return Number(responseJson.data.stats[0].apr).toFixed(2);
    }
    return 0;
  } catch (e) {
    Sentry.captureException(e);
    return 0;
  }
};

export const getConfig = async () => {
  try {
    const config = await STAKE_POOL_CONTRACT.config();
    const minBNBDeposit = config.minBNBDeposit;
    const fee = config.fee.reward;
    const minTokenWithdrawal = config.minTokenWithdrawal;
    const cooldownPeriod = config.cooldownPeriod;
    return { minBNBDeposit, minTokenWithdrawal, cooldownPeriod, fee };
  } catch (e: any) {
    console.log(e, "-bnb getConfig");
    Sentry.captureException(e);
    return null;
  }
};

// export const getInstantClaimableBalance = async () => {
//   try {
//     const instantClaimAvailableAmount =
//       await sdkInstance.getInstantClaimAvailableAmount();
//     console.log(instantClaimAvailableAmount, "instantClaimAvailableAmount");
//     return instantClaimAvailableAmount
//       ? bigNumberToEther(instantClaimAvailableAmount)
//       : 0;
//   } catch (e) {
//     const customScope = new Scope();
//     customScope.setLevel("fatal");
//     customScope.setTags({
//       ["ERROR_WHILE_FETCHING_CLAIMABLE_BALANCE"]: "bnb",
//     });
//     genericErrorHandler(e, customScope);
//     return 0;
//   }
// };

export const getContractStatus = async () => {
  try {
    const stkBNB = await STK_BNB_CONTRACT.paused();
    const stakePool = await STAKE_POOL_CONTRACT.paused();
    return !!(stkBNB || stakePool);
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      ["ERROR_WHILE_INTERACT_CONTRACT"]: "bnb"
    });
    genericErrorHandler(e, customScope);
    return true;
  }
};

export const getEvents = async (filter: any) => {
  try {
    return await STAKE_POOL_CONTRACT.queryFilter(filter);
  } catch (e) {
    Sentry.captureException(e);
  }
};
