import React from "react";
import { Icon } from "@/components";
import {
  Button,
  ButtonLink,
  Modal
} from "@persistenceone/pstake-ui-components";
import { BUG_REPORT_URL } from "../../../constants/static";
import { useAppStore } from "@/store/store";
import { useShallow } from "zustand/react/shallow";

const NetworkCheck = () => {
  const { networkStatus, setNetworkStatus } = useAppStore(
    useShallow((state) => ({
      networkStatus: state.networkStatus,
      setNetworkStatus: state.setNetworkStatus
    }))
  );
  const closeHandler = () => {
    setNetworkStatus(false);
  };

  return (
    <Modal
      show={networkStatus}
      onClose={closeHandler}
      header=""
      className=""
      modalDialogClassName={"!max-w-[600px]"}
      staticBackDrop={false}
      closeButton={true}
    >
      <div>
        <div className="flex items-center mb-6">
          <Icon iconName="error" viewClass=" !w-[32px] !h-[32px] mr-2" />
          <p className="font-semibold text-xl leading-7 text-white-100">
            Oh no! We experienced a network error
          </p>
        </div>
        <div className={"mb-6"}>
          <p className="font-normal text-sm leading-7 text-white-200 mb-4">
            We apologise for the inconvenience. It might be an issue with your
            network connection, including a poor network signal or no internet
            access.
            <br /> In the meantime, you can try refreshing the page and if the
            issue persists, please send us a report so that we can investigate
            further and provide a solution as quickly as possible.
          </p>
        </div>
        <div className="flex items-center justify-start">
          <ButtonLink
            size="medium"
            link={BUG_REPORT_URL}
            type="primary"
            target={"_blank"}
            content={
              <div className="flex justify-center items-center">
                Send Report
                <Icon
                  iconName="arrow-redirect-white"
                  viewClass="redirect stroke-[#fcfcfc] !w-[10px] !h-[10px] ml-1"
                />
              </div>
            }
            className={`button md:text-xsm md:py-2 md:px-4 !text-sm !px-6 text-white-100 mr-2`}
          />
          <Button
            size="medium"
            type="secondary"
            onClick={closeHandler}
            content={`Continue`}
            className={`button md:text-xsm md:py-2 md:px-4 !text-sm !px-6 text-white-100`}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NetworkCheck;
