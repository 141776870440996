import { StateCreator } from "zustand";
import { SliceActions, SwapTransactionState } from "./types";
import { baseAssetList, baseAssetList2, stkAssetList } from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let initialAsset = baseAssetList2[env]["bnb smart chain"].find(
  (baseAsset) => baseAsset.name === "BNB" && !baseAsset.ibcAsset
);

let initialOutputAsset = baseAssetList2[env]["bnb smart chain"].find(
  (baseAsset) => baseAsset.name === "stkBNB" && !baseAsset.ibcAsset
);

const initialState: SwapTransactionState = {
  swapTransaction: {
    amount: "",
    showModal: false,
    txFailed: false,
    stepNumber: 0,
    swapInToken: initialAsset,
    swapOutToken: initialOutputAsset,
    swapInTokenModal: false,
    swapOutTokenModal: false,
    payLoad: "",
    socketQuoteLoading: false,
    socketQuote: null
  }
};

export type SwapTransactionSlice = SwapTransactionState & SliceActions;

export const createSwapTransactionSlice: StateCreator<SwapTransactionSlice> = (
  set
) => ({
  ...initialState,
  swapTransactionActions: {
    setSwapAmount: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          amount: val
        }
      })),
    hideTxnInfoModal: () =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          showModal: false
        }
      })),
    showTxnInfoModal: () =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          hideTxnInfoModal: true
        }
      })),
    setSwapTxnStepNumber: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          stepNumber: val
        }
      })),
    setSwapInToken: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapInToken: val
        }
      })),
    setSwapOutToken: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapOutToken: val
        }
      })),
    setSwapInTokenModal: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapInTokenModal: val
        }
      })),
    setSwapOutTokenModal: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapOutTokenModal: val
        }
      })),
    setSwapTxnSocketQuoteLoading: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          socketQuoteLoading: val
        }
      })),
    setSwapTxnQuote: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          socketQuote: val
        }
      })),
    setSwapTxnPayLoad: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          payLoad: val
        }
      }))
  }
});
