"use client";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import CustomImage from "@/components/molecules/image";
import { SelectionDrawerTypes } from "./types";
import { emptyFunc } from "@persistenceone/pstake-ui-components";
import { Icon } from "../../atoms";
import Tooltip from "rc-tooltip";
import {
  useOnClickOutside,
  useWindowKeyActions
} from "@persistenceone/pstake-ui-components";

export const SelectionDrawer = ({
  show,
  header,
  onClose = emptyFunc,
  className,
  staticBackDrop = true,
  closeButton = true,
  list = [],
  drawerName = "Token",
  onSelect = emptyFunc,
  children,
  srcAsset,
  balances,
  tokenPrices,
  connectedNetwork
}: SelectionDrawerTypes) => {
  const [balanceList, setBalanceList] = useState<any[]>([]);
  const [balanceData, setBalanceData] = useState<any[]>([]);
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, onClose);

  useEffect(() => {
    if (drawerName === "Token" && list.length > 0 && balances.length > 0) {
      let newList: any = [...list];
      const filtered = newList.map((v) => {
        const blc = balances.find(
          (blc) => blc.name === v.name && blc.chain === v.chain
        );
        return {
          ...v,
          balance: blc !== undefined ? blc.amount : "0",
          dollarValue:
            blc !== undefined ? Number(blc.amount) * tokenPrices[blc.name] : "0"
        };
      });
      const sortedData = filtered.sort(
        (a: any, b: any) => b.dollarValue - a.dollarValue
      );
      setBalanceList(sortedData);
      setBalanceData(sortedData);
    } else {
      setBalanceList(list);
      setBalanceData(list);
    }
  }, [list, balances, drawerName]);

  const searchHandler = (evt: any) => {
    const searchTerm = evt.target.value;
    const filtered = balanceData.filter((token) => {
      return token.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setBalanceList(filtered);
  };

  useWindowKeyActions({
    Escape: onClose
  });

  const onCloseProp = () => {
    onClose?.();
  };
  return (
    <>
      <Transition
        show={show}
        as={Fragment}
        enter="transition duration-300 ease-inOutBack"
        enterFrom="invisible opacity-0 translate-y-[15%]"
        enterTo="visible opacity-100 translate-y-0"
        leave="transition duration-300 ease-inOutBack"
        leaveFrom="visible opacity-100 translate-y-0"
        leaveTo="visible opacity-0 translate-y-[15%]"
      >
        <div
          className="absolute inset-0 z-20 !top-auto flex flex-col h-full w-full overflow-hidden rounded-md bg-black-400 p-8 md:p-4"
          ref={staticBackDrop ? null : modalRef}
        >
          <div
            className="relative flex items-center justify-start pb-4 cursor-pointer md:pb-2"
            onClick={() => onCloseProp()}
          >
            <Icon
              iconName="right-arrow"
              viewClass="fill-[#fcfcfc] !w-[16px] !h-[16px] arrow rotate-180 cursor-pointer mr-4"
            />
            <p className="text-white-100 font-medium text-lg md:text-base">
              {header}
            </p>
          </div>
          <p className={"text-white-600 text-[12px] mb-4 md:text-xsm md:mb-2"}>
            Select a token from our default list or search for a token by symbol
          </p>
          <div className={`w-full mb-4 mt-0 relative md:mb-2`}>
            <input
              type="text"
              className={
                "bg-black-200 py-2 pl-[40px] pr-4 text-sm text-white-100 " +
                "rounded-md font-normal border border-solid border-transparent outline-none md:py:1.5 pl-2.5 w-full " +
                "text-white-600 h-[37px] placeholder:text-white-600"
              }
              placeholder={"Search"}
              autoFocus={false}
              onChange={searchHandler}
            />
            <Icon
              iconName="search"
              viewClass={`!fill-[#A6A6A6] absolute left-[15px] top-[10px] w-[14px] h-[14px]`}
            />
          </div>

          <div
            className={
              "bg-black-600 h-auto rounded flex-1 py-[2px] px-[2px] overflow-auto"
            }
          >
            <div className={"overflow-auto flex flex-col"}>
              {balanceList.length > 0 ? (
                balanceList.map((item, index) => (
                  <div
                    className={`px-4 py-3 flex items-center justify-between hover:cursor-pointer hover:bg-black-300
                     hover:rounded-md whitespace-nowrap md:px-2.5 md:py-1.5`}
                    key={index}
                    onClick={() => {
                      onSelect(item);
                    }}
                  >
                    <div className="flex items-center">
                      <div className="mr-[10px] relative md:mr-1">
                        <CustomImage
                          src={item.imgUrl}
                          alt={item.name}
                          className={"md:!w-[28px] md:!h-[28px]"}
                          width={36}
                          height={36}
                        />
                        {(item.name.toLowerCase() ===
                          srcAsset.name.toLowerCase() &&
                          item.ibcAsset === srcAsset.ibcAsset &&
                          srcAsset.network === item.network) ||
                        (item.disable !== undefined && item.disable) ? (
                          <CustomImage
                            src={"/selected.svg"}
                            alt={item.name}
                            className={"absolute -bottom-[1px] -right-[7px]"}
                            width={14}
                            height={14}
                          />
                        ) : null}
                      </div>
                      <div className="flex flex-col">
                        <span className="text-white-100 font-medium leading-normal md:text-xsm md:ml-2">
                          {item.name}
                        </span>
                        <span className="text-sm text-white-700 capitalize font-light leading-normal md:text-xsm md:ml-2">
                          {item.chain === "cosmos" || item.chain === "Cosmos"
                            ? "Cosmos Hub"
                            : item.chain}
                        </span>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-white-100 font-medium md:text-sm">
                        {item.balance === undefined || Number(item.balance) <= 0
                          ? "0"
                          : Number(item.balance).toLocaleString()}
                      </p>{" "}
                      <p className="block text-sm text-white-600 font-light md:text-xsm">
                        $
                        {item.balance === undefined || Number(item.balance) <= 0
                          ? "0"
                          : (
                              Number(item.balance) * tokenPrices[item.name]
                            ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-white-600">Token not found</p>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
