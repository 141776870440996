import { NetworksTypes } from "./types";

// available network list
export const networksList: NetworksTypes[] = [
  {
    network: "Cosmos",
    logo: "/tokens/xprt.svg", // logo name. logo must be svg format
    link: "/cosmos",
    underlineChain: "Persistence",
    type: "cosmos"
  },
  {
    network: "Ethereum",
    logo: "/tokens/eth.svg",
    link: "/eth",
    underlineChain: "Ethereum",
    type: "ethereum"
  },
  {
    network: "BNB Smart Chain",
    logo: "/tokens/bnb.svg",
    link: "/bnb",
    underlineChain: "BNB Smart Chain",
    type: "bnb"
  },
  {
    network: "Optimism",
    logo: "/logos/optimism.svg",
    link: "/eth",
    underlineChain: "Optimism",
    type: "ethereum"
  },
  {
    network: "Arbitrum",
    logo: "/logos/arbitrum.svg",
    link: "/eth",
    underlineChain: "Arbitrum",
    type: "ethereum"
  }
];
