import Axios from "axios";
import {
  decimalize,
  genericErrorHandler,
  getRangeLimitTimestamp,
  getTokenPriceUrl
} from "../../containers/cosmos/helpers/utils";
import { Scope } from "@sentry/nextjs";
import { CRESCENT_STK_ATOM_DENOM, RANGE_WEEK } from "../../constants/static";
import {
  DstChainItem,
  DstChainName
} from "../../containers/cosmos/helpers/types";
import { ExchangeRateData, MetricResponse } from "./types";
import moment from "moment";
import { cosmosSupportedChains, externalChains } from "@/helpers/utils";
import { dstChainsList } from "@/containers/cosmos/helpers/config";
import {
  ApyList,
  InitialTvlApyFeeTypes,
  ShadeInitialInfo,
  initialTVLAPY
} from "@/containers/cosmos/store/slices/initial-data-slice";
import BigNumber from "bignumber.js";

export const ATOM_PRICE_URL = "https://api.coingecko.com/api/v3/coins/cosmos";
export const OSMOSIS_POOL_URL = "https://api-osmosis.imperator.co/pools/v2/886";
export const OSMOSIS_POOL_APR_URL =
  "https://public-osmosis-api.numia.xyz/pools_apr?pool=886";
export const OSMOSIS_POOL2_URL =
  "https://api-osmosis.imperator.co/pools/v2/1323";
export const OSMOSIS_POOL2_APR_URL =
  "https://public-osmosis-api.numia.xyz/pools_apr?pool=1323";
export const CRESCENT_POOL_URL = "https://apigw-v3.crescent.network/pool/live";
export const UMEE_URL =
  "https://testnet-client-bff-ocstrhuppq-uc.a.run.app/convexity/assets/all";
export const STK_ATOM_APY_API =
  "https://api.persistence.one/pstake/stkatom/apy";
export const STK_OSMO_APY_API = "https://api.persistence.one/osmosis-apr";
export const STK_DYDX_APY_API =
  "https://api.persistence.one/pstake/stkdydx/apy";
export const STK_STAR_APY_API =
  "https://api.persistence.one/pstake/stkstars/apy";
export const STK_BLD_APY_API =
  "https://staging.api.persistence.one/pstake/stkbld/apy";
export const STK_HUAHUA_APY_API =
  "https://api.persistence.one/pstake/stkhuahua/apy";
export const STK_XPRT_APY_API =
  "https://api.persistence.one/pstake/stkxprt/apy";
export const STK_ATOM_CVALUE_API =
  "https://api.persistence.one/pstake/stkatom/c_value";
export const STK_ATOM_TVU_API =
  "https://api.persistence.one/pstake/stkatom/atom_tvu";
export const DEXTER_POOL_URL = "https://api2.core-1.dexter.zone/v1/graphql";
export const SHADE_URL =
  "https://na36v10ce3.execute-api.us-east-1.amazonaws.com/API-mainnet-STAGE/shadeswap/pairs";
export const ASTROPORT_URL =
  "https://api.astroport.fi/api/pools/neutron1d73vc84e36d4mmm9dwqql4sty3fx4usjmupxewx36e4qudm5auqs0yryma";
export const MARS_URL =
  "https://celatone-api-prod.alleslabs.dev/rest/neutron/neutron-1/cosmwasm/wasm/v1/contract/neutron1n97wnm7q6d2hrcna3rqlnyqw2we6k0l8uqvmyqq6gsml92epdu7quugyph/smart/ewogICJtYXJrZXQiOiB7CiAgICAiZGVub20iOiAiaWJjLzM2NDlDRTBDOEEyQzc5MDQ4RDhDNkYzMUZGMThGQTY5QzlCQzdFQjE5MzUxMkUwQkQwM0I3MzMwMTEyOTA0NDUiCiAgfQp9";
export const QUASAR_URL =
  "https://api.quasar.fi/vaults/stkosmo-osmo-dynamic-s/info";
export const NITRON_ATOM_URL =
  "https://api-insights.carbon.network/cdp/snapshot?denom=ibc/536D12706900AA0348B093848BDE466637A1ACD31E90A7B35957BBAF07251009&limit=1000000";

export const NITRON_DYDX_URL =
  "https://api-insights.carbon.network/cdp/snapshot?denom=ibc/4E04DF48DECE17AB40A5946931DBA8C308FF670CECC18178D372DDBE01EC1679&limit=1000000";

const baseStatsUrl = "https://stkatom.stats.pstake.finance/metrics";

export const fetchTokenPrice = async (
  dstChainName: DstChainName = "Cosmos"
): Promise<number> => {
  try {
    const res = await Axios.get(getTokenPriceUrl(dstChainName));
    if (res && res.data) {
      return res.data.market_data.current_price.usd;
    }
    return 1;
  } catch (e) {
    console.log(e, "error in fetchTokenPrice");
    return 0;
  }
};

export const getAPY = async () => {
  let apyList: ApyList = {
    cosmos: 0,
    osmo: 0,
    dydx: 0,
    persistence: 0,
    stars: 0,
    agoric: 0,
    chihuahua: 0
  };
  try {
    console.log("inside-oc", cosmosSupportedChains);
    for (const chain of cosmosSupportedChains) {
      // TODO: Add xprt
      const api =
        chain.bech32Config.bech32PrefixAccAddr === "cosmos"
          ? STK_ATOM_APY_API
          : chain.bech32Config.bech32PrefixAccAddr === "dydx"
          ? STK_DYDX_APY_API
          : chain.bech32Config.bech32PrefixAccAddr === "stars"
          ? STK_STAR_APY_API
          : chain.bech32Config.bech32PrefixAccAddr === "agoric"
          ? STK_BLD_APY_API
          : chain.bech32Config.bech32PrefixAccAddr === "chihuahua"
          ? STK_HUAHUA_APY_API
          : chain.bech32Config.bech32PrefixAccAddr === "persistence"
          ? STK_XPRT_APY_API
          : STK_OSMO_APY_API;
      console.log(api, "api-apy");
      try {
        const res = await Axios.get(api);
        console.log(res, "api-apy-response", chain);
        apyList[chain.bech32Config.bech32PrefixAccAddr] =
          res && res.data ? Number((res.data * 100).toFixed(2)) : -1;
      } catch (e) {
        apyList[chain.bech32Config.bech32PrefixAccAddr] = -1;
      }
    }
    return apyList;
  } catch (e) {
    console.log(e, "error in getAPY");
    return {
      cosmos: -1,
      osmo: -1,
      dydx: -1,
      persistence: -1,
      stars: -1,
      agoric: -1,
      chihuahua: -1
    };
  }
};

export const getExchangeRate = async (): Promise<number> => {
  try {
    const res = await Axios.get(STK_ATOM_CVALUE_API);
    if (res && res.data) {
      return Number(res.data);
    }
    return 1;
  } catch (e) {
    console.log(e, "error in getExchangeRate");
    return 1;
  }
};

export const getTVU = async (): Promise<number> => {
  try {
    const res = await Axios.get(STK_ATOM_TVU_API);
    if (res && res.data) {
      return Number(res?.data!.amount!.amount);
    }
    return 0;
  } catch (e) {
    console.log(e, "error in getTVU");
    return 0;
  }
};

export const fetchOsmosisPoolInfo = async () => {
  try {
    const responses = await Axios.all([
      Axios.get(OSMOSIS_POOL_URL),
      Axios.get(OSMOSIS_POOL_APR_URL)
    ]);
    const responseOne = responses[0];
    const responseTwo = responses[1];

    const osmoInfo: InitialTvlApyFeeTypes = {
      fees: 0,
      total_apy: 0,
      tvl: 0
    };

    if (responseTwo && responseTwo.data) {
      if (responseTwo.data[0].total_apr) {
        osmoInfo.total_apy = Number(responseTwo.data[0].total_apr).toFixed(2);
      } else {
        osmoInfo.total_apy = 0;
      }
    } else {
      osmoInfo.total_apy = 0;
    }
    if (responseOne && responseOne.data) {
      osmoInfo.tvl = Number(
        Math.round(responseOne.data[0].liquidity).toFixed(2)
      );
      osmoInfo.fees = responseOne.data[0].fees;
    } else {
      osmoInfo.fees = 0;
      osmoInfo.tvl = 0;
    }
    return osmoInfo;
  } catch (e) {
    console.log(e, "error in fetchOsmoPoolInfo");
    return initialTVLAPY;
  }
};

export const fetchOsmosisPool2Info = async () => {
  try {
    const responses = await Axios.all([
      Axios.get(OSMOSIS_POOL2_URL),
      Axios.get(OSMOSIS_POOL2_APR_URL)
    ]);
    const responseOne = responses[0];
    const responseTwo = responses[1];
    const osmoInfo: InitialTvlApyFeeTypes = {
      fees: 0,
      total_apy: 0,
      tvl: 0
    };

    if (responseTwo && responseTwo.data) {
      if (responseTwo.data[0].total_apr) {
        osmoInfo.total_apy = (
          Number(responseTwo.data[0].total_apr) * 100
        ).toFixed(2);
      }
    } else {
      osmoInfo.total_apy = 0;
    }
    if (responseOne && responseOne.data) {
      osmoInfo.tvl = Number(
        Math.round(responseOne.data[0].liquidity).toFixed(2)
      );
      osmoInfo.fees = responseOne.data[0].fees;
    } else {
      osmoInfo.fees = 0;
      osmoInfo.tvl = 0;
    }
    return osmoInfo;
  } catch (e) {
    console.log(e, "error in fetchOsmoPool2Info");
    return initialTVLAPY;
  }
};

export const fetchCrescentPoolInfo = async () => {
  try {
    const res = await Axios.get(CRESCENT_POOL_URL);
    if (res && res.data) {
      const response = res.data.data;
      let crescentInfo = response.find(
        (item: any) => item!.Reserved[1]?.denom === CRESCENT_STK_ATOM_DENOM
      );
      const atomTvl =
        Number(crescentInfo.Reserved[0].amount) *
        crescentInfo.Reserved[0].priceOracle;
      const stkAtomTvl =
        Number(crescentInfo.Reserved[1].amount) *
        crescentInfo.Reserved[1].priceOracle;
      return {
        tvl: Number(Number(decimalize(atomTvl + stkAtomTvl)).toFixed(2)),
        total_apy: Number(crescentInfo?.apr).toFixed(2)
      };
    }
  } catch (e) {
    console.log(e, "error in fetchCrescentPoolInfo");
    return initialTVLAPY;
  }
  return initialTVLAPY;
};

export const fetchDexterPoolInfo = async () => {
  try {
    const res = await fetch(DEXTER_POOL_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
            pool_weekly_aggregate_with_apr(where: {pool_id: {_in: [1, 7, 9, 11, 12]}}) {
              pool_id
              total_swap_fee
              current_liquidity_usd
              total_volume
              apr
            }
            pool_current_incentive_apr(where: {pool_id: {_in: [1, 7, 9, 11, 12]}}) {
               incentive_apr
               pool_id
            }
          }`
      })
    });
    const responseJson = await res.json();
    console.log(responseJson, "responseJson");
    if (responseJson && responseJson.data) {
      const poolAggregate1 =
        responseJson.data.pool_weekly_aggregate_with_apr?.find((item: any) => {
          return item.pool_id === 1;
        });
      const poolIncentiveAprList1 =
        responseJson.data.pool_current_incentive_apr?.filter((item: any) => {
          return item.pool_id === 1;
        });
      let poolIncentiveApr1 = 0;
      if (poolIncentiveAprList1 && poolIncentiveAprList1.length) {
        poolIncentiveAprList1.forEach((item: any) => {
          poolIncentiveApr1 += item.incentive_apr;
        });
      }

      const poolAggregate2 =
        responseJson.data.pool_weekly_aggregate_with_apr?.find((item: any) => {
          return item.pool_id === 7;
        });
      const poolIncentiveAprList2 =
        responseJson.data.pool_current_incentive_apr?.filter((item: any) => {
          return item.pool_id === 7;
        });
      let poolIncentiveApr2 = 0;
      if (poolIncentiveAprList2 && poolIncentiveAprList2.length) {
        poolIncentiveAprList2.forEach((item: any) => {
          poolIncentiveApr2 += item.incentive_apr;
        });
      }

      const poolAggregate3 =
        responseJson.data.pool_weekly_aggregate_with_apr?.find((item: any) => {
          return item.pool_id === 9;
        });
      const poolIncentiveAprList3 =
        responseJson.data.pool_current_incentive_apr?.filter((item: any) => {
          return item.pool_id === 9;
        });
      let poolIncentiveApr3 = 0;
      if (poolIncentiveAprList3 && poolIncentiveAprList3.length) {
        poolIncentiveAprList3.forEach((item: any) => {
          poolIncentiveApr3 += item.incentive_apr;
        });
      }

      const poolAggregate4 =
        responseJson.data.pool_weekly_aggregate_with_apr?.find((item: any) => {
          return item.pool_id === 11;
        });
      const poolIncentiveAprList4 =
        responseJson.data.pool_current_incentive_apr?.filter((item: any) => {
          return item.pool_id === 11;
        });
      let poolIncentiveApr4 = 0;
      if (poolIncentiveAprList4 && poolIncentiveAprList4.length) {
        poolIncentiveAprList4.forEach((item: any) => {
          poolIncentiveApr4 += item.incentive_apr;
        });
      }

      const poolAggregate5 =
        responseJson.data.pool_weekly_aggregate_with_apr?.find((item: any) => {
          return item.pool_id === 12;
        });

      const poolIncentiveAprList5 =
        responseJson.data.pool_current_incentive_apr?.filter((item: any) => {
          return item.pool_id === 12;
        });
      let poolIncentiveApr5 = 0;
      if (poolIncentiveAprList5 && poolIncentiveAprList5.length) {
        poolIncentiveAprList5.forEach((item: any) => {
          poolIncentiveApr4 += item.incentive_apr;
        });
      }
      return [
        {
          fees: "0.3",
          total_apy: (
            poolIncentiveApr1 +
            (poolAggregate1 && poolAggregate1.apr ? poolAggregate1.apr : 0)
          ).toFixed(2),
          tvl: poolAggregate1
            ? Number(poolAggregate1.current_liquidity_usd!.toFixed(2))
            : 0
        },
        {
          fees: "0.3",
          total_apy: (
            poolIncentiveApr2 +
            (poolAggregate2 && poolAggregate2.apr ? poolAggregate2.apr : 0)
          ).toFixed(2),
          tvl: poolAggregate2
            ? Number(poolAggregate2.current_liquidity_usd!.toFixed(2))
            : 0
        },
        {
          fees: "0.3",
          total_apy: (
            poolIncentiveApr3 +
            (poolAggregate3 && poolAggregate3.apr ? poolAggregate3.apr : 0)
          ).toFixed(2),
          tvl: poolAggregate3
            ? Number(poolAggregate3.current_liquidity_usd!.toFixed(2))
            : 0
        },
        {
          fees: "0.3",
          total_apy: (
            poolIncentiveApr4 +
            (poolAggregate4 && poolAggregate4.apr ? poolAggregate4.apr : 0)
          ).toFixed(2),
          tvl: poolAggregate4
            ? Number(poolAggregate4.current_liquidity_usd!.toFixed(2))
            : 0
        },
        {
          fees: "0.3",
          total_apy: (
            10.89 +
            poolIncentiveApr5 +
            (poolAggregate5 && poolAggregate5.apr ? poolAggregate5.apr : 0)
          ).toFixed(2),
          tvl: poolAggregate5
            ? Number(poolAggregate5.current_liquidity_usd!.toFixed(2))
            : 0
        }
      ];
    }
    return [initialTVLAPY, initialTVLAPY, initialTVLAPY, initialTVLAPY];
  } catch (e) {
    console.log({ e }, "error in fetchDexterPoolInfo");
    return [initialTVLAPY, initialTVLAPY, initialTVLAPY, initialTVLAPY];
  }
};

export const fetchUmeeInfo = async (): Promise<InitialTvlApyFeeTypes> => {
  try {
    const res = await Axios.get(UMEE_URL);
    if (res && res.data) {
      const stkatom = res.data.find((item: any) => item.asset === "STKATOM");
      if (stkatom) {
        return {
          borrow_apy: Number(stkatom.borrow_apy).toFixed(2),
          lending_apy: Number(stkatom.supply_apy).toFixed(2),
          total_supply: Number(Number(stkatom.market_size_usd).toFixed(2)),
          tvl: Number(Number(stkatom.market_size_usd).toFixed(2))
        };
      }
      return initialTVLAPY;
    }
    return initialTVLAPY;
  } catch (e) {
    console.log(e, "error in fetchUmeeInfo");
    return initialTVLAPY;
  }
};

export const getAvatar = async (identity: string) => {
  try {
    const urlLink =
      "https://keybase.io/_/api/1.0/user/lookup.json" +
      `?key_suffix=${identity}&fields=pictures`;
    const res = await Axios.get(urlLink);
    const url = res?.data?.them[0]?.pictures?.primary?.url;
    if (url) {
      return url;
    }
    return "/profile.svg"; // return profile icon if url not exists
  } catch (e) {
    return "/profile.svg";
  }
};

export const fetchShadeInfo = async (): Promise<ShadeInitialInfo> => {
  try {
    const res = await Axios.get(SHADE_URL);
    if (res && res.data) {
      const stkATOMSilk = res.data.find(
        (item: any) => item.id === "ec478c0a-c7cd-4327-b6cb-8d01ca87d319"
      );
      const atomStkATOM = res.data.find(
        (item: any) => item.id === "1d7f9ba8-b4be-4a34-a54d-63554f14f8fb"
      );
      console.log(stkATOMSilk, "stkATOMSilk", atomStkATOM);
      return {
        stkATOMSilk: {
          total_apy: Number(stkATOMSilk.apr.total).toFixed(2),
          tvl: Number(Number(stkATOMSilk.liquidity_usd).toFixed(2)),
          fees: (Number(stkATOMSilk.fees.lp) * 100).toFixed(2)
        },
        atomStkAtom: {
          total_apy: Number(atomStkATOM.apr.total).toFixed(2),
          tvl: Number(Number(atomStkATOM.liquidity_usd).toFixed(2)),
          fees: (Number(atomStkATOM.fees.lp) * 100).toFixed(2)
        }
      };
    }
    return {
      stkATOMSilk: initialTVLAPY,
      atomStkAtom: initialTVLAPY
    };
  } catch (e) {
    console.log(e, "error in fetchShadeInfo");
    return {
      stkATOMSilk: initialTVLAPY,
      atomStkAtom: initialTVLAPY
    };
  }
};

export const fetchAstroPortInfo = async (): Promise<InitialTvlApyFeeTypes> => {
  try {
    const res = await Axios.get(ASTROPORT_URL);
    if (res && res.data) {
      return {
        tvl: Number(Number(res.data.totalLiquidityUSD).toFixed(2)),
        total_apy: "< 0.01"
      };
    }
    return initialTVLAPY;
  } catch (e) {
    console.log(e, "error in fetchAstroportInfo");
    return initialTVLAPY;
  }
};

export const fetchMarsInfo = async (): Promise<InitialTvlApyFeeTypes> => {
  try {
    const res = await Axios.get(MARS_URL);
    if (res && res.data) {
      const bgNumber = new BigNumber(
        Number(res.data.data.collateral_total_scaled)
      )
        .dividedBy(10 ** 6)
        .times(11) //TODO: exchangeRate from mars protocol, make it dynamic
        .toNumber();

      return {
        borrow_apy: 0,
        lending_apy: 0,
        total_supply: Number(bgNumber),
        tvl: Number(bgNumber)
      };
    }
    return initialTVLAPY;
  } catch (e) {
    console.log(e, "error in fetchMarsInfo");
    return initialTVLAPY;
  }
};

export const fetchQuasarnfo = async (): Promise<InitialTvlApyFeeTypes> => {
  try {
    const res = await Axios.get(QUASAR_URL);
    if (res && res.data) {
      return {
        tvl: res.data.tvl.usd,
        total_apy: Number((Number(res.data.apy) * 100).toFixed(2))
      };
    }
    return initialTVLAPY;
  } catch (e) {
    console.log(e, "error in fetchQuasarnfo");
    return initialTVLAPY;
  }
};

export const fetchNitronAtomInfo = async (): Promise<InitialTvlApyFeeTypes> => {
  try {
    const res = await Axios.get(NITRON_ATOM_URL);
    if (res && res.data && res.data.result.entries) {
      const supplyEntries = res.data.result.entries;
      let totalSupplied = 0;
      supplyEntries.map(
        (item) =>
          (totalSupplied =
            totalSupplied +
            item.supplied.reduce((accumulator, object) => {
              return accumulator + Number(object?.amount);
            }, 0))
      );
      console.log(totalSupplied, "totalSupplied-nitro");
      return {
        borrow_apy: 0,
        lending_apy: 0,
        total_supply: Number(totalSupplied),
        tvl: Number(totalSupplied)
      };
    }
    return initialTVLAPY;
  } catch (e) {
    console.log(e, "error in fetchNitronAtomInfo");
    return initialTVLAPY;
  }
};

export const fetchNitronDydxInfo = async (): Promise<InitialTvlApyFeeTypes> => {
  try {
    const res = await Axios.get(NITRON_DYDX_URL);
    console.log(res.data.entries, res, "res-dydx-atom");
    if (res && res.data && res.data.result.entries) {
      const supplyEntries = res.data.result.entries;
      let totalSupplied = 0;
      supplyEntries.map(
        (item) =>
          (totalSupplied =
            totalSupplied +
            item.supplied.reduce((accumulator, object) => {
              return accumulator + Number(object?.amount);
            }, 0))
      );

      console.log(totalSupplied, "totalSupplied-nitro-dydx");

      return {
        borrow_apy: 0,
        lending_apy: 0,
        total_supply: Number(totalSupplied),
        tvl: Number(totalSupplied)
      };
    }
    return initialTVLAPY;
  } catch (e) {
    console.log(e, "error in fetchNitronAtomInfo");
    return initialTVLAPY;
  }
};

export const getExchangeRateData = async (
  range: number = RANGE_WEEK
): Promise<ExchangeRateData> => {
  try {
    const cValues = await getMetrics(
      "/persistence/c-value",
      getRangeLimitTimestamp(range),
      moment().valueOf()
    );

    let series: [number, number][] = [];
    for (let metric of cValues.metrics) {
      let reverseCValue = Number((1 / metric.value).toFixed(6));
      series.push([metric.timestamp, reverseCValue]);
    }

    return series;
  } catch (e) {
    return [];
  }
};

const getMetrics = async (
  url: string,
  start: number,
  end: number
): Promise<MetricResponse> => {
  try {
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseStatsUrl + url,
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        start: start,
        end: end
      }
    };

    const response = await Axios.request(config);
    return response.data as MetricResponse;
  } catch (e) {
    console.log(e, "error querying for metrics");
    return {
      metrics: [],
      count: 0
    };
  }
};
