import { StateCreator } from "zustand";
import { QueryAllBalancesResponse } from "cosmjs-types/cosmos/bank/v1beta1/query";
import { ChainInfo } from "@keplr-wallet/types";
import { PstakeValidator } from "@/containers/cosmos/store/slices/initial-data-slice";

export interface BalanceState {
  srcChainBalances: QueryAllBalancesResponse;
  dstChainBalances: QueryAllBalancesResponse;
}

export interface FetchBalanceParams {
  srcAddress: string;
  dstAddress: string;
  srcChainInfo: ChainInfo;
  dstChainInfo: ChainInfo;
  validators: PstakeValidator[];
}

export interface SliceActions {
  setSrcChainBalances: (val: QueryAllBalancesResponse) => void;
  setDstChainBalances: (val: QueryAllBalancesResponse) => void;
}

const initialState: BalanceState = {
  dstChainBalances: {
    balances: []
  },
  srcChainBalances: {
    balances: []
  }
};

export type BalanceSlice = BalanceState & SliceActions;

export const createBalanceSlice: StateCreator<BalanceSlice> = (set) => ({
  ...initialState,
  setSrcChainBalances: (val) =>
    set((state) => ({
      srcChainBalances: val
    })),
  setDstChainBalances: (val) =>
    set((state) => ({
      dstChainBalances: val
    }))
});
