import Axios from "axios";
import { Scope } from "@sentry/nextjs";
import { genericErrorHandler } from "../../containers/bnb/helpers/utils";
import { bigNumberToEther } from "@/utils";
import { shieldContractAddress } from "../../constants/static";
import { SPEEDY_NODE_URL } from "../../constants/dynamic";
import { ethers } from "ethers";
import shield from "../../containers/bnb/contracts/shield.json";
import * as Sentry from "@sentry/nextjs";
import { InitialTvlApyTypes } from "@/containers/bnb/store/slices/initial-data-slice/types";

export const BNB_PRICE_URL =
  "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin&order=market_cap_desc&per_page=100&page=1&sparkline=false";
export const ALPACA_API =
  "https://alpaca-static-api.alpacafinance.org/bsc/v1/landing/summary.json";
export const BEEFY_APY_API = "https://api.beefy.finance/apy";
export const BEEFY_TVL_API = "https://api.beefy.finance/tvl";
export const PANCAKE_API =
  "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap";
export const PANCAKE_APIV3 =
  "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc";
export const THENA_SUB_GRAPH_API =
  "https://api.thegraph.com/subgraphs/name/thenaursa/thena-fusion";
export const THENA_API = "https://api.thena.fi/api/v1/fusions";
export const OPEN_LEVERAGE_API =
  "https://bnb.openleverage.finance/api/info/pool/0x9630cefdccbc7eb8689ed1de14a1899468b0839d";
export const WOMBAT_API =
  "https://api.thegraph.com/subgraphs/name/wombat-exchange/wombat-exchange-bsc";
export const WOMBAT_APR_API =
  "https://api.thegraph.com/subgraphs/name/wombat-exchange/wombat-apr";
export const SHIELD_TVL_API = "https://api2.shieldex.io/mainnet/mvault/getTVL";

const initialTVL_APY = { tvl: 0.0, total_apy: 0.0 };

export const fetchBNBPrice = async () => {
  try {
    const res = await Axios.get(BNB_PRICE_URL);
    if (res && res.data && res.data && res.data[0]) {
      return Number(res.data[0].current_price);
    }
  } catch (e: any) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching price of BNB": BNB_PRICE_URL
    });
    genericErrorHandler(e, customScope);
    return 0;
  }
  return 0;
};

export const fetchAlpaca = async (): Promise<InitialTvlApyTypes> => {
  try {
    const res = await Axios.get(ALPACA_API);

    if (res && res.data && res.data.data && res.data.data.farmingPools) {
      const farmingPools = res.data.data.farmingPools;
      const data = farmingPools.filter((val: any) => {
        return val.key.toLowerCase() === "pcs-stkbnb-bnb";
      });
      return {
        tvl: parseInt(data[0].tvl),
        total_apy: Number(data[0].totalApy).toFixed(2)
      };
    }
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching data": ALPACA_API
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
  return initialTVL_APY;
};

export const fetchBeefyInfo = async (): Promise<InitialTvlApyTypes> => {
  try {
    const responses = await Axios.all([
      Axios.get(BEEFY_APY_API),
      Axios.get(BEEFY_TVL_API)
    ]);
    const responseOne = responses[0];
    const responseTwo = responses[1];
    let apy;
    let tvl;
    if (responseOne && responseOne.data) {
      apy = responseOne.data["thena-gamma-bnb-stkbnb"] * 100;
    } else {
      apy = 0;
    }
    if (responseTwo && responseTwo.data) {
      tvl = responseTwo.data[56]["thena-gamma-bnb-stkbnb"];
    } else {
      tvl = 0;
    }
    return {
      tvl: parseInt(tvl),
      total_apy: Number(apy).toFixed(2)
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching ": BEEFY_APY_API
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
};

export const fetchPancakeInfo = async (): Promise<InitialTvlApyTypes> => {
  try {
    const res = await fetch(PANCAKE_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
               pair(id: "0x0b03e3d6ec0c5e5bbf993ded8d947c6fb6eec18d") {
                 reserveUSD
               }
             }`
      })
    });
    const responseJson = await res.json();
    if (responseJson && responseJson.data) {
      return {
        tvl: parseInt(responseJson.data.pair.reserveUSD),
        total_apy: 0
      };
    }
    return initialTVL_APY;
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching data": PANCAKE_APIV3
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
};

export const fetchPancakeV3Info = async (): Promise<InitialTvlApyTypes> => {
  try {
    const res = await fetch(PANCAKE_APIV3, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
               pool(id:"0xae7a3d9bed2ba4ef9c134ff4baee33655ae5de6f"){
                  totalValueLockedUSDUntracked
                }
            }`
      })
    });
    const responseJson = await res.json();

    if (responseJson && responseJson.data) {
      return {
        tvl: parseInt(responseJson.data.pool.totalValueLockedUSDUntracked),
        total_apy: 0
      };
    }
    return initialTVL_APY;
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching data": PANCAKE_APIV3
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
};

export const fetchOpenLeverage = async (): Promise<InitialTvlApyTypes> => {
  try {
    const res = await Axios.get(OPEN_LEVERAGE_API);
    if (res && res.data && res.data.data && res.data.data.currentTVLUsd) {
      const tvlUSD = res.data.data.currentTVLUsd;
      return {
        tvl: parseInt(tvlUSD),
        total_apy: 0
      };
    } else {
      return {
        tvl: 0,
        total_apy: 0
      };
    }
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching data": OPEN_LEVERAGE_API
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
};

export const fetchWambot = async (): Promise<InitialTvlApyTypes> => {
  try {
    const res = await fetch(WOMBAT_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
           assets(where:{poolAddress:"0xb0219a90ef6a24a237bc038f7b7a6eac5e01edb0"}) {
            liabilityUSD
           }
          }`
      })
    });
    const responseJson = await res.json();
    if (responseJson && responseJson.data && responseJson.data.assets) {
      return {
        tvl: Math.trunc(
          Number(responseJson.data.assets[0].liabilityUSD) +
            Number(responseJson.data.assets[1].liabilityUSD)
        ),
        total_apy: 0
      };
    }
    return initialTVL_APY;
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching data": WOMBAT_API
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
};

export const fetchShieldTVL = async () => {
  try {
    const res = await Axios.post(SHIELD_TVL_API, {
      shieldContractAddress,
      nodeType: "BSC"
    });
    if (res.data.code === 200) {
      const data = res.data.msg;
      return bigNumberToEther(data.toString());
    }
    return "0";
  } catch (e) {
    Sentry.captureException(e);
    return "0";
  }
};

export const fetchShieldApiInfo = async (): Promise<InitialTvlApyTypes> => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(SPEEDY_NODE_URL);
    const contract = new ethers.Contract(
      shieldContractAddress,
      shield,
      provider
    );
    const response: any = await contract.getLatestRoundInfo();
    // @ts-ignore
    const apy: number = bigNumberToEther(response["APY"]) * 100;
    return {
      tvl: 0,
      total_apy: apy.toFixed(2)
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching data": SHIELD_TVL_API
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
};

export const fetchThenaApy = async () => {
  try {
    let apy;
    const fusionData = await Axios.get(THENA_API);
    if (fusionData && fusionData.data && fusionData.data.data) {
      const data = fusionData.data.data.find(
        (item: any) =>
          item.address === "0x86b481fce116dcd01fbebb963f1358bcc466668c"
      );
      apy = Number(data.gauge.apr).toFixed(2);
    } else {
      apy = 0;
    }
    return apy;
  } catch (e) {
    return 0;
  }
};

export const fetchThenaTVL = async () => {
  try {
    let tvl;
    const res = await fetch(THENA_SUB_GRAPH_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
                  pool(id: "0x84b78452a97c5afda1400943333f691448069a29") {
      totalValueLockedUSD
    }
               }`
      })
    });
    const responseJson = await res.json();
    if (responseJson && responseJson.data) {
      tvl = parseInt(responseJson.data.pool.totalValueLockedUSD);
    } else {
      tvl = 0;
    }
    return tvl;
  } catch (e) {
    return 0;
  }
};

export const fetchThenaInfo = async (): Promise<InitialTvlApyTypes> => {
  try {
    const apy = await fetchThenaApy();
    let tvl = await fetchThenaTVL();
    return {
      tvl: tvl,
      total_apy: apy
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error fetching data": THENA_SUB_GRAPH_API
    });
    genericErrorHandler(e, customScope);
    return initialTVL_APY;
  }
};
