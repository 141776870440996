"use client";
import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.module.css";
import CustomImage from "@/components/molecules/image";
import { ModalTypes } from "./types";
import { BaseAsset } from "@/utils";
import { Icon } from "@/components";
import {
  useOnClickOutside,
  emptyFunc
} from "@persistenceone/pstake-ui-components";

interface SearchList extends BaseAsset {
  balance: string | number;
  disable?: boolean;
}

export const ModalSelect = ({
  show,
  header,
  onClose = emptyFunc,
  className,
  staticBackDrop = true,
  closeButton = true,
  list = [],
  onSelect = emptyFunc,
  children,
  srcAsset,
  balances,
  tokenPrices
}: ModalTypes) => {
  const [searchList, setSearchList] = useState<SearchList[]>([]);
  const [data, setData] = useState<SearchList[]>([]);
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, onClose);

  useEffect(() => {
    if (list.length > 0 && balances.length > 0) {
      let newList: any = [...list];
      const filtered = newList.map((v) => {
        const blc = balances.find(
          (blc) => blc.name === v.name && blc.chain === v.chain
        );
        const totalAmount = balances
          .filter((blc) => blc.name === v.name && blc.network === "cosmos")
          .reduce((accumulator, object) => {
            return accumulator + Number(object?.amount);
          }, 0);

        return {
          ...v,
          balance:
            v.network === "cosmos"
              ? totalAmount
              : blc !== undefined
              ? blc.amount
              : "0"
        };
      });
      setSearchList(filtered);
      setData(filtered);
    }
  }, [list, balances]);

  const searchHandler = (evt: any) => {
    const searchTerm = evt.target.value;
    const filtered = data.filter((token) => {
      return token.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setSearchList(filtered);
  };

  console.log(tokenPrices, "tokenPrices");
  return show ? (
    <div>
      <div
        className={`${show ? "open" : "close"} ${
          styles.backDrop
        } backDrop fixed top-0 right-0 z-10 left-0 w-full h-full`}
      />
      <div
        className={
          `${
            show ? "open" : "close"
          } modal fade2 fixed top-0 right-0 left-0 w-full h-full z-20 overflow-auto ` +
          styles.modal +
          ` ${className}`
        }
      >
        <div
          className={`${styles.modalDialog} flex items-center min-h-full w-auto m-auto relative modalDialog`}
        >
          <div
            className={`${styles.modalContent} relative flex flex-col w-full rounded-lg text-white-600 modalContent`}
            ref={staticBackDrop ? null : modalRef}
          >
            {closeButton ? (
              <button
                type="button"
                onClick={onClose}
                className={`${styles.buttonClose} buttonClose`}
              >
                <Icon iconName="close" viewClass={styles.buttonCloseIcon} />
              </button>
            ) : null}
            {header ? (
              <div
                className="header text-2xl text-white-100 font-semibold
                  flex justify-between md:text-lg items-start px-8 pt-8 md:px-6 md:pt-6 rounded-t dark:border-gray-600"
              >
                <p>{header}</p>
              </div>
            ) : (
              ""
            )}
            <div className="modalBody p-8 md:p-6">
              <p className="text-white-200 font-medium mb-2">Select a token</p>
              <div className={`w-full relative mb-4 mt-0`}>
                <input
                  type="text"
                  className={
                    "bg-[#161616] py-2 pr-8 pl-4 text-sm text-white-100 b-[#070B09] " +
                    "rounded-md font-normal border border-solid border-transparent outline-none md:py:1.5 pl-2.5 w-full " +
                    "focus:border-[#a6a6a687] placeholder:text-white-600"
                  }
                  placeholder={"Search"}
                  autoFocus={false}
                  onChange={searchHandler}
                />
                <Icon
                  iconName="search"
                  viewClass={`${styles.searchInputIcon} absolute right-[15px] top-[10px] w-[14px] h-[14px]`}
                />
              </div>
              <div>
                {searchList.length > 0 ? (
                  searchList.map((item, index) => (
                    <div
                      className={`px-4 py-1.5 flex items-center justify-between hover:cursor-pointer hover:bg-[#383838] whitespace-nowrap ${
                        (item.name.toLowerCase() ===
                          srcAsset.name.toLowerCase() &&
                          item.network === srcAsset.network) ||
                        (item.disable !== undefined && item.disable)
                          ? "opacity-50 cursor-not-allowed pointer-events-none"
                          : "opacity-100"
                      }`}
                      key={index}
                      onClick={() => {
                        onSelect(item);
                      }}
                    >
                      <div className="flex items-center">
                        <div className="mr-2">
                          <CustomImage
                            src={item.imgUrl}
                            alt={item.name}
                            width={34}
                            height={34}
                          />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-white-200 font-medium leading-normal md:text-xsm md:ml-2">
                            {item.name}
                          </span>
                          <span className="text-sm text-white-700 capitalize font-medium leading-normal md:text-xsm md:ml-2">
                            {item.chain}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="mr-2">
                          {Number(item.balance) <= 0 ? (
                            ""
                          ) : (
                            <>
                              {Number(item.balance).toLocaleString()}{" "}
                              <span className="block text-xsm text-white-600">
                                $
                                {(
                                  Number(item.balance) * tokenPrices[item.name]
                                ).toLocaleString()}
                              </span>
                            </>
                          )}
                        </p>
                        <Icon
                          iconName="right"
                          viewClass={`${
                            item.name.toLowerCase() ===
                              srcAsset.name.toLowerCase() &&
                            item.network === srcAsset.network
                              ? "block"
                              : "hidden"
                          } !w-[14px] !h-[14px] mb-1`}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center">Token not found</p>
                )}
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
