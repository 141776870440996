import { ethers } from "ethers";
import { StkBNBWebSDK } from "@persistenceone/stkbnb-web-sdk";
import { chainInfo } from "@/containers/bnb/helpers/config";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const COSMOS_LIQUID_STAKE_URL =
  "/pstake.liquidstakeibc.v1beta1.MsgLiquidStake";
export const COSMOS_NATIVE_LIQUID_STAKE_URL =
  "/pstake.liquidstake.v1beta1.MsgLiquidStake";
export const COSMOS_LIQUID_UN_STAKE_URL =
  "/pstake.liquidstakeibc.v1beta1.MsgLiquidUnstake";
export const COSMOS_NATIVE_LIQUID_UN_STAKE_URL =
  "/pstake.liquidstake.v1beta1.MsgLiquidUnstake";
export const REDEEM_URL = "/pstake.liquidstakeibc.v1beta1.MsgRedeem";
export const IBC_TRANSFER_URL = "/ibc.applications.transfer.v1.MsgTransfer";
export const msgValidatorBondUrl = "/cosmos.staking.v1beta1.MsgValidatorBond";
export const TOKENIZE_URL = "/cosmos.staking.v1beta1.MsgTokenizeShares";
export const TOKENIZE_REDEEM_URL =
  "/cosmos.staking.v1beta1.MsgRedeemTokensForShares";
export const COSMOS_LIQUID_STAKE_LSM_URL =
  "/pstake.liquidstakeibc.v1beta1.MsgLiquidStakeLSM";

export const COSMOS_UNBOND_TIME = env !== "Devnet" ? 1814400 : 600;
export const OSMOSIS_UNBOND_TIME = env !== "Devnet" ? 1209600 : 600;
export const DYDX_UNBOND_TIME = env !== "Devnet" ? 2592000 : 600;

// bnb constans
export const NO_OF_BLOCK_CONFIRMATIONS = chainInfo[env].blockConfirmations;

export const SDK_ENV = chainInfo[env].networkName === "BSC Mainnet" ? 0 : 1;

export const SPEEDY_NODE_URL = chainInfo[env].rpcUrl;

export const APP_ETHERS_PROVIDER = new ethers.providers.JsonRpcProvider(
  SPEEDY_NODE_URL
);

export const sdkInstance = StkBNBWebSDK.getInstance({
  signerOrProvider: APP_ETHERS_PROVIDER,
  env: SDK_ENV,
  numConfirmations: Number(chainInfo[env].blockConfirmations)
});

export const STAKE_POOL_CONTRACT = sdkInstance.stakePool;

export const STK_BNB_CONTRACT = sdkInstance.stkBNB;

export const stkBNBContractAddress = STK_BNB_CONTRACT.address;

export const stakePoolContractAddress = STAKE_POOL_CONTRACT.address;

export const FROM_BLOCK_NUMBER = chainInfo[env].stakePoolDeploymentBlock;

const pancakeLinksData: any = {
  Mainnet: {
    swapUrl: `https://pancakeswap.finance/swap?inputCurrency=${stkBNBContractAddress}&outputCurrency=BNB`,
    poolUrl: `https://pancakeswap.finance/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/${stkBNBContractAddress}`
  },
  Testnet: {
    swapUrl: `https://pancake.kiemtienonline360.com/#/swap?chain=testnet&inputCurrency=${stkBNBContractAddress}&outputCurrency=BNB`,
    poolUrl: `https://pancake.kiemtienonline360.com/#/add/${stkBNBContractAddress}/BNB`
  }
};

export const pancakeLinks = pancakeLinksData[env];
export const MIN_STAKE = env === "Testnet" ? 0.01 : 0.01;
export const PLATFORM_MIN_BALANCE = 0.000001;
export const INSTANT_REDEEM_FEE = 0.5;
