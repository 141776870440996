"use client";
import React, { useEffect, useRef } from "react";
import {
  useOnClickOutside,
  useOnlineStatus,
  useWindowSize
} from "@persistenceone/pstake-ui-components";
import styles from "./styles.module.css";
import SidebarContent from "./sidebar-content";
import { useEthereumStore } from "../store/store";
import { useAppStore } from "@/store/store";

const EthereumSideBar = () => {
  const setNetworkStatus = useAppStore(
    ({ setNetworkStatus }) => setNetworkStatus
  );
  const handleSidebar = useEthereumStore((state) => state.handleSidebar);
  const show = useEthereumStore((state) => state.sidebar.show);

  const closeHandler = () => {
    handleSidebar(false);
  };
  const { isMobile } = useWindowSize();
  const status = useOnlineStatus();
  const sideBarRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(sideBarRef, closeHandler);

  useEffect(() => {
    setNetworkStatus(!status);
  }, [status]);

  return (
    <div
      className={`${
        isMobile ? `${styles.mobileDropdownContainer} offCanvas` : ""
      } ${show ? "show" : "close"} w-[284px]`}
    >
      <div
        className={`${isMobile ? styles.mobileSidebarBackdrop : ""} backDrop`}
      />
      <div
        className={`${isMobile ? styles.mobileSidebar : ""}`}
        ref={isMobile ? sideBarRef : null}
      >
        <SidebarContent />
      </div>
    </div>
  );
};

export default EthereumSideBar;
