"use client";
import { FlagsmithProvider } from "flagsmith/react";
import flagsmith from "flagsmith/isomorphic";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { IState } from "flagsmith/types";
import { useFlags } from "flagsmith/react";

/** copy all feature keys from sdk **/
type AvailableFlags =
  | "cosmos_swap"
  | "cosmos_delegation_stake"
  | "bnb_swap"
  | "eth_swap"
  | "optimism_network"
  | "stkosmo_support"
  | "eth_network"
  | "dydx_support" | "arbitrum_network";

const environmentKey: string = process.env.NEXT_PUBLIC_FLAG_SMITH_KEY!;

interface IFeatureFlagsContext {
  useFlag: (name: AvailableFlags) => boolean;
}

const FeatureFlagsContext = createContext<IFeatureFlagsContext>({
  useFlag: () => false
});

const useFeatureFlags = (): IFeatureFlagsContext =>
  useContext(FeatureFlagsContext);

const FeatureFlagsProviderWrapper = ({ children }) => {
  const useFlag = (id) => {
    const flagValue = useFlags([id]);
    return flagValue[id].enabled;
  };
  return (
    <FeatureFlagsContext.Provider
      value={{
        useFlag
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const FeatureFlagsProvider: FC<any> = ({ children }) => {
  const [flagState, setFlagState] = useState<IState | null>(null);
  useEffect(() => {
    const fetchInfo = async () => {
      await flagsmith.init({
        // fetches flags on the server
        environmentID: environmentKey
      });
      setFlagState(flagsmith.getState());
    };
    fetchInfo();
  }, []);

  return (
    <FlagsmithProvider flagsmith={flagsmith} serverState={flagState}>
      <FeatureFlagsProviderWrapper>{children}</FeatureFlagsProviderWrapper>
    </FlagsmithProvider>
  );
};

export { useFeatureFlags, FeatureFlagsProvider };
