"use client";
import React from "react";
import { usePathname } from "next/navigation";
import EthereumNavigationBar from "../../../../ethereum/navigation-bar";
import CosmosNavbar from "../../../../cosmos/navigation-bar";
import BnbNavigation from "@/containers/bnb/navigation";

const NavigationBar = () => {
  const pathname = usePathname();

  return (
    <>
      {pathname.toLowerCase().includes("eth") ? (
        <EthereumNavigationBar />
      ) : pathname.toLowerCase().includes("bnb") ? (
        <BnbNavigation />
      ) : pathname.includes("cosmos") ? (
        <CosmosNavbar />
      ) : null}
    </>
  );
};

export default NavigationBar;
