import React from "react";
import Link from "next/link";
import { Icon } from "@/components";
import Styles from "./styles.module.css";
import { usePathname } from "next/navigation";
import { useWindowSize, emptyFunc } from "@persistenceone/pstake-ui-components";
import Tooltip from "rc-tooltip";
import CustomImage from "@/components/molecules/image";
import { bnbChainInfo, getActiveSideBarItem } from "../../../helpers/utils";
import { BUG_REPORT_URL } from "../../../constants/static";
import { useBnbStore } from "@/containers/bnb/store";
import { useShallow } from "zustand/react/shallow";

const socialList = [
  {
    url: "https://twitter.com/pSTAKE_stkBNB",
    iconName: "twitter-logo",
    tooltip: "twitter"
  },
  {
    url: "https://t.me/pstakefinance",
    iconName: "telegram-plane",
    tooltip: "telegram"
  },
  {
    url: "https://blog.pstake.finance/",
    iconName: "medium-m",
    tooltip: "medium"
  },
  {
    url: "https://discord.com/invite/qXRmTTGcYD",
    iconName: "discord",
    tooltip: "discord"
  },
  {
    url: "https://pstake.finance/bnb",
    iconName: "globe",
    tooltip: "Website"
  },
  {
    url: BUG_REPORT_URL,
    iconName: "bug",
    tooltip: "Bug Report"
  }
];

const moreList = [
  {
    url: "https://docs.pstake.finance/stkBNB_Staking_Overview/",
    icon: "docs",
    name: "Docs"
  }
];

const SidebarContent = () => {
  const { walletInfo, handleMobileSidebar } = useBnbStore(
    useShallow((state) => ({
      walletInfo: state.walletData.walletInfo,
      handleMobileSidebar: state.walletDataActions.handleMobileSidebar
    }))
  );
  const { account, walletConnection } = walletInfo;

  const { isMobile } = useWindowSize();

  const closeSideHandler = () => {
    handleMobileSidebar(false);
  };

  const pathName = usePathname();

  const routeList = [
    {
      icon: "wallet",
      text: "Dashboard",
      path: "/bnb",
      route: "/"
    },
    {
      icon: "staking",
      text: "Staking",
      path: "/bnb/stake",
      route: "/stake"
    },
    {
      icon: "defi",
      text: "DeFi",
      path: "/bnb/defi",
      route: "/defi"
    }
  ];

  return (
    <aside className="w-[284px] md:w-[220px]">
      <div
        className={`${Styles.sideBarContent} flex flex-col justify-between overflow-y-auto sticky`}
      >
        <div>
          <div className="text-center pt-8 pb-[1.9rem]">
            <Link href="/" className="nav-link" passHref>
              <CustomImage
                src={"/logo.svg"}
                alt={"logo"}
                className="m-auto"
                width={isMobile ? 90 : 124}
                height={22}
              />
            </Link>
          </div>
          <div className="pb-4">
            {routeList.map((item, index) => (
              <li className={`list-none`} key={index}>
                <Link href={item.path} passHref>
                  <span
                    className={`${
                      getActiveSideBarItem(item.route, pathName)
                        ? `bg-black-400 border-r-[3px] border-[#C73238] navItemActive`
                        : "group"
                    } py-3 px-8 flex items-center cursor-pointer`}
                    onClick={isMobile ? closeSideHandler : emptyFunc}
                  >
                    <span className={"mr-8 md:mr-4 "}>
                      <Icon
                        iconName={item.icon}
                        viewClass={`${
                          item.icon === "wallet"
                            ? "!fill-transparent stroke-[#A6A6A6] group-hover:stroke-[#fcfcfc] [.navItemActive_&]:stroke-[#C73238] "
                            : ""
                        } !w-[20px] !h-auto fill-[#a6a6a6] [.navItemActive_&]:fill-[#C73238] side-bar-icon
                         group-hover:fill-[#fcfcfc]`}
                      />
                    </span>
                    <span
                      className="[.navItemActive_&]:text-white-100 text-white-600 leading-6 text-base md:text-sm
                      group-hover:text-white-100"
                    >
                      {item.text}
                    </span>
                  </span>
                </Link>
              </li>
            ))}
            {walletConnection ? (
              <li className={`list-none`}>
                <Link
                  href={`${bnbChainInfo.explorerUrl}/address/${account}`}
                  className="nav-link"
                  passHref
                  target={"_blank"}
                >
                  <span
                    className={`${Styles.navBarLink} group py-[0.625rem] sm:pb-6 px-8 flex items-center cursor-pointer`}
                    onClick={isMobile ? closeSideHandler : emptyFunc}
                  >
                    <span className={"mr-8 md:mr-4 "}>
                      <Icon
                        iconName="transactions"
                        viewClass={`!w-[18px] !h-[18px] side-bar-icon  group-hover:fill-[#fcfcfc]`}
                      />
                    </span>
                    <span className="text text-white-600 leading-6 text-base md:text-sm group-hover:text-white-100">
                      Transactions
                    </span>
                    <span>
                      <Icon
                        iconName="new-tab"
                        viewClass={`!w-[8px] !h-[8px] side-bar-icon -mb-0.5 mr-8 ml-1.5 group-hover:fill-[#fcfcfc]`}
                      />
                    </span>
                  </span>
                </Link>
              </li>
            ) : null}
            {moreList.map((item, index) => (
              <li className={`list-none`} key={index}>
                <a
                  className="group py-[0.625rem] px-8 flex items-center cursor-pointer"
                  href={item.url}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  onClick={isMobile ? closeSideHandler : emptyFunc}
                >
                  <span className={"mr-8 md:mr-4 "}>
                    <Icon
                      iconName={item.icon}
                      viewClass={`${
                        item.icon === "governance"
                          ? "!fill-transparent stroke-[#A6A6A6] group-hover:stroke-[#fcfcfc] [.navItemActive_&]:stroke-[#C73238] "
                          : ""
                      } !w-[18px] !h-[18px] side-bar-icon  group-hover:fill-[#fcfcfc]`}
                    />
                  </span>
                  <span className="text text-white-600 leading-6 text-base md:text-sm group-hover:text-white-100">
                    {item.name}
                  </span>
                  <span>
                    <Icon
                      iconName="new-tab"
                      viewClass={`!w-[8px] !h-[8px] side-bar-icon 
                        -mb-0.5 mr-8 ml-1.5 group-hover:fill-[#fcfcfc]`}
                    />
                  </span>
                </a>
              </li>
            ))}
          </div>
        </div>
        <div className="border-t border-solid border-[#2b2b2b]">
          <div
            className={`socialLinks flex py-3 px-8 justify-between border-t border-solid border-[#2b2b2b]`}
          >
            {socialList.map((item, index) => (
              <Tooltip placement="bottom" overlay={item.tooltip} key={index}>
                <a
                  href={item.url}
                  rel="noopener noreferrer"
                  className="mr-2.5"
                  target="_blank"
                >
                  <Icon viewClass="socialIcon" iconName={item.iconName} />
                </a>
              </Tooltip>
            ))}
          </div>
          <div className="text-light-low text-[10px] font-medium leading-4 pb-3 px-8 flex items-center justify-center">
            <a
              href="https://pstake.finance/terms"
              target="_blank"
              rel="noreferrer"
              className={
                "hover:underline text-white-700 font-medium text-[11px]"
              }
            >
              Terms of Use
            </a>
            <a
              href="https://pstake.finance/privacy"
              target="_blank"
              rel="noreferrer"
              className={
                "hover:underline text-white-700 font-medium text-[11px] ml-[10px]"
              }
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SidebarContent;
