import { Scope } from "@sentry/nextjs";
import * as Sentry from "@sentry/nextjs";
import { ethers, utils } from "ethers";
import BigNumber from "bignumber.js";
import { bep20TokenAddresses, baseAssetList2 } from "../../../helpers/utils";
import { BEP20, BEP20__factory } from "@/containers/bnb/contracts/types";
import { BalanceProps } from "@/utils";
import { APP_ETHERS_PROVIDER } from "@/constants/dynamic";
import { LATEST } from "@/constants/static";

export const autoExponentiation = (valueString: string, fixed = 18) => {
  let bnFinalValue;
  let valueStringBN = new BigNumber(valueString);
  let valueStringBNDP = valueStringBN.decimalPlaces(6, 1);
  // let denominator = new BigNumber(10 ** decimalPlaces);
  let one = new BigNumber(1);
  // let minVal = one.dividedBy(denominator);
  if (valueStringBN.isGreaterThanOrEqualTo(one)) {
    bnFinalValue = valueStringBN.toFixed(Number(fixed), 1);
  } else {
    if (valueStringBN > valueStringBNDP) {
      bnFinalValue = valueStringBN.toExponential(Number(fixed), 1);
    } else {
      bnFinalValue = valueStringBN.toFixed(Number(fixed), 1);
    }
  }

  // return valueStringBN;
  return bnFinalValue.toString();
};

export const decimalize = (valueString: string, decimals = 18, fixed = 18) => {
  // BigNumber.set({ DECIMAL_PLACES: decimalPlaces, ROUNDING_MODE: 5 });
  let bnValueString = valueString
    ? new BigNumber(valueString.toString())
    : new BigNumber(0);
  let bnDecimalPlaces = new BigNumber(decimals);
  let bnBase = new BigNumber(10);
  let bnDenominator = bnBase.pow(bnDecimalPlaces);
  let bnFinalValueToBNString;
  bnFinalValueToBNString = bnValueString.div(bnDenominator);
  return autoExponentiation(bnFinalValueToBNString.toString(), fixed);
};

export const unDecimalize = (valueString: string, decimals = 18) => {
  // since BN.js doesn't accept decimals, we'll use BigNumber.js
  let bnValueString = valueString
    ? new BigNumber(valueString.toString())
    : new BigNumber(0);
  let bnDecimalPlaces = new BigNumber(decimals);
  let bnBase = new BigNumber(10);
  let bnMultiplier = bnBase.pow(bnDecimalPlaces);
  let bnResult = bnValueString.multipliedBy(bnMultiplier).toFixed(0);
  return ethers.utils.formatEther(bnResult);
};

export const genericErrorHandler = (e: any, scope = new Scope()) => {
  console.error(e);
  Sentry.captureException(e, scope);
};

export const getTokenBalances = async (signer, address) => {
  console.log(signer, "getTokenBalances", address);
  try {
    let list: BalanceProps[] = [];
    for (const key in bep20TokenAddresses) {
      console.log(
        key,
        "bep20TokenAddresses[key]",
        bep20TokenAddresses[key],
        baseAssetList2
      );
      const asset = baseAssetList2["bnb smart chain"].find(
        (item) => item.name === key
      );
      let balance;
      if (key === "BNB") {
        balance = await APP_ETHERS_PROVIDER.getBalance(address, LATEST);
      } else {
        const Contract: BEP20 = BEP20__factory.connect(
          bep20TokenAddresses[key],
          signer
        );
        balance = await Contract.balanceOf(address);
      }
      console.log(balance, "balance getTokenBalances");
      list.push({
        name: asset.name,
        label: asset.label,
        chainID: asset.chainID,
        chain: asset.chain,
        network: asset.network,
        imgUrl: asset.imgUrl,
        amount:
          asset.coinDecimals === 6
            ? decimalize(balance.toString(), 18)
            : utils.formatEther(balance!),
        dollorValue: 0,
        coinMinimalDenom: "bnb",
        decimals: 18
      });
    }
    console.log(list, "getTokenBalances");
    return list;
  } catch (e) {
    console.log(e, "error in getTokenBalances");
    return [];
  }
};
