import { displayToast } from "@/components";
import { addTestnetNetwork } from "../navigation";
import {
  BINANCE,
  BNB_TOKEN_DECIMALS,
  COIN_98,
  COIN_BASE,
  LEDGER_LIVE,
  META_MASK,
  SAFE_PAL,
  STK_BNB,
  TOKEN_POCKET,
  TRUST_WALLET,
  WALLET_CONNECT
} from "../../../constants/static";
import { stkBNBContractAddress } from "../../../constants/dynamic";
import { getWeb3ModalProvider } from "./connecters/web3-modal";
import { genericErrorHandler } from "./utils";
import { Scope } from "@sentry/nextjs";
import { ToastType } from "@/components/molecules/toast/types";
import { bnbChainInfo } from "../../../helpers/utils";
import { useBnbStore } from "@/containers/bnb/store";
import { connectBnbWallet } from "@/containers/bnb/store/sagas/fetching-sagas";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const handleExtensionConnect = async (provider: any, wallet: string) => {
  useBnbStore.getState().walletDataActions.setWalletModal(false);
  if (provider.chainId !== bnbChainInfo.networkIdHex) {
    const addedBSC = await addTestnetNetwork(provider, wallet, env);
    if (!addedBSC) {
      displayToast(
        {
          message: "Error while Switching network"
        },
        ToastType.ERROR
      );
      return;
    }
  }
  useBnbStore.getState().walletDataActions.setWalletName(wallet);
  useBnbStore.getState().walletDataActions.setWalletLoader(true);
  connectBnbWallet({ contract: provider, wallet });
};

export const handleWalletConnect = async () => {
  useBnbStore.getState().walletDataActions.setWalletModal(false);
  try {
    const provider = await getWeb3ModalProvider(WALLET_CONNECT);
    if (!provider) {
      displayToast(
        {
          message: "Provider not found"
        },
        ToastType.ERROR
      );

      return;
    }
    if (provider.chainId !== Number(bnbChainInfo.networkID)) {
      displayToast(
        {
          message: `Please switch the network to ${bnbChainInfo.networkName} in wallet application`
        },
        ToastType.INFO
      );
      return;
    }
    useBnbStore.getState().walletDataActions.setWalletName(WALLET_CONNECT);
    useBnbStore.getState().walletDataActions.setWalletLoader(true);
    connectBnbWallet({ contract: provider, wallet: WALLET_CONNECT });
  } catch (e) {
    displayToast(
      {
        message: "Error while connecting"
      },
      ToastType.ERROR
    );
  }
};

export const handleMetamaskWallet = async () => {
  let ethereum;

  if (window.ethereum?.providers) {
    ethereum = window.ethereum?.providers.find((item: any) => {
      return item && item.isMetaMask;
    });
  } else {
    ethereum = window.ethereum;
  }

  if (!ethereum || !ethereum.on) {
    displayToast(
      {
        message: "MetaMask wallet not installed"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(ethereum, META_MASK);
};

export const handleOkxWallet = async () => {
  let provider = window?.okxwallet;
  if (!provider || !provider.on) {
    displayToast(
      {
        message: "OKX Wallet not installed"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(provider, "OKX");
};

export const handleBinanceWallet = async () => {
  const binanceChain = window.BinanceChain;
  if (!binanceChain || !binanceChain.on) {
    displayToast(
      {
        message: "Binance wallet not installed"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(binanceChain, BINANCE);
};

export const handleTrustWallet = async () => {
  // @ts-ignore
  const trustwallet = window.trustwallet;
  if (!trustwallet || !trustwallet.on) {
    displayToast(
      {
        message: "Trust wallet not installed"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(trustwallet, TRUST_WALLET);
};

export const handleCoin98Wallet = async () => {
  // @ts-ignore
  const ethereum = window.coin98?.provider;
  // @ts-ignore
  if (!window.coin98 || !window.ethereum || !ethereum?.isCoin98) {
    displayToast(
      {
        message: "Coin98 wallet not installed"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(ethereum, COIN_98);
};

export const handleSafeWallet = async () => {
  const ethereum = window.ethereum;
  if (!ethereum || !ethereum.isSafePal) {
    displayToast(
      {
        message: "SafePal not installed or not activated"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(ethereum, SAFE_PAL);
};

export const handleCoinbaseWallet = async () => {
  const provider = await getWeb3ModalProvider(COIN_BASE);
  if (!provider) {
    displayToast(
      {
        message: "Coinbase wallet not installed"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(provider, COIN_BASE);
};

export const handleTokenPocketWallet = async () => {
  const ethereum = window.ethereum;
  if (!ethereum || !ethereum.isTokenPocket) {
    displayToast(
      {
        message: "TokenPocket wallet not installed"
      },
      ToastType.ERROR
    );
    return;
  }
  await handleExtensionConnect(ethereum, TOKEN_POCKET);
};

export const registerToken = async (connectedWallet: string) => {
  const contractAddress = stkBNBContractAddress;
  let provider;
  if (connectedWallet === BINANCE) {
    provider = window.BinanceChain;
  } else if (
    connectedWallet === WALLET_CONNECT ||
    connectedWallet === COIN_BASE
  ) {
    provider = await getWeb3ModalProvider(connectedWallet);
  } else if (connectedWallet === META_MASK) {
    if (window.ethereum?.providers) {
      provider = window.ethereum?.providers.find((item: any) => {
        return item && item.isMetaMask;
      });
    } else {
      provider = window.ethereum;
    }
  }
  const chainId = provider.chainId;
  if (
    (connectedWallet === WALLET_CONNECT &&
      chainId === Number(bnbChainInfo.networkID)) ||
    chainId === bnbChainInfo.networkIdHex
  ) {
    try {
      await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: contractAddress,
            symbol: STK_BNB,
            decimals: BNB_TOKEN_DECIMALS,
            image: window.location.origin + `/logos/stk_bnb.svg`
          }
        }
      });
    } catch (error) {
      const customScope = new Scope();
      customScope.setLevel("fatal");
      customScope.setTags({
        "Unable register token": "stkBNB"
      });
      genericErrorHandler(error, customScope);
    }
  }
};
