import { StateCreator } from "zustand";
import { SliceActions, LiveDataState } from "./types";

const initialState: LiveDataState = {
  liveData: {
    dstChainStatus: false,
    srcChainStatus: false,
    tvuList: {
      cosmos: 0,
      osmo: 0,
      dydx: 0,
      persistence: 0,
      stars: 0,
      agoric: 0,
      chihuahua: 0
    }
  }
};

export type LiveDataSlice = LiveDataState & SliceActions;

export const createLiveDataSlice: StateCreator<LiveDataSlice> = (set) => ({
  ...initialState,
  liveDataActions: {
    setDstStatus: (val) =>
      set((state) => ({
        liveData: {
          ...state.liveData,
          dstChainStatus: val
        }
      })),
    setTvuList: (val) =>
      set((state) => ({
        liveData: {
          ...state.liveData,
          tvuList: val
        }
      })),
    setSrcChainStatus: (val) =>
      set((state) => ({
        liveData: {
          ...state.liveData,
          srcChainStatus: val
        }
      }))
  }
});
