"use client";
import React, { useEffect } from "react";
import LoginOptions from "./login-options";
import { displayToast } from "@/components";
import {
  BNB,
  BNB_TOKEN_DECIMALS,
  BSC,
  COIN_98,
  COIN_BASE,
  DEVELOPMENT,
  LONG_INTERVAL,
  META_MASK,
  MID_INTERVAL,
  SAFE_PAL,
  SHORT_INTERVAL,
  TOKEN_POCKET,
  TRUST_WALLET,
  UNSUPPORTED_NETWORK
} from "../../../constants/static";
import Link from "next/link";
import {
  useLocalStorage,
  useWindowSize
} from "@persistenceone/pstake-ui-components";
import * as Sentry from "@sentry/nextjs";
import { getBnbExchangeRate } from "@/api/bnb/on-chain";
import {
  fetchBeefyInfo,
  fetchOpenLeverage,
  fetchPancakeInfo,
  fetchPancakeV3Info,
  fetchThenaInfo,
  fetchWambot
} from "@/api/bnb";
import { initialTVLAPY } from "../store/slices/initial-data-slice";
import {
  handleBinanceWallet,
  handleCoin98Wallet,
  handleMetamaskWallet,
  handleSafeWallet,
  handleTokenPocketWallet,
  handleTrustWallet,
  handleWalletConnect
} from "../helpers/wallets";
import { fetchBnbBalances } from "@/utils";
import { Icon } from "@/components";
import { Networks } from "./networks";
import CustomImage from "@/components/molecules/image";
import { BINANCE } from "../../../constants/static";
import { ToastType } from "@/components/molecules/toast/types";
import { usePathname, useRouter } from "next/navigation";
import { bnbChainInfo, er20ChainIds } from "../../../helpers/utils";
import dynamic from "next/dynamic";
import GeofenceNotice from "@/components/organisms/geofence-banner";
import { useAppStore } from "@/store/store";
import { useShallow } from "zustand/react/shallow";
import { useBnbStore } from "@/containers/bnb/store";
import {
  fetchBalance,
  fetchDefiData
} from "@/containers/bnb/store/sagas/fetching-sagas";
import { fetchInit } from "@/containers/bnb/store/sagas/init";
import TermsModal from "@/components/organisms/terms-modal";
// const TermsModal = dynamic(() => import("@/components/organisms/terms-modal"));

const BNB_TEST_NET_CONFIG = {
  method: "wallet_addEthereumChain",
  params: [
    {
      chainId: bnbChainInfo.networkIdHex, // Hexadecimal version of 80001, prefixed with 0x
      chainName: bnbChainInfo.networkName,
      nativeCurrency: {
        name: BSC,
        symbol: BNB,
        decimals: BNB_TOKEN_DECIMALS
      },
      rpcUrls: [bnbChainInfo.rpcUrl],
      blockExplorerUrls: [bnbChainInfo.explorerUrl],
      iconUrls: [""]
    }
  ]
};

export const addTestnetNetwork = async (
  provider: any,
  wallet: string,
  activeEnv: string
) => {
  try {
    if (
      wallet === META_MASK ||
      wallet === COIN_BASE ||
      wallet === TRUST_WALLET ||
      wallet === COIN_98 ||
      wallet === TOKEN_POCKET ||
      wallet === SAFE_PAL
    ) {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: bnbChainInfo.networkIdHex }] // Hexadecimal version of 80001, prefixed with 0x
      });
    } else {
      const chainId = activeEnv === DEVELOPMENT ? "bsc-testnet" : "bsc-mainnet";
      await provider.switchNetwork(chainId);
    }
    return true;
  } catch (error: any) {
    Sentry.captureException(error?.message);
    if (error.code === 4902) {
      try {
        await provider.request(BNB_TEST_NET_CONFIG);
        return true;
      } catch (addError) {
        Sentry.captureException("Could not add the bsc network");
        return false;
      }
    }
    return false;
  }
};

const BnbNavigation = () => {
  const pathname = usePathname();
  const router = useRouter();
  const { isMobile } = useWindowSize();

  const {
    walletData,
    setNetworkModal,
    setExchangeRate,
    setInitialDefiData,
    handleMobileSidebar
  } = useBnbStore(
    useShallow((state) => ({
      walletData: state.walletData.walletInfo,
      setNetworkModal: state.walletDataActions.setNetworkModal,
      setExchangeRate: state.initialDataActions.setExchangeRate,
      setInitialDefiData: state.initialDataActions.setInitialDefiData,
      handleMobileSidebar: state.walletDataActions.handleMobileSidebar
    }))
  );

  const { account, walletConnection, signer } = walletData;

  const [networkItem, setNetworkItem] = useLocalStorage("eth-network", "");
  const [walletInfo, setWalletInfo] = useLocalStorage("bnb-walletInfo", "");

  const { activeNetwork, allBalances, setBnbBalances, setActiveNetwork } =
    useAppStore(
      useShallow((state) => ({
        activeNetwork: state.activeNetwork,
        allBalances: state.allBalance,
        setBnbBalances: state.setBnbBalances,
        setActiveNetwork: state.setActiveNetwork
      }))
    );

  const handleMenu = () => {
    handleMobileSidebar(true);
  };

  useEffect(() => {
    fetchInit();
    fetchDefiData();
  }, []);

  useEffect(() => {
    // const interval = setInterval(() => {
    if (walletConnection) {
      fetchBalance({
        accountAddress: account,
        signer: signer
      });
    }
    // }, SHORT_INTERVAL);
    // return () => clearInterval(interval);
  }, [walletConnection, account]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const exchangeRate = await getBnbExchangeRate();
      setExchangeRate(exchangeRate);
    }, LONG_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const [
        openLeverage,
        beefyInfo,
        wombatInfo,
        pancakeInfo,
        pancakeV3Info,
        thena
      ] = await Promise.all([
        fetchOpenLeverage(),
        fetchBeefyInfo(),
        fetchWambot(),
        fetchPancakeInfo(),
        fetchPancakeV3Info(),
        fetchThenaInfo()
      ]);
      setInitialDefiData({
        shieldApiInfo: initialTVLAPY,
        openLeverageApiInfo: openLeverage,
        beefyInfo: beefyInfo,
        panCakeApiInfo: pancakeInfo,
        wombatApiInfo: wombatInfo,
        panCakeV3ApiInfo: pancakeV3Info,
        thenaApiInfo: thena
      });
    }, MID_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (walletConnection) {
        fetchInit();
      }
    }, SHORT_INTERVAL);
    return () => clearInterval(interval);
  }, [account, walletConnection]);

  useEffect(() => {
    if (walletConnection) {
      let metamaskProvider: any;
      if (window.ethereum?.providers) {
        metamaskProvider = window.ethereum?.providers.find((item: any) => {
          return item && item.isMetaMask;
        });
      } else {
        metamaskProvider = window.ethereum;
      }
      metamaskProvider?.on("chainChanged", function (chainId: string) {
        if (chainId !== bnbChainInfo.networkIdHex) {
          const chainIdList = er20ChainIds;
          const responseChainId = chainIdList.find(
            (item) => item.networkIdHex === chainId
          );
          if (responseChainId) {
            // switch network when network changed
            setActiveNetwork(responseChainId.name);
            setNetworkItem(responseChainId.name);
            router.push("/eth?token=ETH");
          } else {
            displayToast(
              {
                heading: UNSUPPORTED_NETWORK,
                message: "Please switch network to Supported Network"
              },
              ToastType.ERROR
            );
          }
        } else {
          setNetworkModal(false);
        }
      });
      window.BinanceChain?.on("chainChanged", function (chainId: string) {
        if (chainId !== bnbChainInfo.networkIdHex) {
          const chainIdList = er20ChainIds;
          const responseChainId = chainIdList.find(
            (item) => item.networkIdHex === chainId
          );
          if (responseChainId) {
            setActiveNetwork(responseChainId.name);
            router.push("/eth?token=ETH");
          } else {
            displayToast(
              {
                heading: UNSUPPORTED_NETWORK,
                message: "Please switch network to Supported Network"
              },
              ToastType.ERROR
            );
          }
        } else {
          setNetworkModal(false);
        }
      });
    }
  }, [walletConnection]);

  useEffect(() => {
    const fetchBalances = async () => {
      console.log(walletConnection, account, "walletConnection");
      if (walletConnection && allBalances.bnb.length === 0) {
        const bnbBalance = await fetchBnbBalances(account);
        setBnbBalances(bnbBalance);
      }
    };
    fetchBalances();
  }, [walletConnection]);

  useEffect(() => {
    if (walletInfo !== null && walletInfo !== undefined) {
      if (walletInfo.walletType === META_MASK) {
        handleMetamaskWallet();
      } else if (walletInfo.walletType === TRUST_WALLET) {
        isMobile ? handleWalletConnect() : handleTrustWallet();
      } else if (walletInfo.walletType === COIN_98) {
        handleCoin98Wallet();
      } else if (walletInfo.walletType === SAFE_PAL) {
        handleSafeWallet();
      } else if (walletInfo.walletType === TOKEN_POCKET) {
        handleTokenPocketWallet();
      } else if (walletInfo.walletType === BINANCE) {
        handleBinanceWallet();
      }
    }
  }, []);

  // set active app network on page refresh
  useEffect(() => {
    if (activeNetwork !== "bnb smart chain") {
      setActiveNetwork("BNB Smart Chain".toLowerCase());
    }
  }, []);

  const handleAccountChange = async (accounts) => {
    await handleMetamaskWallet();
    if (account && accounts[0] && accounts[0] !== account) {
      const bnbBalance = await fetchBnbBalances(account);
      setBnbBalances(bnbBalance);
    }
  };

  useEffect(() => {
    if (walletConnection && pathname.toLowerCase().includes("bnb")) {
      window.ethereum?.on("accountsChanged", handleAccountChange);
      return () => {
        window.ethereum?.removeListener("accountsChanged", handleAccountChange);
      };
    }
    if (walletConnection && pathname.toLowerCase().includes("bnb")) {
      window.BinanceChain?.on("accountsChanged", handleAccountChange);
      return () => {
        window.BinanceChain?.removeListener(
          "accountsChanged",
          handleAccountChange
        );
      };
    }
  }, [walletConnection, pathname]);

  return (
    <div>
      <GeofenceNotice />
      <div className="flex pt-8 mb-12 pl-7 pr-8 md:px-3">
        <div className="flex items-center flex-1">
          <div className="hidden md:block">
            <Link href="/" className="nav-link" passHref>
              <CustomImage
                src={`/logo.svg`}
                alt={"logo"}
                width={isMobile ? 90 : 124}
                height={31}
              />
            </Link>
          </div>
          <div className="flex ml-auto">
            <Networks appName={"BNB Smart Chain"} />
            <div>
              <LoginOptions />
            </div>
            <button className="md:block hidden pl-2" onClick={handleMenu}>
              <Icon iconName="menu" viewClass="menu" />
            </button>
          </div>
          <TermsModal isWalletConnected={walletConnection} network={"bnb"} />
        </div>
      </div>
    </div>
  );
};

export default BnbNavigation;
