"use client";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { createAppSlice, AppSlice } from "./slices/app-state-slice";
import { createBalanceSlice, BalanceSlice } from "./slices/balance-slice";
import {
  createTransactionSlice,
  TransactionSlice
} from "./slices/transaction-slice";
import { mountStoreDevtool } from "simple-zustand-devtools";

type StoreState = AppSlice & BalanceSlice & TransactionSlice;

export const useAppStore = createWithEqualityFn<StoreState>()(
  (...a) => ({
    ...createAppSlice(...a),
    ...createBalanceSlice(...a),
    ...createTransactionSlice(...a)
  }),
  shallow
);

mountStoreDevtool("appStore", useAppStore);
