import {
  DstChainItem,
  ExternalChainData,
  IBCChainData,
  DydxTokenData
} from "./types";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const dydxTokenData: DydxTokenData = {
  tokenName: env === "Testnet" ? "DV4TNT" : "DYDX",
  tokenIdentifier: env === "Testnet" ? "adv4tnt" : "adydx",
  stkTokenName: env === "Testnet" ? "stkDV4TNT" : "stkDYDX",
  stkTokenIdentifier: env === "Testnet" ? "stk/adv4tnt" : "stk/adydx"
};

export const IBCConfiguration = {
  timeoutTimestamp: 1000,
  ibcRevisionHeightIncrement: 1000,
  ibcRemoteHeightIncrement: 150,
  ibcDefaultPort: "transfer"
};

export const ChainExternalInfo: any = {
  Testnet: {
    persistence: {
      chainID: "test-core-2",
      explorer: "https://testnet.mintscan.io/persistence-testnet"
    },
    cosmos: {
      chainID: "theta-testnet-001",
      explorer: "https://testnet.mintscan.io/cosmoshub-testnet"
    },
    osmosis: {
      chainID: "osmo-test-5",
      explorer: "https://testnet.mintscan.io/osmosis-testnet"
    },
    dydx: {
      chainID: "dydx-testnet-4",
      explorer: "https://testnet.mintscan.io/dydx-testnet"
    },
    stars: {
      chainID: "elgafar-1",
      explorer: "https://testnet.mintscan.io/stargaze"
    },
    agoric: {
      chainID: "agoric-emerynet-8",
      explorer: "https://testnet.mintscan.io/agoric"
    },
    chihuahua: {
      chainID: "chihuahua-1",
      explorer: "https://testnet.mintscan.io/chihuahua"
    }
  },
  Mainnet: {
    persistence: {
      chainID: "core-1",
      explorer: "https://mintscan.io/persistence"
    },
    cosmos: {
      chainID: "cosmoshub-4",
      explorer: "https://mintscan.io/cosmos"
    },
    osmosis: {
      chainID: "osmosis-1",
      explorer: "https://mintscan.io/osmosis"
    },
    dydx: {
      chainID: "dydx-mainnet-1",
      explorer: "https://mintscan.io/dydx"
    },
    stars: {
      chainID: "stargaze-1",
      explorer: "https://mintscan.io/stargaze"
    },
    agoric: {
      chainID: "agoric-3",
      explorer: "https://bigdipper.live/agoric"
    },
    chihuahua: {
      chainID: "chihuahua-1",
      explorer: "https://mintscan.io/chihuahua"
    }
  }
};

// available Destination chain list
export const dstChainsList: DstChainItem[] = [
  {
    chainName: "Persistence",
    prefix: "persistence",
    logo: "/tokens/xprt.svg", // logo path
    tokenName: "XPRT",
    tokenDenom: "uxprt",
    tokenImgUrl: "/tokens/xprt.svg",
    stkTokenName: "stkXPRT",
    stkTokenImgUrl: "/tokens/stk_xprt.svg",
    stkTokenDenom: "stk/uxprt"
  },
  {
    chainName: "Cosmos",
    prefix: "cosmos",
    logo: "/tokens/atom.svg", // logo path
    tokenName: "ATOM",
    tokenDenom: "uatom",
    tokenImgUrl: "/tokens/atom.svg",
    stkTokenName: "stkATOM",
    stkTokenImgUrl: "/tokens/stk_atom.svg",
    stkTokenDenom: "stk/uatom"
  },
  {
    chainName: "Osmosis",
    prefix: "osmo",
    logo: "/tokens/osmo.svg",
    tokenName: "OSMO",
    tokenDenom: "uosmo",
    tokenImgUrl: "/tokens/osmo.svg",
    stkTokenName: "stkOSMO",
    stkTokenImgUrl: "/tokens/stk_osmo.svg",
    stkTokenDenom: "stk/uosmo"
  },
  {
    chainName: "Dydx",
    prefix: "dydx",
    logo: "/tokens/dydx.svg",
    tokenName: dydxTokenData.tokenName,
    tokenDenom: dydxTokenData.tokenIdentifier,
    tokenImgUrl: "/tokens/dydx.svg",
    stkTokenName: dydxTokenData.stkTokenName,
    stkTokenImgUrl: "/tokens/stk_dydx.svg",
    stkTokenDenom: dydxTokenData.stkTokenIdentifier
  },
  {
    chainName: "Stargaze",
    prefix: "stars",
    logo: "/tokens/stars.svg",
    tokenName: "STARS",
    tokenDenom: "ustars",
    tokenImgUrl: "/tokens/stars.svg",
    stkTokenName: "stkSTARS",
    stkTokenImgUrl: "/tokens/stk_stars.svg",
    stkTokenDenom: "stk/ustars"
  },
  {
    chainName: "Agoric",
    prefix: "agoric",
    logo: "/tokens/bld.svg",
    tokenName: "BLD",
    tokenDenom: "ubld",
    tokenImgUrl: "/tokens/bld.svg",
    stkTokenName: "stkBLD",
    stkTokenImgUrl: "/tokens/stk_bld.svg",
    stkTokenDenom: "stk/ubld"
  },
  {
    chainName: "Chihuahua",
    prefix: "chihuahua",
    logo: "/tokens/huahua.svg",
    tokenName: "HUAHUA",
    tokenDenom: "uhuahua",
    tokenImgUrl: "/tokens/huahua.svg",
    stkTokenName: "stkHUAHUA",
    stkTokenImgUrl: "/tokens/stk_huahua.svg",
    stkTokenDenom: "stk/uhuahua"
  }
];

export const IBCChainTokens: any = {
  Testnet: {
    atom: {
      denom:
        "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9"
    },
    osmo: {
      denom:
        "ibc/6AE2756AA7EAA8FA06E11472EA05CA681BD8D3FBC1AAA9F06C79D1EC1C90DC9B"
    },
    dydx: {
      denom:
        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
    },
    stars: {
      denom:
        "ibc/E6ACE9ACB1198998177C8ED4C6BCD3BFB046FEC4559E5F203473157A9CD0C07B"
    },
    agoric: {
      denom:
        "ibc/910CED9A9DB0C5921E1FC8FD60AA2C1D5F9E8C25E50DA6959117B4B2041D7B46"
    },
    chihuahua: {
      denom:
        "ibc/21DE7368DBC48C833B1A349DCEFD033543C117733B9E17FD04BD8801BDCFF3CB"
    }
  },
  Mainnet: {
    atom: {
      denom:
        "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
    },
    osmo: {
      denom:
        "ibc/646315E3B0461F5FA4C5C8968A88FC45D4D5D04A45B98F1B8294DD82F386DD85"
    },
    dydx: {
      denom:
        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E"
    },
    stars: {
      denom:
        "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B"
    },
    agoric: {
      denom:
        "ibc/5BCA39AEE3A48194602328FF11D873FCC61ADDD40C7F1BBB7B05C9AA2896B407"
    },
    chihuahua: {
      denom:
        "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4"
    }
  }
};

export const IBCChainInfos: IBCChainData = {
  Testnet: [
    {
      counterpartyChainId: ChainExternalInfo["Testnet"].cosmos.chainID,
      chainName: "pStake Cosmos Testnet",
      sourceChannelId: "channel-2777",
      destinationChannelId: "channel-1",
      portID: "transfer",
      coinDenom:
        "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9",
      prefix: "cosmos"
    },
    {
      counterpartyChainId: ChainExternalInfo["Testnet"].osmosis.chainID,
      chainName: "Osmosis",
      sourceChannelId: "channel-4198",
      destinationChannelId: "channel-12",
      portID: "transfer",
      coinDenom:
        "ibc/6AE2756AA7EAA8FA06E11472EA05CA681BD8D3FBC1AAA9F06C79D1EC1C90DC9B",
      prefix: "osmo"
    },
    {
      counterpartyChainId: ChainExternalInfo["Testnet"].dydx.chainID,
      chainName: "Dydx",
      sourceChannelId: "channel-1",
      destinationChannelId: "channel-15",
      portID: "transfer",
      coinDenom:
        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3",
      prefix: "dydx"
    },
    {
      counterpartyChainId: ChainExternalInfo["Testnet"].stars.chainID,
      chainName: "Stargaze",
      sourceChannelId: "channel-803",
      destinationChannelId: "channel-66",
      portID: "transfer",
      coinDenom:
        "ibc/E6ACE9ACB1198998177C8ED4C6BCD3BFB046FEC4559E5F203473157A9CD0C07B",
      prefix: "stars"
    },
    {
      counterpartyChainId: ChainExternalInfo["Testnet"].agoric.chainID,
      chainName: "Agoric",
      sourceChannelId: "channel-19",
      destinationChannelId: "channel-69",
      portID: "transfer",
      coinDenom:
        "ibc/910CED9A9DB0C5921E1FC8FD60AA2C1D5F9E8C25E50DA6959117B4B2041D7B46",
      prefix: "agoric"
    },
    {
      counterpartyChainId: ChainExternalInfo["Testnet"].chihuahua.chainID,
      chainName: "Chihuahua",
      sourceChannelId: "channel-88",
      destinationChannelId: "channel-72",
      portID: "transfer",
      coinDenom:
        "ibc/21DE7368DBC48C833B1A349DCEFD033543C117733B9E17FD04BD8801BDCFF3CB",
      prefix: "chihuahua"
    }
  ],
  Mainnet: [
    {
      counterpartyChainId: ChainExternalInfo["Mainnet"].cosmos.chainID,
      chainName: "Cosmos Hub",
      sourceChannelId: "channel-190",
      destinationChannelId: "channel-24",
      portID: "transfer",
      coinDenom:
        "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA",
      prefix: "cosmos"
    },
    {
      counterpartyChainId: ChainExternalInfo["Mainnet"].osmosis.chainID,
      chainName: "Osmosis",
      sourceChannelId: "channel-4",
      destinationChannelId: "channel-6",
      portID: "transfer",
      coinDenom:
        "ibc/646315E3B0461F5FA4C5C8968A88FC45D4D5D04A45B98F1B8294DD82F386DD85",
      prefix: "osmo"
    },
    {
      counterpartyChainId: ChainExternalInfo["Mainnet"].dydx.chainID,
      chainName: "Dydx",
      sourceChannelId: "channel-4",
      destinationChannelId: "channel-131",
      portID: "transfer",
      coinDenom:
        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E",
      prefix: "dydx"
    },
    {
      counterpartyChainId: ChainExternalInfo["Mainnet"].stars.chainID,
      chainName: "Stargaze",
      sourceChannelId: "channel-287",
      destinationChannelId: "channel-197",
      portID: "transfer",
      coinDenom:
        "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B",
      prefix: "stars"
    },
    {
      counterpartyChainId: ChainExternalInfo["Mainnet"].agoric.chainID,
      chainName: "Agoric",
      sourceChannelId: "channel-72",
      destinationChannelId: "channel-202",
      portID: "transfer",
      coinDenom:
        "ibc/5BCA39AEE3A48194602328FF11D873FCC61ADDD40C7F1BBB7B05C9AA2896B407",
      prefix: "agoric"
    },
    {
      counterpartyChainId: ChainExternalInfo["Mainnet"].chihuahua.chainID,
      chainName: "Chihuahua",
      sourceChannelId: "channel-94",
      destinationChannelId: "channel-203",
      portID: "transfer",
      coinDenom:
        "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4",
      prefix: "chihuahua"
    }
  ]
};

export const ExternalChains: ExternalChainData = {
  // Testnet: [
  //   {
  //     rpc: "http://139.59.20.40:26657",
  //     rest: "http://139.59.20.40:1317",
  //     chainId: ChainExternalInfo["Testnet"].persistence.chainID,
  //     chainName: "pStake Devnet",
  //     stakeCurrency: {
  //       coinDenom: "XPRT",
  //       coinMinimalDenom: "uxprt",
  //       coinDecimals: 6,
  //       coinGeckoId: "persistence",
  //     },
  //     bip44: {
  //       coinType: 118,
  //     },
  //     currencies: [
  //       {
  //         coinDenom: "XPRT",
  //         coinMinimalDenom: "uxprt",
  //         coinDecimals: 6,
  //         coinGeckoId: "persistence",
  //       },
  //       {
  //         coinDenom: "STKATOM",
  //         coinMinimalDenom: "stk/uatom",
  //         coinDecimals: 6,
  //         coinGeckoId: "stk/uatom",
  //       },
  //       {
  //         coinDenom: "STKOSMO",
  //         coinMinimalDenom: "stk/uosmo",
  //         coinDecimals: 6,
  //         coinGeckoId: "stk/uosmo",
  //       },
  //     ],
  //     feeCurrencies: [
  //       {
  //         coinDenom: "XPRT",
  //         coinMinimalDenom: "uxprt",
  //         coinDecimals: 6,
  //         coinGeckoId: "persistence",
  //       },
  //     ],
  //     bech32Config: {
  //       bech32PrefixAccAddr: "persistence",
  //       bech32PrefixAccPub: "persistencepub",
  //       bech32PrefixValAddr: "persistencevaloper",
  //       bech32PrefixValPub: "persistencevaloperpub",
  //       bech32PrefixConsAddr: "persistencevalcons",
  //       bech32PrefixConsPub: "persistencevalconspub",
  //     },
  //   },
  //   {
  //     rpc: "http://139.59.20.40:26653",
  //     rest: "http://139.59.20.40:1313",
  //     chainId: ChainExternalInfo["Testnet"].cosmos.chainID,
  //     chainName: "pStake Cosmos Devnet",
  //     stakeCurrency: {
  //       coinDenom: "ATOM",
  //       coinMinimalDenom: "uatom",
  //       coinDecimals: 6,
  //       coinGeckoId: "cosmos",
  //     },
  //     bip44: {
  //       coinType: 118,
  //     },
  //     currencies: [
  //       {
  //         coinDenom: "ATOM",
  //         coinMinimalDenom: "uatom",
  //         coinDecimals: 6,
  //         coinGeckoId: "cosmos",
  //       },
  //     ],
  //     feeCurrencies: [
  //       {
  //         coinDenom: "ATOM",
  //         coinMinimalDenom: "uatom",
  //         coinDecimals: 6,
  //         coinGeckoId: "cosmos",
  //       },
  //     ],
  //     bech32Config: {
  //       bech32PrefixAccAddr: "cosmos",
  //       bech32PrefixAccPub: "cosmospub",
  //       bech32PrefixValAddr: "cosmosvaloper",
  //       bech32PrefixValPub: "cosmosvaloperpub",
  //       bech32PrefixConsAddr: "cosmosvalcons",
  //       bech32PrefixConsPub: "persistencevalconspub",
  //     },
  //     gasPriceStep: {
  //       low: 0.0,
  //       average: 0.0,
  //       high: 0.0,
  //     },
  //   },
  //   {
  //     rpc: "http://139.59.20.40:26654",
  //     rest: "http://139.59.20.40:1314",
  //     chainId: ChainExternalInfo["Testnet"].osmosis.chainID,
  //     chainName: "pStake Osmosis Devnet",
  //     bip44: {
  //       coinType: 118,
  //     },
  //     bech32Config: {
  //       bech32PrefixAccAddr: "osmo",
  //       bech32PrefixAccPub: "osmopub",
  //       bech32PrefixValAddr: "osmovaloper",
  //       bech32PrefixValPub: "osmovaloperpub",
  //       bech32PrefixConsAddr: "osmovalcons",
  //       bech32PrefixConsPub: "osmovalconspub",
  //     },
  //     currencies: [
  //       {
  //         coinDenom: "OSMO",
  //         coinMinimalDenom: "uosmo",
  //         coinDecimals: 6,
  //         coinGeckoId: "uosmo",
  //       },
  //     ],
  //     gasPriceStep: {
  //       low: 0.0,
  //       average: 0.0,
  //       high: 0.0,
  //     },
  //     feeCurrencies: [
  //       {
  //         coinDenom: "OSMO",
  //         coinMinimalDenom: "uosmo",
  //         coinDecimals: 6,
  //         // coinGeckoId: "osmosis",
  //         coinGeckoId: "pool:uosmo",
  //       },
  //     ],
  //     stakeCurrency: {
  //       coinDenom: "OSMO",
  //       coinMinimalDenom: "uosmo",
  //       coinDecimals: 6,
  //       // coinGeckoId: "osmosis",
  //       coinGeckoId: "pool:uosmo",
  //     },
  //   },
  // ],
  Testnet: [
    {
      rpc: "https://rpc.testnet2.persistence.one/",
      rest: "https://rest.testnet2.persistence.one/",
      chainId: ChainExternalInfo["Testnet"].persistence.chainID,
      chainName: "Persistence test-net",
      stakeCurrency: {
        coinDenom: "XPRT",
        coinMinimalDenom: "uxprt",
        coinDecimals: 6,
        coinGeckoId: "persistence"
      },
      bip44: {
        coinType: 118
      },
      currencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence"
        }
        // {
        //   coinDenom: "STKXPRT",
        //   coinMinimalDenom: "stk/uxprt",
        //   coinDecimals: 6,
        //   coinGeckoId: "persistence"
        // },
        // {
        //   coinDenom: "STKATOM",
        //   coinMinimalDenom: "stk/uatom",
        //   coinDecimals: 6,
        //   coinGeckoId: "persistence"
        // },
        // {
        //   coinDenom: "STKOSMO",
        //   coinMinimalDenom: "stk/uosmo",
        //   coinDecimals: 6,
        //   coinGeckoId: "stk/uosmo"
        // }
        // {
        //   coinDenom: "STKDV4TNT",
        //   coinMinimalDenom: "stk/adv4tnt",
        //   coinDecimals: 18,
        //   coinGeckoId: ""
        // }
        // {
        //   coinDenom: "STKDYDX",
        //   coinMinimalDenom: "stk/adydx",
        //   coinDecimals: 18,
        //   coinGeckoId: ""
        // }
        // {
        //   coinDenom: "STKSTARS",
        //   coinMinimalDenom: "stk/ustars",
        //   coinDecimals: 6,
        //   coinGeckoId: ""
        // },
        // {
        //   coinDenom: "STKBLD",
        //   coinMinimalDenom: "stk/ubld",
        //   coinDecimals: 6,
        //   coinGeckoId: ""
        // }
      ],
      feeCurrencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence"
        }
      ],
      bech32Config: {
        bech32PrefixAccAddr: "persistence",
        bech32PrefixAccPub: "persistencepub",
        bech32PrefixValAddr: "persistencevaloper",
        bech32PrefixValPub: "persistencevaloperpub",
        bech32PrefixConsAddr: "persistencevalcons",
        bech32PrefixConsPub: "persistencevalconspub"
      },
      gasPriceStep: {
        low: 0.0,
        average: 0.0,
        high: 0.0
      }
    },
    {
      rpc: "https://rpc.sentry-02.theta-testnet.polypore.xyz",
      rest: "https://rest.sentry-02.theta-testnet.polypore.xyz",
      chainId: ChainExternalInfo["Testnet"].cosmos.chainID,
      chainName: "pStake Cosmos Testnet",
      stakeCurrency: {
        coinDenom: "ATOM",
        coinMinimalDenom: "uatom",
        coinDecimals: 6,
        coinGeckoId: "cosmos"
      },
      bip44: {
        coinType: 118
      },
      currencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos"
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos"
        }
      ],
      bech32Config: {
        bech32PrefixAccAddr: "cosmos",
        bech32PrefixAccPub: "cosmospub",
        bech32PrefixValAddr: "cosmosvaloper",
        bech32PrefixValPub: "cosmosvaloperpub",
        bech32PrefixConsAddr: "cosmosvalcons",
        bech32PrefixConsPub: "cosmosvalconspub"
      },
      gasPriceStep: {
        low: 0.0,
        average: 0.0,
        high: 0.0
      }
    },
    {
      rpc: "https://osmosis-testnet-rpc.polkachu.com",
      rest: "https://osmosis-testnet-api.polkachu.com",
      chainId: ChainExternalInfo["Testnet"].osmosis.chainID,
      chainName: "Osmosis",
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "osmo",
        bech32PrefixAccPub: "osmopub",
        bech32PrefixValAddr: "osmovaloper",
        bech32PrefixValPub: "osmovaloperpub",
        bech32PrefixConsAddr: "osmovalcons",
        bech32PrefixConsPub: "osmovalconspub"
      },
      currencies: [
        {
          coinDenom: "OSMO",
          coinMinimalDenom: "uosmo",
          coinDecimals: 6,
          coinGeckoId: "uosmo"
        }
      ],
      gasPriceStep: {
        low: 0.0,
        average: 0.0,
        high: 0.0
      },
      feeCurrencies: [
        {
          coinDenom: "OSMO",
          coinMinimalDenom: "uosmo",
          coinDecimals: 6,
          // coinGeckoId: "osmosis",
          coinGeckoId: "pool:uosmo"
        }
      ],
      stakeCurrency: {
        coinDenom: "OSMO",
        coinMinimalDenom: "uosmo",
        coinDecimals: 6,
        // coinGeckoId: "osmosis",
        coinGeckoId: "pool:uosmo"
      }
    },
    {
      rpc: "https://dydx-testnet-rpc.polkachu.com",
      rest: "https://dydx-testnet-api.polkachu.com",
      chainId: "dydx-testnet-4",
      chainName: "Dydx-Test",
      stakeCurrency: {
        coinDenom: "DV4TNT",
        coinDecimals: 18,
        coinMinimalDenom: "adv4tnt"
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "dydx",
        bech32PrefixAccPub: "dydxpub",
        bech32PrefixValAddr: "dydxvaloper",
        bech32PrefixValPub: "dydxvaloperpub",
        bech32PrefixConsAddr: "dydxvalcons",
        bech32PrefixConsPub: "dydxvalconspub"
      },
      currencies: [
        {
          coinDenom: "DV4TNT",
          coinDecimals: 18,
          coinMinimalDenom: "adv4tnt"
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "DV4TNT",
          coinDecimals: 18,
          coinMinimalDenom: "adv4tnt"
        }
      ],
      gasPriceStep: {
        low: 0,
        average: 0,
        high: 25000000000
      },
      features: []
    },
    {
      rpc: "https://rpc.elgafar-1.stargaze-apis.com",
      rest: "https://rest.elgafar-1.stargaze-apis.com",
      chainId: "elgafar-1",
      chainName: "Stargaze Testnet",
      stakeCurrency: {
        coinDenom: "STARS",
        coinMinimalDenom: "ustars",
        coinDecimals: 6
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "stars",
        bech32PrefixAccPub: "starspub",
        bech32PrefixValAddr: "starsvaloper",
        bech32PrefixValPub: "starsvaloperpub",
        bech32PrefixConsAddr: "starsvalcons",
        bech32PrefixConsPub: "starsvalconspub"
      },
      currencies: [
        {
          coinDenom: "STARS",
          coinMinimalDenom: "ustars",
          coinDecimals: 6,
          coinGeckoId: "stars"
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "STARS",
          coinMinimalDenom: "ustars",
          coinDecimals: 6,
          coinGeckoId: "stars"
        }
      ],
      gasPriceStep: {
        low: 0.03,
        average: 0.04,
        high: 0.05
      },
      features: []
    },
    {
      rpc: "https://emerynet.rpc.agoric.net",
      rest: "https://emerynet.api.agoric.net",
      chainId: "agoric-emerynet-8",
      chainName: "Agoric Testnet",
      stakeCurrency: {
        coinDenom: "BLD",
        coinMinimalDenom: "ubld",
        coinDecimals: 6
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "agoric",
        bech32PrefixAccPub: "agoricpub",
        bech32PrefixValAddr: "agoricvaloper",
        bech32PrefixValPub: "agoricvaloperpub",
        bech32PrefixConsAddr: "agoricvalcons",
        bech32PrefixConsPub: "agoricvalconspub"
      },
      currencies: [
        {
          coinDenom: "BLD",
          coinMinimalDenom: "ubld",
          coinDecimals: 6
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "BLD",
          coinMinimalDenom: "ubld",
          coinDecimals: 6
        }
      ],
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.03
      },
      features: []
    },
    {
      rpc: "https://chihuahua-rpc.polkachu.com",
      rest: "https://chihuahua-api.polkachu.com",
      chainId: "chihuahua-1",
      chainName: "Chihuahua",
      stakeCurrency: {
        coinDenom: "HUAHUA",
        coinMinimalDenom: "uhuahua",
        coinDecimals: 6
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "chihuahua",
        bech32PrefixAccPub: "chihuahuapub",
        bech32PrefixValAddr: "chihuahuavaloper",
        bech32PrefixValPub: "chihuahuavaloperpub",
        bech32PrefixConsAddr: "chihuahuavalcons",
        bech32PrefixConsPub: "chihuahuavalconspub"
      },
      currencies: [
        {
          coinDenom: "HUAHUA",
          coinMinimalDenom: "uhuahua",
          coinDecimals: 6
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "HUAHUA",
          coinMinimalDenom: "uhuahua",
          coinDecimals: 6
        }
      ],
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.03
      },
      features: []
    }
  ],
  Mainnet: [
    {
      rpc: "https://cosmos-rpc.polkachu.com/",
      rest: "https://cosmos-rest.staketab.org/",
      chainId: ChainExternalInfo["Mainnet"].cosmos.chainID,
      chainName: "Cosmos Hub",
      stakeCurrency: {
        coinDenom: "ATOM",
        coinMinimalDenom: "uatom",
        coinDecimals: 6,
        coinGeckoId: "cosmos"
      },
      bip44: {
        coinType: 118
      },
      currencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos"
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos"
        }
      ],
      bech32Config: {
        bech32PrefixAccAddr: "cosmos",
        bech32PrefixAccPub: "cosmospub",
        bech32PrefixValAddr: "cosmosvaloper",
        bech32PrefixValPub: "cosmosvaloperpub",
        bech32PrefixConsAddr: "cosmosvalcons",
        bech32PrefixConsPub: "persistencevalconspub"
      }
    },
    {
      rpc: "https://rpc.core.persistence.one/",
      rest: "https://rest.core.persistence.one/",
      chainId: ChainExternalInfo["Mainnet"].persistence.chainID,
      chainName: "Persistence",
      stakeCurrency: {
        coinDenom: "XPRT",
        coinMinimalDenom: "uxprt",
        coinDecimals: 6,
        coinGeckoId: "persistence"
      },
      bip44: {
        coinType: 750
      },
      currencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence"
        },
        {
          coinDenom: "STKATOM",
          coinMinimalDenom: "stk/uatom",
          coinDecimals: 6,
          coinGeckoId: "persistence"
        },
        {
          coinDenom: "STKOSMO",
          coinMinimalDenom: "stk/uosmo",
          coinDecimals: 6,
          coinGeckoId: "stk/uosmo"
        },
        {
          coinDenom: "STKDYDX",
          coinMinimalDenom: "stk/adydx",
          coinDecimals: 18,
          coinGeckoId: "stk/adydx"
        },
        {
          coinDenom: "STKSTARS",
          coinMinimalDenom: "stk/ustars",
          coinDecimals: 6,
          coinGeckoId: ""
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence"
        }
      ],
      bech32Config: {
        bech32PrefixAccAddr: "persistence",
        bech32PrefixAccPub: "persistencepub",
        bech32PrefixValAddr: "persistencevaloper",
        bech32PrefixValPub: "persistencevaloperpub",
        bech32PrefixConsAddr: "persistencevalcons",
        bech32PrefixConsPub: "persistencevalconspub"
      },
      gasPriceStep: {
        low: 0.0,
        average: 0.0,
        high: 0.0
      }
    },
    {
      rpc: "https://osmosis-rpc.polkachu.com",
      rest: "https://osmosis-api.polkachu.com",
      chainId: ChainExternalInfo["Mainnet"].osmosis.chainID,
      chainName: "Osmosis",
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "osmo",
        bech32PrefixAccPub: "osmopub",
        bech32PrefixValAddr: "osmovaloper",
        bech32PrefixValPub: "osmovaloperpub",
        bech32PrefixConsAddr: "osmovalcons",
        bech32PrefixConsPub: "osmovalconspub"
      },
      currencies: [
        {
          coinDenom: "OSMO",
          coinMinimalDenom: "uosmo",
          coinDecimals: 6,
          coinGeckoId: "uosmo"
        }
      ],
      gasPriceStep: {
        low: 0.0,
        average: 0.0,
        high: 0.0
      },
      feeCurrencies: [
        {
          coinDenom: "OSMO",
          coinMinimalDenom: "uosmo",
          coinDecimals: 6,
          coinGeckoId: "uosmo"
        }
      ],
      stakeCurrency: {
        coinDenom: "OSMO",
        coinMinimalDenom: "uosmo",
        coinDecimals: 6,
        coinGeckoId: "uosmo"
      }
    },
    {
      rpc: "https://dydx-dao-rpc.polkachu.com",
      rest: "https://dydx-dao-api.polkachu.com",
      chainId: "dydx-mainnet-1",
      chainName: "dYdX",
      stakeCurrency: {
        coinDenom: "DYDX",
        coinDecimals: 18,
        coinMinimalDenom: "adydx"
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "dydx",
        bech32PrefixAccPub: "dydxpub",
        bech32PrefixValAddr: "dydxvaloper",
        bech32PrefixValPub: "dydxvaloperpub",
        bech32PrefixConsAddr: "dydxvalcons",
        bech32PrefixConsPub: "dydxvalconspub"
      },
      currencies: [
        {
          coinDenom: "DYDX",
          coinDecimals: 18,
          coinMinimalDenom: "adydx"
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "DYDX",
          coinDecimals: 18,
          coinMinimalDenom: "adydx"
        }
      ],
      features: []
    },
    {
      rpc: "https://rpc.stargaze-apis.com",
      rest: "https://rest.stargaze-apis.com",
      chainId: "stargaze-1",
      chainName: "Stargaze",
      stakeCurrency: {
        coinDenom: "STARS",
        coinMinimalDenom: "ustars",
        coinDecimals: 6
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "stars",
        bech32PrefixAccPub: "starspub",
        bech32PrefixValAddr: "starsvaloper",
        bech32PrefixValPub: "starsvaloperpub",
        bech32PrefixConsAddr: "starsvalcons",
        bech32PrefixConsPub: "starsvalconspub"
      },
      currencies: [
        {
          coinDenom: "STARS",
          coinMinimalDenom: "ustars",
          coinDecimals: 6,
          coinGeckoId: "stars"
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "STARS",
          coinMinimalDenom: "ustars",
          coinDecimals: 6,
          coinGeckoId: "stars"
        }
      ],
      gasPriceStep: {
        low: 0.03,
        average: 0.4,
        high: 0.05
      },
      features: []
    },
    {
      rpc: "https://agoric-rpc.polkachu.com",
      rest: "https://agoric-api.polkachu.com",
      chainId: "agoric-3",
      chainName: "Agoric",
      stakeCurrency: {
        coinDenom: "BLD",
        coinMinimalDenom: "ubld",
        coinDecimals: 6
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "agoric",
        bech32PrefixAccPub: "agoricpub",
        bech32PrefixValAddr: "agoricvaloper",
        bech32PrefixValPub: "agoricvaloperpub",
        bech32PrefixConsAddr: "agoricvalcons",
        bech32PrefixConsPub: "agoricvalconspub"
      },
      currencies: [
        {
          coinDenom: "BLD",
          coinMinimalDenom: "ubld",
          coinDecimals: 6
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "BLD",
          coinMinimalDenom: "ubld",
          coinDecimals: 6
        }
      ],
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.03
      },
      features: []
    },
    {
      rpc: "https://chihuahua-rpc.polkachu.com",
      rest: "https://chihuahua-api.polkachu.com",
      chainId: "chihuahua-1",
      chainName: "Chihuahua",
      stakeCurrency: {
        coinDenom: "HUAHUA",
        coinMinimalDenom: "uhuahua",
        coinDecimals: 6
      },
      bip44: {
        coinType: 118
      },
      bech32Config: {
        bech32PrefixAccAddr: "chihuahua",
        bech32PrefixAccPub: "chihuahuapub",
        bech32PrefixValAddr: "chihuahuavaloper",
        bech32PrefixValPub: "chihuahuavaloperpub",
        bech32PrefixConsAddr: "chihuahuavalcons",
        bech32PrefixConsPub: "chihuahuavalconspub"
      },
      currencies: [
        {
          coinDenom: "HUAHUA",
          coinMinimalDenom: "uhuahua",
          coinDecimals: 6
        }
      ],
      feeCurrencies: [
        {
          coinDenom: "HUAHUA",
          coinMinimalDenom: "uhuahua",
          coinDecimals: 6
        }
      ],
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.03
      },
      features: []
    }
  ]
};

export const PollingConfig = {
  initialTxHashQueryDelay: 5000,
  scheduledTxHashQueryDelay: 5000,
  numberOfRetries: 20
};
