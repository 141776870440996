"use client";
import React from "react";
import { Modal } from "@persistenceone/pstake-ui-components";
import { Icon } from "@/components";
import CustomImage from "@/components/molecules/image";
import { WalletType } from "../store/slices/wallet-slice/types";
import { Cosmos } from "@/helpers/feature-flags";
import { useCosmosStore } from "@/containers/cosmos/store";
import { useShallow } from "zustand/react/shallow";
import { connectCosmosWallet } from "@/containers/cosmos/store/sagas/fetching-sagas";

interface WalletList {
  name: string;
  logoUrl: string;
  walletName: WalletType;
}

const walletList: WalletList[] = [
  {
    name: "Keplr Wallet",
    logoUrl: "/wallets/keplr_round.svg",
    walletName: "keplr"
  },
  {
    name: "Cosmostation",
    logoUrl: "/wallets/cosmos_station.svg",
    walletName: "cosmosStation"
  },
  {
    name: "Leap Wallet",
    logoUrl: "/wallets/leap.svg",
    walletName: "leap"
  }
];
const NetworkModal = () => {
  const { stakeInToken, walletModal, setWalletModal } = useCosmosStore(
    useShallow((state) => ({
      stakeInToken: state.stakeTransaction.stakeInToken,
      walletModal: state.walletData.walletModal,
      setWalletModal: state.walletDataActions.setWalletModal
    }))
  );

  const handleClose = () => {
    setWalletModal(false);
  };

  const connectHandler = async (wallet: WalletType) => {
    connectCosmosWallet({
      walletType: wallet,
      dstChainPrefix: stakeInToken.prefix,
      experimentalChains: {
        osmo: Cosmos.osmo,
        dydx: Cosmos.dydx,
        agoric: Cosmos.stkBLD
      }
    });
    setWalletModal(false);
  };

  return (
    <Modal
      show={walletModal}
      onClose={handleClose}
      className="wallets-modal"
      modalHeaderClassName={"!p-6"}
      modalDialogClassName={"!w-[376px]"}
      staticBackDrop={false}
      modalBodyClassName={"!p-0"}
      closeButton={
        <Icon viewClass="arrow-right fill-[#fff] !w-[12px]" iconName="close" />
      }
      header={
        <p className="text-white-100 text-xl font-medium">Connect wallet</p>
      }
    >
      <div className={`px-6 pb-6 md:p-4`}>
        <div className="">
          {walletList.map((item, index) => (
            <div
              key={index}
              className="px-4 py-3 flex items-center md:py-2 whitespace-nowrap mb-1 cursor-pointer"
              onClick={() => connectHandler(item.walletName)}
            >
              <CustomImage
                src={item.logoUrl}
                alt={"logo"}
                width={24}
                height={24}
              />
              <span className="ml-3 text-white-100 text-sm leading-normal md:text-xsm md:ml-2">
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default NetworkModal;
