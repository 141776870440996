import { StateCreator } from "zustand";
import { SliceActions, UnStakeTransactionState } from "./types";
import { stkAssetList } from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let initialUnStakeInAsset = stkAssetList[env].find(
  (stkAsset) => stkAsset.name === "stkATOM" && !stkAsset.ibcAsset
);

const initialState: UnStakeTransactionState = {
  unStakeTransaction: {
    amount: "",
    type: "normal",
    unStakeInToken: initialUnStakeInAsset,
    unStakeInTokenModal: false
  }
};

export type UnStakeTransactionSlice = UnStakeTransactionState & SliceActions;

export const createUnStakeTransactionSlice: StateCreator<
  UnStakeTransactionSlice
> = (set) => ({
  ...initialState,
  unStakeTransactionActions: {
    setUnStakeAmount: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          amount: val
        }
      })),
    setUnStakeOption: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          type: val
        }
      })),
    setUnStakeInToken: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          unStakeInToken: val
        }
      })),
    setUnStakeInTokenModal: (val) =>
      set((state) => ({
        unStakeTransaction: {
          ...state.unStakeTransaction,
          unStakeInTokenModal: val
        }
      }))
  }
});
