import React from "react";
import Image, { ImageProps } from "next/image";

const ipfs = process.env.NEXT_PUBLIC_IPFS_DEPLOYMENT;

export default function CustomImage({
  src,
  width,
  height,
  alt,
  className,
  ...rest
}: ImageProps) {
  // You can add any UI inside Loading, including a Skeleton.
  const isExternal = src.toString().includes("http");
  const path = `${ipfs == "true" ? "." : ""}/images`;
  return (
    <Image
      {...rest}
      src={isExternal ? src : `${path}${src}`}
      alt={alt}
      className={className}
      height={height}
      width={width}
    />
  );
}
