import { StateCreator } from "zustand";
import { ChainInfo } from "@keplr-wallet/types";
import { DstChainItem } from "@/containers/cosmos/helpers/types";
import React from "react";
import {
  MIN_DEPOSIT_COSMOS,
  RANGE_ALL_TIME,
  RANGE_MONTH,
  RANGE_WEEK,
  RANGE_YEAR
} from "@/constants/static";
import { dstChainsList } from "@/containers/cosmos/helpers/config";
import { ExchangeRateData } from "@/api/cosmos";

export type ExchangeRateType = "atom" | "stkAtom";

export interface InitialTvlApyFeeTypes {
  tvl?: number | React.ReactNode;
  total_apy?: number | React.ReactNode;
  fees?: number | React.ReactNode;
  borrow_apy?: number | React.ReactNode;
  lending_apy?: number | React.ReactNode;
  total_supply?: number | React.ReactNode;
}

export interface ShadeInitialInfo {
  atomStkAtom: InitialTvlApyFeeTypes;
  stkATOMSilk: InitialTvlApyFeeTypes;
}

export interface ExchangeRateList {
  cosmos: number;
  osmo: number;
  dydx: number;
  persistence: number;
  stars: number;
  agoric: number;
  chihuahua: number;
}

export interface ApyList {
  cosmos: number;
  osmo: number;
  dydx: number;
  persistence: number;
  stars: number;
  agoric: number;
  chihuahua: number;
}

export type TimeLine = "week" | "month" | "year" | "all";

export type GraphRange = {
  name: TimeLine;
  value: number;
};

export const graphRanges: GraphRange[] = [
  {
    name: "week",
    value: RANGE_WEEK
  },
  {
    name: "month",
    value: RANGE_MONTH
  },
  {
    name: "year",
    value: RANGE_YEAR
  },
  {
    name: "all",
    value: RANGE_ALL_TIME
  }
];

export interface FetchInitialDataParams {
  srcChainInfo: ChainInfo;
  dstChainItem?: DstChainItem;
}

export const initialTVLAPY: InitialTvlApyFeeTypes = {
  tvl: 0,
  total_apy: 0,
  fees: 0,
  total_supply: 0,
  borrow_apy: 0,
  lending_apy: 0
};

export type ActiveStakeTab = "Stake" | "Unstake";

export interface PstakeValidator {
  operatorAddress: string;
  status: string;
  weight: string;
  delegable: boolean;
}

export interface MaxRedeem {
  chainPrefix: string;
  amount: number;
}

export interface InitialDataState {
  initData: {
    apy: number;
    redeemFee: number;
    osmosisInfo: InitialTvlApyFeeTypes;
    osmosisPool2Info: InitialTvlApyFeeTypes;
    crescentInfo: InitialTvlApyFeeTypes;
    dexterInfo: InitialTvlApyFeeTypes;
    umeeInfo: InitialTvlApyFeeTypes;
    shadeInfo: ShadeInitialInfo;
    defiDataLoading: boolean;
    maxRedeem: MaxRedeem;
    minDeposit: number;
    dstChainItem: DstChainItem;
    exchangeRateList: ExchangeRateList;
    exchangeRateData: [number | string, number | string][];
    exchangeRateDataLoading: boolean;
    exchangeRateChartOpen: boolean;
    exchangeRateChartType: ExchangeRateType;
    activeStakeTab: ActiveStakeTab;
    hostChainValidatorsLength: number;
    validators: PstakeValidator[];
    initialStakeToken: any;
    apyList: ApyList;
    astroportInfo: InitialTvlApyFeeTypes;
    marsInfo: InitialTvlApyFeeTypes;
    quasarInfo: InitialTvlApyFeeTypes;
    nitronAtomInfo: InitialTvlApyFeeTypes;
    nitronDydxInfo: InitialTvlApyFeeTypes;
  };
}

export interface FetchInitialDataSaga {
  srcChainInfo: ChainInfo;
  dstChainItem: DstChainItem;
}

export interface FetchValidatorsSaga {
  rpc: string;
  chainID: string;
}

export interface SliceActions {
  setAPY: (val: number) => void;
  setRedeemFee: (val: number) => void;
  setOsmosisInfo: (val: any) => void;
  setOsmosisPool2Info: (val: any) => void;
  setMaxRedeem: (val: MaxRedeem) => void;
  setMinDeposit: (val: number) => void;
  setCrescentInfo: (val: any) => void;
  setDexterInfo: (val: any) => void;
  setShadeInfo: (val: any) => void;
  setAstroportInfo: (val: any) => void;
  setMarsInfo: (val: any) => void;
  setNitronAtomInfo: (val: any) => void;
  setNitronDydx: (val: any) => void;
  setQuasarInfo: (val: any) => void;
  setDstChainItem: (val: DstChainItem) => void;
  setUmeeInfo: (val: any) => void;
  setExchangeRateData: (val: ExchangeRateData) => void;
  setExchangeRateDataLoading: (val: boolean) => void;
  setExchangeRateChartStatus: (val: boolean) => void;
  setExchangeRateChartType: (val: ExchangeRateType) => void;
  setActiveStakeTab: (val: ActiveStakeTab) => void;
  setValidators: (val: PstakeValidator[]) => void;
  setDefiDataLoading: (val: boolean) => void;
  setInitialStakeToken: (val: any) => void;
  setExchangeRateList: (val: ExchangeRateList) => void;
  setApyList: (val: ApyList) => void;
  setHostChainValidatorsLength: (val: number) => void;
}

const initialState: InitialDataState = {
  initData: {
    apy: 0,
    redeemFee: 0,
    osmosisInfo: initialTVLAPY,
    osmosisPool2Info: initialTVLAPY,
    maxRedeem: {
      chainPrefix: "",
      amount: 0
    },
    minDeposit: MIN_DEPOSIT_COSMOS,
    crescentInfo: initialTVLAPY,
    umeeInfo: initialTVLAPY,
    marsInfo: initialTVLAPY,
    quasarInfo: initialTVLAPY,
    dexterInfo: initialTVLAPY,
    dstChainItem: dstChainsList[0],
    shadeInfo: {
      atomStkAtom: initialTVLAPY,
      stkATOMSilk: initialTVLAPY
    },
    defiDataLoading: true,
    exchangeRateData: [],
    exchangeRateDataLoading: false,
    exchangeRateChartOpen: false,
    exchangeRateChartType: "atom",
    validators: [],
    activeStakeTab: "Stake",
    initialStakeToken: null,
    exchangeRateList: {
      cosmos: 1,
      osmo: 1,
      dydx: 1,
      persistence: 1,
      stars: 1,
      agoric: 1,
      chihuahua: 1
    },
    apyList: {
      cosmos: 0,
      osmo: 0,
      dydx: 0,
      persistence: 0,
      stars: 0,
      agoric: 0,
      chihuahua: 0
    },
    hostChainValidatorsLength: 0,
    astroportInfo: initialTVLAPY,
    nitronAtomInfo: initialTVLAPY,
    nitronDydxInfo: initialTVLAPY
  }
};

export type InitialDataSlice = InitialDataState & SliceActions;

export const createInitialSlice: StateCreator<InitialDataSlice> = (set) => ({
  ...initialState,
  setAPY: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        apy: val
      }
    })),
  setRedeemFee: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        redeemFee: val
      }
    })),
  setMaxRedeem: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        maxRedeem: val
      }
    })),
  setMinDeposit: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        minDeposit: val
      }
    })),
  setDstChainItem: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        dstChainItem: val
      }
    })),
  setDefiDataLoading: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        defiDataLoading: val
      }
    })),
  setExchangeRateList: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        exchangeRateList: val
      }
    })),
  setExchangeRateData: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        exchangeRateData: val
      }
    })),
  setExchangeRateChartStatus: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        exchangeRateChartOpen: val
      }
    })),
  setExchangeRateDataLoading: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        exchangeRateDataLoading: val
      }
    })),
  setExchangeRateChartType: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        exchangeRateChartType: val
      }
    })),
  setValidators: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        validators: val
      }
    })),
  setActiveStakeTab: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        activeStakeTab: val
      }
    })),
  setHostChainValidatorsLength: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        hostChainValidatorsLength: val
      }
    })),
  setApyList: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        apyList: val
      }
    })),
  setInitialStakeToken: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        initialStakeToken: val
      }
    })),
  setOsmosisInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        osmosisInfo: val
      }
    })),
  setOsmosisPool2Info: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        osmosisPool2Info: val
      }
    })),
  setDexterInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        dexterInfo: val
      }
    })),
  setUmeeInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        umeeInfo: val
      }
    })),
  setShadeInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        shadeInfo: val
      }
    })),
  setAstroportInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        astroportInfo: val
      }
    })),
  setCrescentInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        crescentInfo: val
      }
    })),
  setMarsInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        marsInfo: val
      }
    })),
  setQuasarInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        quasarInfo: val
      }
    })),
  setNitronAtomInfo: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        nitronAtomInfo: val
      }
    })),
  setNitronDydx: (val) =>
    set((state) => ({
      initData: {
        ...state.initData,
        nitronDydxInfo: val
      }
    }))
});
