"use client";
import React, { createContext, FC, useContext, useEffect } from "react";
import { fetchTokenPrices, TokenPrices } from "@/utils";
import { InitialDataProviderProps, InitialDataState } from "./types";
import { getExchangeRateList } from "@/api/cosmos";
import { useAppStore } from "@/store/store";
import { useShallow } from "zustand/react/shallow";
import { useCosmosStore } from "@/containers/cosmos/store";

const InitialDataContext = createContext<InitialDataState>({});

export const useInitialDataContext = (): InitialDataState => {
  return useContext(InitialDataContext);
};

export const InitialDataProvider: FC<InitialDataProviderProps> = ({
  children
}) => {
  const { setExchangeRateList } = useCosmosStore(
    useShallow((state) => ({
      setExchangeRateList: state.setExchangeRateList
    }))
  );
  const setTokenPrices = useAppStore(({ setTokenPrices }) => setTokenPrices);
  // fetch calls only on initial render
  useEffect(() => {
    const fetchApy = async () => {
      console.log("fetchApym");
      const exchangeRateList = await getExchangeRateList();
      const tokenPrices = await fetchTokenPrices();
      console.log(tokenPrices, "tokenPrices-1", exchangeRateList);
      const allTokens: TokenPrices = { ...tokenPrices };
      allTokens.stkATOM = (1 / exchangeRateList.cosmos) * allTokens.ATOM;
      allTokens.stkOSMO = (1 / exchangeRateList.osmo) * allTokens.OSMO;
      allTokens.stkXPRT = (1 / exchangeRateList.persistence) * allTokens.XPRT;
      allTokens.stkDV4TNT = (1 / exchangeRateList.dydx) * allTokens.DV4TNT;
      allTokens.stkDYDX = (1 / exchangeRateList.dydx) * allTokens.DYDX;
      allTokens.stkSTARS = (1 / exchangeRateList.stars) * allTokens.STARS;
      allTokens.stkBLD = (1 / exchangeRateList.agoric) * allTokens.BLD;
      allTokens.stkHUAHUA = (1 / exchangeRateList.chihuahua) * allTokens.HUAHUA;
      console.log(exchangeRateList, "exchangeRateList", allTokens);
      setExchangeRateList(exchangeRateList);
      setTokenPrices(allTokens);
    };
    fetchApy();
  }, []);

  return (
    <InitialDataContext.Provider value={null}>
      {children}
    </InitialDataContext.Provider>
  );
};

export default InitialDataProvider;
