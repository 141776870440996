import Web3Modal from "web3modal";
import { CoinbaseWalletSDK } from "@coinbase/wallet-sdk";
import { Scope } from "@sentry/react";
import { genericErrorHandler } from "../utils";
import { IProviderOptions } from "web3modal/dist/helpers/types";
import Web3 from "web3";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { COIN_BASE } from "@/constants/static";

const networkID = process.env.NEXT_PUBLIC_BNB_NETWORK_ID;
const bnbRpc = process.env.NEXT_PUBLIC_BNB_CHAIN_RPC_URL;
const projectID: any = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

const coinBaseProviderOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "pSTAKE",
      rpc: bnbRpc,
      chainId: networkID
    }
  }
};

const rpcType: { [key: string]: string | undefined } = {};
rpcType[networkID!] = bnbRpc;

export const getWeb3ModalProvider = async (wallet: string) => {
  let web3Provider = null;
  const providerOptions: IProviderOptions = coinBaseProviderOptions;
  const network =
    process.env.NEXT_PUBLIC_ENVIRONMENT === "Testnet"
      ? "binance-testnet"
      : "binance";
  try {
    if (wallet === COIN_BASE) {
      const web3Modal = new Web3Modal({
        cacheProvider: false, // optional
        network: network,
        providerOptions // required
      });
      web3Provider = await web3Modal.connectTo("coinbasewallet");
    } else {
      const wcProvider = await EthereumProvider.init({
        projectId: projectID,
        chains: [Number(networkID)],
        showQrModal: true, // requires @walletconnect/modal
        qrModalOptions: {
          enableExplorer: false,
          themeMode: "dark"
        }
      });
      await wcProvider.enable();
      web3Provider = new Web3(wcProvider).provider;
    }
    web3Provider.on("disconnect", () => {
      window.location.reload();
    });
  } catch (error) {
    const customScope = new Scope();
    customScope.setLevel("fatal");
    customScope.setTags({
      "Error while connecting Web3Modal": "Web3Modal"
    });
    genericErrorHandler(error, customScope);
  }
  return web3Provider;
};
