export const Loader = () => {
  return (
    <div className="h-[calc(100vh-100px)] relative">
      <div className="absolute top-[35%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span
          className={`spinner w-8 h-8 light icon-toast inline-block align-text-bottom rounded-full`}
        />
      </div>
    </div>
  );
};
