import { ChainInfo } from "@keplr-wallet/types";
import { TransactionType } from "@/store/slices/transaction-slice";
import {
  fetchCrescentPoolInfo,
  fetchDexterPoolInfo,
  fetchOsmosisPoolInfo,
  fetchShadeInfo,
  fetchUmeeInfo,
  getChainStatus,
  getHostChainInfo,
  getAPY,
  fetchOsmosisPool2Info,
  fetchAstroPortInfo,
  fetchMarsInfo,
  fetchQuasarnfo,
  getXprtValidators,
  fetchNitronAtomInfo,
  fetchNitronDydxInfo
} from "@/api/cosmos";
import { ExternalChains } from "@/containers/cosmos/helpers/config";
import { AccountData } from "@cosmjs/launchpad/build/signer";
import { externalChains } from "@/helpers/utils";
import { baseAssetList, fetchBalanceWithDenom, utilityAsset } from "@/utils";
import {
  decimalize,
  pollAccountBalance
} from "@/containers/cosmos/helpers/utils";
import { useAppStore } from "@/store/store";
import { useCosmosStore } from "@/containers/cosmos/store";
import {
  FetchInitialDataParams,
  InitialTvlApyFeeTypes,
  ShadeInitialInfo
} from "../slices/initial-data-slice";
import {
  fetchTokenizeShares,
  fetchChainTVUList,
  fetchDelegations,
  fetchDepositBalance,
  fetchPendingClaims
} from "@/containers/cosmos/store/sagas/fetching-sagas";
import {
  SwitchNetworkActionsParams,
  FailedTransactionPostActionsParams
} from "../slices/wallet-slice/types";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export const fetchInit = async (
  payload: FetchInitialDataParams
): Promise<any> => {
  const { srcChainInfo }: any = payload;
  const srcChainStatus = await getChainStatus(srcChainInfo!.rpc);
  useCosmosStore.getState().liveDataActions.setSrcChainStatus(srcChainStatus);
  const apyList = await getAPY();
  useCosmosStore.getState().setApyList(apyList);
  fetchChainTVUList({
    srcChainInfo: srcChainInfo!
  });
};

export const fetchActionsBasedOnDstChain = async (
  payload: FetchInitialDataParams
): Promise<any> => {
  const { srcChainInfo, dstChainItem }: any = payload;
  let dstChain = externalChains.find(
    (chain: ChainInfo) =>
      chain.bech32Config.bech32PrefixAccAddr === dstChainItem.prefix
  );
  fetchDepositBalance({
    srcChainInfo: srcChainInfo!,
    dstChainName: dstChainItem.chainName,
    dstChainInfo: dstChainItem
  });
  //fetch fee for chains other than native chain
  if (dstChain.bech32Config.bech32PrefixAccAddr !== utilityAsset.prefix) {
    const [hostChainInfo] = await Promise.all([
      getHostChainInfo(srcChainInfo.rpc, dstChain.chainId)
    ]);
    console.log(hostChainInfo, srcChainInfo.rpc, dstChain.chainId, "test-fee");
    useCosmosStore.getState().setRedeemFee(hostChainInfo.fee);
    useCosmosStore
      .getState()
      .setHostChainValidatorsLength(hostChainInfo.validatorsLength);
  } else {
    const hostChainInfo = await getXprtValidators(srcChainInfo.rpc);
    useCosmosStore.getState().setRedeemFee(hostChainInfo.fee);
    useCosmosStore
      .getState()
      .setHostChainValidatorsLength(hostChainInfo.validatorsLength);
  }
};

export const switchNetworkActions = async (
  payload: SwitchNetworkActionsParams
) => {
  const { walletInfo, dstChainPrefix } = payload;
  console.log(walletInfo, dstChainPrefix, "switchNetworkActions");
  let dstChainInfo = ExternalChains[env].find(
    (chain: ChainInfo) =>
      chain.bech32Config.bech32PrefixAccAddr === dstChainPrefix
  );
  const dstWalletResponse = walletInfo.externalChainSigners.find(
    (item) => item.prefix === dstChainPrefix
  )!.signer;
  let dstAccountResponse: readonly AccountData[] =
    await dstWalletResponse!.getAccounts();
  const storageWalletInfo = {
    walletName: walletInfo.walletType,
    dstChainPrefix
  };
  window.localStorage.setItem(
    "cosmos-walletInfo",
    JSON.stringify(storageWalletInfo)
  );
  useCosmosStore.getState().walletDataActions.setWalletInfo({
    isWalletConnected: true,
    walletType: walletInfo.walletType,
    externalChainSigners: walletInfo.externalChainSigners,
    srcChainInfo: walletInfo.srcChainInfo,
    srcSigner: walletInfo.srcSigner,
    srcAccountData: walletInfo.srcAccountData,
    dstChainInfo: dstChainInfo,
    dstAccountData: dstAccountResponse[0],
    dstSigner: dstWalletResponse
  });
};

export const failedTransactionPostActions = async (
  payload: FailedTransactionPostActionsParams
) => {
  const { message } = payload;
  console.log(message, "failedTransactionPostActions");
  useAppStore.getState().transactionActions.setTxnFailed(true);
  useAppStore.getState().transactionActions.resetTransactionProgress();
  useAppStore.getState().transactionActions.setTransactionStatus("failed");
  useAppStore.getState().transactionActions.setTxnFailedResponse(message);
};

export const postTransactionActions = async (
  type: TransactionType,
  srcAddress: string,
  dstAddress: string,
  srcChainInfo: ChainInfo,
  dstChainInfo: ChainInfo,
  status: "success" | "failed"
) => {
  const walletInfo = useCosmosStore.getState().walletData.walletInfo;
  const balances = useAppStore.getState().allBalance.cosmos;
  const init = useCosmosStore.getState().initData;
  const stakeToken = useCosmosStore.getState().stakeTransaction.stakeInToken;
  const unStakeInToken =
    useCosmosStore.getState().unStakeTransaction.unStakeInToken;
  console.log(dstChainInfo, srcChainInfo, type, "postTransactionActions");
  if (type === "delegationStaking" || type === "tokenizedSharesStaking") {
    const cosmosChainAccount = walletInfo.externalChainSigners.find(
      (item) => item.prefix === "cosmos"
    );
    const cosmosChainInfo = externalChains.find(
      (item) => item.bech32Config.bech32PrefixAccAddr === "cosmos"
    );
    if (type === "delegationStaking") {
      fetchDelegations({
        address: cosmosChainAccount.address!,
        chainInfo: cosmosChainInfo,
        validators: init.validators
      });
    }
    if (status === "success") {
      const initStkBalance = balances.find(
        (item) =>
          item.chain === "persistence" && item.name === unStakeInToken.name
      );
      const stkBalance: string = await pollAccountBalance(
        srcAddress,
        "stk/" + dstChainInfo.currencies[0].coinMinimalDenom,
        srcChainInfo.rpc,
        initStkBalance.amount.toString()
      );
      const balanceCopy = [...balances];
      const updatedBalances = balanceCopy.map((item) => {
        if (item.chain === "persistence" && item.name === unStakeInToken.name) {
          return {
            ...item,
            amount: Number(decimalize(stkBalance, item.decimals))
          };
        } else {
          return item;
        }
      });
      useAppStore.getState().setCosmosBalances(updatedBalances);
    }
  }
  if (type === "redeemSharesStaking") {
    fetchDelegations({
      address: dstAddress,
      chainInfo: dstChainInfo,
      validators: init.validators
    });
  }
  if ((type === "stake" || type === "ibcStaking") && status === "success") {
    const ibcAsset = baseAssetList[env].find(
      (item) => item.chain === "persistence" && item.name === stakeToken.name
    );

    let srcBlcResponse: string = "0";
    if (type === "ibcStaking") {
      const initSrcBlc = balances.find(
        (item) => item.chain !== "persistence" && item.name === stakeToken.name
      );
      console.log(initSrcBlc, "initSrcBlc");
      srcBlcResponse = await pollAccountBalance(
        dstAddress,
        dstChainInfo.stakeCurrency.coinMinimalDenom,
        dstChainInfo.rpc,
        initSrcBlc.amount.toString()
      );
    }

    const initStkBalance = balances.find(
      (item) =>
        item.chain === "persistence" && item.name === unStakeInToken.name
    );
    const stkBalance: string = await pollAccountBalance(
      srcAddress,
      "stk/" + dstChainInfo.currencies[0].coinMinimalDenom,
      srcChainInfo.rpc,
      initStkBalance.amount.toString()
    );

    const pBalance: any = await fetchBalanceWithDenom(
      srcAddress,
      srcChainInfo.rpc,
      ibcAsset.identifier
    );

    const balanceCopy = [...balances];
    console.log(pBalance, ibcAsset, srcBlcResponse, "balanceResponse-teds");
    const updatedBalances = balanceCopy.map((item) => {
      if (
        item.chain !== "persistence" &&
        item.name === stakeToken.name &&
        type === "ibcStaking"
      ) {
        return {
          ...item,
          amount: Number(decimalize(srcBlcResponse, item.decimals))
        };
      } else if (
        item.chain === "persistence" &&
        item.name === stakeToken.name
      ) {
        return {
          ...item,
          amount: Number(decimalize(pBalance.amount, item.decimals))
        };
      } else if (
        item.chain === "persistence" &&
        item.name === unStakeInToken.name
      ) {
        return {
          ...item,
          amount: Number(decimalize(stkBalance, item.decimals))
        };
      } else {
        return item;
      }
    });
    useAppStore.getState().setCosmosBalances(updatedBalances);
  }
  if ((type === "deposit" || type === "withdraw") && status === "success") {
    const transferToken =
      useCosmosStore.getState().tokenTransferTransaction.transferToken;
    const ibcAsset = baseAssetList[env].find(
      (item) => item.chain === "persistence" && item.name === transferToken
    );
    console.log(ibcAsset, "ibcAsset-1");
    const initSrcBlc = balances.find(
      (item) => item.chain === "persistence" && item.name === transferToken
    );
    const pBalance: string = await pollAccountBalance(
      type === "deposit" ? dstAddress : srcAddress,
      ibcAsset.identifier,
      type === "deposit" ? dstChainInfo.rpc : srcChainInfo.rpc,
      initSrcBlc.amount.toString()
    );

    const initDstBlc = balances.find(
      (item) => item.chain !== "persistence" && item.name === transferToken
    );
    const cosmosBalance: string = await pollAccountBalance(
      type === "deposit" ? srcAddress : dstAddress,
      type === "deposit"
        ? srcChainInfo.stakeCurrency.coinMinimalDenom
        : dstChainInfo.stakeCurrency.coinMinimalDenom,
      type === "deposit" ? srcChainInfo.rpc : dstChainInfo.rpc,
      initDstBlc.amount.toString()
    );
    console.log(cosmosBalance, "cosmosBalance01-1");
    console.log(pBalance, "pBalance-1");
    const balanceCopy = [...balances];
    const updatedBalances = balanceCopy.map((item) => {
      if (item.chain === "persistence" && item.name === transferToken) {
        return { ...item, amount: Number(decimalize(pBalance, item.decimals)) };
      } else if (item.chain !== "persistence" && item.name === transferToken) {
        return {
          ...item,
          amount: Number(decimalize(cosmosBalance, item.decimals))
        };
      } else {
        return item;
      }
    });
    console.log(updatedBalances, "updatedBalances-1");
    useAppStore.getState().setCosmosBalances(updatedBalances);
  }
  if (type === "unstake" && status === "success") {
    const unStakeType = useCosmosStore.getState().unStakeTransaction.type;
    let cosmosBalance: any = "";
    if (unStakeType === "instant") {
      const initSrcBlc = balances.find(
        (item) =>
          item.chain !== "persistence" && item.name === unStakeInToken.pair
      );
      console.log(initSrcBlc, "initSrcBlc");
      cosmosBalance = await pollAccountBalance(
        dstAddress,
        dstChainInfo.stakeCurrency.coinMinimalDenom,
        dstChainInfo.rpc,
        initSrcBlc.amount.toString()
      );
    }
    const stkBalance: any = await fetchBalanceWithDenom(
      srcAddress,
      srcChainInfo.rpc,
      "stk/" + dstChainInfo.currencies[0].coinMinimalDenom
    );
    console.log(stkBalance, "stkBalance-1");
    console.log(cosmosBalance, "pBalance-1");
    const balanceCopy = [...balances];
    const updatedBalances = balanceCopy.map((item) => {
      if (item.chain === "persistence" && item.name === unStakeInToken.name) {
        return {
          ...item,
          amount: Number(decimalize(stkBalance.amount, item.decimals))
        };
      } else if (
        item.chain !== "persistence" &&
        item.name === unStakeInToken.pair &&
        unStakeType === "instant"
      ) {
        return {
          ...item,
          amount: Number(decimalize(cosmosBalance, item.decimals))
        };
      } else {
        return item;
      }
    });
    console.log(updatedBalances, "updatedBalances-1");
    useAppStore.getState().setCosmosBalances(updatedBalances);
    if (unStakeType === "normal") {
      fetchPendingClaims({
        address: walletInfo.srcAccountData.address,
        srcChainInfo: walletInfo.srcChainInfo!
      });
    }
  }
  fetchTokenizeShares({
    srcAddress: walletInfo.srcAccountData.address,
    dstAddress: walletInfo.dstAccountData.address,
    srcChainInfo: walletInfo.srcChainInfo!,
    dstChainInfo: walletInfo.dstChainInfo!,
    validators: init.validators
  });
};

export const fetchDefiInfo = async () => {
  console.log("called-defi");
  const osmosisInfo: InitialTvlApyFeeTypes = await fetchOsmosisPoolInfo();
  const osmosisInfo2: InitialTvlApyFeeTypes = await fetchOsmosisPool2Info();
  const crescentInfo: InitialTvlApyFeeTypes = await fetchCrescentPoolInfo();
  const dexterInfo: InitialTvlApyFeeTypes | InitialTvlApyFeeTypes[] =
    await fetchDexterPoolInfo();
  const umeeInfo: InitialTvlApyFeeTypes = await fetchUmeeInfo();
  const shadeInfo: ShadeInitialInfo = await fetchShadeInfo();
  const astroportInfo: InitialTvlApyFeeTypes = await fetchAstroPortInfo();
  const marsInfo: InitialTvlApyFeeTypes = await fetchMarsInfo();
  const quasarInfo: InitialTvlApyFeeTypes = await fetchQuasarnfo();
  const nitronAtomInfo: InitialTvlApyFeeTypes = await fetchNitronAtomInfo();
  const nitronDydxInfo: InitialTvlApyFeeTypes = await fetchNitronDydxInfo();
  useCosmosStore.getState().setDexterInfo(dexterInfo);
  useCosmosStore.getState().setAstroportInfo(astroportInfo);
  useCosmosStore.getState().setCrescentInfo(crescentInfo);
  useCosmosStore.getState().setMarsInfo(marsInfo);
  useCosmosStore.getState().setOsmosisInfo(osmosisInfo);
  useCosmosStore.getState().setOsmosisPool2Info(osmosisInfo2);
  useCosmosStore.getState().setUmeeInfo(umeeInfo);
  useCosmosStore.getState().setShadeInfo(shadeInfo);
  useCosmosStore.getState().setQuasarInfo(quasarInfo);
  useCosmosStore.getState().setNitronAtomInfo(nitronAtomInfo);
  useCosmosStore.getState().setNitronDydx(nitronDydxInfo);
  useCosmosStore.getState().setDefiDataLoading(false);
};
