import { Button } from "@persistenceone/pstake-ui-components";
import React from "react";
import { contracts } from "../../helpers/config";
import { useEthereumStore } from "../../store/store";
import CustomImage from "@/components/molecules/image";
import { registerToken } from "../../helpers/wallets";
const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

const AddToken = () => {
  const walletInfo = useEthereumStore((state) => state.wallet);
  const network = useEthereumStore((state) => state.network);

  const dropDownHandler = async () => {
    let contractAddress: string = contracts[env][network.name]["stkETH"];
    await registerToken(walletInfo, contractAddress);
  };

  return (
    <>
      <Button
        size="auto"
        type="custom"
        onClick={dropDownHandler}
        content={
          <div className="flex items-center">
            <span className="text-sm text-white-200 font-medium leading-normal md:text-xsm md:ml-2">
              Add stkETH
            </span>
            <CustomImage
              width={20}
              height={20}
              className="ml-2 md:m-0"
              src={`/tokens/stk_eth.svg`}
              alt="stkATOM logo"
            />
          </div>
        }
        className="bg-[#181818] text-white-100 !py-2.5 lg:!hidden !text-sm mr-3 "
      />
    </>
  );
};

export default AddToken;
