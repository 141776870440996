import { Er20ChainIds } from "./types";

export const er20ChainIds: Er20ChainIds = {
  Testnet: [
    {
      name: "ethereum",
      networkID: 5,
      networkIdHex: "0x5",
    },
    {
      name: "optimism",
      networkID: 420,
      networkIdHex: "0x1a4",
    },
    {
      name: "arbitrum",
      networkID: 421613,
      networkIdHex: "0x66eed",
    },
    {
      name: "BNB Smart Chain",
      networkID: 97,
      networkIdHex: "0x61",
    },
  ],
  Mainnet: [
    {
      name: "ethereum",
      networkID: 1,
      networkIdHex: "0x1",
    },
    {
      name: "optimism",
      networkID: 10,
      networkIdHex: "0xA",
    },
    {
      name: "arbitrum",
      networkID: 42161,
      networkIdHex: "0xa4b1",
    },
    {
      name: "BNB Smart Chain",
      networkID: 56,
      networkIdHex: "0x38",
    },
  ],
};
