const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;
export const BUG_REPORT_URL =
  "https://docs.google.com/forms/d/1SpwpntQ5jVAb5BwtG7-Ul58z0vE7RocCSrN-s6bCl8M/viewform?edit_requested=true";
export const DISCORD_URL = "https://discord.gg/kKvSz6F8AJ";
// Key works
export const EVM: string = "evm";
export const COSMOS: string = "cosmos";
export const TEST_NET = "Testnet";
export const DEV_NET = "Devnet";
export const BNB = "BNB";
export const STK_BNB = "stkBNB";
export const BNB_TOKEN_DECIMALS = 18;
export const BSC = "BNB";
export const TOKEN_BALANCE = "tokenBalance";
export const BNB_BALANCE = "bnbBalance";
export const META_MASK = "Metamask";
export const OKX = "OKX";
export const COIN_98 = "Coin98";
export const SAFE_PAL = "SafePal";
export const BINANCE = "Binance";
export const TRUST_WALLET = "Trust wallet";
export const WALLET_CONNECT = "Wallet connect";
export const COIN_BASE = "Coin base";
export const LEDGER_LIVE = "Ledger Live";
export const TOKEN_POCKET = "Token Pocket";
export const DEVELOPMENT = "Testnet";
export const TVL = "tvl";
export const TOTAL_APY = "total_apy";
export const LATEST = "latest";
export const ETH_REQUEST_ACCOUNTS = "eth_requestAccounts";
export const ETH = "ETH";
export const STK_ETH = "stkETH";
export const ETHEREUM = "Ethereum";
export const OPTIMISM = "Optimism";
export const KEPLR = "keplr";
export const LEDGER = "ledger";
export const INSTANT = "instant";
export const STAKE = "stake";
export const DELEGATION_STAKING = "delegationStaking";
export const SWAP = "swap";
export const UN_STAKE = "unstake";
export const CLAIM = "claim";
export const INSTANT_CLAIM = "instantClaim";
export const FATAL = "fatal";
export const DEPOSIT = "deposit";
export const FEES = "fees";
export const POOL_LIQUIDITY = "pool_liquidity";
export const WITHDRAW = "withdraw";
export const GA_TRACKING_ID = "G-MC3HWEVV3H";

export const DISPLAY_DECIMALS = 0.01;
export const MIN_DEPOSIT_COSMOS = 0.01;
export const MIN_STAKE_FEE_COSMOS = 0.01;
export const MIN_REDEEM_COSMOS = 0.01;
export const IBC_DENOM =
  "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2";
export const CRESCENT_STK_ATOM_DENOM =
  "ibc/E32F6485CDAE995FC492010770936119D0BF90F5226C9AED727988DF03B5F569";
export const APR_DEFAULT = 22.86;
export const PERSISTENCE_GAS_PRICE = "0uxprt";
export const COSMOS_GAS_PRICE = "0.002uatom";
export const OSMO_GAS_PRICE = "0.002uosmo";
export const DYDX_GAS_PRICE =
  env === "Testnet" ? "200000000000adv4tnt" : "200000000000adydx";
export const ATOM_PRICE_URL = "https://api.coingecko.com/api/v3/coins/cosmos";
export const OSMO_PRICE_URL = "https://api.coingecko.com/api/v3/coins/osmosis";
export const OSMO_VALIDATORS_URL = "https://pstake.finance/osmo/validators";
export const DYDX_VALIDATORS_URL = "https://pstake.finance/dydx/validators";
export const ATOM_VALIDATORS_URL = "https://pstake.finance/atom/validators";

// TODO(fixme): This should always be fetch from contract. Shouldn't be a constant here.
export const COOLDOWN_PERIOD = 15;

export const SECONDS_IN_A_DAY = 86400;

/**
 * Currently blocks being confirmed in a day is close to 40K
 * So for 15 days we have 15 * 40K close to 600000 blocks
 * So block range > 600000 * 3 should be large enough to accommodate the last 2 rewards event
 * **/
export const BLOCKS_TO_QUERY_FOR_APY_CALCULATION = 2000000;

export const SHORT_INTERVAL = 10000;

export const MID_INTERVAL = 60000;

export const LONG_INTERVAL = 3600000;

export const ANALYTICS_MEASURE_ID = "G-53Y9MZ1BYN";
/** Block Number of creation of STAKEPOOL Contract (Testnet) **/

export const DEFAULT_DECIMAL_PLACES = 6;

export const thenSwapLink = "https://thena.fi/swap";
export const wombatSwapLink =
  "https://app.wombat.exchange/swap?from=stkBNB&to=WBNB";

export const pancakeV3Links = {
  swapUrl: `https://pancakeswap.finance/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16&chain=bsc`,
  poolUrl: `https://pancakeswap.finance/add/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c/0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16/500?chain=bsc`
};

interface shieldContractsType {
  [index: string]: any;
}

export const shieldContractAddress =
  "0xb221dd27b05ff07704426d9bbecc4b6b9ab931c4";
// ethereum constants

export const ETH_TOKEN_DECIMALS = 18;

// Error Constants
export const WALLET_ERROR = "Connection failed";
export const METAMASK_ERROR = "Metamask error";
export const UNSUPPORTED_NETWORK = "Unsupported network";
export const ERROR_WHILE_CLAIMING = "ERROR_WHILE_CLAIMING";
export const ERROR_WHILE_STAKING = "ERROR_WHILE_STAKING";
export const ERROR_WHILE_DEPOSITING = "ERROR_WHILE_DEPOSITING";
export const ERROR_WHILE_TRANSFERRING = "ERROR_WHILE_TRANSFERRING";
export const ERROR_WHILE_DELEGATION_STAKE = "ERROR_WHILE_DELEGATION_STAKE";
export const ERROR_WHILE_TOKENIZED_DELEGATION_STAKE =
  "ERROR_WHILE_TOKENIZED_DELEGATION_STAKE";
export const ERROR_WHILE_UNSTAKING = "ERROR_WHILE_UNSTAKING";
export const EMPTY_POOL_ERROR =
  "Query failed with (6): rpc error: code = Unknown desc = failed to execute message; message index: 0: Delegated amount: 0uatom is less than total undelegations for the epoch:";

// Graph range values
export const RANGE_WEEK = 7;
export const RANGE_MONTH = 30;
export const RANGE_YEAR = 365;
export const RANGE_ALL_TIME = -1;

export const ARBITRUM_TXN_TIME = 15;
export const OPTIMISM_TXN_TIME = 2;
