import { StateCreator } from "zustand";
import { SliceActions, TokenTransferTransactionState } from "./types";
import { stkAssetList } from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let initialUnStakeInAsset = stkAssetList[env].find(
  (stkAsset) => stkAsset.name === "stkATOM" && !stkAsset.ibcAsset
);

const initialState: TokenTransferTransactionState = {
  tokenTransferTransaction: {
    transferToken: ""
  }
};

export type TokenTransferTransactionSlice = TokenTransferTransactionState &
  SliceActions;

export const createTokenTransferTransactionSlice: StateCreator<
  TokenTransferTransactionSlice
> = (set) => ({
  ...initialState,
  tokenTransferTransactionActions: {
    setTransferToken: (val) =>
      set((state) => ({
        tokenTransferTransaction: {
          ...state.tokenTransferTransaction,
          transferToken: val
        }
      }))
  }
});
