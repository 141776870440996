import {
  stkAssetList as stkAssetListData,
  baseAssetList as baseAssetListData,
  er20ChainIds as er20ChainIdsData,
  baseAssetList2 as baseAssetList2Data,
  networksList,
  Network
} from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

import {
  ChainExternalInfo,
  ExternalChains as ExternalChainsData,
  IBCChainInfos as IBCChainInfosData
} from "../containers/cosmos/helpers/config";
import {
  chainInfo,
  bep20TokenAddresses as bep20TokenAddressesData
} from "../containers/bnb/helpers/config";
import {
  chains,
  erc20TokenAddresses as erc20TokenAddressesData
} from "../containers/ethereum/helpers/config";
import { EMPTY_POOL_ERROR } from "@/constants/static";
import { Cosmos } from "@/helpers/feature-flags";
import { ChainInfo } from "@keplr-wallet/types";

export const stkAssetList = stkAssetListData[env];
export const baseAssetList = baseAssetListData[env];
export const baseAssetList2 = baseAssetList2Data[env];
export const externalChains = ExternalChainsData[env];
export const ibCChainInfos = IBCChainInfosData[env];
export const bnbChainInfo = chainInfo[env];
export const ethChainsInfo = chains[env];
export const er20ChainIds = er20ChainIdsData[env];
export const erc20TokenAddresses = erc20TokenAddressesData[env];
export const bep20TokenAddresses = bep20TokenAddressesData[env];

export const persistenceChain = externalChains.find(
  (chain: ChainInfo) => chain.bech32Config.bech32PrefixAccAddr === "persistence"
);

// TODO: update after stkxpr launch
export const cosmosSupportedChains = externalChains.filter((chain: ChainInfo) =>
  chain.bech32Config.bech32PrefixAccAddr === "persistence"
    ? Cosmos.stkXPRT
      ? chain
      : null
    : chain.bech32Config.bech32PrefixAccAddr === "agoric"
    ? Cosmos.stkBLD
      ? chain
      : null
    : chain
);

// TODO: update after stkBLD launch
export const cosmosBaseAssetList = baseAssetList.filter((asset) => {
  return asset.networkLabel === "Cosmos"
    ? (asset.name === "XPRT" ? (Cosmos.stkXPRT ? asset : null) : asset) &&
        (asset.name === "BLD" ? (Cosmos.stkBLD ? asset : null) : asset)
    : null; // condition to exclude certain token
});

// TODO: update after stkBLD launch
export const cosmosStkAssetList = stkAssetList.filter((asset) => {
  return (
    asset.networkLabel === "Cosmos" &&
    (asset.name === "stkXPRT" ? (Cosmos.stkXPRT ? asset : null) : asset) &&
    (asset.name === "stkBLD" ? (Cosmos.stkBLD ? asset : null) : asset) // condition to exclude certain token
  );
});

export const bscBaseAssetList = baseAssetList.filter(
  (asset) => asset.networkLabel === "BNB Smart Chain"
);

export const bscStkAssetList = stkAssetList.filter(
  (asset) => asset.networkLabel === "BNB Smart Chain"
);

export const erc20BaseAssetList = baseAssetList.filter(
  (asset) =>
    asset.networkLabel === "Ethereum" ||
    asset.networkLabel === "Arbitrum" ||
    asset.networkLabel === "Optimism"
);

export const erc20StkAssetList = stkAssetList.filter(
  (asset) =>
    asset.networkLabel === "Ethereum" ||
    asset.networkLabel === "Arbitrum" ||
    asset.networkLabel === "Optimism"
);

export const getDestinationNetworkList = (unsupportedList: any[]) => {
  console.log(unsupportedList, "unsupportedList");
  return stkAssetListData[env].filter((asset) => {
    const item = unsupportedList.find(
      (item) =>
        item.network === asset.network.toLowerCase() &&
        item.token === asset.pair
    );
    return asset.ibcAsset !== true && (item ? item.enable && asset : asset);
  });
};

export interface ListParams {
  network: Network;
  enable: boolean;
}

export const getNetworkList = (unsupportedList: ListParams[]) => {
  console.log(unsupportedList, "unsupportedList");
  return networksList.filter((asset) => {
    const item = unsupportedList.find(
      (item) => item.network.toLowerCase() === asset.network.toLowerCase()
    );
    return item ? item.enable && asset : asset;
  });
};

export const getBaseTokenList = (unsupportedList: any[]) => {
  return cosmosBaseAssetList.filter((asset) => {
    const item = unsupportedList.find(
      (item) =>
        item.network === asset.network.toLowerCase() &&
        item.token === asset.name
    );
    return item ? item.enable && asset : asset;
  });
};

export const getStkTokenList = (unsupportedList: any[]) => {
  return cosmosStkAssetList.filter((asset) => {
    const item = unsupportedList.find(
      (item) =>
        item.network === asset.network.toLowerCase() &&
        item.token === asset.pair
    );
    return asset.ibcAsset !== true && (item ? item.enable && asset : asset);
  });
};

export const structureErrorMessage = (message: string) => {
  console.log(message, "message-ss");
  if (message.includes("insufficient validator bond shares")) {
    return "Transaction could not be completed. Validator does not have sufficient self-bond to liquid stake.";
  } else if (message.includes("out of gas in location")) {
    return message;
  } else if (message.includes("signature verification failed")) {
    return message;
  } else if (message.includes("rejected")) {
    return "User rejected transaction";
  } else if (message.includes("insufficient funds")) {
    return "Failed due to insufficient funds";
  } else if (message.includes("is not in the validator set")) {
    return "Validator not in active list";
  }
  return "Transaction could not be completed";
};

export const formBlockExplorerLink = (
  txnHash: string,
  activeNetwork: string,
  chain: string
) => {
  if (activeNetwork === "cosmos") {
    const explorerLink = ChainExternalInfo[env][chain].explorer;
    return chain !== "agoric"
      ? `${explorerLink}/tx/${txnHash}`
      : `${explorerLink}/transactions/${txnHash}`;
  } else {
    return `${bnbChainInfo.explorerUrl}/tx/${txnHash}`;
  }
};

export const getActiveSideBarItem = (route: string, path: string) => {
  if (route === "/defi") {
    return path.includes("/defi");
  } else if (route === "/bridge") {
    return path.includes("/bridge");
  } else if (route === "/stake") {
    return path.includes("/stake");
  } else if (route === "/assets") {
    return path.includes("/assets");
  } else {
    return (
      !path.includes("/defi") &&
      !path.includes("/bridge") &&
      !path.includes("/assets") &&
      !path.includes("/stake")
    );
  }
};

export const getTokenImgFromDenom = (denom: string) => {
  switch (denom) {
    case "uxprt":
      return {
        denom: "XPRT",
        chain: "persistence",
        tokenImg: "/tokens/xprt.svg",
        minimalDenom: "uxprt",
        decimals: 6
      };
    case "uatom":
      return {
        denom: "ATOM",
        chain: "cosmos",
        tokenImg: "/tokens/atom.svg",
        minimalDenom: "uatom",
        decimals: 6
      };
    case "adydx":
      return {
        denom: "DYDX",
        chain: "dydx",
        tokenImg: "/tokens/dydx.svg",
        minimalDenom: "adydx",
        decimals: 18
      };
    case "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA": // mainnet asset
      return {
        denom: "ATOM",
        chain: "persistence",
        tokenImg: "/tokens/atom.svg",
        minimalDenom: "uatom",
        decimals: 6
      };
    case "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9": // testnet asset
      return {
        denom: "ATOM",
        chain: "persistence",
        tokenImg: "/tokens/atom.svg",
        minimalDenom: "uatom",
        decimals: 6
      };
    case "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E": // mainnet asset
      return {
        denom: "DYDX",
        chain: "persistence",
        tokenImg: "/tokens/dydx.svg",
        minimalDenom: "adydx",
        decimals: 18
      };
    case "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444":
      return {
        denom: "PSTAKE",
        chain: "persistence",
        tokenImg: "/tokens/pstake.png",
        minimalDenom: "pstake",
        decimals: 18
      };
    case "uosmo":
      return {
        denom: "OSMO",
        chain: "osmosis",
        tokenImg: "/tokens/osmo.svg",
        minimalDenom: "uosmo",
        decimals: 6
      };
    case "ibc/4A17832B26BF318D052563EFFE677C1DE11DF8CE104F00204860F3E3439818B2":
      return {
        denom: "ATOM",
        chain: "persistence",
        tokenImg: "/tokens/atom.svg",
        minimalDenom: "uatom",
        decimals: 6
      };
    case "ibc/5A41832B26BF318D052563EFFE677C1DE11DF8CE104F00204860F3E3439818B2":
      return {
        denom: "OSMO",
        chain: "persistence",
        tokenImg: "/tokens/osmo.svg",
        minimalDenom: "uosmo",
        decimals: 6
      };
    case "ibc/646315E3B0461F5FA4C5C8968A88FC45D4D5D04A45B98F1B8294DD82F386DD85":
      return {
        denom: "OSMO",
        chain: "persistence",
        tokenImg: "/tokens/osmo.svg",
        minimalDenom: "uosmo",
        decimals: 6
      };
    case "stk/uatom":
      return {
        denom: "stkATOM",
        chain: "persistence",
        tokenImg: "/tokens/stk_atom.svg",
        minimalDenom: "stk/uatom",
        decimals: 6
      };
    case "stk/uosmo":
      return {
        denom: "stkOSMO",
        chain: "persistence",
        tokenImg: "/tokens/stk_osmo.svg",
        minimalDenom: "stk/uosmo",
        decimals: 6
      };
    case "stk/uxprt":
      return {
        denom: "stkXPRT",
        chain: "persistence",
        tokenImg: "/tokens/stk_xprt.svg",
        minimalDenom: "stk/uxprt",
        decimals: 6
      };
    case "stk/adydx":
      return {
        denom: "stkDYDX",
        chain: "persistence",
        tokenImg: "/tokens/stk_dydx.svg",
        minimalDenom: "stk/adydx",
        decimals: 18
      };
    case "eth/ethereum":
      return {
        denom: "ETH",
        chain: "ethereum",
        tokenImg: "/tokens/eth.svg",
        minimalDenom: "eth",
        decimals: 18
      };
    case "stkETH/ethereum":
      return {
        denom: "stkETH",
        chain: "optimism",
        tokenImg: "/tokens/stk_eth.svg",
        minimalDenom: "stkETH",
        decimals: 18
      };
    case "eth/optimism":
      return {
        denom: "ETH",
        chain: "optimism",
        tokenImg: "/tokens/eth_optimism.svg",
        minimalDenom: "eth",
        decimals: 18
      };
    case "stkETH/optimism":
      return {
        denom: "stkETH",
        chain: "optimism",
        tokenImg: "/tokens/stketh_optimism.svg",
        minimalDenom: "stkETH",
        decimals: 18
      };
    case "eth/arbitrum":
      return {
        denom: "ETH",
        chain: "arbitrum",
        tokenImg: "/tokens/arbitrum.svg",
        minimalDenom: "eth",
        decimals: 18
      };
    case "stkETH/arbitrum":
      return {
        denom: "stkETH",
        chain: "arbitrum",
        tokenImg: "/tokens/stketh_arbitrum.svg",
        minimalDenom: "stkETH",
        decimals: 18
      };
    case "weth/ethereum":
      return {
        denom: "WETH",
        chain: "ethereum",
        tokenImg: "/tokens/eth.svg",
        minimalDenom: "weth",
        decimals: 18
      };
    case "weth/arbitrum":
      return {
        denom: "WETH",
        chain: "arbitrum",
        tokenImg: "/tokens/arbitrum.svg",
        minimalDenom: "weth",
        decimals: 18
      };
    case "weth/optimism":
      return {
        denom: "WETH",
        chain: "optimism",
        tokenImg: "/tokens/eth_optimism.svg",
        minimalDenom: "weth",
        decimals: 18
      };
    case "adv4tnt":
      return {
        denom: "DV4TNT",
        chain: "persistence",
        tokenImg: "/tokens/dydx.svg",
        minimalDenom: "adv4tnt",
        decimals: 18
      };
    case "stk/adv4tnt":
      return {
        denom: "stkDV4TNT",
        chain: "persistence",
        tokenImg: "/tokens/stk_dydx.svg",
        minimalDenom: "stk/adv4tnt",
        decimals: 18
      };
    case "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3": // testnet-asset
      return {
        denom: "DV4TNT",
        chain: "persistence",
        tokenImg: "/tokens/dydx.svg",
        minimalDenom: "adv4tnt",
        decimals: 18
      };
    case "atytx":
      return {
        denom: "TYTX",
        chain: "persistence",
        tokenImg: "/tokens/dydx.svg",
        minimalDenom: "atytx",
        decimals: 18
      };
    case "stk/atytx":
      return {
        denom: "stkTYTX",
        chain: "persistence",
        tokenImg: "/tokens/stk_dydx.svg",
        minimalDenom: "stk/atytx",
        decimals: 18
      };
    case "ibc/6AE2756AA7EAA8FA06E11472EA05CA681BD8D3FBC1AAA9F06C79D1EC1C90DC9B": // testnet asset
      return {
        denom: "OSMO",
        chain: "persistence",
        tokenImg: "/tokens/osmo.svg",
        minimalDenom: "uosmo",
        decimals: 6
      };
    case "ibc/008E65368CDAC4AEDDAEB41FAD053F9B6B37BD8FA15AD9FC922497F3D8450363": // testnet asset
      return {
        denom: "TYTX",
        chain: "persistence",
        tokenImg: "/tokens/dydx.svg",
        minimalDenom: "atytx",
        decimals: 18
      };
    case "uluna":
      return {
        denom: "LUNA",
        chain: "terra",
        tokenImg: "/tokens/luna.svg",
        minimalDenom: "uluna",
        decimals: 6
      };
    case "stk/uluna":
      return {
        denom: "stkLUNA",
        chain: "persistence",
        tokenImg: "/tokens/stk_luna.svg",
        minimalDenom: "stk/uluna",
        decimals: 6
      };
    case "ibc/E3C1A60A5CA60A744428B35C788E02801EB12D74F857F345CC6E3001B0915283":
      return {
        denom: "LUNA",
        chain: "persistence",
        tokenImg: "/tokens/luna.svg",
        minimalDenom: "uluna",
        decimals: 6
      };
    case "ustars":
      return {
        denom: "STARS",
        chain: "stargaze",
        tokenImg: "/tokens/stars.svg",
        minimalDenom: "ustars",
        decimals: 6
      };
    case "stk/ustars": // testnet asset
      return {
        denom: "stkSTARS",
        chain: "persistence",
        tokenImg: "/tokens/stk_stars.svg",
        minimalDenom: "stk/ustars",
        decimals: 6
      };
    case "ibc/E6ACE9ACB1198998177C8ED4C6BCD3BFB046FEC4559E5F203473157A9CD0C07B": // testnet asset
      return {
        denom: "STARS",
        chain: "persistence",
        tokenImg: "/tokens/stars.svg",
        minimalDenom: "ustars",
        decimals: 6
      };
    case "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B":
      return {
        denom: "STARS",
        chain: "persistence",
        tokenImg: "/tokens/stars.svg",
        minimalDenom: "ustars",
        decimals: 6
      };
    case "ubld":
      return {
        denom: "BLD",
        chain: "agoric",
        tokenImg: "/tokens/bld.svg",
        minimalDenom: "ubld",
        decimals: 6
      };
    case "stk/ubld":
      return {
        denom: "stkBLD",
        chain: "persistence",
        tokenImg: "/tokens/stk_bld.svg",
        minimalDenom: "stk/ubld",
        decimals: 6
      };
    case "ibc/910CED9A9DB0C5921E1FC8FD60AA2C1D5F9E8C25E50DA6959117B4B2041D7B46": // testnet asset
      return {
        denom: "BLD",
        chain: "persistence",
        tokenImg: "/tokens/bld.svg",
        minimalDenom: "ubld",
        decimals: 6
      };
    case "ibc/5BCA39AEE3A48194602328FF11D873FCC61ADDD40C7F1BBB7B05C9AA2896B407": // mainnet asset
      return {
        denom: "BLD",
        chain: "persistence",
        tokenImg: "/tokens/bld.svg",
        minimalDenom: "ubld",
        decimals: 6
      };
    case "uhuahua":
      return {
        denom: "HUAHUA",
        chain: "chihuahua",
        tokenImg: "/tokens/huahua.svg",
        minimalDenom: "uhuahua",
        decimals: 6
      };
    case "stk/uhuahua":
      return {
        denom: "stkHUAHUA",
        chain: "persistence",
        tokenImg: "/tokens/stk_huahua.svg",
        minimalDenom: "stk/uhuahua",
        decimals: 6
      };
    case "ibc/21DE7368DBC48C833B1A349DCEFD033543C117733B9E17FD04BD8801BDCFF3CB": // testnet asset
      return {
        denom: "HUAHUA",
        chain: "persistence",
        tokenImg: "/tokens/huahua.svg",
        minimalDenom: "uhuahua",
        decimals: 6
      };
    case "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4": // mainnet asset
      return {
        denom: "HUAHUA",
        chain: "persistence",
        tokenImg: "/tokens/huahua.svg",
        minimalDenom: "uhuahua",
        decimals: 6
      };
    default:
      return {
        denom: "Unknown",
        chain: "persistence",
        tokenImg: "/tokens/ibc.svg",
        decimals: 0
      };
  }
};
