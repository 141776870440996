"use client";
import React, { useEffect } from "react";
import LoginOptions from "./login-options";
import { useWindowSize } from "@persistenceone/pstake-ui-components";
import { Icon } from "@/components";
import { SHORT_INTERVAL } from "../../../constants/static";
import Link from "next/link";
import Networks from "./networks";
import { getChainStatus, getValidators } from "@/api/cosmos";
import CustomImage from "@/components/molecules/image";
import NetworkModal from "@/containers/cosmos/navigation-bar/network-modal";
import dynamic from "next/dynamic";
import { externalChains } from "@/helpers/utils";
import { ChainInfo } from "@keplr-wallet/types";
import GeofenceNotice from "@/components/organisms/geofence-banner";
import { useCosmosStore } from "@/containers/cosmos/store";
import { useShallow } from "zustand/react/shallow";
import { fetchChainTVUList } from "@/containers/cosmos/store/sagas/fetching-sagas";
import {
  fetchActionsBasedOnDstChain,
  fetchInit
} from "@/containers/cosmos/store/sagas/saga-helpers";
import TermsModal from "@/components/organisms/terms-modal";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

const CosmosNavbar = () => {
  const { isMobile } = useWindowSize();

  const {
    validators,
    dstChainItem,
    setValidators,
    setSrcChainStatus,
    isWalletConnected,
    srcAccountData,
    dstAccountData,
    dstChainInfo,
    srcChainInfo,
    handleMobileSidebar
  } = useCosmosStore(
    useShallow((state) => ({
      validators: state.initData.validators,
      dstChainItem: state.initData.dstChainItem,
      setValidators: state.setValidators,
      setSrcChainStatus: state.liveDataActions.setSrcChainStatus,
      isWalletConnected: state.walletData.walletInfo.isWalletConnected,
      srcAccountData: state.walletData.walletInfo.srcAccountData,
      dstAccountData: state.walletData.walletInfo.dstAccountData,
      dstChainInfo: state.walletData.walletInfo.dstChainInfo,
      srcChainInfo: state.walletData.walletInfo.srcChainInfo,
      //actions
      handleMobileSidebar: state.walletDataActions.handleMobileSidebar
    }))
  );

  const handleMenu = () => {
    handleMobileSidebar(true);
  };

  // fetch initial app information whenever cosmos network changed
  useEffect(() => {
    console.log(srcChainInfo, "srcChainInfo-inside", dstChainItem);
    fetchActionsBasedOnDstChain({
      srcChainInfo: srcChainInfo!,
      dstChainItem: dstChainItem
    });
  }, [dstChainItem]);

  // fetch initial app information without asset selected
  useEffect(() => {
    console.log(srcChainInfo, "srcChainInfo-inside");
    fetchInit({
      srcChainInfo: srcChainInfo!
    });
  }, []);

  // fetch validator list on initial render
  useEffect(() => {
    const fetchValidators = async () => {
      /** TODO: Update to dynamic**/
      let cosmosChain = externalChains.find(
        (chain: ChainInfo) =>
          chain.bech32Config.bech32PrefixAccAddr === "cosmos"
      );
      const response = await getValidators(
        srcChainInfo.rpc,
        cosmosChain.chainId
      );
      setValidators(response);
    };
    fetchValidators();
  }, [srcChainInfo]);

  // fetch data on every 10 sec
  useEffect(() => {
    const interval = setInterval(async () => {
      if (srcChainInfo !== null) {
        fetchChainTVUList({
          srcChainInfo: srcChainInfo!
        });
        const [srcChainStatus] = await Promise.all([
          getChainStatus(srcChainInfo!.rpc)
        ]);
        setSrcChainStatus(srcChainStatus);
      }
    }, SHORT_INTERVAL);
    return () => clearInterval(interval);
  }, [srcChainInfo]);

  return (
    <div>
      <GeofenceNotice />
      <div className="flex pt-8 mb-12 pl-7 pr-8 md:px-3">
        <div className="flex items-center flex-1">
          <div className="hidden md:block">
            <Link href="/" className="nav-link" passHref>
              <CustomImage
                src={"/logo.svg"}
                width={isMobile ? 90 : 124}
                height={31}
                alt={"logo"}
              />
            </Link>
          </div>
          <div className="flex ml-auto items-center">
            <Networks />
            <LoginOptions />
            <button className="md:block hidden pl-2" onClick={handleMenu}>
              <Icon iconName="menu" viewClass="menu" />
            </button>
          </div>
          <NetworkModal />
          <TermsModal
            isWalletConnected={isWalletConnected}
            network={"cosmos"}
          />
        </div>
      </div>
    </div>
  );
};

export default CosmosNavbar;
