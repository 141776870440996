import { StateCreator } from "zustand";
import { SliceActions, SwapTransactionState } from "./types";
import { baseAssetList, stkAssetList } from "@/utils";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

let initialAsset = baseAssetList[env].find(
  (baseAsset) => baseAsset.name === "ATOM" && !baseAsset.ibcAsset
);

let initialOutputAsset = stkAssetList[env].find(
  (stkAsset) => stkAsset.pair === initialAsset.name && !stkAsset.ibcAsset
);

const initialState: SwapTransactionState = {
  swapTransaction: {
    amount: "",
    showModal: false,
    txFailed: false,
    stepNumber: 0,
    swapInToken: initialAsset,
    swapOutToken: initialOutputAsset,
    swapInTokenModal: false,
    swapOutTokenModal: false
  }
};

export type SwapTransactionSlice = SwapTransactionState & SliceActions;

export const createSwapTransactionSlice: StateCreator<SwapTransactionSlice> = (
  set
) => ({
  ...initialState,
  swapTransactionActions: {
    setSwapAmount: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          amount: val
        }
      })),
    hideTxnInfoModal: () =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          showModal: false
        }
      })),
    showTxnInfoModal: () =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          showModal: true
        }
      })),
    setSwapTxnFailed: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          txFailed: val
        }
      })),
    setSwapTxnStepNumber: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          stepNumber: val
        }
      })),
    setSwapInToken: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapInToken: val
        }
      })),
    setSwapOutToken: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapOutToken: val
        }
      })),
    setSwapInTokenModal: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapInTokenModal: val
        }
      })),
    setSwapOutTokenModal: (val) =>
      set((state) => ({
        swapTransaction: {
          ...state.swapTransaction,
          swapOutTokenModal: val
        }
      }))
  }
});
