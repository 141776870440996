export interface ChainInfo {
  networkID: string | number;
  networkIdHex: string;
  networkName: string;
  rpcUrl: string;
  explorerUrl: string;
  stakePoolDeploymentBlock: number;
  blockConfirmations: number;
}

export type ChainList = {
  [index: string | "Testnet" | "Mainnet" | "Devnet"]: ChainInfo;
};

export const chainInfo: ChainList = {
  Devnet: {
    networkIdHex: "0x61",
    networkID: "97",
    networkName: "BSC Testnet",
    explorerUrl: "https://testnet.bscscan.com",
    stakePoolDeploymentBlock: 21641355,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    blockConfirmations: 2,
  },
  Testnet: {
    networkIdHex: "0x61",
    networkID: "97",
    networkName: "BSC Testnet",
    explorerUrl: "https://testnet.bscscan.com",
    stakePoolDeploymentBlock: 21641355,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    blockConfirmations: 2,
  },
  Mainnet: {
    networkIdHex: "0x38",
    networkID: "56",
    networkName: "BSC Mainnet",
    explorerUrl: "https://bscscan.com",
    stakePoolDeploymentBlock: 20155830,
    rpcUrl: "https://bsc-dataseed2.defibit.io",
    // rpcUrl:
    //     "https://bsc-mainnet.nodereal.io/v1/9077738996654734a214979c60a7846d",
    blockConfirmations: 5,
  },
};

export const bep20TokenAddresses = {
  Testnet: {
    BNB: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    DAI: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    // BETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    // BTCB: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    stkBNB: "0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16",
  },
  Mainnet: {
    BNB: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    DAI: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    // BETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    // BTCB: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    stkBNB: "0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16",
  },
};
