"use client";
import React, { useEffect, useRef } from "react";
import Sidebar from "./sidebar-content";
import {
  useOnClickOutside,
  useWindowSize,
  useOnlineStatus
} from "@persistenceone/pstake-ui-components";
import styles from "./styles.module.css";
import { useAppStore } from "@/store/store";
import { useCosmosStore } from "@/containers/cosmos/store";
import { useShallow } from "zustand/react/shallow";

const CosmosSidebar = () => {
  const setNetworkStatus = useAppStore(
    ({ setNetworkStatus }) => setNetworkStatus
  );

  const { show, handleMobileSidebar } = useCosmosStore(
    useShallow((state) => ({
      show: state.sideBar.show,
      handleMobileSidebar: state.walletDataActions.handleMobileSidebar
    }))
  );

  const closeHandler = () => {
    handleMobileSidebar(false);
  };
  const { isMobile } = useWindowSize();
  const status = useOnlineStatus();
  const sideBarRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(sideBarRef, closeHandler);

  useEffect(() => {
    setNetworkStatus(!status);
  }, [status]);

  return (
    <div
      className={`${
        isMobile ? `${styles.mobileDropdownContainer} offCanvas` : ""
      } ${show ? "show" : "close"} w-[284px]`}
    >
      <div
        className={`${isMobile ? styles.mobileSidebarBackdrop : ""} backDrop`}
      />
      <div
        className={`${isMobile ? styles.mobileSidebar : ""}`}
        ref={sideBarRef}
      >
        <Sidebar />
      </div>
    </div>
  );
};

export default CosmosSidebar;
