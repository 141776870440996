import { Dropdown } from "@persistenceone/pstake-ui-components";
import React, { useState } from "react";
import CustomImage from "@/components/molecules/image";
import { Network, NetworksTypes } from "@/utils";
import { Icon } from "@/components";

interface NetworksDropdownProps {
  networkList: NetworksTypes[];
  networkName: Network;
  handleDropdown: (item: NetworksTypes) => void;
}

export const NetworkDropdown = ({
  networkList,
  networkName,
  handleDropdown
}: NetworksDropdownProps) => {
  const [show, setShow] = useState<boolean>(false);
  const filteredList = networkList.filter((item) => {
    return item.network.toLowerCase() !== networkName.toLowerCase();
  });
  const selectedNetwork = networkList.find((item) => {
    return item.network.toLowerCase() === networkName.toLowerCase();
  });
  const dropCloseDownHandler = (value: boolean) => {
    setShow(value);
  };
  return (
    <>
      <Dropdown
        className="text-white-100 mr-3 md:hidden"
        closeDropdown={show}
        closeHandler={(value) => dropCloseDownHandler(value)}
        dropdownLabel={
          <div className="flex items-center">
            <>
              <CustomImage
                width={20}
                height={20}
                className="mr-2"
                src={selectedNetwork.logo}
                alt="stkATOM logo"
              />
              <span className="text-sm text-white-200 font-medium leading-normal md:text-xsm md:ml-2 capitalize">
                {selectedNetwork.underlineChain}
              </span>
            </>
          </div>
        }
        dropDownButtonClass="!h-[37px] text-[12px] text-white-100 !py-2.5"
        dropdownType={"click"}
        staticBackDrop={false}
        dropDownIcon={
          <Icon
            viewClass="arrow-right fill-[#fff] !w-[10px]"
            iconName="chevron"
          />
        }
        dropDownContentClass="bg-black-400 round-md w-max md:p-0"
      >
        {networkList.map((item, index) => (
          <div
            className={`px-6 py-3 flex items-center md:py-3
                        hover:cursor-pointer hover:rounded-md hover:bg-[#2828288a] text-black-400 whitespace-nowrap ${
                          item.network === selectedNetwork.network
                            ? "pointer-events-none"
                            : ""
                        }`}
            key={index}
            onClick={() => {
              handleDropdown(item);
            }}
          >
            <div className="flex items-center">
              <div className={"relative"}>
                <CustomImage
                  width={20}
                  height={20}
                  className="mr-2"
                  src={item.logo}
                  alt="stkATOM logo"
                />
                {item.network === selectedNetwork.network ? (
                  <span
                    className={
                      "w-2 h-2 bg-[#24B574] border-2 border-[#000] rounded-md block absolute right-[6px] bottom-[0px]"
                    }
                  />
                ) : null}
              </div>
              <span
                className="text-sm text-white-200 font-medium
                  leading-normal md:text-xsm md:ml-2 capitalize"
              >
                {item.underlineChain}
              </span>
            </div>
          </div>
        ))}
      </Dropdown>
    </>
  );
};
