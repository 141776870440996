import { NetworkDropdown } from "@/components";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useFeatureFlags } from "@/context/feature-flags-context";
import { getNetworkList } from "@/helpers/utils";
import { getStorageValue } from "@persistenceone/pstake-ui-components";
import { useAppStore } from "@/store/store";
import { useShallow } from "zustand/react/shallow";
import { useCosmosStore } from "@/containers/cosmos/store";

export type Network =
  | "Cosmos"
  | "Ethereum"
  | "Optimism"
  | "BNB Smart Chain"
  | "Arbitrum";

interface NetworksProps {
  appName: Network;
}

export const Networks = ({ appName }: NetworksProps) => {
  const router = useRouter();
  const { useFlag } = useFeatureFlags();
  const isEthEnabled = useFlag("eth_network");
  const isOptimismEnabled = useFlag("optimism_network");
  const isArbitrumEnabled = useFlag("arbitrum_network");
  const [networkList, setNetworkList] = useState<any[]>([]);
  const cosmosWalletInfo = getStorageValue("cosmos-walletInfo", "");
  const { setActiveNetwork } = useAppStore(
    useShallow((state) => ({
      setActiveNetwork: state.setActiveNetwork
    }))
  );

  const { initialStakeToken, stakeInToken } = useCosmosStore(
    useShallow((state) => ({
      initialStakeToken: state.initData.initialStakeToken,
      stakeInToken: state.stakeTransaction.stakeInToken
    }))
  );

  useEffect(() => {
    let supportedNetworks = getNetworkList([
      { network: "Optimism", enable: isOptimismEnabled },
      { network: "Ethereum", enable: isEthEnabled },
      { network: "Arbitrum", enable: isArbitrumEnabled }
    ]);
    console.log(supportedNetworks);
    setNetworkList(supportedNetworks);
  }, [appName, isOptimismEnabled, isArbitrumEnabled, isEthEnabled]);

  const handleNetworkChange = (item) => {
    setActiveNetwork(item.network.toLowerCase());
    if (item.network === "Cosmos" && cosmosWalletInfo !== "") {
      router.push(
        initialStakeToken !== null
          ? `/cosmos/stake?token=${
              stakeInToken.name
            }&chain=${stakeInToken.chain.toString()}`
          : "/cosmos"
      );
    } else {
      router.push(item.link);
    }
  };

  return (
    <>
      {networkList.length > 0 && (
        <NetworkDropdown
          handleDropdown={handleNetworkChange}
          networkName={appName}
          networkList={networkList}
        />
      )}
    </>
  );
};
