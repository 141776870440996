import { Squid } from "@0xsquid/sdk";
import { SigningStargateClient, DeliverTxResponse } from "@cosmjs/stargate";
import { OfflineSigner } from "@cosmjs/launchpad";
import { OfflineDirectSigner } from "@cosmjs/proto-signing";
import { RouteResponse, RouteRequest } from "@0xsquid/squid-types";

export async function getSquidQuote(
  sourceChainId: number | string,
  destinationChainId: number | string,
  amount: string,
  sourceChainSignerAddress: string,
  destinationChainRecipientAddress: string,
  fromToken: string,
  toToken: string
): Promise<RouteResponse> {
  try {
    const squid: Squid = await initSquidClient();

    //
    // const params = {
    //     fromChain: "osmo-test-5", // Osmosis Testnet
    //     fromToken: "ibc/40F1B2458AEDA66431F9D44F48413240B8D28C072463E2BF53655728683583E3", // nUSDC on Osmosis
    //     fromAmount: "1000000", // 1 nUSDC
    //     toChain: 43113, // Avalanche Fuji Tesntet
    //     toToken: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", // AVAX on Avalanche
    //     toAddress: "0x747A6e3824FAB0d1266306C3b492fcB941C5dd93", // the recipient of the trade
    //     slippage: 1.00, // 1.00 = 1% max slippage across the entire route
    //     cosmosSignerAddress: "osmo1eaztm3pqrkw2xgt0lxppahtx5v5pndmjg6yfrh", // Cosmos Sender address
    //     enableForecall: true, // instant execution service, defaults to true
    //     quoteOnly: false // optional, defaults to false
    // };

    const params: RouteRequest = {
      fromChain: sourceChainId.toString(),
      fromToken: fromToken,
      fromAmount: amount,
      toChain: destinationChainId.toString(),
      toToken: toToken,
      toAddress: destinationChainRecipientAddress,
      slippageConfig: {
        slippage: 1.0,
        autoMode: 1,
      },
      fromAddress: sourceChainSignerAddress,
      quoteOnly: false,
    };
    let route: RouteResponse = await squid.getRoute(params);
    return route;
  } catch (e) {
    console.log(e, "error in getSquidQuote");
    throw new Error("Error in Squid API");
  }
}

export async function executeSquidQuote(
  route: RouteResponse,
  offlineSigner: OfflineSigner | OfflineDirectSigner,
  signerAddress: string,
  rpc: string
): Promise<DeliverTxResponse> {
  try {
    const squid: Squid = await initSquidClient();

    const signer = await SigningStargateClient.connectWithSigner(
      rpc,
      offlineSigner
    );

    // @ts-ignore
    const cosmosTx: DeliverTxResponse = await squid.executeRoute({
      signer,
      signerAddress,
      route: route.route,
    });

    // const status: StatusResponse = await squid.getStatus({
    //   transactionId: txHash,
    //   fromChainId: route.params.fromChain,
    //   toChainId: route.params.toChain,
    // });
    //
    // console.log(status);
    return cosmosTx;
  } catch (e) {
    throw new Error("Error in Squid API");
  }
}

async function initSquidClient(): Promise<Squid> {
  // const baseUrl = "https://testnet.api.0xsquid.com";

  const baseUrl =
    "https://squid-api-git-feat-cosmos-mainmainnet-0xsquid.vercel.app";

  // instantiate the SDK
  const squid: Squid = new Squid({
    baseUrl: baseUrl,
  });

  // init the SDK
  await squid.init();

  return squid;
}
