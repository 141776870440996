import { BigNumberish, utils } from "ethers";

export const bigNumberToEther = (bigNumber: BigNumberish) => {
  return utils.formatEther(
    bigNumber === "" || bigNumber === null ? etherToBigNumber("0") : bigNumber
  );
};

export const etherToBigNumber = (ether: string) => {
  return utils.parseEther(ether);
};
