"use client";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { createSidebarSlice, SidebarSlice } from "./slices/sidebar-slice";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { BalanceSlice, createBalanceSlice } from "./slices/balance-slice";
import {
  TransactionSlice,
  createTransactionSlice
} from "./slices/transactions";
import {
  createInitialDataSlice,
  InitialDataSlice
} from "./slices/initial-data-slice";
import { WalletSlice, createWalletSlice } from "./slices/wallet-slice";

type StoreState = InitialDataSlice &
  SidebarSlice &
  BalanceSlice &
  TransactionSlice &
  WalletSlice;

export const useEthereumStore = createWithEqualityFn<StoreState>()(
  (...a) => ({
    ...createSidebarSlice(...a),
    ...createBalanceSlice(...a),
    ...createTransactionSlice(...a),
    ...createInitialDataSlice(...a),
    ...createWalletSlice(...a)
  }),
  shallow
);

mountStoreDevtool("Store", useEthereumStore);
