import React from "react";
import { toast } from "react-toastify";
import { Alert, ToastType } from "./types";
import { Icon } from "@/components";
import { stringTruncate } from "@persistenceone/pstake-ui-components";

const BroadCastMsg = ({ message }: any) => (
  <div className="toast-content border-b-[5px] border-[#fcfcfc]">
    <div className="content flex-1 bg-[#282828] px-5 py-4">
      <div className="flex mb-2 items-center">
        <div className="spinner light icon-toast inline-block w-[18px] h-[18px] align-text-bottom rounded-full mr-2" />
        <p className="title text-white-100 font-medium text-sm">
          Transaction Broadcasting
        </p>
      </div>
      <p className="title text-white-600 text-[12px] ml-[27px]">{message}</p>
    </div>
  </div>
);

const TransactionSuccess = ({ message, txHash, explorerUrl }: any) => (
  <div className="toast-content border-b-[5px] border-[#47C28B]">
    <div className="content flex-1 bg-[#282828] px-5 py-4">
      <div className="flex mb-2 items-center">
        <Icon
          iconName="success"
          viewClass="icon-toast !w-[18px] !h-[18px] mr-2"
        />
        <p className="title text-white-100 font-medium text-sm">
          Transaction Successful
        </p>
      </div>

      {explorerUrl ? (
        <div className={"flex items-center ml-[27px]"}>
          <span className="text-white-600 text-xsm">Txn Hash:&nbsp;</span>
          <a
            rel="noreferrer"
            className="text-blue-500 text-xsm flex items-center"
            target={"_blank"}
            href={explorerUrl}
          >
            {stringTruncate(txHash)}
            <Icon
              iconName="new-tab"
              viewClass={`!w-[8px] !h-[8px] ml-1 fill-[#3E73F0]`}
            />
          </a>
        </div>
      ) : (
        <p className="text-white-600 text-[12px] ml-[27px]">{message}</p>
      )}
    </div>
  </div>
);

const TransactionFailed = ({ message, heading }: any) => (
  <div className="toast-content border-b-[5px] border-[#DB2438]">
    <div className="content flex-1 bg-[#282828] px-5 py-4">
      <div className="flex mb-2 items-center">
        <Icon
          iconName="failed"
          viewClass="icon-toast !w-[18px] !h-[18px] mr-2"
        />
        <p className="title text-white-100 font-medium text-sm">
          {heading ? heading : "Transaction Failed"}
        </p>
      </div>
      <p className="title text-white-600 text-[12px] ml-[27px]">{message}</p>
    </div>
  </div>
);

const TransactionInfo = ({ message }: any) => (
  <div className="toast-content border-b-[5px] border-[#F9B053BD]">
    <div className="content flex-1 bg-[#282828] px-5 py-4">
      <div className="flex mb-2 items-center">
        <Icon iconName="info" viewClass="icon-toast !w-[18px] !h-[18px] mr-2" />
        <p className="title text-white-100 font-medium text-sm">
          Transaction Warning!
        </p>
      </div>
      <p className="title text-white-600 text-[12px] ml-[27px]">{message}</p>
    </div>
  </div>
);

const TransactionWarning = ({ message }: any) => (
  <div className="toast-content border-b-[5px] border-[#F9B053BD]">
    <div className="content flex-1 bg-[#282828] px-5 py-4">
      <div className="flex mb-2 items-center">
        <Icon iconName="info" viewClass="icon-toast !w-[18px] !h-[18px] mr-2" />
        <p className="title text-white-100 font-medium text-sm">
          Transaction Warning!
        </p>
      </div>
      <p className="title text-white-600 text-[12px] ml-[27px]">{message}</p>
    </div>
  </div>
);

const MinimizeMsg = ({ message, actionHandler }: Alert) => (
  <div className="toast-content border-b-[5px] border-[#fcfcfc]">
    <div className="content flex-1 bg-[#282828] px-5 py-4 flex items-center justify-between">
      <div>
        <div className="flex mb-2 items-center">
          <div className="spinner secondary light icon-toast inline-block w-[18px] h-[18px] align-text-bottom rounded-full mr-2" />
          <p className="title text-white-100 font-medium text-sm">
            Transaction Minimized
          </p>
        </div>
        <p className="title text-white-600 text-[10px] ml-[27px]">
          We’ll let you know when the transfer completes
        </p>
      </div>
      <div>
        <div
          className={
            "rounded-md border-white-600 border py-1 w-[80px] h-[23px] flex items-center justify-center cursor-pointer"
          }
          onClick={actionHandler}
        >
          <div className={"flex items-center"}>
            <span className={"text-white-600 text-[10px] mr-[2px]"}>
              Expand
            </span>
            <Icon
              iconName="expand"
              viewClass="icon-toast !w-[12px] !h-[12px] -bottom-[1px]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export function displayToast(alert: Alert, type: ToastType, toastId?: any) {
  if (!toast.isActive(toastId)) {
    toast.dismiss();
    switch (type) {
      case ToastType.SUCCESS:
        toast(<TransactionSuccess {...alert} />, {
          type: "success",
          autoClose: 3000,
          toastId: toastId ? toastId : alert.message
        });
        break;
      case ToastType.ERROR:
        toast(<TransactionFailed {...alert} />, {
          type: "error",
          autoClose: 3000,
          toastId: toastId ? toastId : alert.message
        });
        break;
      case ToastType.LOADING:
        toast(<BroadCastMsg {...alert} />, {
          autoClose: 3000,
          toastId: toastId ? toastId : alert.message
        });
        break;
      case ToastType.MINIMIZE:
        toast(<MinimizeMsg {...alert} />, {
          autoClose: false,
          toastId: toastId ? toastId : alert.message
        });
        break;
      case ToastType.WARNING:
        toast(<TransactionWarning {...alert} />, {
          type: "warning",
          autoClose: 3000,
          toastId: toastId ? toastId : alert.message
        });
        break;
      case ToastType.INFO:
        toast(<TransactionInfo {...alert} />, {
          type: "info",
          autoClose: 3000,
          toastId: toastId ? toastId : alert.message
        });
        break;
    }
  }
}
