import { NetworkDropdown } from "@/components";
import { Network } from "@/utils";
import React, { useEffect, useState } from "react";
import { handleSwitchNetwork } from "../../helpers/wallets";
import { useEthereumStore } from "../../store/store";
import {
  getStorageValue,
  useLocalStorage
} from "@persistenceone/pstake-ui-components";
import { useRouter } from "next/navigation";
import { useFeatureFlags } from "@/context/feature-flags-context";
import { getNetworkList } from "@/helpers/utils";
import { useAppStore } from "@/store/store";
import { useShallow } from "zustand/react/shallow";
import { useCosmosStore } from "@/containers/cosmos/store";

export const Networks = () => {
  const router = useRouter();
  const { useFlag } = useFeatureFlags();
  const isOptimismEnabled = useFlag("optimism_network");
  const { setActiveNetwork } = useAppStore(
    useShallow((state) => ({
      setActiveNetwork: state.setActiveNetwork
    }))
  );
  const [networkList, setNetworkList] = useState<any[]>([]);
  const network = useEthereumStore((state) => state.network);
  const walletInfo = useEthereumStore((state) => state.wallet);
  const [networkItem, setNetworkItem] = useLocalStorage("eth-network", "");
  const cosmosWalletInfo = getStorageValue("cosmos-walletInfo", "");

  const { stakeInToken } = useCosmosStore(
    useShallow((state) => ({
      stakeInToken: state.stakeTransaction.stakeInToken
    }))
  );

  const handleWalletNetwork = useEthereumStore(
    (state) => state.handleWalletNetwork
  );

  useEffect(() => {
    let supportedNetworks = getNetworkList([
      { network: "Optimism", enable: isOptimismEnabled }
    ]);
    setNetworkList(supportedNetworks);
  }, [network.name, isOptimismEnabled]);

  const handleNetworkChange = async (networkInfo) => {
    if (networkInfo.type !== "ethereum") {
      setActiveNetwork(networkInfo.network.toLowerCase());
      setActiveNetwork(networkInfo.network.toLowerCase());
      if (networkInfo.network === "Cosmos" && cosmosWalletInfo !== "") {
        router.push(
          `/cosmos/?token=${
            stakeInToken.name
          }&chain=${stakeInToken.chain.toString()}`
        );
      } else {
        router.push(networkInfo.link);
      }
    } else {
      if (walletInfo.walletConnection) {
        const switchStatus = await handleSwitchNetwork(
          networkInfo.network.toLowerCase()
        );
        if (switchStatus) {
          setNetworkItem(networkInfo.network.toLowerCase());
          let supportedNetworks = getNetworkList([
            { network: "Optimism", enable: isOptimismEnabled }
          ]);
          setActiveNetwork(networkInfo.network.toLowerCase());
          setNetworkList(supportedNetworks);
          handleWalletNetwork(networkInfo.network.toLowerCase());
        }
      } else {
        handleWalletNetwork(networkInfo.network.toLowerCase());
      }
    }
  };

  return (
    <>
      {networkList.length > 0 && (
        <NetworkDropdown
          handleDropdown={handleNetworkChange}
          networkName={network.name as Network}
          networkList={networkList}
        />
      )}
    </>
  );
};
