"use client";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { createBalanceSlice, BalanceSlice } from "./slices/balance-slice";
import {
  createStakeTransactionSlice,
  StakeTransactionSlice
} from "./slices/transaction-slices/stake";
import {
  createUnStakeTransactionSlice,
  UnStakeTransactionSlice
} from "./slices/transaction-slices/un-stake";
import {
  ClaimTransactionSlice,
  createClaimTransactionSlice
} from "./slices/transaction-slices/claim";
import {
  createSwapTransactionSlice,
  SwapTransactionSlice
} from "./slices/transaction-slices/swap";
import { createWalletSlice, WalletSlice } from "./slices/wallet-slice";
import {
  createInitialDataSlice,
  InitialDataSlice
} from "./slices/initial-data-slice";
import { mountStoreDevtool } from "simple-zustand-devtools";

type StoreState = BalanceSlice &
  WalletSlice &
  InitialDataSlice &
  StakeTransactionSlice &
  UnStakeTransactionSlice &
  ClaimTransactionSlice &
  SwapTransactionSlice;

export const useBnbStore = createWithEqualityFn<StoreState>()(
  (...a) => ({
    ...createBalanceSlice(...a),
    ...createWalletSlice(...a),
    ...createInitialDataSlice(...a),
    ...createStakeTransactionSlice(...a),
    ...createUnStakeTransactionSlice(...a),
    ...createClaimTransactionSlice(...a),
    ...createSwapTransactionSlice(...a)
  }),
  shallow
);

mountStoreDevtool("bnbStore", useBnbStore);
