"use client";
import React, { useEffect } from "react";
import Link from "next/link";
import { useEthereumStore } from "../store/store";
import LoginOptions from "./login-options";
import { Networks as NetworkDropdown } from "./networks";
import { Icon, displayToast } from "@/components";
import {
  useLocalStorage,
  useWindowSize
} from "@persistenceone/pstake-ui-components";
import { er20ChainIds } from "@/utils";
import { handleMetamask, handleSwitchNetwork } from "../helpers/wallets";
import { getWalletProvider } from "../helpers";
import { ChainInfo } from "../helpers/config";
import { ethChainsInfo } from "../../../helpers/utils";
import {
  META_MASK,
  RANGE_WEEK,
  UNSUPPORTED_NETWORK
} from "../../../constants/static";
import { shallow } from "zustand/shallow";
import { ToastType } from "@/components/molecules/toast/types";
import CustomImage from "@/components/molecules/image";
import { useRouter } from "next/navigation";
import AddToken from "./add-token";
import dynamic from "next/dynamic";
import GeofenceNotice from "@/components/organisms/geofence-banner";
import { useAppStore } from "@/store/store";
import { useShallow } from "zustand/react/shallow";
const TermsModal = dynamic(() => import("@/components/organisms/terms-modal"));

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

const EthereumNavigationBar = () => {
  const router = useRouter();
  const { activeNetwork, setActiveNetwork } = useAppStore(
    useShallow((state) => ({
      activeNetwork: state.activeNetwork,
      setActiveNetwork: state.setActiveNetwork
    }))
  );
  const handleSidebar = useEthereumStore((state) => state.handleSidebar);
  const { isMobile } = useWindowSize();
  const walletInfo = useEthereumStore((state) => state.wallet);
  const [networkItem, setNetworkItem] = useLocalStorage("eth-network", "");
  const { activeEnv } = useAppStore(
    useShallow((state) => ({
      activeEnv: state.activeEnv
    }))
  );
  const fetchInitialData = useEthereumStore((state) => state.fetchInitialData);
  const fetchAllEthBalances = useEthereumStore(
    (state) => state.fetchAllEthBalances
  );
  const fetchAllowances = useEthereumStore((state) => state.fetchAllowances);
  const handleNetworkError = useEthereumStore(
    (state) => state.handleNetworkError
  );
  const handleWalletNetwork = useEthereumStore(
    (state) => state.handleWalletNetwork
  );
  const fetchExchangeRate = useEthereumStore(
    (state) => state.fetchExchangeRate
  );
  const fetchTVU = useEthereumStore((state) => state.fetchTVU);
  const fetchApr = useEthereumStore((state) => state.fetchApr);
  const connectWallet = useEthereumStore((state) => state.connectWallet);

  const fetchExchangeRateList = useEthereumStore(
    (state) => state.fetchExchangeRateList
  );

  const [walletSigner, network] = useEthereumStore(
    (state) => [state.walletSigner, state.network.name],
    shallow
  );

  // set the value from local storage on every reload or refresh
  useEffect(() => {
    if (networkItem !== "" && networkItem !== null && networkItem !== network) {
      handleWalletNetwork(networkItem);
    }
  }, []);

  useEffect(() => {
    const initialFetch = async () => {
      // this will trigger when eth app called from other network
      // and if only calling network not present in localstorage('eth-network') ex: bnb -> arbitrum(if arbitrum not present in 'eth-network')
      if (
        activeNetwork !== "" &&
        activeNetwork !== null &&
        (activeNetwork === "ethereum" ||
          activeNetwork === "optimism" ||
          activeNetwork === "arbitrum") &&
        activeNetwork !== networkItem
      ) {
        handleWalletNetwork(activeNetwork);
        if (networkItem !== "" && networkItem !== null) {
          const switchStatus = await handleSwitchNetwork(activeNetwork);
          if (switchStatus) {
            setNetworkItem(activeNetwork);
          }
        }
      } else if (
        // this will trigger on every refresh if above condition fails
        networkItem !== "" &&
        networkItem !== null &&
        networkItem !== "cosmos" &&
        networkItem !== "bnb smart chain"
      ) {
        console.log("useEffect-else-if", activeNetwork, networkItem);
        handleMetamask(networkItem);
      }
    };
    initialFetch();
  }, []);

  // set active app network on page refresh
  useEffect(() => {
    if (
      activeNetwork === "cosmos" ||
      activeNetwork === "bnb smart chain" ||
      network !== activeNetwork
    ) {
      setActiveNetwork(network);
    }
  }, [network]);

  const handleMenu = () => {
    handleSidebar(true);
  };

  useEffect(() => {
    const fetchCall = async () => {
      fetchInitialData();
      fetchExchangeRateList(RANGE_WEEK);
      fetchApr();
    };
    fetchCall();
  }, []);

  useEffect(() => {
    const fetchCall = async () => {
      if (walletInfo.walletConnection && walletInfo.account !== "") {
        await fetchAllEthBalances(walletInfo.account, activeEnv, network);
        fetchAllowances();
      }
    };
    fetchCall();
  }, [walletSigner, walletInfo.account]);

  // loading exchangeRate before connecting wallet
  useEffect(() => {
    if (network !== null) {
      const fetchInitialInstances = async () => {
        await fetchExchangeRate();
        await fetchTVU();
      };
      fetchInitialInstances();
    }
  }, [network]);

  const handleAccountChange = async (accounts) => {
    const account = accounts[0];
    console.log("accountsChanged-eth", network);
    await handleMetamask(network);
    if (accounts) {
      await fetchAllEthBalances(account, activeEnv, network);
    }
  };

  useEffect(() => {
    if (walletInfo.walletConnection) {
      window.ethereum?.on("accountsChanged", handleAccountChange);
      return () => {
        window.ethereum?.removeListener("accountsChanged", handleAccountChange);
      };
    }
  }, [walletInfo]);

  /* This will trigger whenever chain changes happened through switch network function or manually in extension*/
  const handleChainChange = async (chainId: string) => {
    const metamaskProvider = getWalletProvider("Metamask");
    if (walletInfo.walletConnection) {
      const chainsData: any = ethChainsInfo;
      let networkCheck = false;
      let networkName: any;
      // check for network support
      for (const item in chainsData) {
        const chainInfo: ChainInfo = chainsData[item];
        if (chainId === chainInfo.networkIdHex) {
          networkName = item;
          networkCheck = true;
          break;
        } else {
          networkCheck = false;
        }
      }
      if (!networkCheck) {
        const chainIdList = er20ChainIds[activeEnv];
        const responseChainId = chainIdList.find(
          (item) => item.networkIdHex === chainId
        );
        if (responseChainId) {
          router.push("/bnb?token=BNB");
        } else {
          displayToast(
            {
              heading: UNSUPPORTED_NETWORK,
              message: "Please switch network to Supported Network"
            },
            ToastType.ERROR
          );
          handleNetworkError(true);
        }
      } else {
        await connectWallet(metamaskProvider, META_MASK, networkName);
      }
    }
  };

  useEffect(() => {
    console.log("chainChanged", "chainChanged");
    window.ethereum?.on("chainChanged", handleChainChange);
    return () => {
      window.ethereum?.removeListener("chainChanged", handleChainChange);
    };
  }, [walletInfo.walletConnection]);

  return (
    <div>
      <GeofenceNotice />
      <div className="flex pt-8 mb-12 pl-7 pr-8 md:px-3">
        <div className="flex items-center flex-1">
          <div className="hidden md:block">
            <Link href="/" className="nav-link" passHref>
              <CustomImage
                src={`/logo.svg`}
                alt={"logo"}
                width={isMobile ? 90 : 124}
                height={31}
              />
            </Link>
          </div>
          <div className="flex ml-auto">
            {walletInfo.walletConnection ? (
              <>
                <AddToken />
              </>
            ) : null}
            <NetworkDropdown />
            <LoginOptions />
            <button className="md:block hidden pl-2" onClick={handleMenu}>
              <Icon iconName="menu" viewClass="menu" />
            </button>
          </div>
          <TermsModal
            isWalletConnected={walletInfo.walletConnection}
            network={"eth"}
          />
        </div>
      </div>
    </div>
  );
};

export default EthereumNavigationBar;
