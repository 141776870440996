import { StateCreator } from "zustand";
import React from "react";
import {
  getApr,
  getContractStatus,
  getExchangeRate,
  getExchangeRateList,
  getTVU,
} from "../../helpers/utils";
import {
  RANGE_ALL_TIME,
  RANGE_MONTH,
  RANGE_WEEK,
  RANGE_YEAR,
} from "../../../../constants/static";

export type ExchangeRateType = "eth" | "stkETH";

export interface InitialTvlApyFeeTypes {
  tvl: number | React.ReactNode;
  apy: number | React.ReactNode;
}

export type TimeLine = "week" | "month" | "year" | "all";

export type GraphRange = {
  name: TimeLine;
  value: number;
};

export const graphRanges: GraphRange[] = [
  {
    name: "week",
    value: RANGE_WEEK,
  },
  {
    name: "month",
    value: RANGE_MONTH,
  },
  {
    name: "year",
    value: RANGE_YEAR,
  },
  {
    name: "all",
    value: RANGE_ALL_TIME,
  },
];

export interface InitialDataSliceState {
  apr: number | React.ReactNode;
  tvl: number | React.ReactNode;
  defiList: {
    uniSwap: InitialTvlApyFeeTypes;
  };
  exchangeRate: number | React.ReactNode;
  exchangeRateData: [number | string, number | string][];
  contractStatus: boolean;
  exchangeRateDataLoading: boolean;
  exchangeRateChartOpen: boolean;
  exchangeRateChartType: ExchangeRateType;
}

export interface InitialDataSliceActions {
  fetchInitialData: () => Promise<void>;
  fetchExchangeRate: () => Promise<void>;
  fetchApr: () => Promise<void>;
  fetchExchangeRateList: (range: number) => Promise<void>;
  setExchangeRateListStatus: (value: boolean) => void;
  setExchangeRateLoading: (value: boolean) => void;
  setExchangeRateChartType: (value: ExchangeRateType) => void;
  fetchTVU: () => Promise<void>;
  resetInitialDataSlice: () => void;
  setContractStatus: (value: boolean) => void;
}

const initialState: InitialDataSliceState = {
  apr: 0,
  tvl: 0,
  exchangeRate: 1,
  contractStatus: false,
  exchangeRateData: [],
  defiList: {
    uniSwap: {
      tvl: 0,
      apy: 0,
    },
  },
  exchangeRateDataLoading: false,
  exchangeRateChartOpen: false,
  exchangeRateChartType: "eth",
};

export type InitialDataSlice = InitialDataSliceState & InitialDataSliceActions;

export const createInitialDataSlice: StateCreator<InitialDataSlice> = (
  set
) => ({
  ...initialState,
  fetchInitialData: async () => {
    const contractStatus: boolean = await getContractStatus();
    set({ contractStatus: contractStatus });
  },
  fetchExchangeRate: async () => {
    const response: string = await getExchangeRate();
    set({
      exchangeRate: response,
    });
  },
  fetchApr: async () => {
    const response: any = await getApr();
    set({
      apr: response,
    });
  },
  fetchExchangeRateList: async (range: number) => {
    set({
      exchangeRateDataLoading: true,
    });
    const response: any = await getExchangeRateList(range);
    console.log(response, "response -getExchangeRateList");
    set({
      exchangeRateDataLoading: false,
    });
    set({
      exchangeRateData: response,
    });
  },
  fetchTVU: async () => {
    const response: string = await getTVU();
    set({
      tvl: response,
    });
  },
  setExchangeRateListStatus: (value: boolean) =>
    set(() => ({
      exchangeRateChartOpen: value,
    })),
  setExchangeRateLoading: (value: boolean) =>
    set(() => ({
      exchangeRateDataLoading: value,
    })),
  setExchangeRateChartType: (value: ExchangeRateType) =>
    set(() => ({
      exchangeRateChartType: value,
    })),
  setContractStatus: (value: boolean) =>
    set(() => ({
      contractStatus: value,
    })),
  resetInitialDataSlice: () => {
    set(initialState);
  },
});
