import React, { useEffect, useRef, useState } from "react";
import { Copy, Icon } from "@/components";
import {
  Button,
  Dropdown,
  stringTruncate,
  useOnClickOutside,
  useWindowSize
} from "@persistenceone/pstake-ui-components";
import { handleMetamask } from "../helpers/wallets";
import { META_MASK } from "../../../constants/static";
import { useEthereumStore } from "../store/store";
import CustomImage from "@/components/molecules/image";

const getWalletIcon = (walletName: string) => {
  if (walletName === META_MASK) {
    return "metamask";
  }
  return "metamask";
};

export const LoginOptions = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [walletIcon, setWalletIcon] = useState("metamask");
  const network = useEthereumStore((state) => state.network);

  const walletInfo = useEthereumStore((state) => state.wallet);
  const { isTablet } = useWindowSize();

  const disconnectHandler = async () => {
    sessionStorage.removeItem("eth-terms");
    localStorage.removeItem("eth-network");
    localStorage.removeItem("eth-address");
    window.location.reload();
  };

  useEffect(() => {
    const wallet: string = getWalletIcon(walletInfo.walletName!);
    setWalletIcon(wallet!);
  }, [walletInfo.walletName]);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setDropdownOpen(false);
  });

  const walletHandler = async () => {
    await handleMetamask(network.name);
  };

  return (
    <div className="inline-block w-fit cursor-pointer relative">
      {walletInfo.walletConnection ? (
        <Dropdown
          className="text-white-100 md:hidden"
          closeDropdown={dropdownOpen}
          closeHandler={(value) => setDropdownOpen(value)}
          dropdownLabel={
            <div className="flex items-center">
              <span
                className={`${
                  dropdownOpen ? "pointer-events-none" : "pointer-events-auto"
                }
              flex items-center !text-sm`}
                onClick={() => {
                  setDropdownOpen(true);
                }}
              >
                <CustomImage
                  src={`/wallets/${walletIcon}.svg`}
                  alt={"logo"}
                  width={20}
                  height={20}
                />
                <span className="ml-3 text-green-500">
                  {stringTruncate(walletInfo.account!, isTablet ? 3 : 6)}
                </span>
              </span>
            </div>
          }
          dropDownButtonClass="bg-[#181818] !h-[37px] !text-sm !font-medium text-white-100 leading-[21px] !py-2.5"
          dropdownType={"click"}
          staticBackDrop={false}
          dropDownIcon={
            <Icon
              viewClass="arrow-right fill-[#fff] !w-[10px]"
              iconName="chevron"
            />
          }
          dropDownContentClass="!bg-[#282828] drop-shadow-md round-md w-max py-1 md:p-0"
        >
          <div
            className="px-4 py-2 flex items-center justify-center md:py-3
                        hover:cursor-pointer hover:bg-[#383838] text-black-400 whitespace-nowrap"
          >
            <div className="text-white-100 text-sm font-normal leading-normal md:text-xsm text-center flex-1">
              <Copy
                id={walletInfo.account!}
                customView={
                  <span className={"flex"}>
                    <Icon
                      iconName="copy2"
                      viewClass="disconnect md:!w-[16px] md:!h-[16px] mr-4"
                    />
                    Copy Address
                  </span>
                }
              />
            </div>
          </div>
          <div
            className="px-4 py-2 flex items-center md:py-3
                        hover:cursor-pointer hover:bg-[#383838] text-black-400 whitespace-nowrap"
            onClick={disconnectHandler}
          >
            <Icon
              iconName="logout"
              viewClass="disconnect md:!w-[16px] md:!h-[16px]"
            />
            <span className="ml-4 text-white-100 text-sm font-normal leading-normal md:text-xsm md:ml-2">
              Disconnect
            </span>
          </div>
        </Dropdown>
      ) : (
        <Button
          className={`button !font-medium md:text-sm ${
            dropdownOpen ? "pointer-events-none" : "pointer-events-auto"
          }
              `}
          type="primary"
          size="auto"
          disabled={false}
          content={"Connect Wallet"}
          onClick={walletHandler}
        />
      )}
    </div>
  );
};

export default LoginOptions;
