import React, { useEffect, useState } from "react";
import { NetworkDropdown } from "@/components";
import { useRouter } from "next/navigation";
import { useFeatureFlags } from "@/context/feature-flags-context";
import { getNetworkList } from "@/helpers/utils";
import { useAppStore } from "@/store/store";
import { useShallow } from "zustand/react/shallow";

const Networks = () => {
  const router = useRouter();
  const { useFlag } = useFeatureFlags();
  const isEthEnabled = useFlag("eth_network");
  const isOptimismEnabled = useFlag("optimism_network");
  const isArbitrumEnabled = useFlag("arbitrum_network");
  const [networkList, setNetworkList] = useState<any[]>([]);
  const { setActiveNetwork } = useAppStore(
    useShallow((state) => ({
      setActiveNetwork: state.setActiveNetwork
    }))
  );
  useEffect(() => {
    let supportedNetworks = getNetworkList([
      { network: "Optimism", enable: isOptimismEnabled },
      { network: "Ethereum", enable: isEthEnabled },
      { network: "Arbitrum", enable: isArbitrumEnabled }
    ]);
    setNetworkList(supportedNetworks);
  }, [isOptimismEnabled]);

  const handleNetworkChange = (item) => {
    setActiveNetwork(item.network.toLowerCase());
    router.push(item.link);
  };

  return (
    <>
      {networkList.length > 0 && (
        <NetworkDropdown
          handleDropdown={handleNetworkChange}
          networkName={"Cosmos"}
          networkList={networkList}
        />
      )}
    </>
  );
};

export default Networks;
