import { StateCreator } from "zustand";
import {
  BaseAsset,
  baseAssetList,
  baseAssetList2,
  StkAsset,
  stkAssetList
} from "@/utils";
import { Networks } from "../../helpers/config";
import { BridgeToken } from "../../helpers/types";
import { BigNumber } from "ethers";

export type TransactionNames =
  | "stake"
  | "mintOnOptimism"
  | "transferToOptimism"
  | "transferToAny"
  | "approve"
  | "swap"
  | null;

export type TransactionStatus = "failed" | "success" | null;
const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export interface TransactionSliceActions {
  setStakeTxnModal: (value: boolean) => void;
  setBridgeTxnModal: (value: boolean) => void;
  setBridgeTxnQuote: (value: any) => void;
  setBridgeInputAmount: (value: number | string) => void;
  setStakeNetwork: (value: Networks) => void;
  setStakeInputAmount: (value: number | string) => void;
  setStakeTxnAmount: (value: number | string | BigNumber) => void;
  setStakeTxnFeeLoading: (value: boolean) => void;
  setStakeTxnPayLoad: (value: any) => void;
  setStakeInToken: (value: BaseAsset) => void;
  setStakeInTokenModal: (value: boolean) => void;
  setStakeInfoModal: (value: boolean) => void;
  setStakeMintOptionModal: (value: boolean) => void;
  setStakeOutTokenModal: (value: boolean) => void;
  setStakeOutToken: (value: StkAsset) => void;
  setTxnInfo: (
    inProgress: boolean,
    name: TransactionNames,
    status?: TransactionStatus
  ) => void;
  setBridgeTokenModal: (value: boolean) => void;
  setBridgeDstNetwork: (value: Networks) => void;
  setBridgeTxnToken: (value: BridgeToken) => void;
  setBridgeSrcNetwork: (value: Networks) => void;
  setTxnBroadCast: (broadCast: boolean) => void;
  setSocketQuoteLoading: (value: boolean) => void;
  setBridgeTxnFeeLoading: (value: boolean) => void;
  setBridgeTxnPayLoad: (value: any) => void;
  setSwapInToken: (value: BaseAsset) => void;
  setSwapInTokenModal: (value: boolean) => void;
  setSwapOutToken: (value: BaseAsset) => void;
  setSwapOutTokenModal: (value: boolean) => void;
  setSwapInputAmount: (value: number | string) => void;
  setSwapTxnPayLoad: (value: any) => void;
  setSwapTxnQuote: (value: any) => void;
  setSwapTxnSocketQuoteLoading: (value: boolean) => void;
  resetTxnSlice: () => void;
}

export interface TransactionSliceState {
  stakeTxnInfo: {
    amount: number | string;
    stakeNetwork: Networks;
    modal: boolean;
    stakeInToken: BaseAsset;
    stakeOutToken: StkAsset;
    stakeInTokenModal: boolean;
    stakeOutTokenModal: boolean;
    infoModal: boolean;
    mintOptionModal: boolean;
    feeLoading: boolean;
    payLoad: any;
    stakeAmount: number | string | BigNumber;
  };
  bridgeTxnInfo: {
    amount: number | string;
    modal: boolean;
    socketQuote: any;
    socketQuoteLoading: boolean;
    sourceTokenNetwork: Networks;
    bridgeTokenModal: boolean;
    feeLoading: boolean;
    payLoad: any;
    destinationTokenNetwork: Networks;
    transferToken: BridgeToken;
  };
  swapTxnInfo: {
    swapAmount: number | string;
    swapInToken: BaseAsset;
    swapOutToken: StkAsset;
    swapInTokenModal: boolean;
    swapOutTokenModal: boolean;
    socketQuote: any;
    socketQuoteLoading: boolean;
    payLoad: any;
  };

  transactionInfo: {
    inProgress: boolean;
    name: TransactionNames | null;
    status?: TransactionStatus;
  };
  txnBroadCast: boolean;
}

export type TransactionSlice = TransactionSliceState & TransactionSliceActions;

let initialAsset = baseAssetList[env].find(
  (baseAsset) => baseAsset.name === "ETH"
);

/*TODO: Update hardcoded network to dynamic*/
let initialStakeOutAsset = stkAssetList[env].find(
  (stkAsset) =>
    stkAsset.pair === initialAsset.name && stkAsset.network === "ethereum"
);

let initialSwapAsset = baseAssetList2[env]["ethereum"].find(
  (baseAsset) => baseAsset.name === "ETH" && !baseAsset.ibcAsset
);

let initialOutputSwapAsset = baseAssetList2[env]["ethereum"].find(
  (baseAsset) => baseAsset.name === "stkETH" && !baseAsset.ibcAsset
);

const initialState: TransactionSliceState = {
  stakeTxnInfo: {
    amount: "",
    stakeNetwork: "optimism",
    modal: false,
    stakeInToken: initialAsset,
    stakeOutToken: initialStakeOutAsset,
    stakeInTokenModal: false,
    stakeOutTokenModal: false,
    payLoad: "",
    feeLoading: false,
    infoModal: false,
    mintOptionModal: false,
    stakeAmount: ""
  },
  transactionInfo: {
    inProgress: false,
    name: null,
    status: null
  },
  bridgeTxnInfo: {
    amount: "",
    modal: false,
    socketQuoteLoading: false,
    bridgeTokenModal: false,
    payLoad: "",
    feeLoading: false,
    sourceTokenNetwork: "ethereum",
    destinationTokenNetwork: "arbitrum",
    transferToken: "stkETH",
    socketQuote: null
  },
  swapTxnInfo: {
    swapInToken: initialSwapAsset,
    swapOutToken: initialOutputSwapAsset,
    swapInTokenModal: false,
    swapOutTokenModal: false,
    payLoad: "",
    swapAmount: "",
    socketQuoteLoading: false,
    socketQuote: null
  },
  txnBroadCast: false
};

export const createTransactionSlice: StateCreator<TransactionSlice> = (
  set
) => ({
  ...initialState,
  setStakeTxnModal: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        modal: val
      }
    })),
  setBridgeTxnModal: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        modal: val
      }
    })),
  setBridgeTxnQuote: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        socketQuote: val
      }
    })),
  setStakeInputAmount: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        amount: val
      }
    })),
  setStakeTxnAmount: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        stakeAmount: val
      }
    })),
  setStakeTxnPayLoad: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        payLoad: val
      }
    })),
  setStakeTxnFeeLoading: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        feeLoading: val
      }
    })),
  setStakeInToken: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        stakeInToken: val
      }
    })),
  setStakeInTokenModal: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        stakeInTokenModal: val
      }
    })),
  setStakeOutTokenModal: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        stakeOutTokenModal: val
      }
    })),
  setStakeOutToken: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        stakeOutToken: val
      }
    })),
  setBridgeInputAmount: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        amount: val
      }
    })),
  setStakeNetwork: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        stakeNetwork: val
      }
    })),
  setTxnInfo: (
    inProgress: boolean,
    name: TransactionNames,
    status?: TransactionStatus
  ) =>
    set((state) => ({
      transactionInfo: {
        ...state.transactionInfo,
        inProgress: inProgress,
        name,
        status
      }
    })),
  setTxnBroadCast: (val) =>
    set((state) => ({
      txnBroadCast: val
    })),
  setBridgeDstNetwork: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        destinationTokenNetwork: val
      }
    })),
  setBridgeSrcNetwork: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        sourceTokenNetwork: val
      }
    })),
  setBridgeTxnToken: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        transferToken: val
      }
    })),
  setBridgeTokenModal: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        bridgeTokenModal: val
      }
    })),
  setBridgeTxnPayLoad: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        payLoad: val
      }
    })),
  setBridgeTxnFeeLoading: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        feeLoading: val
      }
    })),
  setStakeInfoModal: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        infoModal: val
      }
    })),
  setStakeMintOptionModal: (val) =>
    set((state) => ({
      stakeTxnInfo: {
        ...state.stakeTxnInfo,
        mintOptionModal: val
      }
    })),
  setSocketQuoteLoading: (val) =>
    set((state) => ({
      bridgeTxnInfo: {
        ...state.bridgeTxnInfo,
        socketQuoteLoading: val
      }
    })),
  setSwapInToken: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        swapInToken: val
      }
    })),
  setSwapInTokenModal: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        swapInTokenModal: val
      }
    })),
  setSwapOutTokenModal: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        swapOutTokenModal: val
      }
    })),
  setSwapOutToken: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        swapOutToken: val
      }
    })),
  setSwapTxnPayLoad: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        payLoad: val
      }
    })),
  setSwapTxnQuote: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        socketQuote: val
      }
    })),
  setSwapInputAmount: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        swapAmount: val
      }
    })),
  setSwapTxnSocketQuoteLoading: (val) =>
    set((state) => ({
      swapTxnInfo: {
        ...state.swapTxnInfo,
        socketQuoteLoading: val
      }
    })),
  resetTxnSlice: () => {
    set(initialState);
  }
});
