import { StateCreator } from "zustand";

export interface SidebarSliceState {
  sidebar: {
    show: boolean;
  };
}

export interface SidebarSliceActions {
  handleSidebar: (value: boolean) => void;
}

export type SidebarSlice = SidebarSliceState & SidebarSliceActions;

const initialState = {
  sidebar: {
    show: false
  }
};

export const createSidebarSlice: StateCreator<SidebarSlice> = (set) => ({
  ...initialState,
  handleSidebar: (val) =>
    set((state) => ({
      sidebar: {
        ...state.sidebar,
        show: val
      }
    }))
});
