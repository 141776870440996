import {
  RANGE_ALL_TIME,
  RANGE_MONTH,
  RANGE_WEEK,
  RANGE_YEAR,
} from "../../constants/static";
import { GraphRange } from "./types";

export const graphRanges: GraphRange[] = [
  {
    name: "week",
    value: RANGE_WEEK,
  },
  {
    name: "month",
    value: RANGE_MONTH,
  },
  {
    name: "year",
    value: RANGE_YEAR,
  },
  {
    name: "all",
    value: RANGE_ALL_TIME,
  },
];
